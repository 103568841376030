import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../../store/contextStore";
import {
  Col,
  Row,
  Form,
  InputNumber,
  notification,
  Select,
  Input,
  Checkbox,
} from "antd";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { noAuthlist } from "../../../api/Apis";
import Advertisement from "../../../common/Advertisement";

const { TextArea } = Input;
class GeneraForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      publicAddress: "",
      city: "",
      state: "",
      zipCode: "",
      clint: "",
      listPrice: "",
      maxPrice: this.props.context.state.buyerHomeDetails.propertyDetails
        ? this.props.context.state.buyerHomeDetails.propertyDetails.listPrice
        : 900000,
      minPrice: this.props.context.state.buyerHomeDetails.propertyDetails
        ? this.props.context.state.buyerHomeDetails.propertyDetails.minPrice
        : 1000,
      citySearch: "",
      usersTypeData: [],
      newIntrestedTown: [],
      allUsersList: [],
      showOnBuyersPage: false,
      matchingProfile: false,
    };
  }
  componentDidMount = async () => {
    const { buyerHomeDetails } = this.props;

    if (
      buyerHomeDetails &&
      buyerHomeDetails.buyerPropertyDetails &&
      buyerHomeDetails.buyerPropertyDetails.stateTypeAdded &&
      buyerHomeDetails.buyerPropertyDetails.stateTypeAdded
    ) {
      this.setState({
        newIntrestedTown:
          buyerHomeDetails.buyerPropertyDetails.updatedInterestedTowns,
        maxPrice:
          buyerHomeDetails && buyerHomeDetails.buyerPropertyDetails.maxprice,
        minPrice:
          buyerHomeDetails && buyerHomeDetails.buyerPropertyDetails.minprice,
        referedAgentName:
          buyerHomeDetails &&
          buyerHomeDetails.buyerPropertyDetails.referedAgentName,
      });
    }
    if (
      buyerHomeDetails &&
      buyerHomeDetails.buyerPropertyDetails2 &&
      "showOnBuyersPage" in buyerHomeDetails.buyerPropertyDetails2
    ) {
      await this.setState({
        showOnBuyersPage:
          buyerHomeDetails.buyerPropertyDetails2 &&
          "showOnBuyersPage" in buyerHomeDetails.buyerPropertyDetails2 &&
          !buyerHomeDetails.buyerPropertyDetails2.showOnBuyersPage,
      });
    }
    if (
      buyerHomeDetails &&
      buyerHomeDetails.buyerPropertyDetails2 &&
      buyerHomeDetails.buyerPropertyDetails2.matchingProfile === true
    ) {
      this.setState({
        matchingProfile:
          buyerHomeDetails.buyerPropertyDetails2 &&
          buyerHomeDetails.buyerPropertyDetails2.matchingProfile &&
          buyerHomeDetails.buyerPropertyDetails2.matchingProfile,
      });
    }
    if (
      buyerHomeDetails &&
      buyerHomeDetails.buyerPropertyDetails2 &&
      buyerHomeDetails.buyerPropertyDetails2.matchingProfile
    ) {
      this.setState({
        matchingProfile:
          buyerHomeDetails.buyerPropertyDetails2 &&
          buyerHomeDetails.buyerPropertyDetails2.matchingProfile &&
          buyerHomeDetails.buyerPropertyDetails2.matchingProfile,
      });
    }
    this.getAllUsers();
  };
  getAllUsers = async () => {
    try {
      const usersList = await noAuthlist("getUsers?userProfile=AG");
      let finalUsersList = usersList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
        };
      });
      if (usersList.success) {
        this.setState({
          allUsersList: finalUsersList,
        });
      } else {
        notification["error"]({
          message: usersList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };
  handlePrice = (e) => {
    this.setState({
      maxPrice: e[1],
      minPrice: e[0],
      listPrice: e[1],
    });
  };

  onChangeMin = (value) => {
    if (this.state.maxPrice > value) {
      this.setState({ minPrice: value });
    }
  };

  onChangeMax = (value) => {
    if (this.state.minPrice < value) {
      this.setState({ maxPrice: value, listPrice: value });
    }
  };

  handleTimeFrame = (value, dateFormate) => {
    this.setState({
      startTime: dateFormate[0],
      endTime: dateFormate[1],
    });
  };
  onReferedBySelect = (value, option) => {
    this.setState({
      referedAgentName: option.label,
    });
  };
  onFinish = (e) => {
    e["sellerInterestedTown"] = [""];
    e["updatedInterestedTowns"] = this.state.newIntrestedTown;
    e["stateTypeAdded"] = true;
    e["referedAgentName"] = this.state.referedAgentName;
    e["minprice"] = this.state.minPrice;
    e["maxprice"] = this.state.maxPrice;
    // this.props.context.setBuyerHomeDetails(e, );
    // this.props.handleBuyerDetails(e);
    const data = {
      title: e.title,
      description: e.description,
      matchingProfile: this.state.matchingProfile,
      showOnBuyersPage: !this.state.showOnBuyersPage,
    };
    this.props.handleForm(e, "ADD_BUYER_GENERAL_FORM");
    this.props.handleForm(data, "BUYER_PROPERTY_DETAILS2");
  };

  handleListPrice = (e) => {
    this.setState({
      listPrice: e,
    });
  };

  handleSingleSearch = (address) => {
    let cityName = "";
    let stateName = "";
    let citiesList2 =
      this.state.newIntrestedTown && this.state.newIntrestedTown.length > 0
        ? this.state.newIntrestedTown
        : [];
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityName = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateName = address[i].short_name;
          }
        }
        const stateCodes = JSON.parse(
          localStorage.getItem("stateCodesFromAdmin")
        );
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateName.toLowerCase() ||
            restState.stateType.toLowerCase() === stateName.toLowerCase()
        );

        if (setVendor && setVendor.length > 0) {
          let newSearchLocation = {
            cityName: cityName,
            stateName: stateName,
            type: "stateName",
          };
          if (newSearchLocation) {
            citiesList2.push(newSearchLocation);
          }
          this.setState({
            newIntrestedTown: citiesList2,
          });
        } else {
          notification["error"]({
            message: "Please enter valid city...",
            description: "You can search cities from MA, CT, RI, NH states",
            placement: "topLeft",
          });
        }
      })
      .catch((error) => console.error("Error", error));
  };

  changeCity = (e) => {
    this.setState({
      city: e,
    });
  };

  onClose = (value, stateRemove) => {
    const data =
      this.state.newIntrestedTown &&
      this.state.newIntrestedTown.filter(
        (city) => city.cityName !== value && city.stateName !== stateRemove
      );
    this.setState({
      newIntrestedTown: data,
    });
  };
  handleToshowOnBuyerspage = (e) => {
    this.setState({
      showOnBuyersPage: e.target.checked,
    });
  };
  handleToMatchingProfile = (e) => {
    this.setState({
      matchingProfile: e.target.checked,
    });
  };
  render() {
    const { minPrice, maxPrice, listPrice } = this.state;
    const { buyerHomeDetails } = this.props;
    const timeFrame = [
      "1-4 months",
      "4-6 months",
      "6-12 months",
      "12-16 months",
      "16+ months",
    ];
    return (
      <div>
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <div className="privateD-title"></div>
          </Col>
          <Col
            xl={{ span: 16, offset: 0 }}
            lg={{ span: 16, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Form
              id="form"
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              size="large"
            >
              <Row>
                <Col
                  xl={{ span: 11, offset: 0 }}
                  lg={{ span: 11, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <div className="form-titles">
                    Title (if you list this buyer profile as public, this is a
                    short title that sellers or agents will see)
                  </div>
                  <Form.Item
                    name="title"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyDetails &&
                      buyerHomeDetails.buyerPropertyDetails.title
                    }
                    rules={[
                      { required: true, message: "Please input your title!" },
                    ]}
                  >
                    <Input
                      placeholder="Eg. New family looking for bigger home"
                      className="form-input"
                    />
                  </Form.Item>
                  <div className="form-titles">
                    Desired Time Frame for Moving
                  </div>
                  <Form.Item
                    name="timeFrame"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyDetails &&
                      buyerHomeDetails.buyerPropertyDetails.timeFrame
                    }
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select Desired Time Frame"
                    >
                      {timeFrame.map((province) => (
                        <Select.Option key={province} value={province}>
                          {province}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="form-titles">
                    Towns of Interest (can select multiple)
                  </div>
                  <Form.Item
                    name="sellerInterestedTown"
                    className="google-form-search-add-home"
                  >
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_MAPS_KEY}
                      autocompletionRequest={{
                        types: ["(cities)"],
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      className=" css-1uccc91-singleValue"
                      selectProps={{
                        placeholder: "Search for city",
                        value: "",
                        onChange: (result) => {
                          this.handleSingleSearch(result);
                        },
                      }}
                    ></GooglePlacesAutocomplete>

                    {this.state.newIntrestedTown &&
                      this.state.newIntrestedTown.map((city, index) => (
                        <div
                          key={index}
                          style={{
                            display: "inline-flex",
                            margin: "0.73vw",
                            padding: "0 7px",
                            flexWrap: "wrap",
                            justifyContent: "space-around",
                            border: "1px solid darkgrey",
                            marginLeft: "0px",
                          }}
                        >
                          {city.cityName && (
                            <>
                              <div>
                                {city.cityName}, {city.stateName}
                              </div>
                              <div
                                style={{
                                  color: "grey",
                                  cursor: "pointer",
                                  marginLeft: "0.4vw",
                                }}
                                onClick={() =>
                                  this.onClose(city.cityName, city.stateName)
                                }
                              >
                                x
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                  </Form.Item>
                  <div className="label-titles">
                    Agent (select an agent to represent your interests){" "}
                  </div>
                  <Form.Item
                    name="referedAgentName"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyDetails &&
                      buyerHomeDetails.buyerPropertyDetails.referedAgentName
                        ? buyerHomeDetails.buyerPropertyDetails.referedAgentName
                        : ""
                    }
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      options={this.state.allUsersList}
                      onSelect={this.onReferedBySelect}
                      placeholder="Select an agent"
                      filterOption={(inputValue, option) =>
                        option.label
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    ></Select>
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <Row style={{ marginTop: "2vw" }}>
                    <Col span={6} className="style-price">
                      <div
                        style={{
                          fontSize: 15,
                          fontWeight: 600,
                          fontFamily: "Poppins,sans-serif",
                        }}
                      >
                        Min Price
                      </div>

                      <InputNumber
                        formatter={(value) =>
                          ` $ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        className="form-input-for-media1"
                        value={`${minPrice}`}
                        onChange={this.onChangeMin}
                      />
                    </Col>
                    <Col span={10} offset={2}>
                      <div
                        style={{
                          fontSize: 15,
                          marginLeft: "4vw",
                          fontWeight: 600,
                          fontFamily: "Poppins,sans-serif",
                        }}
                      >
                        Max Price
                      </div>
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        value={`${listPrice ? listPrice : maxPrice}`}
                        onChange={this.onChangeMax}
                        className="form-input-for-media2"
                      />
                    </Col>
                  </Row>

                  <div className="form-titles">
                    Description (short description of your home needs)
                  </div>
                  <Form.Item
                    name="description"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPropertyDetails &&
                      buyerHomeDetails.buyerPropertyDetails.description
                    }
                  >
                    <TextArea
                      placeholder="Start typing"
                      rows={4}
                      name="description"
                      style={{ borderRadius: "5px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="showOnBuyersPage"
                    // valuePropName="checked"
                    // initialValue={
                    //   buyerHomeDetails.buyerPropertyDetails &&
                    //   buyerHomeDetails.buyerPropertyDetails.visibleOnBuyersList
                    // }
                  >
                    <Checkbox
                      onChange={this.handleToshowOnBuyerspage}
                      checked={this.state.showOnBuyersPage}
                    >
                      <span className="form-titles">
                        Mark as Private (if private, this profile will not be
                        visible on buyers list page)
                      </span>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="matchingProfile"
                    // valuePropName="checked"
                    // initialValue={
                    //   buyerHomeDetails.buyerPropertyDetails &&
                    //   buyerHomeDetails.buyerPropertyDetails.matchingProfile
                    // }
                  >
                    <Checkbox
                      onChange={this.handleToMatchingProfile}
                      checked={this.state.matchingProfile}
                    >
                      <span className="form-titles">
                        Send me alerts when new on and off MLS properties hit
                        the market matching this profile
                      </span>
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xl={{ span: 7, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Advertisement
              otherAds={false}
              adType="Square"
              adWhereToShow="Buyers"
              adLimit="1"
              zipCode=""
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withAppContext(withRouter(GeneraForm));
