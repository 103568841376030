import React, { Component } from "react";
import { Card, Row, Col, Divider, Collapse, Button, Menu } from "antd";
import tracyPic from "../../images/tracy1.jpeg";
// import kristinPic from "../../images/kristin.jpeg";
import SendMessage from "../../common/SendMessage";
import Loading from "../../common/Loading";

const Panel = Collapse.Panel;
export default class WhyJoinUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItemKey: 1,
      initialLoading: true,
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        initialLoading: false,
      });
    }, 2000);
  };

  handleMenuItems = (e) => {
    if (e.key) {
      this.setState({
        activeItemKey: e.key,
      });
    }
  };

  render() {
    const { activeItemKey } = this.state;
    return (
      <div>
        <Loading enableLoading={this.state.initialLoading} />
        <Row>
          <Col span={20} offset={2}>
            <Divider>
              <h1>Why Join Us</h1>
            </Divider>
          </Col>
        </Row>

        <Row>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Card
              bordered
              title={null}
              bodyStyle={{
                padding: "0px",
              }}
              className="padding-card-whyjoin"
            >
              <Row>
                <Menu
                  mode={
                    window.screen.width >= "501" ? "horizontal" : "vertical"
                  }
                  defaultSelectedKeys={["1"]}
                  onClick={this.handleMenuItems}
                  style={{ width: "100%" }}
                >
                  <Menu.Item key="1">Buyers & Sellers</Menu.Item>
                  <Menu.Item key="2">Agents</Menu.Item>
                  <Menu.Item key="3">Lenders</Menu.Item>
                  <Menu.Item key="4">Vendors</Menu.Item>
                </Menu>
                {activeItemKey === 1 && (
                  <>
                    <p
                      style={{
                        textAlign: "justify",
                        lineHeight: 2,
                        fontSize: "16px",
                        letterSpacing: "0.5px",
                        marginTop: "1vw",
                      }}
                    >
                      <p>
                        If you are a buyer and/or seller you can create a free
                        account and search for homes, save favorites, get daily
                        alerts when new properties hit the market, list your
                        home or investment property for private sale, search
                        buyer profiles that could be a match for what you are
                        looking to sell, and much more. As a buyer you will also
                        be able to see exactly who the listing agent is and
                        always know who you are contacting and will be contacted
                        by. You contact information will never be sold to
                        multiple agents.{" "}
                      </p>

                      <p>
                        That's my Town offers a new and more transparent way
                        to buy and sell homes. It offers everyone equal
                        opportunity to create profiles and let’s you the
                        consumer decide when you would like to speak to an
                        agent. That's my Town also offers more selling
                        solutions for sellers who want to sell in a different
                        fashion than just listing on MLS and telling the entire
                        world their property is for sale when you may have a
                        specific type of buyer in mind. We know and understand
                        that some sellers demand more privacy and other ways of
                        selling beyond the usual and normal ways everyone has
                        had to become accustomed to, until now. Its time for a
                        different home buying and selling experience!{" "}
                        <a href="/register">Click here</a> to join now for FREE!
                      </p>
                    </p>

                    <Row className="width-whyjoinus">
                      <Col
                        span={24}
                        style={{
                          marginTop: "20px",
                          marginBottom: "20px",
                          backgroundColor: "#fafafa",
                        }}
                      >
                        <Collapse bordered={false} defaultActiveKey={["1"]}>
                          <Panel
                            header="Learn More Now"
                            key="1"
                            className="customPanelStyle"
                          >
                            <div class="video-container">
                              <iframe
                                src="https://www.youtube.com/embed/wDTRXKrwqAc?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=http%3A%2F%2Fthatsmytown.com&amp;widgetid=1"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                title="video"
                              ></iframe>
                            </div>
                          </Panel>
                        </Collapse>
                      </Col>
                    </Row>
                  </>
                )}
                {activeItemKey === "2" && (
                  <>
                    <p
                      style={{
                        textAlign: "justify",
                        lineHeight: 2,
                        fontSize: "16px",
                        letterSpacing: "0.5px",
                        marginTop: "1vw",
                      }}
                    >
                      <p>
                        That's my Town is an incredible new buyer and seller
                        search engine that believes in the future of real estate
                        agents nationwide. We believe consumers want a better
                        home buying and selling experience and they want more
                        transparency of information. We also believe that, by
                        building a nationwide home search engine powered by, and
                        owned by, real estate agents, that the future of our
                        profession will be far brighter than it may look today.
                      </p>

                      <p>
                        The future remains bright for our profession if we work
                        together to ensure we remain relevant in an ever
                        changing industry where technology has drawn the
                        consumer away from agent owned search engines to
                        investor owned tech giants. Until now real estate agents
                        have had little other choice in where they advertise
                        other than the major home search sites that are only in
                        the business of massive profits, and are certainly not
                        in business to protect the future of the real estate
                        agent. These search giants all take your listings and
                        sell leads on your listings to as many agents as
                        possible. This has created many frustrations for both
                        the consumer and the agent alike. Its time for a better
                        national home search engine. One controlled by real
                        estate agents and owned by real estate agents. When you
                        create a free account with That's my Town you will
                        always get the leads on your listings unless the
                        consumer decides they want a buyers agent. When you
                        upgrade your account you will begin to unlock many more
                        potential streams of leads and exposure. You will open
                        up the ability to be seen and found on the featured
                        agent page based on the zip codes included in the plan
                        you choose. You will also unlock buyer agent leads based
                        on the zip codes allowed in the subscription plan you
                        choose. When you move from the basic plan to the
                        advanced plan you will open up more visibility as well
                        as the opportunity to be referred clients who requested
                        information on a listing and the listing agent did not
                        respond. These referrals will be matched with an
                        advanced or professional plan member who match with the
                        zip codes chosen in their plan. All lead referrals will
                        be subject to a 25% referral fee. The professional plan
                        then unlocks a world of potential that could literally
                        change your life. As a professional plan member you not
                        only unlock the most visibility possible on the site but
                        you also unlock unlimited potential to earn revenue
                        share and stock options for your efforts in helping us
                        grow nationwide. For every paying agent or marketplace
                        vendor you introduce to the system you will earn revenue
                        share off their subscriptions and banner ad spend. You
                        will also be able to participate in performance and
                        influencer based stock bonus awards for your part in
                        spear heading our growth. If you are a broker and
                        interested in learning about how to give your entire
                        office upgraded accounts as part of an office benefit
                        offering package, and/or are interested in banner ads
                        contact Kristin Barber at kristin@thatsmytown.com
                      </p>
                    </p>
                    <Row className="width-whyjoinus">
                      <Col
                        span={24}
                        style={{
                          marginTop: "20px",
                          marginBottom: "20px",
                          backgroundColor: "#fafafa",
                        }}
                      >
                        <Collapse
                          accordion
                          bordered={false}
                          defaultActiveKey={["1"]}
                        >
                          <Panel
                            header="Free Account"
                            key="1"
                            className="customPanelStyle"
                          >
                            <div class="video-container">
                              <iframe
                                src="https://www.youtube.com/embed/by27XkXbzbo?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=http%3A%2F%2Fthatsmytown.com&amp;widgetid=1"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                title="freeAccount"
                              ></iframe>
                            </div>
                          </Panel>
                          <Panel
                            header="Basic Account"
                            key="2"
                            className="customPanelStyle"
                          >
                            <div class="video-container">
                              <iframe
                                src="https://www.youtube.com/embed/1qQiPShNep8?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=http%3A%2F%2Fthatsmytown.com&amp;widgetid=1"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                title="basicAccount"
                              ></iframe>
                            </div>
                          </Panel>
                          <Panel
                            header="Advanced Account"
                            key="3"
                            className="customPanelStyle"
                          >
                            <div class="video-container">
                              <iframe
                                src="https://www.youtube.com/embed/p5PM08M9eBE?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=http%3A%2F%2Fthatsmytown.com&amp;widgetid=1"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                title="advancedAccount"
                              ></iframe>
                            </div>
                          </Panel>
                          <Panel
                            header="Professional Account"
                            key="4"
                            className="customPanelStyle"
                          >
                            <div class="video-container">
                              <iframe
                                src="https://www.youtube.com/embed/DMVgFTE1Fqg?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=http%3A%2F%2Fthatsmytown.com&amp;widgetid=1"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                title="professionalAccount"
                              ></iframe>
                            </div>
                          </Panel>
                        </Collapse>
                      </Col>
                    </Row>
                  </>
                )}
                {activeItemKey === "3" && (
                  <>
                    <p
                      style={{
                        textAlign: "justify",
                        lineHeight: 2,
                        fontSize: "16px",
                        letterSpacing: "0.5px",
                        marginTop: "1vw",
                      }}
                    >
                      If you are a lender you understand that one of the most
                      important things you can do for your business is to be
                      visible where the consumers are searching for homes and be
                      even closer to where the producing agents are spending
                      their time. By subscribing to a lender plan, as an upgrade
                      to a vendor plan, you will unlock even more potential by
                      becoming visible on the featured lenders page when
                      consumers search by zip code and the “Get Pre approved
                      Now” link on every listing detail page. The higher the
                      plan you choose the more opportunities you will have to be
                      found and seen. Lenders can also then add banner ads
                      located throughout the site to further your exposure that
                      are driven by the location of the consumer. Contact
                      Kristin Barber at kristin@thatsmytown.com for more
                      information on banner ads and/or if you are a lender with
                      multiple loan offices you would like quantity based
                      subscription pricing for.
                    </p>
                    <Row className="width-whyjoinus">
                      <Col
                        span={24}
                        style={{
                          marginTop: "20px",
                          marginBottom: "20px",
                          backgroundColor: "#fafafa",
                        }}
                      >
                        <Collapse bordered={false} defaultActiveKey={["1"]}>
                          <Panel
                            header="Learn More Now"
                            key="1"
                            className="customPanelStyle"
                          >
                            <div class="video-container">
                              <iframe
                                src="https://www.youtube.com/embed/v7mT7h6ilvg?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=http%3A%2F%2Fthatsmytown.com&amp;widgetid=1"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                title="customPanel"
                              ></iframe>
                            </div>
                          </Panel>
                        </Collapse>
                      </Col>
                    </Row>
                  </>
                )}
                {activeItemKey === "4" && (
                  <>
                    <p
                      style={{
                        textAlign: "justify",
                        lineHeight: 2,
                        fontSize: "16px",
                        letterSpacing: "0.5px",
                        marginTop: "1vw",
                      }}
                    >
                      Are you an industry professional who owns a business that
                      works in and around real estate purchases, sales, and home
                      maintenance/repair? Create a vendor account and you will
                      be able to be found on the marketplace page where
                      consumers and agents will be able to find you and seek out
                      your services. If you believe in being seen where
                      consumers and agents are doing business then signing up
                      for a vendor account is a smart business decision. The
                      “Premier Partner” subscription will allow you to stand out
                      among your competition and be seen when someone is
                      searching your profession in your selected zip codes based
                      on your subscription plan level. Contact Kristin Barber at
                      kristin@thatsmytown.com for more information on banner
                      ads and other site wide advertising opportunities.
                    </p>
                    <Row className="width-whyjoinus">
                      <Col
                        span={24}
                        style={{
                          marginTop: "20px",
                          marginBottom: "20px",
                          backgroundColor: "#fafafa",
                        }}
                      >
                        <Collapse bordered={false} defaultActiveKey={["1"]}>
                          <Panel
                            header="Learn More Now"
                            key="1"
                            className="customPanelStyle"
                          >
                            <div class="video-container">
                              <iframe
                                src="https://www.youtube.com/embed/GgohSILNp_8?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=http%3A%2F%2Fthatsmytown.com&amp;widgetid=1"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                title="learnMore"
                              ></iframe>
                            </div>
                          </Panel>
                        </Collapse>
                      </Col>
                    </Row>
                  </>
                )}
              </Row>

              <Row style={{ float: "right", padding: "10px" }}>
                <Button
                  onClick={() => this.props.history.push(`/register`)}
                  type="primary"
                  danger
                  className="footer-button"
                  size="large"
                >
                  <span className="footer-buttonText">JOIN FOR FREE</span>
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Row style={{ marginTop: "10px", marginBottom: "-10px" }}>
              <h2>Contact us anytime for questions, suggestions, etc:</h2>
            </Row>

            <Row gutter={16}>
              <Col
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                className="card-description1"
              >
                <Card
                  hoverable
                  bordered={false}
                  title={null}
                  bodyStyle={{ padding: "0px" }}
                  className="padding-card1-whyjoinUs"
                  style={{
                    background: "#FFF",
                    borderColor: "black",
                    marginTop: "1vw",
                  }}
                >
                  <Row>
                    <Col
                      xl={{ span: 8, offset: 0 }}
                      lg={{ span: 8, offset: 0 }}
                      md={{ span: 8, offset: 0 }}
                      sm={{ span: 22, offset: 1 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <img
                        alt="Tracy"
                        src={tracyPic}
                        style={{
                          boxShadow: "0px 0px 10px 0px",
                          border: "6px solid rgb(28, 45, 65)",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Col>
                    <Col
                      xl={{ span: 14, offset: 2 }}
                      lg={{ span: 14, offset: 2 }}
                      md={{ span: 14, offset: 2 }}
                      sm={{ span: 22, offset: 1 }}
                      xs={{ span: 22, offset: 1 }}
                      className="card-description"
                    >
                      <h3 style={{ fontWeight: "bold" }}>
                        Tracy Barber, Founder
                      </h3>
                      <h3>That's My Town, Inc.</h3>
                      <h3>14 Linda Drive, Westminster, MA 01473</h3>
                      <h3>Email : trbarber10@msn.com</h3>
                      <h3>Phone : (978) 400-1617</h3>
                      <SendMessage
                        toName={"Tracy Barber"}
                        msgButtonTitle={"Contact Me"}
                        buttonAlign="Contact-Me"
                        mlsId={false}
                        agentEmail="trbarber10@msn.com"
                        messageType=""
                        propertyTitle=" That's myTown"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              {/* <Col
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                className="card-description1"
              >
                <Card
                  hoverable
                  bordered={false}
                  title={null}
                  bodyStyle={{ padding: "0px" }}
                  className="padding-card1-whyjoin"
                  style={{
                    background: "#FFF",
                    // padding: "30px",
                    borderColor: "black",
                    marginTop: "1vw",
                  }}
                >
                  <Row>
                    <Col
                      xl={{ span: 8, offset: 0 }}
                      lg={{ span: 8, offset: 0 }}
                      md={{ span: 8, offset: 0 }}
                      sm={{ span: 22, offset: 1 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <img
                        alt="Kristin"
                        src={kristinPic}
                        style={{
                          boxShadow: "0px 0px 10px 0px",
                          border: "6px solid rgb(28, 45, 65)",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Col>
                    <Col
                      xl={{ span: 14, offset: 2 }}
                      lg={{ span: 14, offset: 2 }}
                      md={{ span: 14, offset: 2 }}
                      sm={{ span: 22, offset: 1 }}
                      xs={{ span: 22, offset: 1 }}
                      className="card-description"
                    >
                      <h3 style={{ fontWeight: "bold" }}>
                        Kristin Barber, Marketing Director
                      </h3>
                      <h3>That's My Town, Inc.</h3>
                      <h3>14 Linda Drive, Westminster, MA 01473</h3>
                      <h3>Email : kristin@thatsmytown.com</h3>
                      <h3>Phone : (978) 407-4070</h3>
                      <SendMessage
                        toName={"Kristin Barber"}
                        msgButtonTitle={"Contact Me"}
                        buttonAlign="Contact-Me"
                        mlsId={false}
                        agentEmail="kristin@thatsmytown.com"
                        messageType=""
                        propertyTitle=" That's myTown"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
