import { useEffect, useState } from "react";
import { Col, Input, Modal, Row, Select } from "antd";
import { Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { noAuthadd, noAuthlist } from "../api/Apis";
import Loading from "../common/Loading";
const { Option } = Select;

function SigninWithGoogle({ getLoginData, buttonText }) {
  const [isSelectedUserType, setisSelectedUserType] = useState({
    isOpen: false,
    isType: "B",
  });
  const [isLoadingPage, setIsLoadingpage] = useState(false)
  const [isProvideDetails, setDetails] = useState({ isOpenTrue: false, firstName: "", lastName: "", userData: {} })

  useEffect(() => {
    const ga =
      window.gapi && window.gapi.auth2
        ? window.gapi.auth2.getAuthInstance()
        : null;

    if (!ga) createScript();

    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          if (data.username.indexOf("Google") > -1) {
            handleRegisterUser(data);
          }
          break;
        case "signOut":
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          console.log("Default");
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegisterUser = () => {
    setIsLoadingpage(true)
    Auth.currentAuthenticatedUser()
      .then((details) => {
        if (details) {
          noAuthlist("getUsers").then((res) => {
            let loginId = details?.signInUserSession?.idToken?.payload?.sub
            let userCred = res.data.filter((us) => us.id === loginId);
            if (userCred.length === 0) {
              setDetails({ ...isProvideDetails, isOpenTrue: true, userData: details })
            } else {
              // alert("You have alredy Registered with this email. Please continue.")
              getLoginData(loginId);
            }
          })

        }
      })
      .catch(() => console.log("Not signed in"));
  };

  const handleClickble = () => {
    if (buttonText === "Sign Up") {
      setisSelectedUserType({ ...isSelectedUserType, isOpen: true });
    } else {
      handleToContinueSignUp();
      // signIn();
    }
  };

  // const signIn = () => {
  //   window.gapi.load("client:auth2", () => {
  //     window.gapi.client.init({
  //       clientId:
  //         "31545956627-4qq47kn6ro0h3g4mq587nmf6egso2dp3.apps.googleusercontent.com",
  //       scope: "profile email openid",
  //     });
  //   });

  //   setisSelectedUserType({ ...isSelectedUserType, isOpen: false });

  //   const ga = window.gapi.auth2.getAuthInstance();
  //   ga.signIn().then(
  //     (googleUser) => {
  //       console.log(googleUser, "user connected");
  //       const d = googleUser.getAuthResponse();
  //       const profile = googleUser.getBasicProfile();
  //       let user = {
  //         email: profile.getEmail(),
  //         name: profile.getName(),
  //       };
  //       const federatedInfo = Cache.getItem('federatedInfo');
  //       // const { token } = federatedInfo;
  //       console.log(d, "details", user, "---><", federatedInfo);
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // };

  const createScript = () => {
    // load the Google SDK
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.async = true;
    script.onload = initGapi;
    document.body.appendChild(script);
  };

  const initGapi = () => {
    console.log("initGapi");
    // init the Google SDK client
    const g = window.gapi;
    g.load("auth2", function () {
      g.auth2.init({
        client_id:
          "31545956627-4qq47kn6ro0h3g4mq587nmf6egso2dp3.apps.googleusercontent.com",
        // authorized scopes
        scope: "profile email openid",
      });
      console.log(g.auth2, "a");
    });
  };

  const handleToSaveProfile = () => {
    setIsLoadingpage(true)
    const { userData } = isProvideDetails;
    let selectedProfile = JSON.parse(localStorage.getItem("signupUserSelectedProfile"))
    let payload = {
      entityData: {
        id: userData?.signInUserSession?.idToken?.payload?.sub,
        firstName: isProvideDetails.firstName,
        lastName: isProvideDetails.lastName,
        userProfile: selectedProfile,
        email: userData?.signInUserSession?.idToken?.payload?.email,
        googleCreatedUserName: userData.username,
        googleAppUserId:
          userData?.signInUserSession?.idToken?.payload?.identities[0]["userId"],
        agentInfo: {
          agentEmail: "",
          agentName: "",
        },
        addUserName: "",
        recordStatus: "",
      },
    };

    noAuthadd("users", payload).then((userSuccess) => {
      if (userSuccess.success) {
        setIsLoadingpage(false);
        getLoginData(payload.entityData.id);
      }
    });
  }

  const handleToContinueSignUp = () => {
    setIsLoadingpage(true)
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    })
      .then((res) => {
        console.log(res, "lll--->");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Loading enableLoading={isLoadingPage} />

      <button
        onClick={handleClickble}
        className="loginBtn loginBtn--google"
      // style={{ cursor: "not-allowed" }}
      >
        {" "}
        {buttonText} With Google{" "}
      </button>


      {isSelectedUserType.isOpen && (
        <Modal
          className="modal-black-close"
          visible={isSelectedUserType.isOpen}
          style={{ marginTop: "5%" }}
          onCancel={() =>
            setisSelectedUserType({ ...isSelectedUserType, isOpen: false })
          }
          // footer={null}
          title="SELECT PROFILE TYPE"
          okText={"Submit"}
          onOk={handleToContinueSignUp}
        >
          <Select
            style={{
              width: "100%",
            }}
            placeholder="I am "
            onChange={(e) => localStorage.setItem("signupUserSelectedProfile",JSON.stringify(e))}
            className="select_height"
          >
            <Option value="AG">Agent</Option>
            <Option value="B">Buyer</Option>
            <Option value="S">Seller</Option>
            <Option value="L">Lender</Option>
            <Option value="V">Vendor</Option>
          </Select>
        </Modal>
      )}

      {isProvideDetails.isOpenTrue &&
        <Modal
          className="modal-black-close"
          visible={isProvideDetails.isOpenTrue}
          style={{ marginTop: "5%" }}
          onCancel={() =>
            setDetails({ ...isProvideDetails, isOpenTrue: false })
          }
          // footer={null}
          title="Please Provide Details"
          okText={"Submit"}
          onOk={handleToSaveProfile}
          width={"554px"}
        >
          <Row>
            <Col>
              <p style={{ padding: "10px" }}>Please enter your first and last name to proceed. </p>
            </Col>
            <Col span={11}>
              <div className="label-titles">First Name</div>

              <Input placeholder="First Name" onChange={(e) => setDetails({ ...isProvideDetails, firstName: e.target.value })} />
            </Col>
            <Col span={11} offset={1}>
              <div className="label-titles">Last Name</div>
              <Input placeholder="Last Name" onChange={(e) => setDetails({ ...isProvideDetails, lastName: e.target.value })} />
            </Col>
          </Row>

        </Modal>}
    </div>
  );
}
export default SigninWithGoogle;
