import React, { Component } from "react";
import { Row, Col } from "antd";
import profilePic from "../../images/profileAvatar.png";
import SendMessage from "../../common/SendMessage";
import { Link } from "react-router-dom";
export default class BuyerAgentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentInfo: "",
      propertyTitle: "",
      propertyImages: "",
      propertyId: "",
    };
  }

  componentDidMount = () => {
    if (this.props && this.props.agentInfo && this.props.agentInfo) {
      this.setState({
        agentInfo: this.props.agentInfo,
      });
    }

    if (this.props && this.props.propertyId) {
      this.setState({
        propertyId: this.props.propertyId,
      });
    }

    if (this.props && this.props.propertyTitle) {
      this.setState({
        propertyTitle: this.props.propertyTitle,
      });
    }

    if (
      this.props &&
      this.props.propertyImages &&
      this.props.propertyImages.length > 0
    ) {
      this.setState({
        propertyImages: this.props.propertyImages[0],
      });
    }
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleSpecialities = () => {
    const { agentInfo } = this.state;

    let agentsSpecialtiesList = [];

    if (
      agentInfo &&
      agentInfo.newdata &&
      agentInfo.newdata.Specialties &&
      agentInfo.newdata.Specialties.length > 0
    ) {
      agentsSpecialtiesList = agentInfo.newdata.Specialties.join(", ");
    } else {
      let specialtiesList = [];
      agentInfo &&
        agentInfo.portfolio &&
        agentInfo.portfolio.length > 0 &&
        agentInfo.portfolio.map((services) => {
          return specialtiesList.push(services.portfolioName);
        });

      agentsSpecialtiesList = specialtiesList.join(", ");
    }
    return agentsSpecialtiesList;
  };

  render() {
    const { agentInfo, propertyId, propertyTitle, propertyImages } = this.state;
    const { isLender } = this.props;

    return (
      <Row>
        <Col
          xl={{ span: 4 }}
          lg={{ span: 4 }}
          md={{ span: 4 }}
          xs={{ span: 4 }}
          sm={{ span: 4 }}
          style={{ marginTop: "12px" }}
        >
          {agentInfo.AgnetProfileUrl ? (
            <img
              src={agentInfo.AgnetProfileUrl}
              alt=""
              style={{
                backgroundColor: "#d4d8f1fa",
                width: "60px",
                height: "60px",
                borderRadius: "30px",
              }}
            />
          ) : (
            <img
              src={profilePic}
              alt=""
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "30px",
              }}
            />
          )}
        </Col>
        <Col
          xl={{ span: 15 }}
          lg={{ span: 15 }}
          md={{ span: 15 }}
          xs={{ span: 15 }}
          sm={{ span: 15 }}
          style={{ marginTop: "12px" }}
        >
          <Row>
            <div>
              <h4>
                {agentInfo.firstName &&
                  agentInfo.firstName + " " + agentInfo.lastName}
              </h4>
            </div>
            <div>
              <Link
                to={{
                  pathname: `/viewProfile/${agentInfo.id && agentInfo.id}`,
                  state:
                    isLender && isLender
                      ? "getPreApprovedForaLoan"
                      : "speakToBuyerAgent",
                }}
                style={{ textDecoration: "none" }}
                onClick={this.handleLink}
              >
                <Col>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "300",
                      marginLeft: "10px",
                      color: "#000",
                      textDecoration: "underline",
                    }}
                  >
                    View Profile
                  </span>
                </Col>
              </Link>
            </div>
          </Row>
          <Row>
            <h6>{this.handleSpecialities()}</h6>
          </Row>
        </Col>

        <Col
          xl={{ span: 4 }}
          lg={{ span: 4 }}
          md={{ span: 4 }}
          xs={{ span: 5 }}
          sm={{ span: 4 }}
          style={{
            marginTop: "12px",
          }}
        >
          <div
            style={{
              border: "1px solid #FC572B",
              textAlign: "center",
            }}
          >
            {agentInfo && (
              <SendMessage
                msgButtonTitle="Message"
                toName={
                  agentInfo.firstName &&
                  agentInfo.firstName + " " + agentInfo.lastName
                }
                propertyTitle={propertyTitle}
                propertyId={propertyId}
                propertyImages={propertyImages}
                buttonAlign="listing"
                mlsId={agentInfo.mlsId}
                agentEmail={agentInfo.email}
                messageType="buyerAgent"
                isRegistredUser={true}
              />
            )}
          </div>
        </Col>
      </Row>
    );
  }
}
