import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../store/contextStore";
import { Layout, Row, Steps, Button, notification, Col } from "antd";
import PrivateDetails from "./Addhomes/PrivateDetails";
import PropertyDetails from "./Addhomes/PropertyDetails";
import PropertyFeatures from "./Addhomes/PropertyFeatures";
import PropertyDetails2 from "./Addhomes/PropertyDetails2";
import { noAuthgetDetails, add } from "../../api/Apis";
import { Link } from "react-router-dom";
import { update } from "../../api/Apis";
import Loading from "../../common/Loading";

const { Step } = Steps;
class AddSellerhome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      next: 0,
      form: "form",
      errorMsg: "",
      savingDetails: false,
      data: {
        entityData: {},
      },
      sellerHomeDetails: "",
      doBackPage: false,
      savePropertyDetails: false,
    };
  }
  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (window.location.pathname !== "/add/sellerProfile") {
      const id = this.props.match.params.id;
      noAuthgetDetails("getHomes", id)
        .then((editProperty) => {
          this.setState({
            sellerHomeDetails: editProperty.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  newMethod = (e, type) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (type === "PRIVATE_DETAILS") {
      this.props.context.setHomeDetails(e, "PRIVATE_DETAILS");
    } else if (type === "PROPERTY_DETAILS") {
      this.props.context.setHomeDetails(e, "PROPERTY_DETAILS");
    } else if (type === "PROPERTY_FEATURES") {
      this.props.context.setHomeDetails(e, "PROPERTY_FEATURES");
    } else if (type === "PROPERTY_DETAILS2") {
      this.props.context.setHomeDetails(e, "PROPERTY_DETAILS2");
    }
    if (e && this.state.current !== 3 && !this.state.doBackPage) {
      this.setState({ current: this.state.current + 1 });
    } else {
      this.setState({ current: this.state.current - 1 });
    }
  };

  prev = (e) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      doBackPage: true,
    });
    // this.setState({ current: this.state.current - 1 });
  };
  next = () => {
    this.setState({
      doBackPage: false,
    });
  };
  callingApiForAddHome = async () => {
    const { sellerHomeDetails, userDetails } = this.props.context.state;
    if (userDetails && userDetails.id) {
      const data = Object.assign(
        {},
        {
          entityData: {
            userId: userDetails.id,
            privateDetails: sellerHomeDetails.privateDetails,
            propertyDetails: sellerHomeDetails.propertyDetails,
            propertyFeatures: sellerHomeDetails.propertyFeatures,
            propertyDetails2: sellerHomeDetails.propertyDetails2,
          },
        }
      );
      if (
        sellerHomeDetails &&
        sellerHomeDetails.propertyDetails2 &&
        sellerHomeDetails.propertyDetails2.title &&
        sellerHomeDetails.propertyDetails2.title.length !== 0
      ) {
        if (
          window.location.pathname ===
          `/add/sellerProfile/${this.props.match.params.id}`
        ) {
          const data = Object.assign(
            {},
            {
              entityData: {
                createdAt: this.props.location.state.createdAt,
                privateDetails: sellerHomeDetails.privateDetails,
                propertyDetails: sellerHomeDetails.propertyDetails,
                propertyDetails2: sellerHomeDetails.propertyDetails2,
                propertyFeatures: sellerHomeDetails.propertyFeatures,
                userId: userDetails.id,
              },
            }
          );
          try {
            if ("entityData" in data) {
              const UpdateHomeDetails = await update(
                "homesList",
                this.props.location.state.id,
                data
              );
              if (UpdateHomeDetails.success) {
                this.setState({
                  savingDetails: false,
                });
                notification["success"]({
                  message: "Home details updated",
                });
                this.props.history.push(`/viewProfile/${userDetails.id}`);
              }
            }
          } catch (error) {
            notification["error"]({
              message: "Something went wrong!",
              description: "Please try again",
            });
          }
        } else {
          try {
            add("homesList", data).then(
              (result) =>
                result.success &&
                this.setState({
                  savingDetails: false,
                })
            );
            notification["success"]({
              message: "New home added",
            });
            const data12 = "";
            this.props.context.setHomeDetails(data12, "EMPTY_DATA");
            this.props.history.push(`/viewProfile/${userDetails.id}`);
          } catch (error) {
            notification["error"]({
              message: "Something went wrong!",
              description: "Please try again",
            });
          }
        }
      } else {
        this.setState({
          errorMsg: "Please input your title",
        });
      }
    } else {
      notification["error"]({
        message: "Something went wrong!",
        description: "Please try again",
      });
    }
  };
  handleAddhome = async () => {
    this.setState({
      savingDetails: true,
    });
    await setTimeout(() => this.callingApiForAddHome(), 3000);
  };

  setLoadingFalse = () => {
    this.props.context.setLoading(true);
  };

  handleErrorMsg = () => {
    this.setState({
      errorMsg: "",
    });
  };
  disAbleSaveButton = () => {
    this.setState({
      savePropertyDetails: true,
    });
  };
  enableSaveButton = () => {
    this.setState({
      savePropertyDetails: false,
    });
  };
  render() {
    const { current, savingDetails, sellerHomeDetails, savePropertyDetails } =
      this.state;
    const steps = [
      {
        title: "Private details",
        content: (
          <PrivateDetails
            handleForm={this.newMethod}
            editPrivateDetails={
              this.props.location.state &&
              this.props.location.state.privateDetails
            }
            sellerHomeDetails={
              window.location.pathname === "/add/sellerProfile"
                ? this.props.context.state.sellerHomeDetails
                : sellerHomeDetails
            }
          />
        ),
      },
      {
        title: "Property Details",
        content: (
          <PropertyDetails
            handleForm={this.newMethod}
            sellerHomeDetails={
              window.location.pathname === "/add/sellerProfile"
                ? this.props.context.state.sellerHomeDetails
                : sellerHomeDetails
            }
            doBackPage={this.state.doBackPage}
          />
        ),
      },
      {
        title: "Property Features",
        content: (
          <PropertyFeatures
            handleForm={this.newMethod}
            sellerHomeDetails={
              window.location.pathname === "/add/sellerProfile"
                ? this.props.context.state.sellerHomeDetails
                : sellerHomeDetails
            }
            doBackPage={this.state.doBackPage}
          />
        ),
      },
      {
        title: "Property Details",
        content: (
          <PropertyDetails2
            sellerHomeDetails={
              window.location.pathname === "/add/sellerProfile"
                ? this.props.context.state.sellerHomeDetails
                : sellerHomeDetails
            }
            handleErrorMsg={this.handleErrorMsg}
            errorMsg={this.state.errorMsg}
            handleForm={this.newMethod}
            saveHome={this.handleAddhome}
            doBackPage={this.state.doBackPage}
            enableSaveButton={this.enableSaveButton}
            disAbleSaveButton={this.disAbleSaveButton}
          />
        ),
      },
    ];
    return (
      <div style={{ backgroundColor: "rgb(249, 250, 255)" }}>
        <Loading enableLoading={savingDetails} />

        <Layout className="Add-home-details">
          {sellerHomeDetails &&
          window.location.pathname !== "/add/sellerProfile" ? (
            <>
              <Steps
                size="small"
                current={current}
                direction={
                  window.screen.width >= "605" ? "horizontal" : "vertical"
                }
              >
                {steps.map((item) => (
                  <Step key={item.title} title={item.title}></Step>
                ))}
              </Steps>
              <div className="steps-content">{steps[current].content}</div>
            </>
          ) : (
            <>
              {window.location.pathname !== "/add/sellerProfile" && (
                <Loading enableLoading={true} />
              )}
              {sellerHomeDetails === "" &&
                window.location.pathname === "/add/sellerProfile" && (
                  <>
                    <Steps
                      size="small"
                      current={current}
                      direction={
                        window.screen.width >= "605" ? "horizontal" : "vertical"
                      }
                    >
                      {steps.map((item) => (
                        <Step key={item.title} title={item.title}></Step>
                      ))}
                    </Steps>
                    <div className="steps-content">
                      {steps[current].content}
                    </div>
                  </>
                )}
            </>
          )}
        </Layout>

        <Row style={{ marginBottom: "1vw" }}>
          <Col
            xl={{ span: 3, offset: 8 }}
            lg={{ span: 3, offset: 8 }}
            md={{ span: 3, offset: 8 }}
            xs={{ span: 10, offset: 1 }}
            sm={{ span: 10, offset: 1 }}
          >
            {current > 0 ? (
              <Button
                // type="primary"
                // onClick={this.prev}
                // style={{ width: "100%", height: "40px" }}
                onClick={this.prev}
                type="primary"
                form="form"
                style={{ width: "100%", height: "40px" }}
                htmlType="submit"
              >
                Back
              </Button>
            ) : (
              <Link
                to={`/viewProfile/${this.props.context.state.userDetails.id}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{ width: "100%", height: "40px" }}
                  type="primary"
                  onClick={this.setLoadingFalse}
                >
                  Cancel
                </Button>
              </Link>
            )}
          </Col>
          <Col
            xl={{ span: 3, offset: 1 }}
            lg={{ span: 3, offset: 1 }}
            md={{ span: 3, offset: 1 }}
            xs={{ span: 10, offset: 2 }}
            sm={{ span: 10, offset: 2 }}
          >
            {current < steps.length - 1 && (
              <>
                <Button
                  type="primary"
                  form="form"
                  style={{ width: "100%", height: "40px" }}
                  htmlType="submit"
                  onClick={this.next}
                >
                  Next Step
                </Button>
              </>
            )}
            {current === steps.length - 1 && (
              <>
                <Button
                  type="primary"
                  id="step1"
                  style={{ width: "100%", height: "40px" }}
                  form="form"
                  htmlType="submit"
                  disabled={savePropertyDetails}
                >
                  Save
                </Button>
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
export default withAppContext(withRouter(AddSellerhome));
