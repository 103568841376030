import React, { Component } from "react";
import {
  HomeOutlined,
  TeamOutlined,
  UserSwitchOutlined,
  ShopOutlined,
  DollarCircleOutlined,
  QuestionCircleOutlined,
  HeartOutlined,
  MessageOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
export default class DataNotFound extends Component {
  render() {
    const { type, title } = this.props;
    return (
      <>
        <div style={{ minHeight: "400px" }}>
          <div
            style={{
              width: "300px",
              height: "300px",
              backgroundColor: "#f9faff",
              borderRadius: "100px",
              position: "relative",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
            }}
          >
            {type === "homes" ? (
              <HomeOutlined
                style={{
                  fontSize: "200px",
                  position: "absolute",
                  top: 40,
                  left: 0,
                  right: 0,
                  margin: "auto",
                  color: "lightgray",
                }}
              />
            ) : type === "buyers" ? (
              <TeamOutlined
                style={{
                  fontSize: "200px",
                  position: "absolute",
                  top: 40,
                  left: 0,
                  right: 0,
                  margin: "auto",
                  color: "lightgray",
                }}
              />
            ) : type === "agents" ? (
              <UserSwitchOutlined
                style={{
                  fontSize: "200px",
                  position: "absolute",
                  top: 40,
                  left: 0,
                  right: 0,
                  margin: "auto",
                  color: "lightgray",
                }}
              />
            ) : type === "lenders" ? (
              <DollarCircleOutlined
                style={{
                  fontSize: "200px",
                  position: "absolute",
                  top: 40,
                  left: 0,
                  right: 0,
                  margin: "auto",
                  color: "lightgray",
                }}
              />
            ) : type === "market" ? (
              <ShopOutlined
                style={{
                  fontSize: "200px",
                  position: "absolute",
                  top: 40,
                  left: 0,
                  right: 0,
                  margin: "auto",
                  color: "lightgray",
                }}
              />
            ) : type === "favourite" ? (
              <HeartOutlined
                style={{
                  fontSize: "200px",
                  position: "absolute",
                  top: 40,
                  left: 0,
                  right: 0,
                  margin: "auto",
                  color: "lightgray",
                }}
              />
            ) : type === "testimonial" ? (
              <MessageOutlined
                style={{
                  fontSize: "200px",
                  position: "absolute",
                  top: 40,
                  left: 0,
                  right: 0,
                  margin: "auto",
                  color: "lightgray",
                }}
              />
            ) : type === "portFolio" ? (
              <FileImageOutlined
                style={{
                  fontSize: "200px",
                  position: "absolute",
                  top: 40,
                  left: 0,
                  right: 0,
                  margin: "auto",
                  color: "lightgray",
                }}
              />
            ) : (
              <QuestionCircleOutlined
                style={{
                  fontSize: "200px",
                  position: "absolute",
                  top: 40,
                  left: 0,
                  right: 0,
                  margin: "auto",
                  color: "lightgray",
                }}
              />
            )}
          </div>
          <h1
            style={{
              textAlign: "center",
              marginTop: "20px",
              color: "gray",
            }}
          >
            {title}
          </h1>
          {/* <h4
            style={{
              textAlign: "center",
              color: "gray",
            }}
          >
            Please try again
          </h4> */}
        </div>
      </>
    );
  }
}
