import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Modal,
  Avatar,
  Badge,
  Card,
  notification,
  Button,
  Popover,
  Divider,
  Switch,
} from "antd";
import Amplify, { Auth } from "aws-amplify";
import AwsConfig from "../config/AwsConfig";
// import logo from "../images/logo.png";
// import login1 from "../images/loginLogo.png";
import loginLogo from "../images/loginLogo.png";
// import logo from "../images/footerLogo.png";
import {
  update,
  getDetails,
  list,
  noAuthgetDetails,
  noAuthlist,
} from "../api/Apis";
import { withAppContext } from "../store/contextStore";
import {
  LoginOutlined,
  LogoutOutlined,
  HeartFilled,
  UserOutlined,
  ShopOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Notifications from "./Notifications";
import moment from "moment";
const { Meta } = Card;
Amplify.configure(AwsConfig);
class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      searchType:
        localStorage.getItem("searchType") != null
          ? localStorage.getItem("searchType")
          : "Homes",
      newUserDetails: "",
      Favorites: [],
      loading: false,
      showVendorConfirmation: false,
    };
  }

  componentDidMount() {
    this.getFourStatesCode();
    this.props.context.setLoading(true);
    Auth.currentUserInfo()
      .then((user) => {
        if (user) {
          let userId = user.username;
          if (user.username.indexOf("Facebook") > -1) {
            userId = user.attributes?.sub;
          }
          if (user.username.indexOf("Google") > -1) {
            userId = user.attributes?.sub;
          }
          if (user.username !== undefined && user.username !== null) {
            this.getUserProfile(userId);
          }
        }
      })
      .catch((err) => console.log(err, "erorr"));
    this.getFourStatesCode();
  }
  getFourStatesCode = () => {
    try {
      const dataObj = {
        dataType: "apiConfig",
      };
      noAuthlist("getConfigData", dataObj)
        .then((stateCodes) => {
          if (stateCodes.success) {
            localStorage.setItem(
              "stateCodesFromAdmin",
              JSON.stringify(stateCodes.data)
            );
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      alert("Something went wrong");
    }
  };
  getUserProfile = async (id) => {
    let newUserDetails = {};
    noAuthgetDetails("getUsers", id).then(async (result) => {
      if (result.success) {
        if (result.data.profilePicture) {
          const profileUrl =
            `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
            result.data.profilePicture[0];
          result.data["profileUrl"] = profileUrl;
          newUserDetails = { ...result.data };
          this.props.context.setUserDetails(newUserDetails);
          this.setState({ userDetails: newUserDetails });
        } else {
          this.props.context.setUserDetails(result.data);
          this.setState({ userDetails: result.data });
        }
        this.props.context.setLoading(false);
      } else {
        console.log(result.errors);
      }
    });

    const userId = id;
    try {
      const getFavorites = await list("userFavorites", { userId: userId });
      if (getFavorites.success) {
        this.setState({
          Favorites: getFavorites.data,
        });
        this.props.context.handleFavoritesCount(
          this.state.Favorites,
          this.state.Favorites.length
        );
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      window.location.assign("/pageNotFound/newtWork");
    }

    this.setState({
      loading: false,
      showVendorConfirmation: false,
    });
  };

  handleClear = () => {
    window.location.reload();
  };

  handleLogout = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    // const user = JSON.parse(localStorage.getItem("user"));
    let currentDate = moment(new Date()).format("MM/DD/YYYY");

    let userLoginTime = localStorage.getItem("userLoginTime");
    let userLogoutTime = new Date().getTime();

    var diffMs = userLogoutTime - userLoginTime; // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    let diffTime = { Days: diffDays, Hours: diffHrs, Mins: diffMins };
    let totalVisits =
      userDetails && userDetails.totalVisits ? userDetails.totalVisits : [];
    totalVisits?.map((p) => {
      let totalTime = p.totalTime ? p.totalTime : [];
      if (p.check_today_date === currentDate) {
        totalTime.push(diffTime);
        p["totalTime"] = totalTime;
      }
      return p;
    });

    let data = {
      entityData: {
        totalVisits,
      },
    };
    console.log(data);
    await update("users", userDetails.id, data);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    try {
      await Auth.signOut()
        .then((res) => {
          localStorage.setItem("userDetails", JSON.stringify(""));
          localStorage.setItem("subscriptionPack", JSON.stringify(""));
          localStorage.removeItem("userLogin");
          localStorage.removeItem("userLoginTime");
          this.props.context.setUserDetails("");
          this.props.context.setUser("");
          this.props.context.setUserLogin(false);
          localStorage.removeItem("signupUserSelectedProfile");
          localStorage.removeItem("facebookAccessToken");
          localStorage.removeItem("fbUserProfilePic");
          window.location.replace("/login");
        })
        .catch((error) => {
          console.log("error signing out: ", error);
          window.location.replace("/login");
        });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  setLoading = () => {
    this.props.context.setLoading(true);
  };

  setFilterSearchEmpty = () => {
    const filterdata = [];
    this.props.context.setFilterData(filterdata);
    this.props.context.setAgentFilterSearch(filterdata);
    localStorage.removeItem("selectedCities");
    localStorage.removeItem("filterSearchData");
    this.props.context.handleToSetCurrentPage(1, 10);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  handleMyProfile = () => {
    const { userDetails } = this.state;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (window.location.href === this.props.context?.state?.page_pathname) {
      window.location.assign(`/viewProfile/${userDetails.id}`);
    }
  };
  handleCancel = () => {
    this.setState({
      loading: false,
      showVendorConfirmation: false,
    });
  };

  changeProfile = async () => {
    this.setState({
      loading: true,
    });
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const newUpdatedData = await getDetails("users", userDetails.id);
    if (newUpdatedData && newUpdatedData.data && newUpdatedData.data.newdata) {
      const newdata = newUpdatedData.data.newdata;
      newdata["zipCodes"] = [];
      newdata["Specialties"] = [];
      const data = {
        entityData: {
          userProfile: "V",
          newdata: newdata,
        },
      };
      this.updateProfileData(data, userDetails.id);
    } else {
      const data = {
        entityData: {
          userProfile: "V",
        },
      };
      this.updateProfileData(data, userDetails.id);
    }
  };

  updateProfileData = async (data, userId) => {
    const updatingUserDetails = await update("users", userId, data);
    if (updatingUserDetails.success) {
      this.getUserProfile(userId);
      notification["success"]({
        message: "You have successfully changed your profile. Thank you.",
      });
    } else {
      this.getUserProfile(userId);
      notification["error"]({
        message: "Somethig Went Wrong",
        description: "Please try again",
      });
    }
  };

  render() {
    const { userDetails, showVendorConfirmation, loading } = this.state;
    const { data1, authenticate, count, currentPage } = this.props;
    let userLogin = localStorage.getItem("userLogin");
    let fbUserProfile = JSON.parse(localStorage.getItem("fbUserProfilePic"));

    return (
      <div className="header">
        <div
          className="header-sidebar"
          style={{ background: userLogin && userLogin ? "#3045d2" : "white" }}
        >
          <header class="header navbar-fixed-top">
            <nav class="navbar" role="navigation">
              {userLogin && userLogin ? (
                <Row justify="space-between">
                  <Col span={12}>
                    <Link to="/homes">
                      <img src={loginLogo} alt="logo" className="logo-header" />
                    </Link>
                  </Col>
                  <Col span={12} style={{ padding: "10px" }}>
                    <Row justify="end" style={{ marginTop: "-10px" }}>
                      <Col className="notify-col">
                        <Link
                          to={`/favourties/${userDetails.id}`}
                          onClick={this.handleLink}
                          className="heart-col"
                          style={{ margin: "0px" }}
                        >
                          <Badge>
                            {/* <a href="#" className="head-example" /> */}
                            <HeartFilled className="heart-header" />
                          </Badge>
                        </Link>
                      </Col>
                      <Col className="notify-col">
                        {userDetails && userDetails.id && (
                          <Notifications userId={userDetails.id} />
                        )}
                      </Col>
                      <Col className="profile-height">
                        <Popover
                          placement="bottomRight"
                          content={
                            <div style={{ width: "200px" }}>
                              <Meta
                                avatar={
                                  <Avatar
                                    src={
                                      fbUserProfile !== null ? (
                                        fbUserProfile
                                      ) : this.props.context.state
                                          .userDetails &&
                                        this.props.context.state.userDetails
                                          .profilePicture ? (
                                        `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                        this.props.context.state.userDetails
                                          .profilePicture[0]
                                      ) : (
                                        <Button
                                          shape="circle"
                                          size="big"
                                          block={true}
                                          style={{ backgroundColor: "#f9faff" }}
                                        >
                                          {userDetails.firstName &&
                                            userDetails.firstName
                                              .charAt(0)
                                              .toUpperCase()}
                                        </Button>
                                      )
                                    }
                                  />
                                }
                                title={
                                  userDetails.firstName
                                    ? userDetails.firstName +
                                      " " +
                                      userDetails.lastName
                                    : userDetails.fullName
                                }
                                description={
                                  userDetails.userProfile === "B"
                                    ? "Buyer"
                                    : userDetails.userProfile === "S"
                                    ? "Seller"
                                    : userDetails.userProfile === "AG"
                                    ? "Agent"
                                    : userDetails.userProfile === "V"
                                    ? "vendor"
                                    : "Lender"
                                }
                              />
                              <Divider
                                style={{
                                  marginBottom: "1vw",
                                  marginTop: "1vw",
                                }}
                              />
                              <p>
                                <Link
                                  to={{
                                    pathname: `/viewProfile/${userDetails.id}`,
                                    state:
                                      userDetails.userProfile === "AG"
                                        ? "Agents"
                                        : userDetails.userProfile === "V"
                                        ? "Vendor"
                                        : userDetails.userProfile === "L"
                                        ? "Lender"
                                        : userDetails.userProfile === "B"
                                        ? "Buyer"
                                        : "Seller",
                                  }}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    marginLeft: "1vw",
                                  }}
                                  onClick={this.handleMyProfile}
                                >
                                  My Profile
                                </Link>
                              </p>

                              {userDetails.userProfile === "B" ||
                              userDetails.userProfile === "S" ? (
                                <Row>
                                  <p
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                      marginLeft: "1vw",
                                    }}
                                  >
                                    Enable Vendor
                                  </p>

                                  <div style={{ marginLeft: "5px" }}>
                                    <Switch
                                      checked={showVendorConfirmation}
                                      checkedChildren="Yes"
                                      unCheckedChildren="No"
                                      onClick={() => {
                                        this.setState({
                                          showVendorConfirmation:
                                            !showVendorConfirmation,
                                        });
                                      }}
                                    />
                                  </div>
                                </Row>
                              ) : userDetails.userProfile === "L" ||
                                userDetails.userProfile === "V" ? (
                                <p>
                                  <Link
                                    to={{
                                      pathname: `/sellerBuyer/viewProfile/${userDetails.id}`,
                                      state: {
                                        userDetails: userDetails,
                                        data: data1,
                                        authenticate: authenticate,
                                        currentPage: currentPage,
                                        count: count,
                                      },
                                    }}
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                      marginLeft: "1vw",
                                    }}
                                    onClick={this.handleLink}
                                  >
                                    Seller/Buyer Profile
                                  </Link>
                                </p>
                              ) : (
                                <p></p>
                              )}

                              <p>
                                <Link
                                  to={{
                                    pathname: `/myProfile/${
                                      userDetails && userDetails.id
                                    }`,
                                    state: {
                                      userDetails: userDetails,
                                      data: data1,
                                      authenticate: authenticate,
                                      currentPage: currentPage,
                                      count: count,
                                    },
                                  }}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    marginLeft: "1vw",
                                  }}
                                  onClick={this.handleLink}
                                >
                                  Profile Settings
                                </Link>
                              </p>
                              <Divider
                                style={{
                                  marginBottom: "1vw",
                                  marginTop: "1vw",
                                }}
                              />
                              <Row
                                onClick={this.handleLogout}
                                style={{ cursor: "pointer" }}
                              >
                                <Col>
                                  <LogoutOutlined
                                    style={{
                                      color: "red",
                                      fontSize: "18px",
                                    }}
                                  />
                                </Col>
                                <Col
                                  style={{
                                    color: "red",
                                    fontSize: "16px",
                                    marginLeft: "10px",
                                    marginTop: "-5px",
                                  }}
                                >
                                  Logout
                                </Col>
                              </Row>
                            </div>
                          }
                          title={null}
                          trigger="hover"
                        >
                          {userDetails && userDetails.userProfile === "AG" ? (
                            <Link
                              to={{
                                pathname: `/viewProfile/${
                                  userDetails && userDetails.id
                                }`,
                                state: {
                                  userDetails: userDetails,
                                  data: data1,
                                  authenticate: authenticate,
                                  currentPage: currentPage,
                                  count: count,
                                },
                              }}
                              style={{ textDecoration: "none" }}
                              onClick={this.handleLink}
                            >
                              <Avatar
                                className="avatar-img-header"
                                src={
                                  fbUserProfile !== null ? (
                                    fbUserProfile
                                  ) : this.props.context.state.userDetails &&
                                    this.props.context.state.userDetails
                                      .profilePicture ? (
                                    `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                    this.props.context.state.userDetails
                                      .profilePicture[0]
                                  ) : (
                                    <Button
                                      shape="circle"
                                      size="big"
                                      block={true}
                                      style={{ backgroundColor: "#f9faff" }}
                                    >
                                      {userDetails.firstName &&
                                        userDetails.firstName
                                          .charAt(0)
                                          .toUpperCase()}
                                    </Button>
                                  )
                                }
                              />
                            </Link>
                          ) : (
                            <Link
                              to={{
                                pathname: `/myProfile/${
                                  userDetails && userDetails.id
                                }`,
                                state: {
                                  userDetails: userDetails,
                                  data: data1,
                                  authenticate: authenticate,
                                  currentPage: currentPage,
                                  count: count,
                                },
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <Avatar
                                className="avatar-img-header"
                                src={
                                  fbUserProfile !== null ? (
                                    fbUserProfile
                                  ) : this.props.context.state.userDetails &&
                                    this.props.context.state.userDetails
                                      .profilePicture ? (
                                    `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                    this.props.context.state.userDetails
                                      .profilePicture[0]
                                  ) : (
                                    <Button
                                      shape="circle"
                                      size="big"
                                      block={true}
                                      style={{ backgroundColor: "#f9faff" }}
                                    >
                                      {userDetails.firstName &&
                                        userDetails.firstName
                                          .charAt(0)
                                          .toUpperCase()}
                                    </Button>
                                  )
                                }
                              />
                            </Link>
                          )}
                        </Popover>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row justify="space-between">
                  <Col span={12}>
                    <Link to="/">
                      <img src={loginLogo} alt="logo" className="logo-header" />
                    </Link>
                  </Col>
                  <Col className="notify-col">
                    <Link
                      to="/login"
                      onClick={this.handleLink}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div style={{ marginRight: "10px" }}>
                        <LoginOutlined
                          style={{ fontSize: "18px", color: "black" }}
                        />{" "}
                        <span style={{ fontSize: "18px", color: "black" }}>
                          Login
                        </span>
                      </div>
                    </Link>
                  </Col>
                </Row>
              )}
            </nav>
          </header>
        </div>

        <Row
          style={{
            background:
              userLogin && userLogin
                ? "#3045D2 0% 0% no-repeat padding-box"
                : "#fff 0% 0% no-repeat padding-box",
          }}
        >
          <Col
            xxl={{
              span: userLogin && userLogin ? 3 : 3,
              offset: userLogin && userLogin ? 0 : 1,
            }}
            xl={{
              span: userLogin && userLogin ? 3 : 4,
              offset: 0,
            }}
            lg={{
              span: userLogin && userLogin ? 2 : 4,
              offset: 0,
            }}
            md={{
              span: userLogin && userLogin ? 3 : 4,
              offset: userLogin && userLogin ? 0 : 1,
            }}
            sm={{ span: "3", offset: "0" }}
            xs={{ span: "3", offset: "0" }}
            className="header-logo-class"
          >
            {userLogin && userLogin ? (
              <Link to="/homes" onClick={this.handleLink}>
                <img src={loginLogo} alt="logo" className="logo-header" />
              </Link>
            ) : (
              <Link to="/" onClick={this.handleLink}>
                <img src={loginLogo} alt="logo" className="logo-header" />
              </Link>
            )}
          </Col>

          <Col
            xxl={{ span: userLogin && userLogin ? "15" : "16" }}
            xl={{ span: userLogin && userLogin ? "15" : "16" }}
            lg={{ span: userLogin && userLogin ? "16" : "16" }}
            md={{ span: "11" }}
            sm={{ span: "13" }}
            xs={{ span: "13" }}
            className="display-flex-end"
            style={{ marginTop: "2vw", marginBottom: "2vw" }}
          >
            <Col>
              <Link to="/homes">
                <span
                  className="header-menu-titles"
                  style={{
                    color:
                      window.location.pathname === "/homes"
                        ? "#fd7e14"
                        : userLogin
                        ? "#FFF"
                        : "black",
                  }}
                  onClick={this.setFilterSearchEmpty}
                >
                  Homes
                </span>
              </Link>
            </Col>

            <Col>
              <Link to="/offMLSHomes">
                <span
                  className="header-menu-titles"
                  style={{
                    color:
                      window.location.pathname === "/offMLSHomes"
                        ? "#fd7e14"
                        : userLogin
                        ? "#FFF"
                        : "black",
                  }}
                  onClick={this.setFilterSearchEmpty}
                >
                  Off MLS Homes
                </span>
              </Link>
            </Col>
            <Col>
              <Link to="/buyers">
                <span
                  className="header-menu-titles"
                  style={{
                    color:
                      window.location.pathname === "/buyers"
                        ? "#fd7e14"
                        : userLogin
                        ? "#FFF"
                        : "black",
                  }}
                  onClick={this.setFilterSearchEmpty}
                >
                  Buyers
                </span>
              </Link>
            </Col>
            <Col>
              <Link to="/agent">
                <span
                  className="header-menu-titles"
                  style={{
                    color:
                      window.location.pathname === "/agent"
                        ? "#fd7e14"
                        : userLogin
                        ? "#FFF"
                        : "black",
                  }}
                  onClick={() => {
                    this.setFilterSearchEmpty();
                    this.handleLink();
                  }}
                >
                  Agents
                </span>
              </Link>
            </Col>
            <Col>
              <Link to="/lenders">
                <span
                  className="header-menu-titles"
                  style={{
                    color:
                      window.location.pathname === "/lenders"
                        ? "#fd7e14"
                        : userLogin
                        ? "#FFF"
                        : "black",
                  }}
                  onClick={() => {
                    this.setFilterSearchEmpty();
                    this.handleLink();
                  }}
                >
                  Lenders
                </span>
              </Link>
            </Col>
            <Col>
              <Link to="/marketplace">
                <span
                  className="header-menu-titles"
                  style={{
                    color:
                      window.location.pathname === "/marketplace"
                        ? "#fd7e14"
                        : userLogin
                        ? "#FFF"
                        : "black",
                  }}
                  onClick={() => {
                    this.setFilterSearchEmpty();
                    this.handleLink();
                  }}
                >
                  Marketplace
                </span>
              </Link>
            </Col>
            <Col>
              <Link to="/about">
                <span
                  className="header-menu-titles"
                  style={{
                    color:
                      window.location.pathname === "/about"
                        ? "#fd7e14"
                        : userLogin
                        ? "#FFF"
                        : "black",
                  }}
                  onClick={() => {
                    this.setFilterSearchEmpty();
                    this.handleLink();
                  }}
                >
                  About Us
                </span>
              </Link>
            </Col>
            <Col>
              <Link to="/whyJoinUs" onClick={this.handleclick}>
                <span
                  className="header-menu-titles"
                  style={{
                    color:
                      window.location.pathname === "/whyJoinUs"
                        ? "#fd7e14"
                        : userLogin
                        ? "#FFF"
                        : "black",
                  }}
                  onClick={() => {
                    this.setFilterSearchEmpty();
                    this.handleLink();
                  }}
                >
                  Why Join Us
                </span>
              </Link>
            </Col>
            <Col>
              <Link to="/contactUs" onClick={this.handleclick}>
                <span
                  className="header-menu-titles"
                  style={{
                    color:
                      window.location.pathname === "/contactUs"
                        ? "#fd7e14"
                        : userLogin
                        ? "#FFF"
                        : "black",
                  }}
                  onClick={() => {
                    this.setFilterSearchEmpty();
                    this.handleLink();
                  }}
                >
                  Contact Us
                </span>
              </Link>
            </Col>
          </Col>

          <Col
            className="favourties-header"
            // xl={{ span: "6" }}
            xxl={{
              span: userLogin && userLogin ? 6 : 4,
            }}
            xl={{
              span: userLogin && userLogin ? 6 : 4,
            }}
            lg={{
              span: userLogin && userLogin ? 6 : 4,
            }}
            md={{ span: "8" }}
            sm={{ span: "8" }}
            xs={{ span: "8" }}
          >
            {userLogin && userLogin ? (
              <Row className="header-row">
                <Col span={4} className="header-margin-desk">
                  <Link
                    to={`/favourties/${userDetails.id}`}
                    onClick={this.handleLink}
                  >
                    <Badge>
                      {/* <a href="#" className="head-example" /> */}
                      <HeartFilled className="heart-header" />
                    </Badge>
                  </Link>
                </Col>

                <Col span={4} className="header-margin-desk">
                  {userDetails && userDetails.id && (
                    <Notifications userId={userDetails.id} />
                  )}
                </Col>

                <Col
                  span={16}
                  style={{ width: "100%", marginTop: "1.9vw" }}
                  className="header-profile font-family-style color-white"
                >
                  <Popover
                    placement="bottom"
                    content={
                      <div style={{ width: "200px" }}>
                        <Row
                          style={{ cursor: "pointer", marginBottom: "10px" }}
                        >
                          <Col>
                            <UserOutlined
                              style={{
                                fontSize: "14px",
                                marginRight: "10px",
                              }}
                            />
                          </Col>
                          <Col>
                            <Link
                              to={{
                                pathname: `/viewProfile/${userDetails.id}`,
                                state:
                                  userDetails.userProfile === "AG"
                                    ? "Agents"
                                    : userDetails.userProfile === "V"
                                    ? "Vendor"
                                    : userDetails.userProfile === "L"
                                    ? "Lender"
                                    : userDetails.userProfile === "B"
                                    ? "Buyer"
                                    : "Seller",
                              }}
                              style={{
                                textDecoration: "none",
                                color: "black",
                                fontSize: "16px",
                              }}
                              onClick={this.handleMyProfile}
                            >
                              My Profile
                            </Link>
                          </Col>
                        </Row>

                        {userDetails.userProfile === "B" ||
                        userDetails.userProfile === "S" ? (
                          <Row style={{ cursor: "pointer" }}>
                            <Col>
                              <ShopOutlined
                                style={{
                                  fontSize: "14px",
                                  marginRight: "10px",
                                }}
                              />
                            </Col>
                            <Col>
                              <p
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                  fontSize: "16px",
                                }}
                              >
                                Enable Vendor
                              </p>
                            </Col>
                            <Col>
                              <div style={{ marginLeft: "5px" }}>
                                <Switch
                                  checked={showVendorConfirmation}
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  onClick={() => {
                                    this.setState({
                                      showVendorConfirmation:
                                        !showVendorConfirmation,
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : userDetails.userProfile === "L" ||
                          userDetails.userProfile === "V" ? (
                          <Row
                            style={{ cursor: "pointer", marginBottom: "10px" }}
                          >
                            <Col>
                              <ShopOutlined
                                style={{
                                  fontSize: "14px",
                                  marginRight: "10px",
                                }}
                              />
                            </Col>
                            <Col>
                              <Link
                                to={{
                                  pathname: `/sellerBuyer/viewProfile/${userDetails.id}`,
                                  state: {
                                    userDetails: userDetails,
                                    data: data1,
                                    authenticate: authenticate,
                                    currentPage: currentPage,
                                    count: count,
                                  },
                                }}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                  fontSize: "16px",
                                }}
                                onClick={this.handleLink}
                              >
                                Seller/Buyer Profile
                              </Link>
                            </Col>
                          </Row>
                        ) : (
                          <p></p>
                        )}

                        <Row
                          style={{ cursor: "pointer", marginBottom: "10px" }}
                        >
                          <Col>
                            <SettingOutlined
                              style={{
                                fontSize: "14px",
                                marginRight: "10px",
                              }}
                            />
                          </Col>
                          <Col>
                            <Link
                              to={{
                                pathname: `/myProfile/${
                                  userDetails && userDetails.id
                                }`,
                                state: {
                                  userDetails: userDetails,
                                  data: data1,
                                  authenticate: authenticate,
                                  currentPage: currentPage,
                                  count: count,
                                },
                              }}
                              onClick={this.handleLink}
                              style={{
                                textDecoration: "none",
                                color: "black",
                                fontSize: "16px",
                              }}
                            >
                              Profile Settings
                            </Link>
                          </Col>
                        </Row>

                        <Divider
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        />
                        <Row
                          onClick={this.handleLogout}
                          style={{ cursor: "pointer" }}
                        >
                          <Col>
                            <LogoutOutlined
                              style={{
                                color: "red",
                                fontSize: "16px",
                              }}
                            />
                          </Col>
                          <Col
                            style={{
                              color: "red",
                              fontSize: "16px",
                              marginLeft: "10px",
                              marginTop: "-5px",
                            }}
                          >
                            Logout
                          </Col>
                        </Row>
                      </div>
                    }
                    title={null}
                    trigger="hover"
                  >
                    {userDetails && userDetails.userProfile === "AG" ? (
                      <Link
                        to={{
                          pathname: `/viewProfile/${
                            userDetails && userDetails.id
                          }`,
                          state: {
                            userDetails: userDetails,
                            data: data1,
                            authenticate: authenticate,
                            currentPage: currentPage,
                            count: count,
                          },
                        }}
                        style={{ textDecoration: "none" }}
                        onClick={this.handleLink}
                      >
                        <Meta
                          avatar={
                            <Avatar
                              src={
                                fbUserProfile !== null ? (
                                  fbUserProfile
                                ) : this.props.context.state.userDetails &&
                                  this.props.context.state.userDetails
                                    .profilePicture ? (
                                  `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                  this.props.context.state.userDetails
                                    .profilePicture[0]
                                ) : (
                                  <Button
                                    shape="circle"
                                    size="big"
                                    block={true}
                                    className="header-img-btn"
                                  >
                                    {userDetails.firstName &&
                                      userDetails.firstName
                                        .charAt(0)
                                        .toUpperCase()}
                                  </Button>
                                )
                              }
                            />
                          }
                          title={
                            userDetails.firstName
                              ? userDetails.firstName +
                                " " +
                                userDetails.lastName
                              : userDetails.fullName
                          }
                          description={
                            userDetails.userProfile === "B"
                              ? "Buyer"
                              : userDetails.userProfile === "S"
                              ? "Seller"
                              : userDetails.userProfile === "AG"
                              ? "Agent"
                              : userDetails.userProfile === "V"
                              ? "vendor"
                              : "Lender"
                          }
                        />
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: `/myProfile/${
                            userDetails && userDetails.id
                          }`,
                          state: {
                            userDetails: userDetails,
                            data: data1,
                            authenticate: authenticate,
                            currentPage: currentPage,
                            count: count,
                          },
                        }}
                        onClick={this.handleLink}
                        style={{ textDecoration: "none" }}
                      >
                        <Meta
                          avatar={
                            <Avatar
                              src={
                                fbUserProfile !== null ? (
                                  fbUserProfile
                                ) : this.props.context.state.userDetails &&
                                  this.props.context.state.userDetails
                                    .profilePicture ? (
                                  `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                                  this.props.context.state.userDetails
                                    .profilePicture[0]
                                ) : (
                                  <Button
                                    shape="circle"
                                    size="big"
                                    block={true}
                                    className="header-img-btn"
                                  >
                                    {userDetails.firstName &&
                                      userDetails.firstName
                                        .charAt(0)
                                        .toUpperCase()}
                                  </Button>
                                )
                              }
                            />
                          }
                          title={
                            userDetails.firstName
                              ? userDetails.firstName +
                                " " +
                                userDetails.lastName
                              : userDetails.fullName
                          }
                          description={
                            userDetails.userProfile === "B"
                              ? "Buyer"
                              : userDetails.userProfile === "S"
                              ? "Seller"
                              : userDetails.userProfile === "AG"
                              ? "Agent"
                              : userDetails.userProfile === "V"
                              ? "vendor"
                              : "Lender"
                          }
                        />
                      </Link>
                    )}
                  </Popover>
                </Col>
              </Row>
            ) : (
              <Row className="header-margin-desk">
                <Col span={8}>
                  <Link
                    to="/login"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <span
                      className="header-menu-titles"
                      style={{
                        color:
                          window.location.pathname === "" ? "black" : "black",
                      }}
                    >
                      Login
                    </span>
                  </Link>
                </Col>
                <Col span={6}>
                  <Button
                    className="font-family-style text-size1"
                    type="primary"
                    onClick={() => {
                      this.props.history.push("/register");
                    }}
                    danger
                  >
                    Join for free
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Modal
          className="modal-black-close"
          visible={showVendorConfirmation}
          title="Vendor profile change request"
          onOk={() => this.changeProfile()}
          onCancel={() =>
            this.setState({
              showVendorConfirmation: false,
            })
          }
          footer={[
            <Button
              key="back"
              onClick={() =>
                this.setState({
                  showVendorConfirmation: false,
                })
              }
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => this.changeProfile()}
            >
              Proceed
            </Button>,
          ]}
        >
          <p style={{ padding: "10px", textAlign: "justify" }}>
            Vendor profiles are for professionals who own a business that
            provides services in and around real estate purchases, sales, and
            home maintenance/repair. By changing your profile to a vendor, your
            business will be listed on the marketplace page (with a basic
            subscription) where consumers and agents will be able to find you
            and seek out your services. Please confirm to proceed.
          </p>
        </Modal>
      </div>
    );
  }
}

export default withAppContext(withRouter(HeaderMenu));
