import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import login from "../images/Login.png";
// import logo from "../images/logo.png";
import logo from "../images/footerLogo.png";
class Login extends Component {
  render() {
    return (
      <div className="login-container">
        <img src={login} alt="login" className="image1 height-image" />
        <div className="logo">
          <img
            src={logo}
            onClick={() => {
              this.props.history.push("/");
            }}
            alt="logo"
            style={{ width: "25%", cursor: "pointer" }}
          />
        </div>
        <div className="content">
          {this.props.location.pathname === "/login" && (
            <p>
              Log in to create buyer and seller profiles, search for
              opportunities, contact sellers and buyers, and more.
            </p>
          )}
          {this.props.location.pathname === "/register" && (
            <p>
              Create an account to create buyer and seller profiles, search for
              opportunities, contact sellers and buyers, and more.
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
