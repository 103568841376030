import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../../store/contextStore";
import {
  Col,
  Row,
  Form,
  Input,
  Checkbox,
  Upload,
  notification,
  Spin,
  Select,
  Button,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { noAuthlist } from "../../../api/Apis";
import uploadImg from "../../../images/upload.png";
import Advertisement from "../../../common/Advertisement";
import Amplify, { Storage } from "aws-amplify";
import AwsConfig from "../../../config/AwsConfig";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";

Amplify.configure(AwsConfig);

const { Dragger } = Upload;
const { TextArea } = Input;

class OtherDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageLoading: false,
      fileList: [],
      imageKeys: [],
      title: "",
      description: "",
      commission: "",
      agent: "",
      videoLink: "",
      interest: "",
      height: 44,
      provinceData: [],
      sellerInterestedTown: [],
      errorMsg: "",
      listPrice: "",
      minPrice: "",
      allUsersList: [],
      referedAgentName: "",
      enableAdduser: false,
      savednewsAgentName: false,
      addAgentName: "",
      addAgentEmail: "",
      agentsList: [],
    };
  }

  componentDidMount() {
    const addAgentDefaultName = JSON.parse(localStorage.getItem("userDetails"));
    this.getAllAgents();
    const { sellerHomeDetails } = this.props;
    this.setState({
      sellerInterestedTown:
        sellerHomeDetails.propertyDetails2.sellerInterestedTown,
      referedAgentName:
        sellerHomeDetails.propertyDetails2 &&
        sellerHomeDetails.propertyDetails2.referedAgentName &&
        sellerHomeDetails.propertyDetails2.referedAgentName
          ? sellerHomeDetails.propertyDetails2.referedAgentName
          : addAgentDefaultName && addAgentDefaultName.addUserName,
    });
    if (
      sellerHomeDetails &&
      sellerHomeDetails.propertyDetails2 &&
      sellerHomeDetails.propertyDetails2.homePhotos &&
      sellerHomeDetails.propertyDetails2.homePhotos.length > 0
    ) {
      const fileList = [];
      sellerHomeDetails.propertyDetails2.homePhotos.map((o) =>
        fileList.push({
          uid: o,
          name: "picture",
          url: `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${o}`,
        })
      );
      this.setState({
        fileList,
        imageKeys: sellerHomeDetails.propertyDetails2.homePhotos,
        sellerInterestedTown:
          sellerHomeDetails.propertyDetails2.sellerInterestedTown,
      });
    }
    if (
      sellerHomeDetails.propertyDetails2 &&
      sellerHomeDetails.propertyDetails2.title
    ) {
      this.setState({
        title: sellerHomeDetails.propertyDetails2.title,
      });
    }
  }
  getAllAgents = async () => {
    try {
      const AllagentsList = await noAuthlist("getAgents");
      let singleAgent = AllagentsList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
          email: user.email,
        };
      });
      if (AllagentsList.success) {
        this.setState({
          agentsList: singleAgent,
        });
      } else {
        notification["error"]({
          message: AllagentsList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  uploadHomeImages = async (info) => {
    this.props.disAbleSaveButton();
    this.setState({
      imageLoading: true,
    });
    let Images = this.state.imageKeys;
    switch (info.file.status) {
      case "done":
        this.setState({
          fileList: info.fileList,
        });
        if (info.fileList.length > 0 && info.file.status !== "removed") {
          // info.fileList.map((file) => {
          try {
            if (info.file.size > 0) {
              Storage.put(info.file.uid, info.file.originFileObj, {
                contentType: info.file.type,
              }).then((result) => {
                Images.push(result.key);
                this.setState({
                  imageKeys: Images,
                  imageLoading: false,
                });
                this.props.enableSaveButton();
              });
            } else {
              this.setState({
                imageLoading: false,
              });
              this.props.enableSaveButton();
            }
          } catch (err) {
            console.log(err, "errr");
          }
          // return info.file;
          // });
          if (info.fileList.length > 5) {
            this.setState({
              height: this.state.height + 5,
            });
          }
        }
        break;
      case "removed":
        let imageKeys = this.state.imageKeys.filter(
          (uidImage) => uidImage !== info.file.uid
        );
        let fileList = this.state.fileList.filter(
          (imgKey) => imgKey.uid !== info.file.uid
        );
        this.setState({
          imageLoading: false,
          imageKeys,
          fileList,
        });
        this.props.enableSaveButton();
        if (this.state.fileList.length > 5) {
          this.setState({
            height: this.state.height - 4,
          });
        }
        break;
      default:
        // error or removed
        this.setState({
          // imageKeys: [],
          imageLoading: false,
        });
    }
  };

  handleLastForm = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onFinish = (e) => {
    if (
      (this.state.title === "" || this.state.title === "undefined") &&
      !this.props.doBackPage
    ) {
      this.setState({
        errorMsg: "Please input your title ",
      });
    }
    let PropertyDetails2 = {
      commission: e.commission,
      description: e.description,
      homePhotos: this.state.imageKeys,
      title: e.title,
      videoLink: e.videoLink,
      sellerInterestedTown: this.state.sellerInterestedTown,
      DgressTourLink360: e.DgressTourLink360,
      countyArea: e.countyArea,
      privateNote: e.privateNote,
      confirmation: true,
    };
    if (!this.props.doBackPage) {
      this.props.handleForm(PropertyDetails2, "PROPERTY_DETAILS2");
      this.props.saveHome();
    }
    if (this.props.doBackPage) {
      this.props.handleForm(PropertyDetails2, "PROPERTY_DETAILS2");
    }
  };

  handleLastFormCommision = (e) => {
    const percentage = `${e}%`;
    this.setState({
      commission: percentage,
    });
  };
  onReferedBySelect = (value, option) => {
    this.setState({
      referedAgentName: option.label,
    });
  };

  handleSingleSearch = (address) => {
    let citiesList =
      this.state.sellerInterestedTown &&
      this.state.sellerInterestedTown.length > 0
        ? this.state.sellerInterestedTown
        : [];
    let cityValue = "";
    let stateName = "";
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateName = address[i].short_name;
          }
        }

        const stateCodes = JSON.parse(
          localStorage.getItem("stateCodesFromAdmin")
        );
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateName.toLowerCase() ||
            restState.stateType.toLowerCase() === stateName.toLowerCase()
        );

        if (setVendor && setVendor.length > 0) {
          let newSearchLocation = {
            cityName: cityValue,
            stateName: stateName,
            type: "stateName",
          };
          if (newSearchLocation) {
            citiesList.push(newSearchLocation);
          }
          citiesList.push(cityValue);
          this.setState({
            sellerInterestedTown: citiesList,
          });
        } else {
          notification["error"]({
            message: "Please enter valid city...",
            description: "You can search cities from MA, CT, RI, NH states",
            placement: "topLeft",
          });
        }
      })
      .catch((error) => console.error("Error", error));
  };

  onClose = (value, stateRemove) => {
    let updateSearchLocations = this.state.sellerInterestedTown;
    updateSearchLocations.splice(
      updateSearchLocations.findIndex(
        (e) => e.cityName === value && e.stateName === stateRemove
      ),
      1
    );
    this.setState({
      sellerInterestedTown: updateSearchLocations,
    });
  };

  onCloseTitle = (value) => {
    const data =
      this.state.title && this.state.title.filter((city) => city !== value);
    this.setState({
      title: data,
    });
    if (data.length === 0 || data === "undefined") {
      this.setState({
        errorMsg: "Please input your title",
      });
    }
  };

  handleSelect = (address) => {
    let title = [];
    geocodeByAddress(address.label)
      .then((results) => {
        this.props.handleErrorMsg();
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            title.push(address[i].long_name);
            this.setState({
              title,
              errorMsg: "",
            });
          }
        }
      })
      .catch((error) => console.error("Error", error));
  };

  handleHomeTitle = (e) => {
    this.setState({
      title: e.target.value,
    });
  };

  saveNewAgent = () => {
    const { addAgentName, addAgentEmail } = this.state;
    if (addAgentName !== "" && addAgentEmail !== "") {
      let agentsList = this.state.agentsList;
      agentsList.push({
        label: addAgentName,
        email: addAgentEmail,
        value: addAgentName,
      });
      this.setState({
        agentsList,
        savednewsAgentName: true,
      });
    }
  };

  handleAddUser = () => {
    this.setState({
      enableAdduser: true,
    });
  };

  render() {
    const {
      fileList,
      sellerInterestedTown,
      enableAdduser,
      addAgentName,
      addAgentEmail,
      savednewsAgentName,
    } = this.state;
    const { sellerHomeDetails } = this.props;
    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    };
    return (
      <div>
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <div className="privateD-title">Other Details</div>
            <div style={{ marginBottom: 20 }}>
              <span className="privateD-des">
                Just a few more details and your listing will be live. For the
                title field below enter an attractive title to your listing like
                “Don’t miss this amazing privately offered home located in
                city/state"
              </span>
            </div>
          </Col>
          <Col
            xl={{ span: 16, offset: 0 }}
            lg={{ span: 16, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Form
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              size="large"
              id="form"
            >
              <Row style={{ backgroundColor: "#F9FAFF" }}>
                <Col
                  xl={{ span: 11, offset: 0 }}
                  lg={{ span: 11, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <div className="form-titles">
                    Title (Can be address or just town to keep the address
                    secret)*
                  </div>
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: this.props.doBackPage ? false : true,
                        message: "Please input your title!",
                      },
                    ]}
                    initialValue={
                      sellerHomeDetails &&
                      sellerHomeDetails.propertyDetails2 &&
                      sellerHomeDetails.propertyDetails2.title
                    }
                  >
                    <Input
                      name="title"
                      placeholder="Start Typing"
                      className="form-input"
                      //   value={title}
                      //   onChange={this.handleHomeTitle}
                    />
                  </Form.Item>
                  <div className="form-titles">
                    Cooperating Commission (if under signed contract or if you
                    are seller and willing to pay commission){" "}
                  </div>
                  <Form.Item
                    name="commission"
                    initialValue={
                      sellerHomeDetails &&
                      sellerHomeDetails.propertyDetails2 &&
                      sellerHomeDetails.propertyDetails2.commission
                    }
                  >
                    <Input
                      placeholder="please provide percentage"
                      className="form-input"
                      suffix="%"
                      name="commission"
                      type="number"
                      step="any"
                    />
                  </Form.Item>
                  <div className="form-titles">
                    Towns of interest if buying a home upon sale{" "}
                  </div>
                  <Form.Item
                    name="sellerInterestedTown"
                    className="google-form-search"
                  >
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_MAPS_KEY}
                      autocompletionRequest={{
                        types: ["(cities)"],
                        componentRestrictions: {
                          country: ["us"],
                        },
                      }}
                      className=" css-1uccc91-singleValue"
                      selectProps={{
                        sellerInterestedTown,
                        placeholder: "Search for city",
                        value: "",
                        onChange: (result) => {
                          this.handleSingleSearch(result);
                        },
                      }}
                    ></GooglePlacesAutocomplete>

                    {this.state.sellerInterestedTown &&
                      this.state.sellerInterestedTown.map((city, index) => (
                        <>
                          {typeof city === "object" && (
                            <div
                              key={index}
                              style={{
                                display: "inline-flex",
                                margin: "0.73vw",
                                padding: "0 7px",
                                flexWrap: "wrap",
                                justifyContent: "space-around",
                                border: "1px solid darkgrey",
                                marginLeft: "0px",
                              }}
                            >
                              {city.cityName && (
                                <>
                                  <div>
                                    {city.cityName}, {city.stateName}
                                  </div>
                                  <div
                                    style={{
                                      color: "grey",
                                      cursor: "pointer",
                                      marginLeft: "0.4vw",
                                    }}
                                    onClick={() =>
                                      this.onClose(
                                        city.cityName,
                                        city.stateName
                                      )
                                    }
                                  >
                                    x
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </>
                      ))}
                  </Form.Item>

                  <div className="form-titles">Vitual Tour / Video link </div>
                  <Form.Item
                    name="videoLink"
                    initialValue={
                      sellerHomeDetails &&
                      sellerHomeDetails.propertyDetails2 &&
                      sellerHomeDetails.propertyDetails2.videoLink
                    }
                  >
                    <Input
                      placeholder="Start typing"
                      className="form-input"
                      name="videoLink"
                    />
                  </Form.Item>
                  <div className="form-titles">
                    360 Degree Virtual Tour Link: Address
                  </div>
                  <Form.Item
                    name="DgressTourLink360"
                    initialValue={
                      sellerHomeDetails &&
                      sellerHomeDetails.propertyDetails2 &&
                      sellerHomeDetails.propertyDetails2.DgressTourLink360
                    }
                  >
                    <Input
                      placeholder="Start typing"
                      className="form-input"
                      name="videoLink"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: 12, offset: 1 }}
                  lg={{ span: 12, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <>
                    <div className="form-titles">Add Agent</div>
                    <Form.Item name="AddAgent">
                      <Select
                        className="input-width"
                        showSearch
                        optionFilterProp="children"
                        options={this.state.agentsList}
                        onSelect={this.onReferedBySelect}
                        filterOption={(inputValue, option) =>
                          option.label
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        value={this.state.referedAgentName}
                      ></Select>
                      {!savednewsAgentName && (
                        <Button
                          type="primary"
                          onClick={
                            enableAdduser
                              ? this.saveNewAgent
                              : this.handleAddUser
                          }
                          className="add-user-width"
                          icon={<PlusOutlined />}
                          // style={{backgroundColor:enableAdduser ? "red" : "blue",color:"white"}}
                        >
                          {enableAdduser ? "Save Agent" : "Add Agent"}
                        </Button>
                      )}
                    </Form.Item>
                    {enableAdduser && (
                      <>
                        <Row>
                          <Col span={11}>
                            <div className="label-titles">Agent Name</div>
                            <Form.Item
                              name="AgentName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Agent Name!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Agent Name"
                                type="name"
                                value={addAgentName}
                                onChange={(e) =>
                                  this.setState({
                                    addAgentName: e.target.value,
                                  })
                                }
                                className="form-input"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={11} offset={1}>
                            <div className="label-titles">Agent Email</div>
                            <Form.Item
                              name="AgentEmail"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Agent Email!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Agent Email"
                                type="email"
                                value={addAgentEmail}
                                onChange={(e) =>
                                  this.setState({
                                    addAgentEmail: e.target.value,
                                  })
                                }
                                className="form-input"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                  </>

                  <div className="form-titles">
                    Property Description (Tell the story about the home. You can
                    add your phone # if you want direct calls)
                  </div>
                  <Form.Item
                    name="description"
                    initialValue={
                      sellerHomeDetails &&
                      sellerHomeDetails.propertyDetails2.description
                    }
                  >
                    <TextArea
                      placeholder="Start typing"
                      rows={4}
                      name="description"
                      style={{ borderRadius: "5px" }}
                    />
                  </Form.Item>
                  <Spin spinning={this.state.imageLoading}>
                    <div className="form-titles">
                      Sneak Peak (you can add up to 10 photos){" "}
                    </div>
                    <Form.Item name="homePhotos">
                      <Dragger
                        name="file"
                        maxCount={10}
                        multiple
                        onChange={this.uploadHomeImages}
                        listType="picture"
                        fileList={fileList && fileList}
                        // beforeUpload={() => false}
                        customRequest={dummyRequest}
                        style={{ borderRadius: "5px" }}
                      >
                        <>
                          <p className="ant-upload-drag-icon">
                            <img
                              src={uploadImg}
                              alt="upload"
                              width="50px"
                              height="33px"
                            />
                          </p>
                          <p className="ant-upload-text">Drag & Drop image</p>
                        </>
                      </Dragger>
                    </Form.Item>
                  </Spin>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xl={{ span: 7, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Advertisement
              otherAds={false}
              adType="Square"
              adWhereToShow="Homes"
              adLimit="1"
              zipCode=""
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "1vw" }}>
          <Col span={24}>
            <div style={{ backgroundColor: "#F9FAFF", marginTop: "20px" }}>
              <Checkbox checked={true}>
                I Accept: I understand that by submitting this property listing
                it will not mean it has been listed in MLS. I understand that as
                a consumer submitting this listing that this does not allow for
                the property to be syndicated to thousands of search sites and
                it will only be listed on That's my Town. I also understand
                that in order to maximize exposure to my home it may be in my
                best interests to consider listing it on MLS with a Realtor or
                seek an entry-only MLS listing service. As a real estate agent
                entering this listing, I understand that I am subject to all MLS
                and Realtor board rules and regulations which may require me to
                list this property in MLS. By entering this listing I am
                attesting that I am in full compliance with all MLS & Realtor
                board rules and regulations and have in place any required
                contracts at the time of posting this listing which may include
                but not limited to a listing agreement, non-mls listing
                agreement, delayed listing forms, coming soon forms, etc.
                That's my Town will not be responsible for any violations by
                an agent or consumer due to false advertising or lack or
                required contracts, disclosures, etc.
              </Checkbox>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withAppContext(withRouter(OtherDetails));
