import React from "react";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../store/contextStore";
import { Row, Input, Button, Form, notification, Col } from "antd";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import CheckBoxGroup from "./CheckBoxGroup";
import { FilterOutlined } from "@ant-design/icons";

class FilterAgent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      zip: "",
      name: {
        name: "",
        type: "",
      },
      AgentHomes: "",
      cities: {
        cities: [],
        type: "",
      },
      zipCode: {
        zipCode: "",
        type: "",
      },
      specialties: {
        specialties: "",
        type: "",
      },
      enableFilter: false,
      showspecialties: false,
      sortValue: "",
      categories: {
        categories: "",
        type: "",
      },
      setOldSavedSearch: "",
    };
  }

  componentDidMount() {
    const { filtersearch } = this.props.context.state;
    if (filtersearch && filtersearch.length > 0) {
      // filtersearch &&
      // filtersearch.map((singlefilter) => {
      for (let singlefilter of filtersearch) {
        if (singlefilter.type === "NAME") {
          this.setState({
            name: {
              name: singlefilter.name,
              type: "NAME",
            },
          });
        }
        if (singlefilter.type === "ZIP") {
          this.setState({
            zipCode: {
              zipCode: singlefilter.zipCode,
              type: "ZIP",
            },
          });
        }
        if (singlefilter.type === "cities") {
          this.setState({
            cities: {
              cities: singlefilter.multi,
              type: singlefilter.type,
            },
          });
        }
        if (singlefilter.type === "SPA") {
          this.setState({
            specialties: {
              specialties: singlefilter.specialties,
              type: singlefilter.type,
            },
            showspecialties: true,
          });
        }
        if (singlefilter.type === "CTA") {
          this.setState({
            categories: {
              categories: singlefilter.categories,
              type: singlefilter.type,
            },
            showspecialties: true,
          });
        }
      }
      // });
    }
    this.setState({
      showspecialties: true,
    });
  }

  handleClear = () => {
    window.location.reload();
  };

  handleZipCode = (e) => {
    this.setState({
      zipCode: {
        zipCode: e.target.value,
        type: "ZIP",
      },
    });
  };

  handleName = (e) => {
    this.setState({
      name: {
        name: e.target.value,
        type: "NAME",
      },
    });
  };

  setSpecialties = (e) => {
    this.setState({
      specialties: {
        specialties: e,
        type: "SPA",
      },
    });
  };
  setCategories = (e) => {
    this.setState({
      categories: {
        categories: e,
        type: "CTA",
      },
    });
  };

  onClose = (value) => {
    const { cities } = this.state.cities;
    const data = cities.filter((city) => city !== value);
    this.setState({
      cities: {
        cities: data,
        type: "cities",
      },
    });
  };

  updateFilter = () => {
    this.setState({
      enableFilter: false,
    });
    const { cities, zipCode, specialties, name, categories } = this.state;
    const filter = [];
    if (name.type === "NAME") {
      filter.push({ name: name.name, type: name.type });
    }
    if (zipCode.type === "ZIP") {
      filter.push({ zipCode: zipCode.zipCode, type: zipCode.type });
    }
    if (specialties.type === "SPA") {
      filter.push({
        specialties: specialties.specialties,
        type: specialties.type,
      });
    }
    if (categories.type === "CTA") {
      filter.push({
        categories: categories.categories,
        type: categories.type,
      });
    }
    if (cities.type === "cities") {
      filter.push({ multi: cities.cities, type: cities.type });
    }
    this.props.update(filter);
  };

  handleServiceAreas = (address) => {
    let cities = [];
    let stateValue = "";
    let cityValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
        }

        const stateCodes = JSON.parse(
          localStorage.getItem("stateCodesFromAdmin")
        );
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateValue.toLowerCase() ||
            restState.stateType.toLowerCase() === stateValue.toLowerCase()
        );
        if (setVendor && setVendor.length > 0) {
          let newCityAndStates = {
            cityName: cityValue,
            stateName: stateValue,
          };
          cities.push(...this.state.cities.cities, newCityAndStates);
          this.setState({
            cities: {
              cities,
              type: "cities",
            },
          });
        } else {
          notification["error"]({
            message: "Please enter valid city...",
            description: "You can search cities from MA, CT, RI, NH states",
            placement: "topLeft",
          });
        }
      })
      .catch((error) => console.error("Error", error));
  };
  methodOldSavedSearch = () => {
    this.setState({
      setOldSavedSearch: false,
    });
  };
  filterView = () => {
    const property1 = [
      { label: "Buyer representation", value: "Buyer representation" },
      { label: "Seller representation", value: "Seller representation" },
      { label: "Downsizing", value: "Downsizing" },
      { label: "Upsizing", value: "Upsizing" },
      { label: "Relocation", value: "Relocation" },
      { label: "Investment properties", value: "Investment properties" },
      { label: "Flipping", value: "Flipping" },
      { label: "1031 exchanges", value: "1031 exchanges" },
      { label: "Commercial", value: "Commercial" },
      { label: "Land", value: "Land" },
      { label: "New Construction", value: "New Construction" },
      { label: "Builder representation", value: "Builder representation" },
      { label: "waterfront properties", value: "waterfront properties" },
      { label: "Farm/horse properties", value: "Farm/horse properties" },
      { label: "Rentals", value: "Rentals" },
      { label: "First Time Buyers", value: "First Time Buyers" },
      { label: "Short Sales", value: "Short Sales" },
      { label: "Business Representation", value: "Business Representation" },
      { label: "Over 55/Active Adult", value: "Over 55/Active Adult" },
      { label: "Bank Owned", value: "Bank Owned" },
    ];
    const Category = [
      // { label: "Lenders", value: "Lenders" },
      { label: "Attorney Real Estate", value: "Attorney Real Estate" },
      { label: "Attorney Family Law", value: "Attorney Family Law" },
      { label: "Smoke Detector", value: "Smoke Detector" },
      { label: "Paving", value: "Paving" },
      { label: "Carpenter", value: "Carpenter" },
      { label: "Handy Person", value: "Handy Person" },
      { label: "Window Treatment", value: "Window Treatment" },
      { label: "Painter", value: "Painter" },
      { label: "Cleaner", value: "Cleaner" },
      { label: "Attorney", value: "Attorney" },
      { label: "Insurance", value: "Insurance" },
      { label: "Solar", value: "Solar" },
      { label: "Pest Control", value: "Pest Control" },
      { label: "Home Inspector", value: "Home Inspector" },
      { label: "Trash Out", value: "Trash Out" },
      { label: "Dumpsters", value: "Dumpsters" },
      { label: "Kitchen Remodel", value: "Kitchen Remodel" },
      { label: "Moving Company", value: "Moving Company" },
      { label: "Bath Remodel", value: "Bath Remodel" },
      {
        label: "Heating & Cooling (HVAC)",
        value: "Heating & Cooling (HVAC)",
      },
      { label: "Oil Companies", value: "Oil Companies" },
      { label: "Fencing", value: "Fencing" },
      { label: "Architect", value: "Architect" },
      { label: "Builder", value: "Builder" },
      {
        label: "Concrete/Foundation Repair",
        value: "Concrete/Foundation Repair",
      },
      { label: "Roofing Repair/Replace", value: "Roofing Repair/Replace" },
      { label: "Windows/Doors", value: "Windows/Doors" },
      { label: "Flooring", value: "Flooring" },
      { label: "Closet Systems", value: "Closet Systems" },
      { label: "Deck Builder", value: "Deck Builder" },
      { label: "Credit Repair", value: "Credit Repair" },
      { label: "Granite Counters", value: "Granite Counters" },
      { label: "Asbestos Removal", value: "Asbestos Removal" },
      { label: "Lead Paint Removal", value: "Lead Paint Removal" },
      { label: "Plowing", value: "Plowing" },
      { label: "Interior", value: "Interior" },
      { label: "Decorator", value: "Decorator" },
      { label: "Tree Removal", value: "Tree Removal" },
      { label: "Title V inspector", value: "Title V inspector" },
      { label: "Power Washing", value: "Power Washing" },
      { label: "Mosquito Control", value: "Mosquito Control" },
      { label: "Marketing", value: "Marketing" },
      { label: "Other", value: "Other" },
    ];
    const lenderSpecialties = [
      { label: "New Construction", value: "New Construction" },
      { label: "FHA Loans", value: "FHA Loans" },
      { label: "Renovation Loans", value: "Renovation Loans" },
      { label: "Conventional loans", value: "Conventional loans" },
      { label: "First Time Buyers", value: "First Time Buyers" },
      { label: "Investment Loans", value: "Investment Loans" },
      { label: "1031 Exchanges", value: "1031 Exchanges" },
      { label: "Commercial Loans", value: "Commercial Loans" },
      { label: "USDA Loans", value: "USDA Loans" },
      { label: "VA Loans", value: "VA Loans" },
      { label: "Jumbo Loans", value: "Jumbo Loans" },
      { label: "Direct Lender", value: "Direct Lender" },
      { label: "Mass Housing", value: "Mass Housing" },
    ];
    const { zipCode, name, specialties, showspecialties, categories } =
      this.state;
    return (
      <Row style={{ background: "white" }}>
        <Col
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row>
            <Col
              xl={{ span: 10, offset: 1 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              sm={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              className="side-first-row"
            >
              <Button
                type="primary"
                onClick={this.updateFilter}
                className="side-filter"
              >
                Apply
              </Button>
            </Col>
            <Col
              xl={{ span: 10, offset: 2 }}
              lg={{ span: 10, offset: 2 }}
              md={{ span: 10, offset: 2 }}
              sm={{ span: 10, offset: 2 }}
              xs={{ span: 10, offset: 2 }}
              className="side-first-row"
            >
              <Button
                type="ghost"
                onClick={this.handleClear}
                className="side-Reset"
                style={{ float: "right" }}
              >
                Reset All
              </Button>
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="side-second-row"
            >
              <p className="second-row-title">Name</p>
              <Form.Item>
                <Input
                  className="FilterAgent"
                  type="text"
                  onChange={this.handleName}
                  placeholder="Search Name"
                  allowClear
                  value={name.name}
                ></Input>
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="side-second-row"
            >
              <p className="second-row-title">City</p>
              <Form.Item name="city" className="google-city-search">
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_MAPS_KEY}
                  className="city-filter"
                  autocompletionRequest={{
                    types: ["(cities)"],
                    componentRestrictions: {
                      country: ["us"],
                    },
                  }}
                  selectProps={{
                    placeholder: "Search City Name",
                    value: "",
                    onChange: (result) => {
                      this.handleServiceAreas(result);
                    },
                    style: { padding: "2vw" },
                  }}
                />
                {this.state.cities.cities &&
                  this.state.cities.cities.map((city, index) => (
                    <div
                      key={index}
                      style={{
                        display: "inline-flex",
                        margin: "0.73vw",
                        padding: "0 7px",
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                        border: "1px solid darkgrey",
                      }}
                    >
                      <div>
                        {city.cityName}
                        {", "}
                        {city.stateName}
                      </div>
                      <div
                        style={{
                          color: "grey",
                          cursor: "pointer",
                          marginLeft: "0.4vw",
                        }}
                        onClick={() => this.onClose(city)}
                      >
                        x
                      </div>
                    </div>
                  ))}
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="side-second-row"
            >
              <p className="second-row-title">Zip</p>
              <Form.Item>
                <Input
                  className="FilterAgent"
                  type="number"
                  onChange={this.handleZipCode}
                  placeholder="Search Zip"
                  allowClear
                  value={zipCode.zipCode}
                ></Input>
              </Form.Item>
            </Col>

            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="side-second-row"
            >
              {window.location.pathname === "/marketplace" ? (
                <>
                  <p className="second-row-title" style={{ marginLeft: "5px" }}>
                    Service Category
                  </p>
                  <Form.Item name="Specialties">
                    {showspecialties && (
                      <CheckBoxGroup
                        Category
                        plainOptions={Category}
                        handleCheckCategory={this.setCategories}
                        checkBoxFor={
                          categories.categories &&
                          categories.categories.length > 0
                            ? categories.categories
                            : []
                        }
                        methodOldSavedSearch={this.methodOldSavedSearch}
                      />
                    )}
                  </Form.Item>
                </>
              ) : (
                <>
                  <p className="second-row-title">Specialties</p>
                  <Form.Item name="Specialties">
                    {showspecialties && (
                      <CheckBoxGroup
                        property
                        plainOptions={
                          this.props.lender ? lenderSpecialties : property1
                        }
                        handleCheckProperty={this.setSpecialties}
                        checkBoxFor={
                          specialties.specialties &&
                          specialties.specialties.length > 0
                            ? specialties.specialties
                            : []
                        }
                        methodOldSavedSearch={this.methodOldSavedSearch}
                      />
                    )}
                  </Form.Item>
                </>
              )}
            </Col>
            <Col
              xl={{ span: 10, offset: 1 }}
              lg={{ span: 10, offset: 1 }}
              md={{ span: 10, offset: 1 }}
              sm={{ span: 10, offset: 1 }}
              xs={{ span: 10, offset: 1 }}
              className="side-first-row"
            >
              <Button
                type="primary"
                onClick={this.updateFilter}
                className="side-filter"
              >
                Apply
              </Button>
            </Col>
            <Col
              xl={{ span: 10, offset: 2 }}
              lg={{ span: 10, offset: 2 }}
              md={{ span: 10, offset: 2 }}
              sm={{ span: 10, offset: 2 }}
              xs={{ span: 10, offset: 2 }}
              className="side-first-row"
            >
              <Button
                type="ghost"
                onClick={this.handleClear}
                className="side-Reset"
                style={{ float: "right" }}
              >
                Reset All
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  handleSort = (sortValue) => {
    this.props.handleSort(sortValue);
  };
  render() {
    const { enableFilter, enableInfo } = this.state;
    return (
      <>
        <div className="show-on-desktop">{this.filterView()}</div>
        <div className="show-on-mobile">
          <Row
            // justify="space-between"
            style={{ marginTop: "10px", marginBottom: "0px" }}
          >
            <Col md={{ span: 17 }} sm={{ span: 18 }} xs={{ span: 17 }}>
              <p
                style={{
                  fontWeight: 600,
                  marginBottom: "0px",
                }}
              >
                {window.location.pathname === "/agent"
                  ? "Find an agent to match your needs"
                  : window.location.pathname === "/lenders"
                  ? "Find a lender to match your needs"
                  : window.location.pathname === "/marketplace"
                  ? "Find a vendor to match your needs"
                  : ""}
              </p>
            </Col>
            <Col md={{ span: 7 }} sm={{ span: 6 }} xs={{ span: 7 }}>
              <Button
                onClick={() => {
                  this.setState({
                    enableFilter: !enableFilter,
                  });
                }}
                type="primary"
                icon={<FilterOutlined />}
                ghost
                style={{ float: "right", width: "100%" }}
              >
                Filters
              </Button>
            </Col>
          </Row>
          {enableInfo && this.offmlsInfo()}
          {enableFilter && this.filterView()}
        </div>
      </>
    );
  }
}

export default withAppContext(withRouter(FilterAgent));
