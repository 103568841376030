import React, { Component } from "react";
import { Layout, Row, Col, Button } from "antd";
import "antd/dist/antd.css";
import { Link, withRouter } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { withAppContext } from "../../../store/contextStore";
import { list } from "../../../api/Apis";
import HomeCard from "../../../components/Card/HomeCard";
import Advertisement from "../../../common/Advertisement";
import DataNotFound from "../../../common/DataNotFound";
import Loading from "../../../common/Loading";
import Pagination from "../../../components/pagination/Paginations";

const { Content } = Layout;
class AllFavourties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allFavouritesData: [],
      properties: "",
      count: "",
      emptyData: false,
      dataLoading: false,
      currentPage: 1,
      perpageSize: 10,
      filterStatus: false,
      city: "",
      Favorites: [],
      favoritesPageLoading: true,
      shaFavoritesData: false,
      minValue: 0,
      maxValue: 10,
    };
  }

  componentDidMount = async () => {
    this.getFourites();

    if (
      window.location.pathname === `/favourties/${this.props.match.params.id}`
    ) {
      this.setState({
        shaFavoritesData: true,
      });
    }

    if (
      this.props.context.state.currentPage &&
      this.props.context.state.pageSize
    ) {
      this.setState({
        currentPage: this.props.context.state.currentPage,
      });
      this.handlePagination(
        this.props.context.state.currentPage,
        this.props.context.state.pageSize
      );
    }
  };

  getFourites = async () => {
    const loginUserId = JSON.parse(localStorage.getItem("userDetails"));
    this.setState({
      dataLoading: true,
      currentPage: this.state.currentPage,
    });
    const userId = loginUserId.id;

    const getFavorites = await list("userFavorites", { userId: userId });
    if (getFavorites.success) {
      this.setState({
        allFavouritesData: getFavorites.data,
        dataLoading: false,
        favoritesPageLoading: false,
      });
      this.props.context.handleFavoritesCount(
        getFavorites.data,
        getFavorites.data.length
      );
    }
  };

  handlePagination = async (page, pageSize) => {
    this.props.context.handleToSetCurrentPage(page, pageSize);
    this.setState({
      dataLoading: true,
    });
    if (page <= 1) {
      this.setState({
        minValue: 0,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (page - 1) * pageSize,
        maxValue: page * pageSize,
      });
    }
    await this.setState({
      currentPage: page,
      perpageSize: pageSize,
    });
    setTimeout(() => {
      this.setState({
        dataLoading: false,
      });
    }, 3000);
  };

  handleLoadMoreButton = (total, currentPage) => {
    this.props.handleMLSDataCall(total, currentPage);
  };

  handleItems = (userid, agent, profileUrl, authenticate, locationState) => {
    this.props.context.setOffMLSuserId(
      userid,
      agent,
      profileUrl,
      authenticate,
      locationState
    );
  };

  render() {
    const {
      dataLoading,
      allFavouritesData,
      shaFavoritesData,
      currentPage,
      perpageSize,
    } = this.state;
    const userLogin = localStorage.getItem("userLogin");
    let data = [];
    allFavouritesData &&
      allFavouritesData.map(async (e) =>
        data.push({
          mls: e.leadId && typeof e.leadId === "number" ? true : false,
          favouriteId: e.id,
          id: e.leadId,
          title: e.data && e.data.title,
          date: e.date,
          cost:
            e.data && e.data.cost
              ? e.data.cost
              : e.data && e.data.OriginalListprice
              ? e.data.OriginalListprice
              : "",
          type:
            e.data && e.data.type
              ? e.data.type
              : e.data && e.data.PropertyType
              ? e.data.PropertyType
              : "",
          agent:
            e.data && e.data.homeAgentName
              ? e.data.homeAgentName
              : e.data.userRegisterd && e.data.userRegisterd.length > 0
              ? e.data.userRegisterd[0].firstName +
                "" +
                e.data.userRegisterd[0].lastName
              : e.data && e.data.agent,
          baths: e.data && e.data.baths,
          beds: e.data && e.data.beds,
          area:
            e.data && e.data.maxArea
              ? e.data.maxArea
              : e.data && e.data.area
              ? e.data.area
              : "",
          photos:
            e.data && e.data.photoIds
              ? e.data.photoIds
              : e.data && e.data.homePhotos
              ? e.data.homePhotos.length > 0 && e.data.homePhotos[0]
              : e.data && e.data.photos && e.data.photos,
          homeAgentName: e.data && e.data.homeAgentName,
          homeAgentProfileUrl:
            e.data && e.data.homeAgentProfileUrl
              ? e.data.homeAgentProfileUrl
              : e.data.userRegisterd &&
                e.data.userRegisterd.length > 0 &&
                e.data.userRegisterd[0] &&
                e.data.userRegisterd[0].profileUrl,

          labels:
            e.data && e.data.Labels
              ? e.data.Labels
              : e.data && e.data.labels
              ? e.data.labels
              : "",
          stateName: e.stateType && e.stateType,
          favorited: true,
          userid: e.data && e.data.userid ? e.data.userid : "",
        })
      );

    return (
      <div className="homeSearch">
        <Loading enableLoading={dataLoading} />
        <Layout>
          <Layout
            style={{ backgroundColor: "grey" }}
            className="margin-body-agent"
          >
            <Layout>
              <Content style={{ paddingBottom: "2%" }}>
                <Row>
                  <Col
                    xl={{ span: 18, offset: 0 }}
                    lg={{ span: 18, offset: 0 }}
                    md={{ span: 22, offset: 1 }}
                    sm={{ span: 22, offset: 1 }}
                    xs={{ span: 22, offset: 1 }}
                    style={{ marginBottom: "0.55vw" }}
                  >
                    <p className="listing-heading">All Favorties</p>
                    <Link to="/homes" style={{ marginLeft: "-15px" }}>
                      <Button
                        type="text"
                        icon={<ArrowLeftOutlined />}
                        className="font-family-style font-medium-size"
                      >
                        Go Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl={{ span: 18, offset: 0 }}
                    lg={{ span: 18, offset: 0 }}
                    md={{ span: 22, offset: 1 }}
                    sm={{ span: 22, offset: 1 }}
                    xs={{ span: 22, offset: 1 }}
                  >
                    {data && data.length === 0 && !dataLoading ? (
                      <div style={{ padding: "4vw" }}>
                        <DataNotFound
                          type="favourite"
                          title="You don't have any listings marked as favorites"
                        />
                      </div>
                    ) : (
                      <>
                        {data &&
                          shaFavoritesData &&
                          data.length > 0 &&
                          data
                            .slice(this.state.minValue, this.state.maxValue)
                            .map((val, index) => {
                              if (index % 4 === 0 && index > 1) {
                                return (
                                  <HomeCard
                                    value={val}
                                    getFourites={this.getFourites}
                                    favourite
                                    authenticatePage={userLogin}
                                    contextProps={this.props.context}
                                    handleItems={this.handleItems}
                                    handleFavoritesCount={
                                      this.handleFavoritesCount
                                    }
                                  />
                                );
                              } else {
                                return (
                                  <HomeCard
                                    value={val}
                                    getFourites={this.getFourites}
                                    favourite
                                    authenticatePage={userLogin}
                                    contextProps={this.props.context}
                                    handleItems={this.handleItems}
                                    handleFavoritesCount={
                                      this.handleFavoritesCount
                                    }
                                  />
                                );
                              }
                            })}
                      </>
                    )}
                  </Col>

                  <Col
                    xl={{ span: 5, offset: 0 }}
                    lg={{ span: 5, offset: 0 }}
                    md={{ span: 22, offset: 1 }}
                    sm={{ span: 22, offset: 1 }}
                    xs={{ span: 22, offset: 1 }}
                    style={{ marginTop: "0.45vw" }}
                    className="margin-left-agent"
                  >
                    <Advertisement
                      otherAds={false}
                      adType="Square"
                      adWhereToShow="Favorites"
                      adLimit="10"
                      zipCode=""
                    />
                  </Col>
                  {!dataLoading && data && data.length > 0 && (
                    <Col
                      xl={{ span: 18, offset: 0 }}
                      lg={{ span: 18, offset: 0 }}
                      md={{ span: 22, offset: 1 }}
                      sm={{ span: 22, offset: 1 }}
                      xs={{ span: 22, offset: 1 }}
                      className="align-right"
                      style={{
                        marginTop: "20px",
                        marginBottom: "30px",
                      }}
                    >
                      <Pagination
                        total={data.length}
                        handlePagination={this.handlePagination}
                        currentPage={currentPage}
                        handleLoadMoreButton={this.handleLoadMoreButton}
                        perpageSize={perpageSize}
                      />
                    </Col>
                  )}
                </Row>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default withAppContext(withRouter(AllFavourties));
