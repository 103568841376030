import React, { Component } from "react";
import { Card, Row, Col, notification } from "antd";
import Carousel from "react-elastic-carousel";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import Item from "./Item";
import { noAuthlist } from "../../api/Apis";
import arroworangeIcon from "../../../src/images/ArrowOrangeIcon.svg";
class BuyerProfileContext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyersList: [],
      testimonialsList: [],
      dataLoading: false,
    };
  }

  componentDidMount() {
    this.getUserTestmonials();
    this.getBuyers();
  }

  getUserTestmonials = async () => {
    this.setState({
      dataLoading: true,
    });
    try {
      const response = await noAuthlist(
        "testimonials?limit=4&sortBy=updatedAt"
      );
      // .slice(0, 4)
      if (response.success) {
        this.setState({
          testimonialsList: response.data,
          dataLoading: false,
        });
      } else {
        notification["error"]({
          message: response.data.message,
        });
        this.setState({
          dataLoading: false,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      window.location.assign("/pageNotFound/newtWork");
      this.setState({
        dataLoading: false,
      });
    }
  };

  getBuyers = async () => {
    this.setState({
      dataLoading: true,
    });
    try {
      const response = await noAuthlist("getBuyers?limit=10&sortBy=updatedAt");
      if (response.success) {
        let publicBuyers = response.data.filter((buyer) => {
          return buyer.buyerPropertyDetails2.showOnBuyersPage && buyer;
        });

        this.setState({
          buyersList: publicBuyers.slice(0, 4),
          dataLoading: false,
        });
      } else {
        notification["error"]({
          message: response.data.message,
        });
        this.setState({
          dataLoading: false,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      window.location.assign("/pageNotFound/newtWork");
      this.setState({
        dataLoading: false,
      });
    }
  };
  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  render() {
    const { buyersList, dataLoading } = this.state;
    return (
      <div>
        <Row className="padding-top" style={{ background: " #F9FAFF" }}>
          <Col
            xl={{ span: 8, offset: 2 }}
            lg={{ span: 8, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <div>
              <p className="buyers-text">Buyer Profiles</p>
              <p className="buyers-content">
                Find and contact buyers and agents with buyers who may be a
                match for one of your listings or off market homes for sale.
              </p>
              <Link to="/buyers" onClick={this.handleLink}>
                <p style={{ color: "#F75B47", fontSize: "20px" }}>
                  Learn more{" "}
                  {
                    <img
                      src={arroworangeIcon}
                      style={{ marginLeft: "10px" }}
                      alt="arrow-icon"
                    />
                  }
                </p>
              </Link>
            </div>
          </Col>

          <Col
            xl={{ span: 10, offset: 2 }}
            lg={{ span: 12, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Row gutter={[30, 30]} className="row-gap-home">
              {buyersList.map((buyer) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12} key={buyer.id} >
                  <Link
                    to={`/buyersHome/${buyer.id}`}
                    style={{ textDecoration: "none" }}
                    onClick={this.handleLink}
                  >
                    <Card
                      hoverable
                      loading={dataLoading}
                      className="card-minHeight"
                    >
                      <p className="private-color">
                        <b className="card-title-homes">
                          {buyer.buyerPropertyDetails2.title}
                        </b>
                      </p>

                      {buyer.buyerPropertyDetails.stateTypeAdded &&
                        buyer.buyerPropertyDetails.updatedInterestedTowns &&
                        buyer.buyerPropertyDetails.updatedInterestedTowns
                          .length > 0 && (
                          <Row className="card-description-homes">
                            <p className="private-color-towns">
                              Towns of Interest :{" "}
                              <span
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {buyer.buyerPropertyDetails.updatedInterestedTowns.map(
                                  (city, index) => (
                                    <>
                                      {city.cityName + ", "}
                                      {city.stateName.toLowerCase() ===
                                      "massachusetts"
                                        ? "MA"
                                        : city.stateName.toLowerCase() ===
                                          "connecticut"
                                        ? "CT"
                                        : "RI"}
                                      {index !==
                                      buyer.buyerPropertyDetails
                                        .updatedInterestedTowns.length -
                                        1
                                        ? ". "
                                        : "."}
                                    </>
                                  )
                                )}
                              </span>
                            </p>
                          </Row>
                        )}

                      <Row>
                        <p className="private-color-budget">
                          {"Budget :"}&nbsp;
                        </p>
                        <p className="private-color-budget">
                          <NumberFormat
                            value={buyer.buyerPropertyDetails.minprice}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                          {" - "}
                          <NumberFormat
                            value={buyer.buyerPropertyDetails.maxprice}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </p>
                      </Row>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <Row className="padding-row-home">
          <Col
            xl={{ span: 8, offset: 1 }}
            lg={{ span: 8, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <p className="contentTitle">Recent reviews from our agents</p>
          </Col>
          <Col
            xl={{ span: 13, offset: 1 }}
            lg={{ span: 13, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <div className="home-carousel">
              {/* 
                Don't delete
                <Carousel itemsToShow={1} itemsToScroll={1}>
                  {testimonialsList.map((testimonial) => (
                    <Col span={24}>
                      <Row style={{ padding: "3vw" }}>
                        <Col span={24}>
                          <List.Item>
                            <Skeleton
                              avatar
                              title={false}
                              loading={dataLoading}
                              active
                            >
                              <List.Item.Meta
                                avatar={
                                  <Button
                                    type="ghost"
                                    shape="circle"
                                    size="large"
                                    block={true}
                                  >
                                    {testimonial.postedBy !== null
                                      ? testimonial.postedBy.firstName
                                          .charAt(0)
                                          .toUpperCase()
                                      : testimonial.firstName
                                          .charAt(0)
                                          .toUpperCase()}
                                  </Button>
                                }
                                title={
                                  testimonial.postedBy !== null
                                    ? testimonial.postedBy.firstName +
                                      " " +
                                      testimonial.postedBy.lastName
                                    : testimonial.firstName +
                                      " " +
                                      testimonial.lastName
                                }
                                description={
                                  <Col span={24}>
                                    <h5>
                                      <Rate
                                        disabled
                                        defaultValue={testimonial.rating}
                                      />
                                      <span
                                        style={{
                                          fontWeight: "300",
                                          color: "grey",
                                          float: "right",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {moment(testimonial.updatedAt).format(
                                          "lll"
                                        )}
                                      </span>
                                    </h5>
                                  </Col>
                                }
                              />
                            </Skeleton>
                          </List.Item>
                        </Col>
                        <Col span={24} style={{ marginTop: "-10px" }}>
                          <h5>{testimonial.testimonialDesc}</h5>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Carousel> 
                Don't delete
                */}

              <Carousel itemsToShow={1} itemsToScroll={1}>
                <Item>
                  <div className="contentStyle" style={{ marginTop: "0px" }}>
                    <p
                      style={{
                        marginBottom: "0.5rem",
                        fontWeight: "bold",
                        fontSize: "22px",
                        color: "black",
                      }}
                    >
                      Tracy Barber
                    </p>
                    <p
                      style={{
                        marginBottom: "0.5rem",
                        fontWeight: "bold",
                        color: "#727272",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      Founder{" "}
                    </p>
                    <p
                      className="private-color"
                      style={{ color: "#414555", fontSize: "18px" }}
                    >
                      That's my Town is here to help you build a brighter
                      future in real estate. Let us know how we are doing.
                      Submit a question or suggestion through the contact page
                      and/or provide us with a testimonial on your profile page.
                      Together the future is bright!
                    </p>
                  </div>
                </Item>
                <Item>
                  <div className="contentStyle" style={{ marginTop: "0px" }}>
                    <p
                      style={{
                        marginBottom: "0.5rem",
                        fontWeight: "bold",
                        fontSize: "22px",
                        color: "black",
                      }}
                    >
                      Mike F
                    </p>
                    <p
                      style={{
                        marginBottom: "0.5rem",
                        fontWeight: "bold",
                        color: "#727272",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      Lender{" "}
                    </p>
                    <p
                      className="private-color"
                      style={{ color: "#414555", fontSize: "18px" }}
                    >
                      That's my Town is easy to use and keeps all parties
                      involved in a home sale on the same page. We have gotten
                      all high quality leads from here.
                    </p>
                  </div>
                </Item>
                <Item>
                  <div className="contentStyle" style={{ marginTop: "0px" }}>
                    <p
                      style={{
                        marginBottom: "0.5rem",
                        fontWeight: "bold",
                        fontSize: "22px",
                        color: "black",
                      }}
                    >
                      Nick D
                    </p>
                    <p
                      style={{
                        marginBottom: "0.5rem",
                        fontWeight: "bold",
                        color: "#727272",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      Agent{" "}
                    </p>
                    <p
                      className="private-color"
                      style={{ color: "#414555", fontSize: "18px" }}
                    >
                      This is the first platform that is great for agents,
                      buyers, sellers and vendors too. I love the matches shown
                      for my different buyer preferences. It cuts by search time
                      by half. Thank you That's my Town.
                    </p>
                  </div>
                </Item>
              </Carousel>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BuyerProfileContext;
