import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "antd/dist/antd.css";
import { Button } from "antd";
import { FullscreenOutlined } from "@ant-design/icons";

export default class ImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      images: [],
    };
  }

  componentDidMount() {
    if (this.props && this.props.photos) {
      this.setState({
        images: this.props.photos,
      });
    }
  }

  render() {
    const { photoIndex, isOpen, images } = this.state;

    return (
      <div>
        <Button
          //   className="btn-message-width"
          style={{
            height: "40px",
            background: "#FC572B",
            borderColor: "#fc572b",
            color: "white",
            zIndex: 1,
            // opacity:"0.8"
          }}
          onClick={() => this.setState({ isOpen: true })}
        >
          <FullscreenOutlined style={{ fontSize: "30px" }} />
        </Button>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}
