import React from "react";
import {
  Layout,
  Row,
  Col,
  Input,
  Modal,
  Button,
  Collapse,
  Select,
  Switch,
  Skeleton,
  notification,
  Popconfirm,
  Tooltip,
  Checkbox,
} from "antd";
import "antd/dist/antd.css";
import {
  DeleteOutlined,
  InfoCircleOutlined,
  FilterOutlined,
  FileSearchOutlined,
  BellOutlined,
  BellFilled,
  InfoCircleTwoTone,
  ShareAltOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Auth } from "aws-amplify";
import axios from "axios";
import NumberFormat from "react-number-format";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { withRouter, Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
// import { SearchOutlined } from "@ant-design/icons";
import { withAppContext } from "../../store/contextStore";
import { add, update, deleteRecord } from "../../api/Apis";
import Advertisement from "../../common/Advertisement";
import Sliders from "../filter/Sliders";
import Number from "../filter/Number";
import Loading from "../../common/Loading";
import CheckBoxGroup from "../filter/CheckBoxGroup";
import HomeCard from "../Card/HomeCard";
import Pagination from "../../components/pagination/Paginations";
import DataNotFound from "../../common/DataNotFound";
import moment from "moment";
import bedIcon from "../../images/awesome-bed-icon.png";
import bathIcon from "../../images/awesome-bath-icon.png";
import reSize from "../../images/sq-feet-icon.png";

const queryString = require("query-string");
const { Option } = Select;
const Panel = Collapse.Panel;

const property1 = [
  { label: "Land", value: "LND" },
  { label: "Mobile Homes", value: "MBL" },
  {
    label: "Residential Multi Family Home",
    value: "MLF",
  },
  {
    label: "Residential Single Family Home",
    value: "RES",
  },
  { label: "Residential Rental ", value: "RNT" },
  { label: "Residential Condominium", value: "CND" },
  { label: "Coopertive", value: "Coopertive" },
  { label: "Commercial Office", value: "CO" },
  { label: "Commercial MLS", value: "CM" },
  { label: "Business Opportunity", value: "BO" },
];
class HomeTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: false,
      divHeight: 0,
      enableInfo: false,
      sortByCount: 0,

      /*** filterState ***/
      enableFilter: false,
      citiesList: [],
      allHomesList: [],
      homesListCount: 0,
      sortOrder: "",
      displayCitiesAndStates: [],
      minValue: 0,
      maxValue: 10,
      currentPage: 1,
      perpageSize: 10,
      dataLoading: false,
      apiCallSuccessMsg: false,
      halfBathState: false,
      enableSaveSearch: false,
      enableSearchTitle: false,
      searchTitle: "",

      price: {
        minprice: "",
        maxprice: "",
        type: "",
      },
      area: {
        minarea: 1,
        maxarea: 20000,
        type: "",
      },
      beds: {
        minbeds: 0,
        maxbeds: 0,
        type: "",
      },
      baths: {
        minbaths: 0,
        maxbaths: 0,
        type: "",
      },
      subTypeText: {
        subTypeText: [],
        type: "",
      },
      status: {
        status: "",
        type: "",
      },
      label: {
        label: [],
        type: "",
      },
      cities: {
        searchLocation: [],
        type: "",
        citiesList: [],
      },
      halfBath: {
        halfBath: false,
        type: "",
      },
      openHouses: {
        openHouses: false,
        type: "",
      },
      showPropertyType: [],
      showPropertyStatus: [],
      userSavedSearchList: [],
      switchHalfBath: false,
      showcheckItemNamesLables: [],
      showcheckItemNamesStatus: [],
      showcheckItemNamesProperty: [],

      maOffset: 0,
      ctOffset: 0,
      riOffset: 0,
      nhOffset: 0,
      localOffset: 0,

      setOldSavedSearch: false,
      showingAdsAtSearch: false,
      buyerPreference: false,
      savedIndex: [],
      save_search_alert_true: true,
      save_search_login_alert_message: false,
      enableShare: "",
      showMatchesPage: "",
      showHeight: [],
      resetState: false,
    };
  }

  componentDidMount() {
    if (
      this.props.context.state.currentPage &&
      this.props.context.state.pageSize
    ) {
      this.setState({
        currentPage: this.props.context.state.currentPage,
      });
      this.handlePagination(
        this.props.context.state.currentPage,
        this.props.context.state.pageSize,
        "Init"
      );
    }

    this.init();
  }

  init = async () => {
    let filterItems = {};
    if (this.props.context.state && this.props.context.state.filterSearchData) {
      filterItems =
        this.props.context.state && this.props.context.state.filterSearchData;
    } else {
      filterItems = {
        filter: true,
        cities:
          this.props.context.state && this.props.context.state.city
            ? this.props.context.state.city
            : undefined,
      };
    }
    filterItems = {
      ...filterItems,
      filter: true,
    };

    if (filterItems && filterItems.sortOrder) {
      this.setState({
        sortOrder: filterItems.sortOrder,
      });
    } else {
      this.setState({
        sortOrder: "-listprice",
      });
      filterItems = {
        ...filterItems,
        sortOrder: "-listprice",
      };
    }

    if (filterItems && filterItems.halfBaths) {
      this.setState({
        halfBath: {
          halfBath: filterItems.halfBaths === "yes" ? true : false,
          type: "halfBath",
        },
        switchHalfBath: true,
      });
    } else {
      this.setState({
        switchHalfBath: true,
      });
    }

    if (filterItems && filterItems.openHouses) {
      this.setState({
        openHouses: {
          openHouses: filterItems.openHouses === "yes" ? true : false,
          type: "openHouses",
        },
        switchHalfBath: true,
      });
    } else {
      this.setState({
        switchHalfBath: true,
      });
    }

    const cityData = JSON.parse(localStorage.getItem("selectedCities"));
    if (cityData && cityData !== "") {
      this.setState({
        cities: {
          citiesList: cityData.citiesList,
          searchLocation: cityData.searchLocation,
          type: "location",
        },
      });
      filterItems = {
        ...filterItems,
        cities: cityData.citiesList.join(),
      };
    }

    if (filterItems.types) {
      let selectedPropertyTypes = JSON.parse(
        localStorage.getItem("selectedPropertyTypes")
      );
      let filterTypes = [];
      // if (!filterItems.types?.length > 0) {
      filterTypes = filterItems.types.split(",").map((item) => item.trim());
      // }
      this.setState({
        subTypeText: {
          subTypeText: filterTypes,
          type: "subtype",
        },
        showPropertyType: selectedPropertyTypes,
      });

      if (
        selectedPropertyTypes?.length === 0 ||
        selectedPropertyTypes === null
      ) {
        selectedPropertyTypes = localStorage.setItem(
          "selectedPropertyTypes",
          JSON.stringify(filterTypes)
        );
        this.setState({
          showPropertyType: filterTypes,
        });
      }
    }

    if (filterItems.status) {
      const selectedPropertyStatus = JSON.parse(
        localStorage.getItem("selectedPropertyStatus")
      );
      this.setState({
        status: {
          status: filterItems.status,
          type: "status",
        },
        showPropertyStatus: selectedPropertyStatus,
      });

      if (
        selectedPropertyStatus?.length === 0 ||
        selectedPropertyStatus === null
      ) {
        this.setState({
          showPropertyStatus: filterItems.status,
        });
      }
    }
    /*  INITIAL API WITH FILTER ITEMS */
    this.getHomesList(
      filterItems,
      window.location.pathname !== "/offMLSHomes" ? "both" : "off"
    );
    if (filterItems) {
      const detailsPageFilter = {
        ...filterItems,
        mls: window.location.pathname === "/homes" ? "both" : "off",
      };
      localStorage.setItem(
        "filterSearchData",
        JSON.stringify(detailsPageFilter)
      );
    }
    setTimeout(() => {
      this.setUserSavedSearchList();
    }, 2000);
  };
  setUserSavedSearchList = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails && userDetails.userSavedSearch) {
      let userSavedSearchData = userDetails.userSavedSearch;
      await this.setState({
        userSavedSearchList: userSavedSearchData,
      });
    }
  };
  handleSort = async (sortOrder) => {
    await this.setState({ sortOrder }, () => {
      this.update(false);
    });
  };

  handleSearchTitle = (e) => {
    this.setState({
      searchTitle: e.target.value.toLowerCase(),
    });
  };

  /* APPLYING FILTER  */
  update = (isSaveSearch) => {
    const {
      price,
      area,
      beds,
      baths,
      subTypeText,
      status,
      label,
      halfBath,
      cities,
      sortOrder,
      searchTitle,
      openHouses,
    } = this.state;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const userLogin = JSON.parse(localStorage.getItem("userDetails"));
    this.setState({
      currentPage: 1,
      minValue: 0,
      maxValue: 10,
      perpageSize: 10,
      showingAdsAtSearch: true,
      save_search_login_alert_message: !userLogin && true,
    });

    if (isSaveSearch && isSaveSearch === true && searchTitle === "") {
      notification["error"]({
        message: "Please enter title",
      });
      return;
    }

    let filterObject = {};
    if (price.type === "price") {
      filterObject = {
        ...filterObject,
        minprice: price.minprice,
        maxprice: price.maxprice,
      };
    }

    if (area.type === "area") {
      filterObject = {
        ...filterObject,
        minarea: parseInt(area.minarea),
        maxarea: parseInt(area.maxarea),
      };
    }

    if (beds.type === "beds") {
      filterObject = {
        ...filterObject,
        minbeds: beds.minbeds,
      };
    }

    if (baths.type === "baths") {
      filterObject = {
        ...filterObject,
        minbaths: baths.minbaths,
      };
    }

    if (subTypeText.type === "subtype") {
      filterObject = {
        ...filterObject,
        types: subTypeText.subTypeText,
      };
    }

    if (status.type === "status") {
      filterObject = {
        ...filterObject,
        status: status.status,
      };
    }

    if (label.type === "label") {
      filterObject = {
        ...filterObject,
        label: label.label,
      };
    }

    if (cities.type === "location") {
      filterObject = {
        ...filterObject,
        cities: cities.citiesList,
      };
    }

    if (halfBath.type === "halfBath") {
      filterObject = {
        ...filterObject,
        halfBath: halfBath.halfBath,
      };
    }
    if (openHouses.type === "openHouses") {
      filterObject = {
        ...filterObject,
        openHouses: openHouses.openHouses,
      };
    }
    let finalFilter = {};

    if (filterObject.maxprice) {
      finalFilter = {
        ...finalFilter,
        maxprice: filterObject.maxprice,
      };
    }

    if (filterObject.minprice) {
      finalFilter = {
        ...finalFilter,
        minprice: filterObject.minprice,
      };
    }

    if (filterObject.maxarea) {
      finalFilter = {
        ...finalFilter,
        maxarea: filterObject.maxarea,
      };
    }

    if (filterObject.minarea) {
      finalFilter = {
        ...finalFilter,
        minarea: filterObject.minarea,
      };
    }

    if (filterObject.minbeds) {
      finalFilter = {
        ...finalFilter,
        minbeds: filterObject.minbeds,
      };
    }

    if (filterObject.minbaths) {
      finalFilter = {
        ...finalFilter,
        minbaths: filterObject.minbaths,
      };
    }

    if (filterObject.types && filterObject.types.length > 0) {
      finalFilter = {
        ...finalFilter,
        types:
          typeof filterObject.types === "object"
            ? filterObject.types.join()
            : filterObject.types,
      };
    }
    if (filterObject.status && filterObject.status.length > 0) {
      finalFilter = {
        ...finalFilter,
        status: filterObject.status,
      };
    }

    if (filterObject.label && filterObject.label.length > 0) {
      finalFilter = {
        ...finalFilter,
        labels: filterObject.label.join(),
      };
    }

    if (filterObject.halfBath) {
      finalFilter = {
        ...finalFilter,
        halfBaths: filterObject.halfBath ? "yes" : "no",
      };
      this.setState({
        halfBathState: true,
      });
    } else {
      finalFilter = {
        ...finalFilter,
        halfBaths: filterObject.halfBath ? "yes" : "no",
      };
      this.setState({
        halfBathState: false,
      });
    }

    if (filterObject.openHouses) {
      finalFilter = {
        ...finalFilter,
        openHouses: filterObject.openHouses ? "yes" : "no",
      };
    } else {
      finalFilter = {
        ...finalFilter,
        openHouses: filterObject.openHouses ? "yes" : "no",
      };
    }

    if (filterObject.cities) {
      finalFilter = {
        ...finalFilter,
        cities: filterObject.cities.join(),
      };
    }

    if (sortOrder) {
      finalFilter = {
        ...finalFilter,
        sortOrder: sortOrder,
      };
    }

    this.setState({
      dataLoading: true,
      enableFilter: false,
      showMatchesPage: true,
    });

    finalFilter = {
      ...finalFilter,
      filter: true,
    };

    if (isSaveSearch && isSaveSearch) {
      this.saveSearch(finalFilter);
    } else {
      this.searchFiltredHomes(finalFilter);
    }
  };

  searchFiltredHomes = (
    finalFilter,
    searchingwithOldfilter,
    totalDataSaved
  ) => {
    localStorage.removeItem("selectedPropertyTypes");
    this.setState({
      halfBath: {
        halfBath: false,
        type: "halfBath",
      },
    });
    if (
      searchingwithOldfilter &&
      searchingwithOldfilter === "searchingwithOldfilter"
    ) {
      this.setState({
        setOldSavedSearch: true,
        resetState: true,
      });
      if (finalFilter && finalFilter.minbeds) {
        this.bedSize(finalFilter.minbeds);
      }
      if (finalFilter && finalFilter.minbaths) {
        this.bathSize(finalFilter.minbaths);
      }
      if ((finalFilter && finalFilter.minprice) || finalFilter.maxprice) {
        this.handlePrice(finalFilter.minprice, finalFilter.maxprice);
      }
      if ((finalFilter && finalFilter.minarea) || finalFilter.maxarea) {
        this.handleLivingArea(finalFilter.minarea, finalFilter.maxarea);
      }
      if (finalFilter && finalFilter.types) {
        let typeInfo = [];
        totalDataSaved?.showcheckItemNamesProperty?.forEach((element) => {
          typeInfo?.push(element.value);
        });
        this.handleCheckProperty(typeInfo);
      }
      if (finalFilter && finalFilter.status) {
        this.handleCheckStatus(finalFilter.status);
      }
      if (finalFilter && finalFilter.halfBaths) {
        this.setState({
          halfBath: {
            halfBath: finalFilter.halfBaths === "yes" ? true : false,
            type: "halfBath",
          },
        });
      }
    }
    this.props.context.setFilterData(finalFilter);
    let detailsPageFilter = finalFilter;
    detailsPageFilter = {
      ...detailsPageFilter,
      mls: window.location.pathname === "/homes" ? "both" : "off",
    };
    localStorage.setItem("filterSearchData", JSON.stringify(detailsPageFilter));
    if (window.location.pathname === "/offMLSHomes") {
      this.getHomesList(finalFilter, "off");
    } else {
      if (
        finalFilter.status === "For Sale by Owner" ||
        finalFilter.status === "For Sale (Off MLS)"
      ) {
        this.getHomesList(finalFilter, "off");
      } else {
        this.getHomesList(finalFilter, "both");
      }
    }

    if (finalFilter.cities) {
      if (
        searchingwithOldfilter &&
        searchingwithOldfilter === "searchingwithOldfilter"
      ) {
        let cities =
          totalDataSaved &&
          totalDataSaved.showCities &&
          totalDataSaved.showCities;
        localStorage.setItem("selectedCities", JSON.stringify(cities));
        this.setState({
          cities,
        });
      } else {
        localStorage.setItem(
          "selectedCities",
          JSON.stringify(this.state.cities)
        );
      }
    } else {
      localStorage.removeItem("selectedCities");
      this.setState({
        cities: {
          searchLocation: [],
          type: "",
          citiesList: [],
        },
      });
    }
    if (finalFilter && finalFilter.halfBaths) {
      this.setState({
        halfBath: {
          halfBath: finalFilter.halfBaths === "yes" ? true : false,
          type: "halfBath",
        },
        switchHalfBath: true,
      });
    }
    if (finalFilter && finalFilter.openHouses) {
      this.setState({
        openHouses: {
          openHouses: finalFilter.openHouses === "yes" ? true : false,
          type: "openHouses",
        },
        switchHalfBath: true,
      });
    }
    if (finalFilter.types) {
      if (
        searchingwithOldfilter &&
        searchingwithOldfilter === "searchingwithOldfilter"
      ) {
        localStorage.setItem(
          "selectedPropertyTypes",
          JSON.stringify(finalFilter.types.split(","))
        );
      } else {
        localStorage.setItem(
          "selectedPropertyTypes",
          JSON.stringify(this.state.showPropertyType)
        );
      }
    }

    if (finalFilter.status) {
      localStorage.setItem(
        "selectedPropertyStatus",
        JSON.stringify(this.state.showPropertyStatus)
      );
    }
    if (finalFilter.sortOrder) {
      this.setState({
        sortOrder: finalFilter.sortOrder,
      });
    }
  };

  delSaveSearchHome = async (deleteItem, index) => {
    let userDetails = this.props.context.state.userDetails;
    let savedSearchList = [];
    if (
      this.props.context.state &&
      this.props.context.state.userDetails &&
      this.props.context.state.userDetails.userSavedSearch
    ) {
      savedSearchList = this.props.context.state.userDetails.userSavedSearch;
    }

    // let searchListData = savedSearchList.filter(
    //   (item) => item.title !== deleteItem
    // );
    savedSearchList.splice(index, 1);
    const data = {
      entityData: {
        userSavedSearch: savedSearchList,
      },
    };
    deleteRecord("buyers", deleteItem.preferenceId)
      .then((results) => {
        console.log("success");
      })
      .catch((error) => {
        notification["error"]({
          message: "Something went wrong",
        });
      });

    const updatingUserDetails = await update("users", userDetails.id, data);
    this.props.context.setUserDetails(updatingUserDetails.data.data);

    this.setState({
      searchTitle: "",
      enableSearchTitle: false,
      dataLoading: false,
      userSavedSearchList: savedSearchList,
    });
  };

  saveSearch = async (userSavedSearch) => {
    const {
      showcheckItemNamesLables,
      showcheckItemNamesStatus,
      showcheckItemNamesProperty,
      searchTitle,
      cities,
    } = this.state;
    let propertyType = showcheckItemNamesProperty || [];
    let userDetails = this.props.context.state.userDetails;
    let searchListData = [];
    if (
      this.props.context.state &&
      this.props.context.state.userDetails &&
      this.props.context.state.userDetails.userSavedSearch
    ) {
      searchListData = this.props.context.state.userDetails.userSavedSearch;
    }

    if (
      showcheckItemNamesProperty === undefined ||
      showcheckItemNamesProperty === ""
    ) {
      if (userSavedSearch.types) {
        let data = userSavedSearch.types?.split(",");
        data?.forEach((d) => {
          property1?.filter((e, index) => {
            if (e.value === d) {
              e["key"] = index;
              e["children"] = e["label"];
              propertyType.push(e);
            }
            return e;
          });
        });
      }
    }

    let saveSearchInfo = {
      title: searchTitle,
      info: userSavedSearch,
      showCities: cities,
      showcheckItemNamesLables,
      showcheckItemNamesStatus,
      showcheckItemNamesProperty: propertyType,
    };

    searchListData.push(saveSearchInfo);
    const data = {
      entityData: {
        userSavedSearch: searchListData,
      },
    };

    update("users", userDetails.id, data)
      .then((response) => {
        if (response.success) {
          this.props.context.setUserDetails(response.data.data);
          const searchListLength =
            response.data.data.userSavedSearch.length - 1;
          if (this.state.save_search_alert_true) {
            this.setResultBack(searchListLength, saveSearchInfo, "defaultSave");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({
      searchTitle: "",
      enableSearchTitle: false,
      dataLoading: false,
      userSavedSearchList: searchListData,
    });
  };

  showSavedSearch = () => {
    const { enableSaveSearch } = this.state;
    this.setState({
      enableSaveSearch: !enableSaveSearch,
    });
    if (
      this.props.context.state &&
      this.props.context.state.userDetails &&
      this.props.context.state.userDetails.userSavedSearch
    ) {
      let userSavedSearchData =
        this.props.context.state.userDetails.userSavedSearch;
      this.setState({
        userSavedSearchList: userSavedSearchData,
      });
    }
  };

  updateDivHeight = (height) => {
    this.setState({
      divHeight: height,
    });
  };

  showAds = () => {
    return (
      <Advertisement
        otherAds={false}
        adType="Square"
        adWhereToShow="Homes"
        adLimit={this.state.perpageSize}
        zipCode=""
      />
    );
  };
  searchShowAds = () => {
    return (
      <Advertisement
        otherAds={false}
        adType="Square"
        adWhereToShow="Homes"
        adLimit={this.state.perpageSize}
        zipCode=""
      />
    );
  };
  offmlsInfo = () => {
    return (
      <Row style={{ background: "#f0f2f5" }}>
        <Col
          xl={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
        >
          {window.location.pathname === "/offMLSHomes" && (
            <p
              style={{
                fontWeight: "600",
                fontSize: "15px",
                color: "#000",
                marginTop: "1vw",
                marginBottom: "0",
                background: "#f0f2f5",
              }}
            >
              Search for homes that are not presently on MLS. These may be
              coming soon listings, for sale by owners, or homes sellers would
              prefer to sell outside of MLS. You just may find the home you have
              been looking for before your competition finds out about it.
            </p>
          )}
        </Col>
      </Row>
    );
  };

  /* SEARCH MULTI CITIES FOR FILTER */
  onSelect = (address) => {
    let cityValue = "";
    let stateValue = "";
    let newLocations = this.state.cities.searchLocation;
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
        }

        const stateCodes = JSON.parse(
          localStorage.getItem("stateCodesFromAdmin")
        );
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateValue.toLowerCase() ||
            restState.stateType.toLowerCase() === stateValue.toLowerCase()
        );

        if (setVendor && setVendor.length > 0) {
          let newSearchLocation = {
            cityName: cityValue,
            stateName: stateValue,
            locationType: "location",
          };
          newLocations.push(newSearchLocation);
          this.setState({
            cities: {
              searchLocation: newLocations,
              type: "location",
              citiesList: [
                ...this.state.cities.citiesList,
                `${stateValue}:${cityValue}`,
              ],
            },
          });
        } else {
          notification["error"]({
            message: "Please enter valid city...",
            description: "You can search cities from MA, CT, RI, NH states",
            placement: "topLeft",
          });
        }
      })
      .catch((error) => console.error("Error", error));
  };

  onClose = (value, stateRemove) => {
    let updateSearchLocations = this.state.cities.searchLocation;

    updateSearchLocations.splice(
      updateSearchLocations.findIndex(
        (e) => e.cityName === value && e.stateName === stateRemove
      ),
      1
    );

    const removeValue = `${stateRemove}:${value}`;

    let updatedCitiesList = this.state.cities.citiesList.filter(
      (singlecity) => singlecity !== removeValue
    );
    this.setState({
      cities: {
        searchLocation: updateSearchLocations,
        type:
          updateSearchLocations && updateSearchLocations.length > 0
            ? "location"
            : "",
        citiesList: updatedCitiesList,
      },
    });
  };

  handlePrice = (minprice, maxprice) => {
    this.setState({
      price: {
        minprice,
        maxprice,
        type: "price",
      },
    });
  };

  handleLivingArea = (minarea, maxarea) => {
    this.setState({
      area: {
        minarea,
        maxarea,
        type: "area",
      },
    });
  };

  bedSize = (minbeds) => {
    this.setState({
      beds: {
        minbeds,
        type: "beds",
      },
    });
  };

  bathSize = (minbaths) => {
    this.setState({
      baths: {
        minbaths,
        type: "baths",
      },
    });
  };

  handleHalfBath = (halfBath) => {
    this.setState({
      halfBath: {
        halfBath,
        type: "halfBath",
      },
    });
  };
  handleOpenHouses = (openHouses) => {
    this.setState({
      openHouses: {
        openHouses,
        type: "openHouses",
      },
    });
  };
  handleCheckProperty = (propertyType, checkItemNames) => {
    this.setState({
      subTypeText: {
        subTypeText: propertyType,
        type: "subtype",
      },
      showPropertyType: propertyType,
      showcheckItemNamesProperty: checkItemNames,
    });
  };

  handleCheckStatus = (propertyStatus, checkItemNames) => {
    this.setState({
      status: {
        status: propertyStatus,
        type: "status",
      },
      showPropertyStatus: propertyStatus,
      showcheckItemNamesStatus: checkItemNames,
    });
  };

  handleCheckLabel = (propertyLables, checkItemNames) => {
    this.setState({
      label: {
        label: propertyLables,
        type: "label",
      },
      showcheckItemNamesLables: checkItemNames,
    });
  };

  handleClear = () => {
    window.location.reload();
    localStorage.removeItem("selectedCities");
  };

  /* CALLING API FOR LOGIN USER AND NON-LOGIN USER */
  getHomesList = async (filterItems, mlsType, getFavorite) => {
    const userLogin = localStorage.getItem("userLogin");
    this.setState({
      dataLoading: getFavorite === "homeCard" ? false : true,
      apiCallSuccessMsg: false,
    });
    let checkDataType = this.props.location.pathname;
    let apiUrl = "";
    console.log(userLogin, "userLoginuserLogin");
    if (userLogin) {
      if (filterItems && filterItems.length > 0) {
        apiUrl = `${process.env.REACT_APP_API_GATEWAY}/api/getHomesList?mls=${mlsType}`;
      } else {
        if (checkDataType === "/offMLSHomes") {
          apiUrl = `${process.env.REACT_APP_API_GATEWAY}/api/getHomesList?mls=off`;
        } else {
          apiUrl = `${process.env.REACT_APP_API_GATEWAY}/api/getHomesList?mls=${mlsType}`;
        }
      }
    } else {
      if (filterItems && filterItems.length > 0) {
        apiUrl = `${process.env.REACT_APP_API_GATEWAY}/noAuth/getHomesList?mls=${mlsType}`;
      } else {
        if (checkDataType === "/offMLSHomes") {
          apiUrl = `${process.env.REACT_APP_API_GATEWAY}/noAuth/getHomesList?mls=off`;
        } else {
          apiUrl = `${process.env.REACT_APP_API_GATEWAY}/noAuth/getHomesList?mls=both`;
        }
      }
    }
    let url = queryString.stringifyUrl({ url: apiUrl, query: filterItems });

    const method = "get";
    const headers = {};

    if (userLogin) {
      let token;
      await Auth.currentSession()
        .then((response) => {
          token = response.idToken.jwtToken;
        })
        .catch((error) => {
          console.log(error);
        });
      headers["Authorization"] = `Bearer ${token}`;
    } else {
      headers["Content-Type"] = "application/json";
      headers["Accept"] = "application/json";
    }
    const data = {};
    axios({
      method,
      url,
      headers,
      data,
    })
      .then((response) => {
        if (response.status === 200) {
          this.statusCheck(response);
        }
      })
      .catch((error) => {
        // window.location.assign("/pageNotFound/newtWork");
        console.log("error", error);
        this.setState({
          dataLoading: false,
        });
      });
  };

  statusCheck = async (response) => {
    const { sortOrder, showPropertyStatus } = this.state;
    let activeStatusList = [];
    let otherStatusList = [];
    let allStatusList = [];
    let date = new Date();
    let last = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
    let day = last.getDate();
    let month = last.getMonth() + 1;
    let year = last.getFullYear();
    let comparingDate = Date.parse(`${month}/${day}/${year}`);

    if (showPropertyStatus && showPropertyStatus === "active") {
      response.data.filter((activeItem) => {
        if (activeItem.status === "Active") {
          return activeStatusList.push(activeItem);
        } else {
          return otherStatusList.push(activeItem);
        }
      });
      const newListStatusData = activeStatusList.filter((eachListing) => {
        return (
          Date.parse(moment(eachListing.listDate).format("L")) >= comparingDate
        );
      });
      allStatusList = await newListStatusData.concat(otherStatusList);
      if (allStatusList && allStatusList.length > 1) {
        if (sortOrder === "listprice") {
          allStatusList.sort((a, b) => parseFloat(a.cost - b.cost));
        }
        if (sortOrder === "-listprice") {
          allStatusList &&
            allStatusList.sort((a, b) => parseFloat(b.cost - a.cost));
        }
        if (sortOrder === "listdate") {
          allStatusList &&
            allStatusList.sort(
              (a, b) => new Date(a.listDate) - new Date(b.listDate)
            );
        }
        if (sortOrder === "-listdate") {
          allStatusList &&
            allStatusList.sort(
              (a, b) => new Date(b.listDate) - new Date(a.listDate)
            );
        }
      }
    } else {
      allStatusList = response.data;
    }
    this.setState({
      dataLoading: false,
      allHomesList: allStatusList,
      homesListCount:
        response && response.headers && response.headers["x-total-count"],

      apiCallSuccessMsg: true,
      maOffset: response && response.headers && response.headers["x-total-ma"],
      ctOffset: response && response.headers && response.headers["x-total-ct"],
      riOffset: response && response.headers && response.headers["x-total-ri"],
      nhOffset: response && response.headers && response.headers["x-total-nh"],
      localOffset:
        response && response.headers && response.headers["x-total-localcount"],
    });
    this.props.showMatchesPage && this.handleShowMatches();
  };

  handleShowMatches = () => {
    this?.props?.handleShowMatchesLoading();
  };

  methodOldSavedSearch = () => {
    this.setState({
      setOldSavedSearch: false,
      resetState: false,
    });
  };
  /* LEFT SIDE FILTER VIEW */
  filterView = () => {
    const { switchHalfBath } = this.state;

    const Mlsstatus = [
      { label: "Coming Soon", value: "comingsoon" },
      { label: "New Listing", value: "active" },
      { label: "Under Agreement", value: "pending,activeundercontract" },
      {
        label: "For Sale (On MLS)",
        value: "active,comingsoon,activeundercontract",
      },
      { label: "For Sale by Owner", value: "For Sale by Owner" },

      { label: "For Sale (Off MLS)", value: "For Sale (Off MLS)" },
    ];

    const offMlsstatus = [
      { label: "Coming Soon", value: "comingsoon" },
      { label: "New Listing", value: "active" },
      { label: "Under Agreement", value: "pending,activeundercontract" },
      { label: "For Sale by Owner", value: "For Sale by Owner" },
      { label: "Temp off MLS", value: "Temp off MLS" },
    ];

    const lablesTags = [
      { label: "1031 Exchange", value: "1031 Exchange" },
      { label: "As Is Sale", value: "As Is Sale" },

      {
        label: "Available via kick-out clause",
        value: "Available via kick-out clause",
      },

      { label: "Cash cow", value: "Cash cow" },

      { label: "Downsize seller", value: "Downsize seller" },

      { label: "Hot offer", value: "Hot offer" },

      { label: "Like New", value: "Like New" },
      {
        label: "Need to find suitable housing",
        value: "Need to find suitable housing",
      },
      { label: "New construction", value: "New construction" },
      { label: "New listing", value: "New listing" },
      { label: "Temp Off MLS", value: "Temp Off MLS" },
      { label: "Open House", value: "Open House" },
      { label: "Portfolio", value: "Portfolio" },
      { label: "Ready to Move", value: "Ready to Move" },
      { label: "To be built", value: "To be built" },
      { label: "Under Agreement", value: "Under Agreement" },
      { label: "upsize", value: "upsize" },
      { label: "Cash Only", value: "Cash Only" },
      { label: "Investor Special", value: "Investor Special" },
    ];

    const { filterSearchData } = this.props.context.state;
    // const { sortOrder } = this.state;
    // const multiState = [];
    const oldPrice = [];
    const oldArea = [];
    const oldBaths = [];
    const oldBeds = [];
    const oldSubTypeText = [];
    const oldStatus = [];
    const oldLabel = [];
    filterSearchData &&
      filterSearchData.minbeds &&
      oldBeds.push({ beds: filterSearchData.minbeds });
    filterSearchData.minbeds &&
      oldBaths.push({ baths: filterSearchData.minbaths });
    filterSearchData.halfBaths &&
      oldBaths.push({ halfBaths: filterSearchData.halfBaths });

    filterSearchData.openHouses &&
      oldBaths.push({ openHouses: filterSearchData.openHouses });
    filterSearchData.maxprice
      ? oldPrice.push({
          maxprice: filterSearchData.maxprice,
          minprice: filterSearchData.minprice,
          type: "price",
        })
      : oldPrice.push({
          maxprice: filterSearchData.maxprice,
          minprice: filterSearchData.minprice,
          type: "price",
        });
    filterSearchData.minarea
      ? oldArea.push({
          minarea: filterSearchData.minarea,
          maxarea: filterSearchData.maxarea,
          type: "area",
        })
      : oldArea.push({
          minarea: filterSearchData.minarea,
          maxarea: filterSearchData.maxarea,
          type: "area",
        });

    const selectedPropertyTypes = JSON.parse(
      localStorage.getItem("selectedPropertyTypes")
    );
    filterSearchData.types &&
      oldSubTypeText.push({ subTypeText: selectedPropertyTypes });
    filterSearchData.status &&
      oldStatus.push({ status: filterSearchData.status });
    filterSearchData.labels &&
      oldLabel.push({ label: filterSearchData.labels.split(",") });
    const checkBoxForSubtypeText =
      oldSubTypeText &&
      oldSubTypeText !== "undefined" &&
      oldSubTypeText !== undefined &&
      oldSubTypeText !== null &&
      oldSubTypeText.length > 0
        ? oldSubTypeText[0].subTypeText
        : [];
    const checkBoxForStatus =
      oldStatus && oldStatus !== "undefined" && oldStatus.length > 0
        ? oldStatus[0].status
        : [];
    const checkBoxForLabel =
      oldLabel && oldLabel !== "undefined" && oldLabel.length > 0
        ? oldLabel[0].label
        : [];
    return (
      <Row style={{ background: "white" }}>
        <Col
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row>
            <Col
              xl={{ span: 7, offset: 1 }}
              lg={{ span: 7, offset: 1 }}
              md={{ span: 7, offset: 1 }}
              sm={{ span: 7, offset: 1 }}
              xs={{ span: 7, offset: 1 }}
              className="side-first-row"
            >
              <Button
                type="primary"
                onClick={() => this.update(false)}
                className="side-filter"
              >
                Apply
              </Button>
            </Col>

            <Col
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              xs={{ span: 8 }}
              className="side-first-row"
            >
              <Button
                type="link"
                className="side-Search"
                onClick={() =>
                  localStorage.getItem("userLogin")
                    ? this.setState({
                        enableSearchTitle: true,
                      })
                    : this.update(false)
                }
              >
                Save Search
              </Button>
            </Col>

            <Col
              xl={{ span: 7 }}
              lg={{ span: 7 }}
              md={{ span: 7 }}
              sm={{ span: 7 }}
              xs={{ span: 7 }}
              className="side-first-row"
            >
              <Button
                type="ghost"
                onClick={this.handleClear}
                className="side-Reset"
                style={{ float: "right" }}
              >
                <span className="rest-all">Reset All</span>
              </Button>
            </Col>

            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <span style={{ fontSize: 13.5, color: "hsl(0, 0%, 50%)" }}>
                You can enter multiple cities, one at a time
              </span>
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_MAPS_KEY}
                autocompletionRequest={{
                  types: ["(cities)"],
                  componentRestrictions: {
                    country: ["us"],
                  },
                }}
                selectProps={{
                  placeholder: "Enter City Name",
                  value: "",
                  onChange: (result) => {
                    this.onSelect(result);
                  },
                  style: { padding: "2%" },
                }}
              />
              {this.state.cities &&
                this.state.cities.searchLocation &&
                this.state.cities.searchLocation.map((city, index) => (
                  <div
                    key={index}
                    style={{
                      display: "inline-flex",
                      margin: "0.73vw",
                      padding: "0 7px",
                      flexWrap: "wrap",
                      justifyContent: "space-around",
                      border: "1px solid darkgrey",
                    }}
                  >
                    <div>
                      {city.cityName}, {city.stateName}
                    </div>
                    <div
                      style={{
                        color: "grey",
                        cursor: "pointer",
                        marginLeft: "0.4vw",
                      }}
                      onClick={() =>
                        this.onClose(city.cityName, city.stateName)
                      }
                    >
                      x
                    </div>
                  </div>
                ))}
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <Sliders
                one
                title={"Budget "}
                handlePrice={this.handlePrice}
                oldPrice={oldPrice}
                setOldSavedSearch={this.state.setOldSavedSearch}
                methodOldSavedSearch={this.methodOldSavedSearch}
              />
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <Sliders
                three
                title={"Living Area"}
                handleLivingArea={this.handleLivingArea}
                oldArea={oldArea}
                setOldSavedSearch={this.state.setOldSavedSearch}
                methodOldSavedSearch={this.methodOldSavedSearch}
              />
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="side-second-row"
            >
              <p className="second-row-title">Beds & Baths</p>
              <Number
                title={"Min Beds"}
                beds
                bedSize={this.bedSize}
                resetState={this.state.resetState}
                oldNumber={oldBeds}
                setOldSavedSearch={this.state.setOldSavedSearch}
                methodOldSavedSearch={this.methodOldSavedSearch}
              />
              <Number
                title={"Min Baths"}
                baths
                bathSize={this.bathSize}
                resetState={this.state.resetState}
                oldNumber={oldBaths}
                setOldSavedSearch={this.state.setOldSavedSearch}
                methodOldSavedSearch={this.methodOldSavedSearch}
              />

              <Col offset={1} className="baths-level-show">
                {switchHalfBath && (
                  <>
                    <span className="margin-header">Half Bath</span>
                    <Switch
                      style={{ marginLeft: "15px", marginRight: "15px" }}
                      onChange={this.handleHalfBath}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={this.state.halfBath.halfBath}
                    />
                  </>
                )}
              </Col>
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="side-second-row"
            >
              <p className="second-row-title">Open Houses</p>
              <Col
                offset={1}
                className="baths-level-show"
                style={{ marginTop: "1vw" }}
              >
                {switchHalfBath && (
                  <>
                    {/* // <div style={{ display: "flex" }}> */}
                    <span className="margin-header">Show only open houses</span>
                    <Switch
                      style={{ marginLeft: "0px", marginRight: "15px" }}
                      onChange={this.handleOpenHouses}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={this.state.openHouses.openHouses}
                    />
                    {/* // </div> */}
                  </>
                )}
              </Col>
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="side-second-row"
            >
              <p className="second-row-title">Property Type</p>
              <CheckBoxGroup
                property
                plainOptions={property1}
                handleCheckProperty={this.handleCheckProperty}
                checkBoxFor={checkBoxForSubtypeText}
                setOldSavedSearch={this.state.setOldSavedSearch}
                methodOldSavedSearch={this.methodOldSavedSearch}
              />
            </Col>
            {this.props.match &&
              this.props.match.path !== "/buyers" &&
              this.props.match && (
                <Col
                  xl={{ span: 22, offset: 1 }}
                  lg={{ span: 22, offset: 1 }}
                  md={{ span: 22, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  className="side-second-row"
                >
                  <p className="second-row-title">Status</p>

                  <CheckBoxGroup
                    status
                    plainOptions={
                      window.location.pathname === "/offMLSHomes"
                        ? offMlsstatus
                        : Mlsstatus
                    }
                    handleCheckStatus={this.handleCheckStatus}
                    checkBoxFor={checkBoxForStatus}
                    setOldSavedSearch={this.state.setOldSavedSearch}
                    methodOldSavedSearch={this.methodOldSavedSearch}
                  />
                </Col>
              )}
            {window.location.pathname === "/offMLSHomes" && (
              <Col
                xl={{ span: 22, offset: 1 }}
                lg={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                className="side-second-row"
              >
                <p className="second-row-title">Labels</p>

                <CheckBoxGroup
                  label
                  plainOptions={lablesTags}
                  handleCheckLabel={this.handleCheckLabel}
                  checkBoxFor={checkBoxForLabel}
                  setOldSavedSearch={this.state.setOldSavedSearch}
                  methodOldSavedSearch={this.methodOldSavedSearch}
                />
              </Col>
            )}
            <Col
              xl={{ span: 7, offset: 1 }}
              lg={{ span: 7, offset: 1 }}
              md={{ span: 7, offset: 1 }}
              sm={{ span: 7, offset: 1 }}
              xs={{ span: 7, offset: 1 }}
              className="side-first-row"
            >
              <Button
                type="primary"
                onClick={() => this.update(false)}
                className="side-filter"
              >
                Apply
              </Button>
            </Col>

            {localStorage.getItem("userLogin") ? (
              <Col
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 8 }}
                xs={{ span: 8 }}
                className="side-first-row"
              >
                <Button
                  type="link"
                  className="side-Search"
                  onClick={() =>
                    this.setState({
                      enableSearchTitle: true,
                    })
                  }
                >
                  Save Search
                </Button>
              </Col>
            ) : (
              <Col
                xl={{ span: 6, offset: 1 }}
                lg={{ span: 6, offset: 1 }}
                md={{ span: 6, offset: 1 }}
                sm={{ span: 6, offset: 1 }}
                xs={{ span: 6, offset: 1 }}
                style={{ marginTop: "1vw" }}
              ></Col>
            )}

            <Col
              xl={{ span: 7 }}
              lg={{ span: 7 }}
              md={{ span: 7 }}
              sm={{ span: 7 }}
              xs={{ span: 7 }}
              className="side-first-row"
            >
              <Button
                type="ghost"
                onClick={this.handleClear}
                className="side-Reset"
                style={{ float: "right" }}
              >
                <span className="rest-all">Reset All</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  EmailshowMatches = () => {
    const { filterSearchData } = this.props.context.state;
    let typeOfProperty = filterSearchData["types"];
    const cityData = JSON.parse(localStorage.getItem("selectedCities"));
    return (
      <Row style={{ background: "white" }}>
        {/* <h1>See All Matches</h1> */}
        <Col
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              {cityData &&
                cityData.searchLocation &&
                cityData.searchLocation.length > 0 && (
                  <>
                    <p
                      className="second-row-title"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      Cities
                    </p>
                    <span className="showMatches-value">
                      {cityData &&
                        cityData.searchLocation &&
                        cityData.searchLocation.map((city, index) => (
                          <>
                            {city.cityName}, {city.stateName}
                            {". "}
                          </>
                        ))}
                    </span>
                  </>
                )}
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
            >
              <p
                className="second-row-title"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                Price
              </p>
              <span className="showMatches-value">
                <NumberFormat
                  value={parseFloat(filterSearchData["minprice"]).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
                {" - "}
                <NumberFormat
                  value={parseFloat(filterSearchData["maxprice"]).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </Col>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="side-second-row"
            >
              <p
                className="second-row-title"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                Beds & Baths & Area
              </p>
              <Row>
                <div className="layout-last-step">
                  <span>
                    &nbsp;
                    <img src={bedIcon} alt="space" width="18px" height="10px" />
                  </span>
                  <span className="layout-subtitles">
                    <p className="beds-size-mobile">
                      {filterSearchData["minbeds"]} Beds
                    </p>
                  </span>
                </div>
                <div
                  className="layout-last-step"
                  style={{ marginLeft: "20px" }}
                >
                  <span>
                    <img
                      src={bathIcon}
                      alt="space"
                      width="18px"
                      height="10px"
                    />
                  </span>
                  <span className="layout-subtitles">
                    <p
                      style={{ fontSize: "16px" }}
                      className="beds-size-mobile"
                    >
                      {filterSearchData["minbaths"]} Baths
                    </p>
                  </span>
                </div>
                <div
                  className="layout-last-step"
                  style={{ marginLeft: "20px" }}
                >
                  <span>
                    <img src={reSize} alt="space" width="16px" height="10px" />
                  </span>
                  <span className="layout-subtitles">
                    <p
                      style={{ fontSize: "16px" }}
                      className="beds-size-mobile"
                    >
                      {filterSearchData["maxarea"]} sq.ft
                    </p>
                  </span>
                </div>
              </Row>
            </Col>

            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 22, offset: 1 }}
              className="side-second-row"
            >
              <p
                className="second-row-title"
                style={{ marginTop: "0px", marginBottom: "10px" }}
              >
                Property Type
              </p>
              <span className="showMatches-value">
                {typeOfProperty &&
                  typeOfProperty.split(",").map((eachType, index) => (
                    <>
                      {eachType === "LND"
                        ? "Land"
                        : eachType === "MBL"
                        ? "Mobile Homes"
                        : eachType === "MLF"
                        ? "Residential Multi Family Home"
                        : eachType === "RES"
                        ? "Residential"
                        : eachType === "RNT"
                        ? "Residential Rental"
                        : eachType === "CND"
                        ? "Condominium"
                        : eachType === "CRE"
                        ? "Commercial"
                        : eachType}
                      {typeOfProperty &&
                        typeOfProperty.split(",").length - 1 !== index &&
                        ", "}
                    </>
                  ))}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  handlePagination = async (page, pageSize, load) => {
    this.props.context.handleToSetCurrentPage(page, pageSize);
    if (!load) {
      this.setState({
        dataLoading: true,
      });
    }
    if (page <= 1) {
      this.setState({
        minValue: 0,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (page - 1) * pageSize,
        maxValue: page * pageSize,
      });
    }
    this.setState({
      currentPage: page,
      perpageSize: pageSize,
    });
    if (!load) {
      setTimeout(() => {
        this.setState({
          dataLoading: false,
        });
      }, 1000);
    }
  };

  handleLoadMoreButton = async (currentPage) => {
    const { maOffset, ctOffset, riOffset, nhOffset, localOffset } = this.state;
    this.setState({
      currentPage: currentPage + 1,
    });
    let filterItems = {
      maOffset,
      ctOffset,
      riOffset,
      nhOffset,
      localOffset,
    };
    this.getHomesList(filterItems, "both");
  };
  setResultToBuyerPref = (index, searchResult) => {
    let savedIndex = this.state.savedIndex.filter((f) => f !== index);
    this.setState({
      savedIndex,
    });
    this.saveAsBuyerPrefrence(searchResult, index, "removeBuyerPref");
  };

  setResultBack = (index, searchResult, defaultSave) => {
    let savedIndex = this.state.savedIndex;
    savedIndex.push(index);
    this.setState({
      savedIndex,
    });
    this.saveAsBuyerPrefrence(searchResult, index, defaultSave);
  };

  saveAsBuyerPrefrence = (searchResult, index, removeBuyerPref) => {
    const loginUserDetails = this.props.context.state.userDetails;
    let searchListData = [];
    let checkBuyerPref = false;
    let removePrefId = false;
    if (
      this.props.context.state &&
      this.props.context.state.userDetails &&
      this.props.context.state.userDetails.userSavedSearch
    ) {
      searchListData = this.props.context.state.userDetails.userSavedSearch;
    }
    if (removeBuyerPref !== "removeBuyerPref") {
      let buyerPrivateDetails = {},
        buyerPropertyDetails = {},
        buyerPropertyFeatures = {},
        buyerPropertyDetails2 = {};

      buyerPrivateDetails["firstName"] =
        loginUserDetails && loginUserDetails.firstName
          ? loginUserDetails.firstName
          : null;
      buyerPrivateDetails["email"] =
        loginUserDetails && loginUserDetails.email
          ? loginUserDetails.email
          : null;
      buyerPrivateDetails["lastName"] =
        loginUserDetails && loginUserDetails.lastName
          ? loginUserDetails.lastName
          : null;
      buyerPrivateDetails["phone"] =
        loginUserDetails && loginUserDetails.phone
          ? loginUserDetails.phone
          : null;

      buyerPropertyDetails["propertyType"] =
        searchResult.showcheckItemNamesProperty &&
        searchResult.showcheckItemNamesProperty.length > 0
          ? searchResult.showcheckItemNamesProperty
          : null;
      buyerPropertyDetails["updatedInterestedTowns"] =
        searchResult && searchResult.showCities
          ? searchResult.showCities.searchLocation
          : null;
      buyerPropertyDetails["minprice"] = searchResult.info
        ? searchResult.info.minprice
          ? searchResult.info.minprice
          : null
        : null;
      buyerPropertyDetails["maxprice"] = searchResult.info
        ? searchResult.info.maxprice
          ? searchResult.info.maxprice
          : null
        : null;

      buyerPropertyFeatures["status"] = searchResult
        ? searchResult.info
          ? searchResult.info.status
            ? searchResult.info.status
            : null
          : null
        : null;

      buyerPropertyFeatures["sortOrder"] = searchResult
        ? searchResult.info
          ? searchResult.info.sortOrder
            ? searchResult.info.sortOrder
            : null
          : null
        : null;

      buyerPropertyFeatures["halfBaths"] = searchResult
        ? searchResult.info
          ? searchResult.info.halfBaths
            ? searchResult.info.halfBaths
            : false
          : false
        : false;

      buyerPropertyFeatures["openHouses"] = searchResult
        ? searchResult.info
          ? searchResult.info.openHouses
            ? searchResult.info.openHouses
            : false
          : false
        : false;

      buyerPropertyFeatures["beds"] = searchResult
        ? searchResult.info
          ? searchResult.info.minbeds
            ? searchResult.info.minbeds
            : null
          : null
        : null;
      buyerPropertyFeatures["baths"] = searchResult
        ? searchResult.info
          ? searchResult.info.minbaths
            ? searchResult.info.minbaths
            : null
          : null
        : null;
      buyerPropertyFeatures["minArea"] = searchResult
        ? searchResult.info
          ? searchResult.info.minarea
            ? searchResult.info.minarea
            : null
          : null
        : null;
      buyerPropertyFeatures["maxArea"] = searchResult
        ? searchResult.info
          ? searchResult.info.maxarea
            ? searchResult.info.maxarea
            : null
          : null
        : null;

      buyerPropertyDetails2["title"] = searchResult.title
        ? searchResult.title
        : "Saved search";
      buyerPropertyDetails2["matchingProfile"] = true;
      buyerPropertyDetails2["showOnBuyersPage"] = false;
      const data = Object.assign(
        {},
        {
          entityData: {
            userId: loginUserDetails.id,
            buyerPrivateDetails: buyerPrivateDetails,
            buyerPropertyDetails: buyerPropertyDetails,
            buyerPropertyFeatures: buyerPropertyFeatures,
            buyerPropertyDetails2: buyerPropertyDetails2,
          },
        }
      );
      try {
        add("buyers", data).then((result) => {
          if (result.success) {
            checkBuyerPref = true;
            searchResult["preferenceId"] = result.data.data.id;
          }
        });
      } catch (error) {
        notification["error"]({
          message: "Something went wrong!",
          description: "Please try again",
        });
      }
    }
    if (removeBuyerPref === "removeBuyerPref") {
      try {
        deleteRecord("buyers", searchResult.preferenceId).then((response) => {
          if (response.success) {
            removePrefId = true;
            delete searchResult.preferenceId;
          }
        });
      } catch (error) {
        notification["error"]({
          message: "Something went wrong",
        });
        window.location.assign("/pageNotFound/newtWork");
      }
    }
    searchResult["savedToBuyerPref"] =
      removeBuyerPref !== "removeBuyerPref" ? true : false;
    searchListData.splice(index, 1, searchResult);
    setTimeout(() => {
      this.updateSavedSearchWithBuyerPref(
        searchListData,
        checkBuyerPref,
        removePrefId,
        loginUserDetails.id
      );
    }, 2000);
  };
  updateSavedSearchWithBuyerPref = async (
    searchListData,
    checkBuyerPref,
    removePrefId,
    userId
  ) => {
    const updateSavedSearch = await Object.assign(
      {},
      {
        entityData: {
          userSavedSearch: searchListData,
        },
      }
    );

    try {
      update("users", userId, updateSavedSearch).then((result) => {
        if (result.success && checkBuyerPref) {
          notification["success"]({
            message: "Your saved search created as a new buyer preference",
          });
        } else {
          if (result.success && removePrefId) {
            notification["warning"]({
              message: "Removed from buyer preference",
            });
          }
        }
        if (result.success) {
          this.props.context.setUserDetails(result.data.data);
        }
      });
    } catch (error) {
      notification["error"]({
        message: "Something went wrong!",
        description: "Please try again",
      });
    }
  };
  sendAlertsForSaveSearch = (e) => {
    this.setState({
      save_search_alert_true: e.target.checked,
    });
  };

  copyUrl = () => {
    var textBox = document.getElementById("shareUrl");
    textBox.select();
    document.execCommand("copy");
    notification["info"]({
      message: "URL copied successfully",
    });
  };

  handleChange = (val) => {
    const { showHeight } = this.state;
    let data = showHeight?.length > 0 ? showHeight : [];
    if (data?.filter((e) => parseInt(val) === parseInt(e))?.length > 0) {
      data.splice(
        data.findIndex((d) => d === val),
        1
      );
    } else {
      data.push(val);
    }
    this.setState({
      showHeight: data,
    });
  };

  render() {
    const {
      showHeight,
      showMatchesPage,
      enableShare,
      enableInfo,
      enableFilter,
      allHomesList,
      homesListCount,
      dataLoading,

      minValue,
      maxValue,
      currentPage,
      perpageSize,
      sortOrder,
      enableSaveSearch,
      userSavedSearchList,
      enableSearchTitle,
      searchTitle,
      cities,
      showingAdsAtSearch,
      savedIndex,
      save_search_alert_true,
      save_search_login_alert_message,
    } = this.state;
    const { listOption } = this.props;

    const subscriptionPack =
      localStorage.getItem("subscriptionPack") !== undefined
        ? JSON.parse(localStorage.getItem("subscriptionPack"))
        : "";
    return (
      <div className="homeSearch">
        {!this.props.showColor && <Loading enableLoading={dataLoading} />}
        <Layout className="margin-body-agent">
          {userSavedSearchList && userSavedSearchList.length > 0 && (
            <>
              {/* <h3
              style={{ margin: "1vw 0 0 3vw", cursor: "pointer" }}
              onClick={this.showSavedSearch}
            >
              <FileSearchOutlined /> Previous search results
            </h3> */}

              {enableSaveSearch && (
                <Row style={{ marginLeft: "-10px", marginRight: "20px" }}>
                  <Carousel
                    showArrows={false}
                    pagination
                    itemPosition="CENTER"
                    autoPlaySpeed={4000}
                    enableAutoPlay
                    showEmptySlots
                    disableArrowsOnEnd
                    enableMouseSwipe
                    enableSwipe
                    itemsToShow={
                      window.screen.width >= "1025"
                        ? 3
                        : window.screen.width > "992" &&
                          window.screen.width <= "1024"
                        ? 2
                        : 1
                    }
                    itemsToScroll={1}
                    className={`${
                      showHeight?.length > 0
                        ? "home-carousel open_card_carousel"
                        : "home-carousel close_card_carousel"
                    }`}
                    itemPadding={[20, 10, 5, 0]}
                  >
                    {userSavedSearchList.map((searchResult, index) => (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "none !important",
                        }}
                      >
                        <Collapse
                          bordered={false}
                          className="filter_search"
                          onChange={() => this.handleChange(index)}
                        >
                          <Panel
                            header={searchResult.title}
                            key={index}
                            extra={
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <a
                                  href={() => false}
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    // paddingRight: "8px",
                                    // paddingLeft: "10px",
                                  }}
                                  onClick={() =>
                                    this.searchFiltredHomes(
                                      searchResult.info,
                                      "searchingwithOldfilter",
                                      searchResult
                                    )
                                  }
                                >
                                  Show Results{" "}
                                </a>
                                {subscriptionPack &&
                                  subscriptionPack?.price !== "0" && (
                                    <span>
                                      {/* <a
                                    href={`https://${orgDetails?.websiteName}/email/seematches?viewPropertiesfrom=mail&buyerId=${searchResult.preferenceId}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  > */}
                                      <ShareAltOutlined
                                        style={{
                                          color: "green",
                                          fontWeight: "bold",
                                          fontSize: "18px",
                                          paddingRight: "8px",
                                          paddingLeft: "10px",
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            enableShare:
                                              searchResult.preferenceId,
                                          })
                                        }
                                      />
                                      {/* </a> */}
                                    </span>
                                  )}
                                <span>
                                  {savedIndex.includes(index) ||
                                  searchResult.savedToBuyerPref ? (
                                    <Tooltip
                                      placement="topLeft"
                                      color="#fc572b"
                                      title="Turn off email notifications"
                                    >
                                      <BellFilled
                                        style={{
                                          color: "#2f45d2",
                                          fontWeight: "bold",
                                          fontSize: "18px",
                                          paddingRight: "8px",
                                          paddingLeft: "10px",
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                        onClick={() =>
                                          this.setResultToBuyerPref(
                                            index,
                                            searchResult
                                          )
                                        }
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      placement="topLeft"
                                      color="#fc572b"
                                      title="Turn on email notifications"
                                    >
                                      <BellOutlined
                                        style={{
                                          color: "#2f45d2",
                                          fontWeight: "bold",
                                          fontSize: "18px",
                                          paddingRight: "8px",
                                          paddingLeft: "10px",
                                        }}
                                        onClick={() =>
                                          this.setResultBack(
                                            index,
                                            searchResult
                                          )
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </span>
                                <Popconfirm
                                  title="Are you sure to delete this record?"
                                  onConfirm={() =>
                                    this.delSaveSearchHome(searchResult, index)
                                  }
                                  onCancel={() => console.log("cancelled")}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <DeleteOutlined
                                    style={{
                                      color: "#f00",
                                      fontWeight: "bold",
                                      fontSize: "18px",
                                      marginLeft: "10px",
                                    }}
                                  />
                                </Popconfirm>
                              </div>
                            }
                          >
                            <div
                              style={{
                                paddingLeft: "24px",
                              }}
                            >
                              {enableShare === searchResult.preferenceId && (
                                <Row
                                  style={{
                                    marginBottom: "20px",
                                    marginRight: "15px",
                                  }}
                                >
                                  <Col span={18}>
                                    <Input
                                      name="shareUrl"
                                      id="shareUrl"
                                      value={`${process.env.REACT_APP_BASE_URL}email/seematches?viewPropertiesfrom=mail&buyerId=${searchResult.preferenceId}`}
                                    />
                                  </Col>

                                  <Col span={6}>
                                    <Button
                                      type="primary"
                                      icon={<CopyOutlined />}
                                      onClick={() => this.copyUrl()}
                                      style={{ width: "100%" }}
                                    >
                                      Copy URL
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                              {/* {searchResult &&
                              searchResult.info &&
                              searchResult.info.cities && ( */}
                              <p
                                style={{
                                  color: "#99a2c3",
                                  fontSize: "16px",
                                  margin: "-10px 0 -2px",
                                }}
                              >
                                Cities &nbsp;:{" "}
                                <span
                                  style={{
                                    color: "#414555",
                                  }}
                                >
                                  {searchResult &&
                                  searchResult.showCities &&
                                  searchResult.showCities.searchLocation
                                    ? searchResult.showCities.searchLocation.map(
                                        (eachCity) => (
                                          <>
                                            {eachCity.cityName}
                                            {","}
                                            {eachCity.stateName}
                                            {", "}
                                          </>
                                        )
                                      )
                                    : "-"}
                                </span>
                              </p>
                              {/* )} */}
                              {/* {searchResult &&
                              searchResult.info &&
                              searchResult.info.status && ( */}
                              <p
                                style={{
                                  color: "#99a2c3",
                                  fontSize: "16px",
                                  margin: "0 0 0px",
                                }}
                              >
                                Status :{" "}
                                <span
                                  style={{
                                    color: "#414555",
                                  }}
                                >
                                  {/* {searchResult.info.status
                                  ? searchResult.info.status
                                  : "-"} */}

                                  {searchResult.info.status &&
                                  searchResult.info.status === "comingsoon"
                                    ? "Coming Soon"
                                    : searchResult.info.status === "active"
                                    ? "New Listing"
                                    : searchResult.info.status ===
                                      "pending,activeundercontract"
                                    ? "Under Agreement"
                                    : searchResult.info.status ===
                                      "active,comingsoon,activeundercontract"
                                    ? "For Sale (On MLS)"
                                    : searchResult.info.status ===
                                      "For Sale (Off MLS)"
                                    ? "For Sale (Off MLS)"
                                    : searchResult.info.status ===
                                      "For Sale by Owner"
                                    ? "For Sale by Owner"
                                    : searchResult.info.status ===
                                      "Temp off MLS"
                                    ? "Temp off MLS"
                                    : "-"}
                                </span>
                              </p>
                              {/* )} */}
                              {/* {searchResult &&
                              searchResult.showcheckItemNamesProperty &&
                              searchResult.showcheckItemNamesProperty.length >
                                0 && ( */}
                              <p
                                style={{
                                  color: "#99a2c3",
                                  fontSize: "16px",
                                  margin: "0 0 -2px",
                                }}
                              >
                                Types &nbsp;:{" "}
                                <span
                                  style={{
                                    color: "#414555",
                                  }}
                                >
                                  {searchResult.showcheckItemNamesProperty &&
                                  searchResult.showcheckItemNamesProperty
                                    .length > 0
                                    ? searchResult.showcheckItemNamesProperty.map(
                                        (eachType, index) => (
                                          <>
                                            {eachType.children}
                                            {searchResult
                                              .showcheckItemNamesProperty
                                              .length -
                                              1 !==
                                              index && ", "}
                                          </>
                                        )
                                      )
                                    : "-"}
                                </span>
                              </p>
                              {/* )} */}

                              {searchResult.info.listPrice ? (
                                <p
                                  style={{
                                    color: "#99a2c3",
                                    fontSize: "16px",
                                    margin: "0 0 -2px",
                                  }}
                                >
                                  Price &nbsp;&nbsp;:{" "}
                                  <span
                                    style={{
                                      color: "#414555",
                                    }}
                                  >
                                    <NumberFormat
                                      value={parseFloat(
                                        searchResult.info.listPrice
                                      ).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </span>
                                </p>
                              ) : (
                                // searchResult &&
                                // searchResult.info &&
                                // (searchResult.info.minprice ||
                                //   searchResult.info.maxprice) && (
                                <p
                                  style={{
                                    color: "#99a2c3",
                                    fontSize: "16px",
                                    margin: "0 0 -2px",
                                  }}
                                >
                                  Price &nbsp;&nbsp;:{" "}
                                  <span
                                    style={{
                                      color: "#414555",
                                    }}
                                  >
                                    <NumberFormat
                                      value={parseFloat(
                                        searchResult.info.minprice
                                      ).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                    {" - "}
                                    <NumberFormat
                                      value={parseFloat(
                                        searchResult.info.maxprice
                                      ).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </span>
                                </p>
                                // )
                              )}
                              {/* {searchResult &&
                              searchResult.showcheckItemNamesLables &&
                              searchResult.showcheckItemNamesLables.length >
                                0 && ( */}
                              <p
                                style={{
                                  color: "#99a2c3",
                                  fontSize: "16px",
                                  margin: "0 0 -2px",
                                }}
                              >
                                Labels &nbsp;:{" "}
                                <span
                                  style={{
                                    color: "#414555",
                                  }}
                                >
                                  {searchResult.showcheckItemNamesLables &&
                                  searchResult.showcheckItemNamesLables.length >
                                    0
                                    ? searchResult.showcheckItemNamesLables.map(
                                        (eachType, index) => (
                                          <>
                                            {eachType.children}
                                            {searchResult
                                              .showcheckItemNamesLables.length -
                                              1 !==
                                              index && ", "}
                                          </>
                                        )
                                      )
                                    : "-"}
                                </span>
                              </p>
                              {/* )} */}
                              <p
                                style={{
                                  color: "#99a2c3",
                                  fontSize: "16px",
                                  margin: "0 0 -2px",
                                }}
                              >
                                Half Bath &nbsp;:{" "}
                                <span
                                  style={{
                                    color: "#414555",
                                  }}
                                >
                                  {searchResult.info && searchResult.info
                                    ? searchResult.info.halfBaths
                                    : "-"}
                                </span>
                              </p>
                              <p
                                style={{
                                  color: "#99a2c3",
                                  fontSize: "16px",
                                  margin: "0 0 -2px",
                                }}
                              >
                                Sort By &nbsp;:{" "}
                                <span
                                  style={{
                                    color: "#414555",
                                  }}
                                >
                                  {searchResult.info && searchResult.info
                                    ? searchResult.info.sortOrder ===
                                      "-listprice"
                                      ? "Price (High to Low)"
                                      : searchResult.info.sortOrder ===
                                        "listprice"
                                      ? "Price (Low to High)"
                                      : searchResult.info.sortOrder ===
                                        "listdate"
                                      ? "Date (Old to New)"
                                      : searchResult.info.sortOrder ===
                                          "-listdate" && "Date (New to Old)"
                                    : "-"}
                                </span>
                              </p>
                              <Row style={{ marginBottom: "-15px" }}>
                                <div className="layout-last-step">
                                  {/* {searchResult.info.minbeds && ( */}
                                  <>
                                    <span>
                                      &nbsp;
                                      <img
                                        src={bedIcon}
                                        alt="space"
                                        width="18px"
                                        height="10px"
                                      />
                                    </span>
                                    <span className="layout-subtitles">
                                      <p className="beds-size-mobile">
                                        {searchResult.info.minbeds === null
                                          ? 0
                                          : searchResult.info.minbeds}{" "}
                                        Beds
                                      </p>
                                    </span>
                                  </>
                                  {/* )} */}
                                </div>
                                <div
                                  className="layout-last-step"
                                  style={{ marginLeft: "20px" }}
                                >
                                  {/* {searchResult.info.minbaths && ( */}
                                  <>
                                    <span>
                                      <img
                                        src={bathIcon}
                                        alt="space"
                                        width="18px"
                                        height="10px"
                                      />
                                    </span>
                                    <span className="layout-subtitles">
                                      <p
                                        style={{ fontSize: "16px" }}
                                        className="beds-size-mobile"
                                      >
                                        {searchResult.info.minbaths === null
                                          ? 0
                                          : searchResult.info.minbaths}
                                        {searchResult.info.halfBaths > 0
                                          ? ".5"
                                          : ""}{" "}
                                        Baths
                                      </p>
                                    </span>
                                  </>
                                  {/* )} */}
                                </div>
                                <div
                                  className="layout-last-step"
                                  style={{ marginLeft: "20px" }}
                                >
                                  {/* {(searchResult.info.minarea ||
                                  searchResult.info.maxarea) && (
                                  <> */}
                                  <span>
                                    <img
                                      src={reSize}
                                      alt="space"
                                      width="16px"
                                      height="10px"
                                    />
                                  </span>
                                  <span className="layout-subtitles">
                                    <p
                                      style={{ fontSize: "16px" }}
                                      className="beds-size-mobile"
                                    >
                                      {searchResult.info.minarea &&
                                      searchResult.info.maxarea
                                        ? `${searchResult.info.minarea} sq.ft` +
                                          " - " +
                                          `${searchResult.info.maxarea} sq.ft`
                                        : searchResult.info.minarea &&
                                          (searchResult.info.minarea !== null ||
                                            searchResult.info.minarea !==
                                              undefined)
                                        ? `${searchResult.info.minarea} sq.ft`
                                        : searchResult.info.maxarea &&
                                          (searchResult.info.maxarea !== null ||
                                            searchResult.info.maxarea !==
                                              undefined) &&
                                          `${searchResult.info.maxarea} sq.ft`}
                                    </p>
                                  </span>
                                  {/* </>
                                )} */}
                                </div>
                              </Row>
                            </div>
                          </Panel>
                        </Collapse>
                      </div>
                    ))}
                  </Carousel>
                </Row>
              )}
            </>
          )}
        </Layout>

        <Layout
          className={listOption !== "resultsOnly" ? "margin-body-agent" : ""}
        >
          <Row style={{ backgroundColor: "#f0f2f5" }}>
            {listOption !== "resultsOnly" && (
              <Col
                xl={{ span: 5, offset: 0 }}
                lg={{ span: 5, offset: 0 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                className="side-bar"
              >
                {window.location.pathname === "/offMLSHomes" ? (
                  <div>
                    <Row justify="center">
                      {userSavedSearchList && userSavedSearchList.length > 0 ? (
                        <p
                          className="header-down-text"
                          style={{ textAlign: "center", cursor: "pointer" }}
                          onClick={this.showSavedSearch}
                        >
                          <FileSearchOutlined /> Previous search results
                        </p>
                      ) : (
                        <p
                          className="header-down-text"
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          Off MLS Homes Listing
                        </p>
                      )}
                      <div
                        className="show-on-mobile"
                        style={{ cursor: "pointer" }}
                      >
                        <Button
                          type="link"
                          onClick={() => {
                            this.setState({
                              enableInfo: !enableInfo,
                            });
                          }}
                          icon={<InfoCircleOutlined />}
                        ></Button>
                      </div>
                    </Row>
                  </div>
                ) : (
                  <div>
                    {userSavedSearchList && userSavedSearchList.length > 0 ? (
                      <p
                        className="header-down-text"
                        style={{ textAlign: "center", cursor: "pointer" }}
                        onClick={this.showSavedSearch}
                      >
                        <FileSearchOutlined /> Previous search results
                      </p>
                    ) : (
                      <p
                        className="header-down-text"
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        Search MLS listings & Off MLS Listings
                      </p>
                    )}
                  </div>
                )}

                {enableInfo && this.offmlsInfo()}

                <>
                  {this.props.EmailShowMatchesList ? (
                    <div className="show-on-desktop">
                      {this.EmailshowMatches()}
                    </div>
                  ) : (
                    listOption !== "resultsOnly" && (
                      <div className="show-on-desktop">{this.filterView()}</div>
                    )
                  )}

                  <div className="show-on-mobile">
                    <Row
                      justify="space-between"
                      style={{ marginTop: "5px", marginBottom: "10px" }}
                    >
                      <Col
                        md={{ span: 16 }}
                        sm={{ span: 18 }}
                        xs={{ span: 16 }}
                      >
                        {!this.props.EmailShowMatchesList && (
                          <Select
                            defaultValue={`${sortOrder}`}
                            placeholder="Sort By :"
                            className="sort-by-box"
                            onSelect={(sortValue) => this.handleSort(sortValue)}
                          >
                            <Option value="listprice">
                              Price: Low to High
                            </Option>
                            <Option value="-listprice">
                              Price: High to Low
                            </Option>
                            <Option value="-listdate">Date: New to Old</Option>
                            <Option value="listdate"> Date: Old to New</Option>
                          </Select>
                        )}
                      </Col>
                      <Col md={{ span: 7 }} sm={{ span: 6 }} xs={{ span: 7 }}>
                        <Button
                          onClick={() => {
                            this.setState({
                              enableFilter: !enableFilter,
                            });
                          }}
                          type="primary"
                          icon={<FilterOutlined />}
                          ghost
                          style={{ float: "right" }}
                        >
                          Filters
                        </Button>
                      </Col>
                    </Row>
                    {enableFilter && this.filterView()}
                  </div>
                </>

                <div
                  className="Advertisement-show"
                  style={{ marginTop: "2vw" }}
                >
                  {showingAdsAtSearch && this.showAds()}
                  {!showingAdsAtSearch && this.searchShowAds()}
                </div>
              </Col>
            )}

            <Col
              xl={{
                span: listOption !== "resultsOnly" ? 18 : 23,
                offset: listOption !== "resultsOnly" ? 0 : 1,
              }}
              lg={{
                span: listOption !== "resultsOnly" ? 18 : 23,
                offset: listOption !== "resultsOnly" ? 0 : 1,
              }}
              md={{
                span: listOption !== "resultsOnly" ? 22 : 23,
                offset: 1,
              }}
              sm={{
                span: listOption !== "resultsOnly" ? 22 : 23,
                offset: 1,
              }}
              xs={{
                span: listOption !== "resultsOnly" ? 22 : 23,
                offset: 1,
              }}
              className="margin-left-agent"
            >
              <Row className="show-on-desktop">
                <Col
                  xl={{ span: 16 }}
                  lg={{ span: 16 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div>
                    <p className="listing-heading">
                      Listings Found :{" "}
                      {allHomesList.length < 100
                        ? allHomesList.length
                        : homesListCount}{" "}
                      Homes that match your query
                    </p>
                  </div>
                </Col>

                <Col
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  {listOption !== "resultsOnly" &&
                    !this.props.EmailShowMatchesList && (
                      <span className="listing-heading"> Sort By : </span>
                    )}
                  {!this.props.EmailShowMatchesList && (
                    <Select
                      // defaultValue={}
                      className="sort-by-box"
                      onChange={(sortValue) => this.handleSort(sortValue)}
                      value={`${sortOrder}`}
                    >
                      <Option value="listprice">Price: Low to High</Option>
                      <Option value="-listprice">Price: High to Low</Option>
                      <Option value="-listdate">Date: New to Old</Option>
                      <Option value="listdate"> Date: Old to New</Option>
                    </Select>
                  )}
                </Col>
              </Row>
              <Row style={{ background: "#f0f2f5" }}>
                <Col
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                >
                  {window.location.pathname === "/offMLSHomes" && (
                    <p
                      className="show-on-desktop"
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        color: "#000",
                        marginBottom: "0",
                        background: "#f0f2f5",
                      }}
                    >
                      Search for homes that are not presently on MLS. These may
                      be coming soon listings, for sale by owners, or homes
                      sellers would prefer to sell outside of MLS. You just may
                      find the home you have been looking for before your
                      competition finds out about it.
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {!dataLoading && allHomesList && allHomesList.length === 0 ? (
                    <div style={{ padding: "4vw" }}>
                      <DataNotFound
                        type="homes"
                        title="There are no listings Found"
                      />
                    </div>
                  ) : (
                    allHomesList &&
                    allHomesList
                      .slice(minValue, maxValue)
                      .map((value, index) => (
                        <Skeleton
                          title={false}
                          loading={dataLoading}
                          active={dataLoading}
                        >
                          {index % 4 === 0 && index > 1 ? (
                            <>
                              <Advertisement
                                otherAds={false}
                                adType="Rectangle"
                                adWhereToShow="Homes"
                                adLimit="1"
                                zipCode=""
                                showMatchesPage={this.props.showMatchesPage}
                                cityName={cities && cities.citiesList}
                                postalCode=""
                                stateName=""
                              />
                              <HomeCard
                                value={value}
                                valueId={this.props.context.state.user.userid}
                                contextProps={this.props}
                                getHomesList={this.getHomesList}
                                showMatchesPage={
                                  showMatchesPage
                                    ? showMatchesPage
                                    : this.props.showMatchesPage
                                }
                                // showMatchesPage={this.props.showMatchesPage}
                                currentPage={currentPage}
                                perpageSize={perpageSize}
                              />
                            </>
                          ) : (
                            <HomeCard
                              value={value}
                              valueId={this.props.context.state.user.userid}
                              contextProps={this.props}
                              getHomesList={this.getHomesList}
                              showMatchesPage={
                                showMatchesPage
                                  ? showMatchesPage
                                  : this.props.showMatchesPage
                              }
                              // showMatchesPage={this.props.showMatchesPage}
                              currentPage={currentPage}
                              perpageSize={perpageSize}
                            />
                          )}
                        </Skeleton>
                      ))
                  )}
                </Col>
              </Row>
              {allHomesList.length === 0 ? (
                ""
              ) : (
                <div
                  className="align-right"
                  style={{
                    marginTop: "20px",
                    marginBottom: "30px",
                  }}
                >
                  <Pagination
                    total={allHomesList && allHomesList.length}
                    handlePagination={this.handlePagination}
                    currentPage={currentPage}
                    handleLoadMoreButton={this.handleLoadMoreButton}
                    perpageSize={perpageSize}
                  />
                </div>
              )}
            </Col>

            {listOption !== "resultsOnly" && (
              <Col
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <div className="Advertisement-show-on-mobile-view">
                  {this.showAds()}
                </div>
              </Col>
            )}
          </Row>
        </Layout>

        <Modal
          className="modal-black-close"
          visible={enableSearchTitle}
          title="Save Search Title"
          onCancel={() =>
            this.setState({
              enableSearchTitle: false,
            })
          }
          footer={[
            <Button
              key="back"
              onClick={() =>
                this.setState({
                  enableSearchTitle: false,
                })
              }
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => this.update(true)}
            >
              Save
            </Button>,
          ]}
        >
          <Row style={{ padding: "10px" }}>
            <Input
              type="text"
              placeholder="Enter title for the search"
              value={searchTitle}
              onChange={this.handleSearchTitle}
              className="form-input"
              autoFocus
            />
          </Row>
          <Row style={{ padding: "10px" }}>
            <Checkbox
              onChange={this.sendAlertsForSaveSearch}
              checked={save_search_alert_true}
            >
              Send me alerts for this search
            </Checkbox>
          </Row>
        </Modal>
        <Modal
          visible={save_search_login_alert_message}
          onCancel={() =>
            this.setState({
              save_search_login_alert_message: false,
            })
          }
          style={{ top: 20 }}
          className="modal-black-close"
          // title="Create a free account"
          footer={[
            <Button key="submit" type="primary">
              <Link
                to="/register"
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                Create account{" "}
              </Link>
            </Button>,
            <Button
              key="cancel"
              onClick={() =>
                this.setState({
                  save_search_login_alert_message: false,
                })
              }
            >
              Not now
            </Button>,
          ]}
        >
          <Row style={{ paddingTop: "40px" }}>
            <Col span={1}>
              <InfoCircleTwoTone style={{ fontSize: "35px" }} />
            </Col>
            <Col span={20} offset={2}>
              <p className="property-description font-family-style font-normal-size">
                Create a free account now and save this search to get daily
                alerts when new properties hit the site
              </p>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default withAppContext(withRouter(HomeTab));
