import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Drawer, Spin } from "antd";
import { withAppContext } from "../store/contextStore";
import { noAuthlist } from "../api/Apis";
import Advertisement from "../common/Advertisement";
import BuyerCard from "../components/Card/BuyerCard";
import HomeTab from "../components/Tabs/HomeTab";
import DataNotFound from "./DataNotFound";

import bedIcon from "../images/awesome-bed-icon.png";
import bathIcon from "../images/awesome-bath-icon.png";
import reSize from "../images/sq-feet-icon.png";

class LocalHomes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyerHomesList: [],
      enableShowMatches: false,
      FilterData: "",
      filterCount: 0,
      buyerTitle: "",
      buyerRequirement: {},
      showPropertyType: [],
      allHomesList: [],
      homesListCount: 0,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    this.getBuyerHomeDetails();
  }

  getBuyerHomeDetails = async () => {
    const { userDetails } = this.props.context.state;
    const { sharingUserParamsId } = this.props;
    let id;
    id = sharingUserParamsId ? sharingUserParamsId : this.props.match.params.id;
    let userId = id;
    const buyerHomesList = [];
    const newApi = await noAuthlist("getBuyers", { userId: userId });

    for (let singleItem of newApi.data) {
      buyerHomesList.push({
        ...singleItem,
        homeAgentName:
          userDetails &&
          userDetails.firstName &&
          userDetails.firstName + " " + userDetails.lastName,
        homeAgentProfileUrl:
          userDetails.profilePicture &&
          `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
            userDetails.profilePicture[0],
        agentProfile: true,
      });
    }
    if (newApi.success) {
      this.props.setLoadingfalse();
      this.setState({
        buyerHomesList,
        loading: false,
      });
    }
    this.setState({
      loading: false,
    });
  };

  handleBuyerTitle = (buyerTitle) => {
    this.setState({
      buyerTitle: buyerTitle,
    });
  };

  handleShowMatches = () => {
    this.setState({
      enableShowMatches: !this.state.enableShowMatches,
    });
  };

  handleShowMatchData = (FilterData) => {
    this.setState({ showMatchesLoading: true });
    this.setState({
      filterCount: this.state.filterCount + 1,
      FilterData,
    });
    let filterObject = {};
    for (let filterOption of FilterData) {
      if (filterOption.type === "beds" && filterOption.minbeds !== 0) {
        filterObject["minbeds"] = filterOption.minbeds;
      }

      if (filterOption.type === "baths" && filterOption.minbaths !== 0) {
        filterObject["minbaths"] = filterOption.minbaths;
      }

      if (filterOption.type === "area") {
        filterObject["maxarea"] = filterOption.maxarea;
        filterObject["minarea"] = filterOption.minarea;
      }

      if (filterOption.type === "price") {
        filterObject["maxprice"] = filterOption.maxprice;
        filterObject["minprice"] = filterOption.minprice;
      }

      if (
        filterOption.type === "subtype" &&
        filterOption.subTypeText.length > 0
      ) {
        filterObject["types"] =
          filterOption.subTypeText && filterOption.subTypeText.join(",");
        this.setState({
          showPropertyType:
            filterOption.subTypeText && filterOption.showPropertyType.join(","),
        });
      }

      if (
        filterOption.type === "status" &&
        filterOption.status &&
        filterOption.status.length > 0
      ) {
        filterObject["status"] = filterOption.status;
      }
      if (
        filterOption.type === "location" &&
        filterOption.multiLocations &&
        filterOption.multiLocations.length > 0
      ) {
        let cities = {};
        let citiesList = [];
        filterOption.multiLocations &&
          filterOption.multiLocations.map((eachCity) => {
            return citiesList.push(
              `${eachCity.stateName}:${eachCity.cityName}`
            );
          });
        cities = {
          searchLocation: filterOption.multiLocations,
          type: "location",
          citiesList: citiesList,
        };
        localStorage.setItem("selectedCities", JSON.stringify(cities));
      } else {
        localStorage.removeItem("selectedCities");
      }
    }

    this.props.context.setFilterData({});
    this.setState({
      buyerRequirement: filterObject,
    });
    filterObject["filter"] = true;
    this.props.context.setFilterData(filterObject);
  };
  handleShowMatchesLoading = () => {
    this.setState({
      showMatchesLoading: false,
    });
  };
  render() {
    const { authenticate } = this.props;
    const {
      buyerHomesList,
      buyerTitle,
      enableShowMatches,
      buyerRequirement,
      homesListCount,
      allHomesList,
      showMatchesLoading,
      loading,
    } = this.state;

    const buyersData = [];
    buyerHomesList &&
      buyerHomesList.map((e, index) =>
        buyersData.push({
          id: e.id,
          title: e.buyerPropertyDetails2.title,
          baths: e.buyerPropertyFeatures.baths
            ? e.buyerPropertyFeatures.baths
            : 0,
          beds: e.buyerPropertyFeatures.beds ? e.buyerPropertyFeatures.beds : 0,
          minprice:
            e.buyerPropertyDetails && e.buyerPropertyDetails.minprice
              ? e.buyerPropertyDetails.minprice
              : "",
          maxprice:
            e.buyerPropertyDetails && e.buyerPropertyDetails.maxprice
              ? e.buyerPropertyDetails.maxprice
              : "",
          minArea:
            e.buyerPropertyFeatures && e.buyerPropertyFeatures.minArea
              ? e.buyerPropertyFeatures.minArea
              : "NA",
          maxArea:
            e.buyerPropertyFeatures && e.buyerPropertyFeatures.minArea
              ? e.buyerPropertyFeatures.maxArea
              : "NA",

          PropertyType:
            e.sellerVsBuyerVsVendor && e.sellerVsBuyerVsVendor
              ? e.buyerPropertyFeatures.propertyType.join(", ")
              : e.buyerPropertyDetails &&
                e.buyerPropertyDetails.propertyType &&
                e.buyerPropertyDetails.propertyType.length > 0
              ? e.buyerPropertyDetails.propertyType.join(", ")
              : [],

          userid: e.userId,
          buyersList: true,
          createdAt: e.createdAt,
          editBuyersData: e,
          stateTypeAdded:
            e.buyerPropertyDetails &&
            e.buyerPropertyDetails.stateTypeAdded &&
            e.buyerPropertyDetails.stateTypeAdded,
          updatedInterestedTowns:
            e.buyerPropertyDetails &&
            e.buyerPropertyDetails.updatedInterestedTowns &&
            e.buyerPropertyDetails.updatedInterestedTowns.length > 0
              ? e.buyerPropertyDetails.updatedInterestedTowns
              : [],
          sellerVsBuyerVsVendor:
            e.sellerVsBuyerVsVendor && e.sellerVsBuyerVsVendor,

          showOnBuyersPage:
            e.buyerPropertyDetails2 && e.buyerPropertyDetails2.showOnBuyersPage,
        })
      );
    return (
      <div>
        {/* <Loading enableLoading={showMatchesLoading} /> */}
        <Spin spinning={loading}>
          {!showMatchesLoading && buyersData && buyersData.length === 0 && (
            <DataNotFound type="buyers" title="No buyer profiles as yet." />
          )}
        </Spin>
        {buyersData &&
          buyersData.length > 0 &&
          buyersData.map((val, index) => {
            if (authenticate && index % 4 === 0 && index > 1) {
              return (
                <>
                  <Advertisement
                    otherAds={false}
                    adType="Rectangle"
                    adWhereToShow="Buyers"
                    adLimit="1"
                    zipCode=""
                  />
                  <BuyerCard
                    key={val.id}
                    value={val}
                    contextProps={this.props.context}
                    handleFavoritesCount={this.props.context}
                    buyerTitleInfo={this.handleBuyerTitle}
                    showMatches={this.handleShowMatches}
                    handleMatches={this.handleShowMatchData}
                    getBuyerHomeDetails={this.getBuyerHomeDetails}
                  />
                </>
              );
            } else {
              return (
                <BuyerCard
                  key={val.id}
                  value={val}
                  handleFavoritesCount={this.props.context}
                  buyerTitleInfo={this.handleBuyerTitle}
                  showMatches={this.handleShowMatches}
                  handleMatches={this.handleShowMatchData}
                  getBuyerHomeDetails={this.getBuyerHomeDetails}
                />
              );
            }
          })}

        <Drawer
          title={"Showing matches for : " + buyerTitle}
          placement="right"
          width={
            window.screen.width >= "768"
              ? "80%"
              : window.screen.width > "501" && window.screen.width <= "767"
              ? "80%"
              : "80%"
          }
          mask={true}
          closable={true}
          destroyOnClose={true}
          getContainer={false}
          visible={enableShowMatches}
          onClose={() =>
            this.setState({
              enableShowMatches: false,
              buyerTitle: "",
              filterCount: 0,
              allHomesList: [],
            })
          }
          headerStyle={{ textAlign: "center", textTransform: "capitalize" }}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Col
            span={24}
            style={{
              backgroundColor: "#f9faff",
              padding: "5px",
              marginBottom: "2vw",
            }}
          >
            <Row>
              <Col span={10}>
                <h3>Features : </h3>
                <Row>
                  {buyerRequirement && buyerRequirement.minbeds && (
                    <Row>
                      <div className="layout-last-step">
                        <span>
                          &nbsp;
                          <img
                            src={bedIcon}
                            alt="space"
                            width="18px"
                            height="10px"
                          />
                        </span>
                        <span className="layout-subtitles">
                          <p style={{ fontSize: "16px" }}>
                            {buyerRequirement.minbeds === null
                              ? 0
                              : buyerRequirement.minbeds}{" "}
                            Beds
                          </p>
                        </span>
                      </div>
                    </Row>
                  )}
                  {buyerRequirement && buyerRequirement.minbaths && (
                    <Row>
                      <div
                        className="layout-last-step"
                        style={{ marginLeft: "20px" }}
                      >
                        <span>
                          <img
                            src={bathIcon}
                            alt="space"
                            width="18px"
                            height="10px"
                          />
                        </span>
                        <span className="layout-subtitles">
                          <p style={{ fontSize: "16px" }}>
                            {buyerRequirement.minbaths === null
                              ? 0
                              : buyerRequirement.minbaths}{" "}
                            Baths
                          </p>
                        </span>
                      </div>
                    </Row>
                  )}

                  {buyerRequirement && buyerRequirement.maxarea && (
                    <Row>
                      <div
                        className="layout-last-step"
                        style={{ marginLeft: "20px" }}
                      >
                        <span>
                          <img
                            src={reSize}
                            alt="space"
                            width="16px"
                            height="10px"
                          />
                        </span>
                        <span className="layout-subtitles">
                          <p style={{ fontSize: "16px" }}>
                            {buyerRequirement.maxarea === null ||
                            buyerRequirement.minarea === null ||
                            buyerRequirement.maxarea >= 99999
                              ? "NA"
                              : `${buyerRequirement.minarea} sq.ft -` +
                                `${buyerRequirement.maxarea} sq.ft`}
                          </p>
                        </span>
                      </div>
                    </Row>
                  )}
                </Row>
              </Col>
              <Col span={13} offset={1}>
                <Row>
                  {buyerRequirement && buyerRequirement.multi && (
                    <Col span={12}>
                      <h3>Interested Towns :</h3>
                      <p>
                        {buyerRequirement.multi
                          ? buyerRequirement.multi.map((city, index) => (
                              <>
                                {city.cityName + ", " + city.stateName}
                                {index !== buyerRequirement.multi.length - 1
                                  ? ". "
                                  : ". "}
                              </>
                            ))
                          : ""}
                      </p>
                    </Col>
                  )}

                  {this.state.showPropertyType && (
                    <Col span={12}>
                      <h3>Property Type :</h3>
                      <p>{this.state.showPropertyType}</p>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          {enableShowMatches && (
            <Spin spinning={showMatchesLoading}>
              <Col span={24}>
                <HomeTab
                  showColor
                  allHomesList={allHomesList}
                  listInfo={homesListCount}
                  listType="list"
                  listOption="resultsOnly"
                  handleShowMatchesLoading={this.handleShowMatchesLoading}
                  showMatchesPage={true}
                />
              </Col>
            </Spin>
          )}
        </Drawer>
      </div>
    );
  }
}
export default withAppContext(withRouter(LocalHomes));
