import HomeTab from "../components/Tabs/HomeTab";
import Agent from "../containers/Modules/Agent";
import Marketplace from "../containers/Modules/Marketplace";
import Lenders from "../containers/Modules/Lenders";
import AddSellerhome from "../containers/Modules/AddSellerhome";
import AddBuyerprofile from "../containers/Modules/AddBuyerprofile";
import Myprofile from "../containers/Modules/Myprofile/Myprofile";
import AllFavourties from "../containers/Modules/Favourties/AllFavourties";
import Home from "../containers/homePage/Home";
import LoginForm from "../containers/LoginForm";
import RegisterForm from "../containers/RegisterForm";
import BuyersTab from "../components/Tabs/BuyersTab";
import About from "../containers/About/About";
import WhyJoinUs from "../containers/WhyJoinUs/WhyJoinUs";
import ContactUs from "../containers/ContactUs/ContactUs";
import Testmonials from "../containers/Testimonials/Testimonials";
import AllusersBuyerForm from "../containers/Modules/AllusersBuyerForm";
import AllusersHomeForm from "../containers/Modules/AllusersHomeForm";
import VendorProfileForm from "../containers/Modules/Myprofile/VendorProfileForm";
import BuyerProfile from "../containers/Modules/Myprofile/BuyerProfile";
import BuyerProfileForm from "../containers/Modules/Myprofile/BuyerProfileForm";
import PrivacyPolicies from "../containers/PrivacyPolicies/PrivacyPolicies";
import TermsAndConditions from "../containers/PrivacyPolicies/TermsAndConditions";
import ViewProfile from "../containers/Modules/ViewProfile";
import Details from "../components/Details/Details";
import PageNotFound from "../common/PageNotFound";
import BuyerDetails from "../components/Details/BuyerDetails";
import EmailShowMatchesList from "../containers/EmailShowMatchesList";

const AppRoutes = [
  {
    key: "000",
    exact: true,
    path: "/",
    component: Home,
  },
  {
    key: "001",
    exact: true,
    path: "/homes",
    component: HomeTab,
  },
  {
    key: "002",
    exact: true,
    path: "/homeDetails/:stateName/:id",
    component: Details,
  },
  {
    key: "003",
    exact: true,
    path: "/buyers",
    component: BuyersTab,
  },
  {
    key: "004",
    exact: true,
    path: "/agent",
    component: Agent,
  },

  {
    key: "006",
    exact: true,
    path: "/add/sellerProfile",
    component: AddSellerhome,
  },
  {
    key: "007",
    exact: true,
    path: "/add/buyerProfile",
    component: AddBuyerprofile,
  },
  {
    key: "008",
    exact: true,
    path: "/myProfile/:id",
    component: Myprofile,
  },
  {
    key: "009",
    exact: true,
    path: "/homeDetails/:id",
    component: Details,
  },
  {
    key: "010",
    exact: true,
    path: "/buyersHome/:id",
    component: BuyerDetails,
  },
  {
    key: "011",
    exact: true,
    path: "/favourties/:id",
    component: AllFavourties,
  },

  {
    key: "013",
    exact: true,
    path: "/offMLSHomes",
    component: HomeTab,
  },
  {
    key: "014",
    exact: true,
    path: "/login",
    component: LoginForm,
  },
  {
    key: "015",
    exact: true,
    path: "/register",
    component: RegisterForm,
  },
  {
    key: "016",
    exact: true,
    path: "/about",
    component: About,
  },
  {
    key: "017",
    exact: true,
    path: "/whyJoinUs",
    component: WhyJoinUs,
  },
  {
    key: "018",
    exact: true,
    path: "/contactUs",
    component: ContactUs,
  },
  {
    key: "019",
    exact: true,
    path: "/testimonials/:id",
    component: Testmonials,
  },
  {
    key: "020",
    exact: true,
    path: "/marketplace",
    component: Marketplace,
  },
  {
    key: "023",
    exact: true,
    path: "/add/buyerform",
    component: AllusersBuyerForm,
  },
  {
    key: "024",
    exact: true,
    path: "/lenders",
    component: Lenders,
  },
  {
    key: "027",
    exact: true,
    path: "/add/userHomeForm",
    component: AllusersHomeForm,
  },
  {
    key: "028",
    exact: true,
    path: "/vendorEditProfile/:id",
    component: VendorProfileForm,
  },
  {
    key: "029",
    exact: true,
    path: "/sellerBuyer/viewProfile/:id",
    component: ViewProfile,
  },
  {
    key: "029",
    exact: true,
    path: "/buyerProfile",
    component: BuyerProfile,
  },
  {
    key: "030",
    exact: true,
    path: "/buyerProfileForm",
    component: BuyerProfileForm,
  },
  {
    key: "031",
    exact: true,
    path: "/privacyPolicies",
    component: PrivacyPolicies,
  },
  {
    key: "032",
    exact: true,
    path: "/termsOfService",
    component: TermsAndConditions,
  },
  {
    key: "033",
    exact: true,
    path: "/add/buyerProfile/:id",
    component: AddBuyerprofile,
  },

  {
    key: "034",
    exact: true,
    path: "/add/sellerProfile/:id",
    component: AddSellerhome,
  },
  {
    key: "035",
    exact: true,
    path: "/add/userHomeForm/:id",
    component: AllusersHomeForm,
  },
  {
    key: "036",
    exact: true,
    path: "/add/buyerform/:id",
    component: AllusersBuyerForm,
  },
  {
    key: "037",
    exact: true,
    path: "/marketplace/viewProfile/:id",
    component: ViewProfile,
  },
  {
    key: "038",
    exact: true,
    path: "/lenders/viewProfile/:id",
    component: ViewProfile,
  },
  {
    key: "039",
    exact: true,
    path: "/agent/viewProfile/:id",
    component: ViewProfile,
  },
  {
    key: "040",
    exact: true,
    path: "/viewProfile/:id",
    component: ViewProfile,
  },
  {
    key: "041",
    exact: true,
    path: "/:userName",
    component: ViewProfile,
  },
  {
    key: "042",
    exact: true,
    path: "/pageNotFound/newtWork",
    component: PageNotFound,
  },
  {
    key: "043",
    exact: true,
    path: "/email/seematches",
    component: EmailShowMatchesList,
  },
];

export default AppRoutes;
