import React, { Component } from "react";
import { Row, Col, InputNumber, Input } from "antd";

class Sliders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minValue: this.props.one ? "" : 1,
      maxValue: this.props.one ? "" : 20000,
    };
  }
  componentDidMount() {
    this.setOldFilterData();
  }

  // static getDerivedStateFromProps(props, state) {
  //   const areaData = props.oldArea && props.oldArea[0];
  //   const priceData = props.oldPrice && props.oldPrice[0];
  //   if (props.staticNumber !== state.staticNumber) {
  //     if (areaData && areaData.type === "area") {
  //       return {
  //         maxValue: areaData.maxarea,
  //         minValue: areaData.minarea,
  //       };
  //     }
  //   }
  //   if (props.staticNumber !== state.staticNumber) {
  //     if (priceData && priceData.type === "price") {
  //       return {
  //         maxValue: priceData.maxprice,
  //         minValue: priceData.minprice,
  //       };
  //     }
  //   }
  //   return false;
  // }

  setOldFilterData = () => {
    if (window.location.pathname !== "/buyers") {
      this.props.methodOldSavedSearch();
    }
    const { oldArea, oldPrice } = this.props;
    const areaData = oldArea && oldArea[0];
    const priceData = oldPrice && oldPrice[0];
    if (areaData && areaData.type === "area") {
      this.setState({
        maxValue: areaData.maxarea,
        minValue: areaData.minarea,
      });
      this.props.handleLivingArea(
        parseInt(areaData.minarea),
        parseInt(areaData.maxarea)
      );
    }
    if (priceData && priceData.type === "price") {
      this.setState({
        maxValue: priceData.maxprice,
        minValue: priceData.minprice,
      });
      this.props.handlePrice(priceData.minprice, priceData.maxprice);
    }
  };
  handleMinPrice = (price) => {
    const { maxValue } = this.state;
    this.setState({
      minValue: price,
      staticNumber: 1,
    });
    this.props.handlePrice(price, maxValue);
  };
  handleMaxPrice = (price) => {
    const { minValue } = this.state;
    this.setState({
      maxValue: price,
      staticNumber: 1,
    });
    this.props.handlePrice(minValue, price);
  };
  handleMinArea = (e) => {
    const { maxValue } = this.state;
    this.setState({
      minValue: e.target.value,
      staticNumber: 1,
    });
    this.props.handleLivingArea(e.target.value, maxValue);
  };
  handleMaxArea = (e) => {
    const { minValue } = this.state;
    this.setState({
      maxValue: e.target.value,
      staticNumber: 1,
    });
    this.props.handleLivingArea(minValue, e.target.value);
  };
  render() {
    const { minValue, maxValue } = this.state;
    return (
      <div>
        <p className="second-row-title">{this.props.title}</p>
        {this.props.one && (
          <Row>
            <Col
              xl={{ span: 23, offset: 1 }}
              lg={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              xs={{ span: 23, offset: 1 }}
              className="slider-one"
            >
              <span className="min-price-label">Min Price</span>&nbsp;
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={this.handleMinPrice}
                value={minValue}
                className="min-price-width"
              />
            </Col>
            <Col
              xl={{ span: 23, offset: 1 }}
              lg={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              xs={{ span: 23, offset: 1 }}
              className="slider-one"
            >
              <span className="min-price-label">Max Price</span>
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={this.handleMaxPrice}
                value={maxValue}
                className="min-price-width"
              />
            </Col>
          </Row>
        )}
        {this.props.three && (
          <Row>
            <Col
              xl={{ span: 23, offset: 1 }}
              lg={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              xs={{ span: 23, offset: 1 }}
              className="slider-one"
            >
              <span className="min-price-label">Min Area</span>&nbsp;
              <Input
                addonAfter="sq ft"
                onChange={this.handleMinArea}
                value={minValue}
                className="min-area-width"
              />
            </Col>
            <Col
              xl={{ span: 23, offset: 1 }}
              lg={{ span: 23, offset: 1 }}
              md={{ span: 23, offset: 1 }}
              sm={{ span: 23, offset: 1 }}
              xs={{ span: 23, offset: 1 }}
              className="slider-one"
            >
              <span className="min-price-label">Max Area</span>
              <Input
                addonAfter="sq ft"
                onChange={this.handleMaxArea}
                value={maxValue}
                className="min-area-width"
              />
            </Col>
          </Row>
        )}
        {this.props.setOldSavedSearch && this.setOldFilterData()}
      </div>
    );
  }
}

export default Sliders;
