import React, { Component } from "react";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Row, Col, Space } from "antd";

export class Number extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicks: 0,
      show: true,
      staticNumber: true,
    };
  }
  
  componentDidMount() {
    this.setState({
      staticNumber: true,
    });
    // if (this.props.oldNumber) {
    //   const oldData = this.props.oldNumber[0];
    //   if (oldData && oldData.beds && oldData.beds !== "undefined") {
    //     this.setState({
    //       clicks: oldData.beds,
    //     });
    //     this.props.bedSize(oldData.beds);
    //   } else {
    //     if (oldData && oldData.baths && oldData.baths !== "undefined") {
    //       this.setState({
    //         clicks: oldData.baths,
    //       });
    //       this.props.bathSize(oldData.baths);
    //     }
    //   }
    // }

    this.setOldFilterData();
  }

  resetState = () => {
    this.setState({
      clicks: 0,
      show: true,
      staticNumber: true,
    });
    this.props.methodOldSavedSearch();
  };

  setOldFilterData = () => {
    if (window.location.pathname !== "/buyers") {
      this.props.methodOldSavedSearch();
    }
    if (this.props.oldNumber?.length > 0) {
      const oldData = this.props.oldNumber[0];
      if (oldData && oldData.beds && oldData.beds !== "undefined") {
        this.setState({
          clicks: oldData.beds,
        });
        this.props.bedSize(oldData.beds);
      } else {
        if (oldData && oldData.baths && oldData.baths !== "undefined") {
          this.setState({
            clicks: oldData.baths,
          });
          this.props.bathSize(oldData.baths);
        }
      }
    }
  };
  // static getDerivedStateFromProps(props, state) {
  //   const oldData = props.oldNumber[0];
  //   if (props.staticNumber !== state.staticNumber) {
  //     if (oldData && oldData.beds && oldData.beds !== "undefined") {
  //       return { clicks: oldData.beds };
  //     } else {
  //       if (props.beds) {
  //         return {
  //           clicks: 0,
  //         };
  //       }
  //     }
  //     if (oldData && oldData.baths && oldData.baths !== "undefined") {
  //       return {
  //         clicks: oldData.baths,
  //       };
  //     } else {
  //       if (props.baths) {
  //         return {
  //           clicks: 0,
  //         };
  //       }
  //     }
  //   }
  // }
  IncrementItem = () => {
    this.setState({ clicks: this.state.clicks + 1, staticNumber: 1 });
    if (this.props.beds) {
      this.props.bedSize(this.state.clicks + 1);
    } else {
      this.props.bathSize(this.state.clicks + 1);
    }
  };

  DecreaseItem = () => {
    if (this.state.clicks !== 0) {
      this.setState({ clicks: this.state.clicks - 1, staticNumber: 1 });
      if (this.props.beds) {
        this.props.bedSize(this.state.clicks - 1);
      } else {
        this.props.bathSize(this.state.clicks - 1);
      }
    }
  };
  render() {
    const { title, resetState, setOldSavedSearch, beds } = this.props;
    const { clicks, show } = this.state;
    return (
      <Col
        span={23}
        offset={1}
        className={!this.props.beds && "baths-level-show"}
      >
        <Row className="beds-beaths-row">
          <Space size="small">
            <Col className="margin-header">
              {title}
              {title === "Min Baths" ? <>&nbsp;</> : ""}
            </Col>
            <Col className={beds ? "beds-margin" : "margin"}>
              <MinusCircleOutlined
                onClick={this.DecreaseItem}
                className="beds-minusCircle"
              />
            </Col>
            <Col
              className="margin-text"
              style={{ marginBottom: "10%", width: "10px" }}
            >
              {show ? <span className="beds-values-baths">{clicks}</span> : ""}
            </Col>
            <Col className="margin">
              <PlusCircleOutlined
                onClick={this.IncrementItem}
                className="beds-PlusCircle"
              />
            </Col>
          </Space>
        </Row>
        {resetState && this.resetState()}
        {setOldSavedSearch && this.setOldFilterData()}
      </Col>
    );
  }
}

export default Number;
