import React, { Component } from "react";
import { Tabs, Table, Card, notification, Row, Col, Modal } from "antd";

import { withRouter } from "react-router";
import { list, update } from "../../api/Apis";
import { withAppContext } from "../../store/contextStore";
import moment from "moment";

const { TabPane } = Tabs;

class Missleads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completedLoading: true,
      pendingLeads: [],
      completedLeads: [],
      modalVisible: false,
      viewProperty: "",
      activeTabKey: 1,
    };
  }

  componentDidMount() {
    this.getLeads();
  }

  getLeads = async () => {
    const userId = this.props.match.params && this.props.match.params.id;
    try {
      let pendingList = [];
      let completedList = [];

      const allAlerts = await list(
        "notifications?relatedTo=" + userId + "&isAssigned=true"
      );
      if (allAlerts.success) {
        allAlerts.data.filter((lead) => {
          if (lead.assignedStatus === "completed") {
            return completedList.push(lead);
          } else {
            return pendingList.push(lead);
          }
        });

        this.setState({
          pendingLeads: pendingList,
          completedLeads: completedList,
          completedLoading: false,
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  completeMissedLead = async (id, record) => {
    this.setState({
      completedLoading: true,
    });

    const data = {
      entityData: {
        assignedStatus: "completed",
      },
    };

    try {
      update("updateNotificationStatus", id, data).then((result) => {
        if (result.success) {
          notification["success"]({
            message: "Status changed successfully!",
          });
          this.getLeads();
          this.setState({
            completedLoading: false,
          });
        } else {
          this.setState({
            completedLoading: false,
          });
          notification["error"]({
            message: "Something went wrong!",
          });
          console.log(result.errors);
        }
      });
    } catch (error) {
      notification["error"]({
        message: "Something went wrong!",
      });
      console.log(error, "error");
    }
  };

  render() {
    const {
      // activeTabKey,
      pendingLeads,
      completedLeads,
      completedLoading,
      modalVisible,
      viewProperty,
    } = this.state;

    const columns = [
      {
        title: "Lead Type",
        dataIndex: "notificationType",
        key: "notificationType",
        render: (notificationType) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color:
                  notificationType === "schedule"
                    ? "#3045d2"
                    : notificationType === "question"
                    ? "#f7d131"
                    : "#3abe15",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {notificationType === "schedule"
                ? "Schedule a Meeting"
                : notificationType === "question"
                ? "Q & A"
                : "Buyer Interest"}
            </span>
          );
        },
      },
      {
        title: "Property Title",
        dataIndex: "propertyTitle",
        key: "propertyTitle",
        render: (propertyTitle) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              {propertyTitle}
            </span>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "assignedStatus",
        key: "assignedStatus",
        render: (assignedStatus) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                color: assignedStatus === "pending" ? "#3045d2" : "#27c24c",
              }}
            >
              {assignedStatus === "pending" ? "In Progress" : "Completed"}
            </span>
          );
        },
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              {moment(createdAt).format("MM-DD-YYYY H:M a")}
            </span>
          );
        },
      },
      {
        title: "Action",
        key: "id",
        dataIndex: "id",
        show: this.state.show,
        render: (id, record) => (
          <div>
            <span
              style={{
                textTransform: "capitalize",
                backgroundColor: "#5bc0de",
                fontWeight: "bold",
                padding: "5px 10px",
                color: "#fff",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={() => {
                this.setState({ modalVisible: true, viewProperty: record });
              }}
            >
              View
            </span>

            {record.assignedStatus === "pending" && (
              <span
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "#3045d2",
                  fontWeight: "bold",
                  padding: "5px 10px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.completeMissedLead(id, record);
                }}
              >
                Contacted
              </span>
            )}
          </div>
        ),
      },
    ];

    return (
      <div style={{ margin: "20px 0px 0px" }}>
        {/* <Loading enableLoading={completedLoading} /> */}
        <Tabs
          defaultActiveKey="1"
          type="card"
          onChange={(key) =>
            this.setState({
              activeTabKey: key,
            })
          }
        >
          <TabPane key="1" tab="In Progress Leads">
            <div style={{ backgroundColor: "#FFF" }}>
              <Table
                columns={columns}
                dataSource={pendingLeads}
                loading={completedLoading}
                rowKey={(record) => record.id}
              />
            </div>
          </TabPane>

          <TabPane key="2" tab="Completed Leads">
            <div style={{ backgroundColor: "#FFF" }}>
              <Table
                columns={columns}
                dataSource={completedLeads}
                loading={completedLoading}
                rowKey={(record) => record.id}
              />
            </div>
          </TabPane>
        </Tabs>

        <Modal
          className="modal-black-close"
          visible={modalVisible}
          title={
            viewProperty && viewProperty.notificationType === "schedule"
              ? "Schedule a Meeting"
              : viewProperty && viewProperty.notificationType === "question"
              ? "Q & A"
              : "Buyer Interest"
          }
          width="80vw"
          style={{ marginTop: "2%" }}
          onCancel={() =>
            this.setState({
              modalVisible: false,
              viewProperty: "",
            })
          }
          footer={null}
        >
          {viewProperty && viewProperty.propertyTitle && (
            <>
              <div
                style={{
                  backgroundColor: "#f9fafb",
                  padding: "20px 20px 30px",
                }}
              >
                <Row gutter={[24, 24]}>
                  <Col span={24} style={{ padding: "20px 20px 0px 20px" }}>
                    <h4 style={{ color: "#aaa" }}>Property title :</h4>
                    <a
                      target="_blank"
                      href={`/homeDetails/${viewProperty.stateType}/${viewProperty.propertyId}`}
                      rel="noreferrer"
                    >
                      <h2
                        style={{
                          cursor: "pointer",
                          color: "#3045d2",
                          textDecoration: "underline",
                        }}
                      >
                        {viewProperty.propertyTitle}
                      </h2>
                    </a>
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col span={24} style={{ padding: "20px" }}>
                    <h4 style={{ color: "#aaa" }}>Message :</h4>
                    <h3>{viewProperty.message}</h3>
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col span={12} style={{ padding: "10px 10px 10px 20px" }}>
                    {viewProperty && viewProperty.requestorDetails && (
                      <Card>
                        <Row>
                          <h4
                            style={{
                              color: "#aaa",
                              margin: "2px 10px 0px 0px",
                            }}
                          >
                            Requester Name :{" "}
                          </h4>
                          <h3>
                            {viewProperty.requestorDetails.name !== "" &&
                            viewProperty.requestorDetails.name !== null &&
                            viewProperty.requestorDetails.name !== undefined
                              ? viewProperty.requestorDetails.name
                              : "N/A"}
                          </h3>
                        </Row>
                        <Row>
                          <h4
                            style={{
                              color: "#aaa",
                              margin: "2px 10px 0px 0px",
                            }}
                          >
                            Requester E-Mail :{" "}
                          </h4>
                          {viewProperty.requestorDetails.email !== "" &&
                          viewProperty.requestorDetails.email !== null &&
                          viewProperty.requestorDetails.email !== undefined ? (
                            <a
                              href={`mailto:${viewProperty.requestorDetails.email}`}
                            >
                              <h3>
                              {viewProperty.requestorDetails.email !== "" &&
                              viewProperty.requestorDetails.email !== null &&
                              viewProperty.requestorDetails.email !== undefined
                                ? viewProperty.requestorDetails.email
                                : "N/A"}
                                </h3>
                            </a>
                          ) : (
                           <h3>N/A</h3> 
                          )}
                        </Row>
                        <Row>
                          <h4
                            style={{
                              color: "#aaa",
                              margin: "2px 10px 0px 0px",
                            }}
                          >
                            Requester Phone :{" "}
                          </h4>
                          <h3>
                            {viewProperty.requestorDetails.phone !== "" &&
                            viewProperty.requestorDetails.phone !== null &&
                            viewProperty.requestorDetails.phone !== undefined
                              ? viewProperty.requestorDetails.phone
                              : "N/A"}
                          </h3>
                        </Row>
                      </Card>
                    )}
                  </Col>
                  <Col span={12} style={{ padding: "10px 20px 10px 10px" }}>
                    {viewProperty && viewProperty.agentDetails && (
                      <Card>
                        <Row>
                          <h4
                            style={{
                              color: "#aaa",
                              margin: "2px 10px 0px 0px",
                            }}
                          >
                            Requested Date :{" "}
                          </h4>
                          <h3>
                            {moment(viewProperty.createdAt).format(
                              "MM-DD-YYYY H:M a"
                            )}
                          </h3>
                        </Row>
                        <Row>
                          <h4
                            style={{
                              color: "#aaa",
                              margin: "2px 10px 0px 0px",
                            }}
                          >
                            Listed Agent Name :{" "}
                          </h4>
                          <h3>
                            {viewProperty.agentDetails.name !== "" &&
                            viewProperty.agentDetails.name !== null &&
                            viewProperty.agentDetails.name !== undefined
                              ? viewProperty.agentDetails.name
                              : "N/A"}
                          </h3>
                        </Row>
                        <Row>
                          <h4
                            style={{
                              color: "#aaa",
                              margin: "2px 10px 0px 0px",
                            }}
                          >
                            Listed Agent E-Mail :
                          </h4>
                          <h3>
                            {viewProperty.agentDetails.email !== "" &&
                            viewProperty.agentDetails.email !== null &&
                            viewProperty.agentDetails.email !== undefined
                              ? viewProperty.agentDetails.email
                              : "N/A"}
                          </h3>
                        </Row>
                      </Card>
                    )}
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

export default withAppContext(withRouter(Missleads));
