import { Col, Row } from "antd";
import React, { Component } from "react";
import DataNotFound from "./DataNotFound";
export default class PageNotFound extends Component {
  render() {
    return (
      <div>
        <Row justify="center">
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div style={{ padding: "5vw" }}>
              <DataNotFound
                type="homes"
                title="Ooops! You weren't supposed to see this"
              />
              <center>
                <h3>
                  The link you followed may be broken, please try after
                  sometime.
                </h3>
                <p>
                  Return to{" "}
                  <a href={process.env.REACT_APP_BASE_URL}>home page.</a>{" "}
                </p>
              </center>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
