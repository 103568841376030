import React, { Component } from "react";
import {
  Row,
  Col,
  Divider,
  Popover,
  List,
  Badge,
  Skeleton,
  Drawer,
  Tabs,
  Pagination,
  Spin,
  Button,
} from "antd";
import "antd/dist/antd.css";
import { withRouter } from "react-router";
import moment from "moment";
import { ClockCircleOutlined, HomeOutlined } from "@ant-design/icons";
import { list, update } from "../api/Apis";
import { withAppContext } from "../store/contextStore";
import notificationBell from "../images/notificationBell.png";

const { TabPane } = Tabs;
export class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      viewAllAlerts: [],
      viewAllUnreadAlerts: [],
      viewAllNotifications: [],
      viewAllQueNotifications: [],
      viewAllAnsNotifications: [],
      allNotifications: [],
      queNotifications: [],
      ansNotifications: [],
      loginUserId: "",
      activeKey: "1",
      loading: false,
      currentPage: 1,
      perpageSize: 50,
      minValue: 0,
      maxValue: 50,
      lastEvaluatedKey: "",
      unReadMatchAlerts: [],
      unReadscheduleAlerts: [],
      unReadQAAlerts: [],
    };
  }

  componentDidMount() {
    this.getQuestionAlerts();
  }

  showAllAlerts = async () => {
    const { loginUserId } = this.state;
    this.setState({
      enableAllNotifications: true,
      loading: true,
    });
    let apiUrl = `getNotifications?relatedTo=${loginUserId}&status=${"SM"}`;
    try {
      const allAlerts = await list(apiUrl);
      this.setState({
        viewAllAlerts: allAlerts?.data?.data || [],
        loading: false,
        lastEvaluatedKey: allAlerts?.data?.lastEvaluatedKey || "",
      });
    } catch (error) {
      this.setState({
        viewAllAlerts: [],
        loading: false,
        lastEvaluatedKey: "",
      });
      console.log(error, error.message);
    }
  };

  getQuestionAlerts = async () => {
    const loginUserId = JSON.parse(localStorage.getItem("userDetails"));
    let userId = loginUserId.id;
    if (userId && userId !== "") {
      try {
        const allUnreadAlerts = await list(
          `getNotifications?relatedTo=${userId}&isNew=true`
        );
        const unReadMatchAlerts =
          allUnreadAlerts?.data?.data?.filter((e) => e.status === "SM") || [];
        const unReadscheduleAlerts =
          allUnreadAlerts?.data?.data?.filter(
            (e) => e.status === "P" || e.status === "R"
          ) || [];
        const unReadQAAlerts =
          allUnreadAlerts?.data?.data?.filter(
            (e) => e.status === "Q" || e.status === "A"
          ) || [];
        this.setState({
          loginUserId: userId,
          viewAllUnreadAlerts: allUnreadAlerts?.data?.data,
          unReadMatchAlerts,
          unReadscheduleAlerts,
          unReadQAAlerts,
        });
      } catch (error) {
        console.log(error, "error");
        this.setState({
          loading: false,
          loginUserId: userId,
        });
      }
    }
  };

  readAllAlerts = async () => {
    const loginUserId = JSON.parse(localStorage.getItem("userDetails"));
    let userId = loginUserId.id;
    if (userId && userId !== "") {
      try {
        const response = await update("clearNotifications", userId);
        if (response.success) {
          this.getQuestionAlerts();
        } else {
          console.log(response, "error");
        }
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  updateQuery = async (propertyId, queryId, stateType, icon) => {
    let data;
    data = {
      entityData: {
        isNew: false,
      },
    };

    try {
      const response = await update("updateNotificationStatus", queryId, data);
      if (response.success) {
        this.getQuestionAlerts();
        this.resetToDefaults();
        window.location.replace(
          `/homeDetails/${stateType}/${propertyId}${
            icon === "P" || icon === "R"
              ? "#schedule"
              : icon === "Q" || icon === "A"
              ? "#questions"
              : ""
          }`
        );
      } else {
        this.resetToDefaults();
        console.log(response, "error");
      }
    } catch (error) {
      this.resetToDefaults();
      console.log(error, "error");
    }
  };

  resetToDefaults = () => {
    this.setState({
      enableAllNotifications: false,
    });
  };

  noResults = () => {
    return (
      <List.Item>
        <h4 className="noResults">No results found</h4>
      </List.Item>
    );
  };

  showMatchesAlerts = (alerts, action) => {
    return alerts.map((alert) =>
      this.propertyItem(
        alert.status,
        alert.propertyTitle,
        alert.message,
        alert.createdBy,
        alert.updatedAt,
        alert.propertyId,
        alert.id,
        alert.isNew,
        alert.stateType,
        action
      )
    );
  };

  scheduleAlerts = (alerts, action) => {
    return alerts.map((alert) =>
      this.propertyItem(
        alert.status,
        alert.propertyTitle,
        alert.message,
        alert.createdBy,
        alert.updatedAt,
        alert.propertyId,
        alert.id,
        alert.isNew,
        alert.stateType,
        action
      )
    );
  };

  qandaAlerts = (alerts, action) => {
    return alerts.map((alert) =>
      this.propertyItem(
        alert.status,
        alert.propertyTitle,
        alert.message,
        alert.createdBy,
        alert.updatedAt,
        alert.propertyId,
        alert.id,
        alert.isNew,
        alert.stateType,
        action
      )
    );
  };

  propertyItem = (
    icon,
    title,
    description,
    userName,
    date,
    propertyId,
    queryId,
    isNew,
    stateType,
    fullDesc
  ) => {
    return (
      <div
        onClick={() => this.updateQuery(propertyId, queryId, stateType, icon)}
        style={{
          border: "1px solid #eee",
          marginBottom: "5px",
          padding: "0 10px",
          cursor: "pointer",
          backgroundColor: isNew ? "#FFF" : "#eee",
        }}
      >
        <List.Item>
          <Skeleton avatar title={false} loading={false} active>
            <List.Item.Meta
              avatar={
                <h1
                  style={{
                    backgroundColor: isNew ? "#d4d8f1fa" : "#ccc",
                    width: "50px",
                    height: "50px",
                    textAlign: "center",
                    color: "#333",
                    marginRight: "-6px",
                  }}
                >
                  {icon === "P" ? (
                    <ClockCircleOutlined style={{ marginTop: "10px" }} />
                  ) : icon === "SM" ? (
                    <HomeOutlined style={{ marginTop: "10px" }} />
                  ) : (
                    icon
                  )}
                </h1>
              }
              title={
                <div style={{ marginTop: "-5px" }}>
                  <h4>
                    {title && title !== "" && title !== undefined
                      ? title.substring(
                          0,
                          title.length >= 45 ? title.length : 45
                        )
                      : ""}

                    {title &&
                    title !== "" &&
                    title !== undefined &&
                    title.length > 45
                      ? "..."
                      : ""}
                  </h4>
                </div>
              }
              description={
                <Row style={{ marginTop: "-5px" }}>
                  <Col span={24}>
                    <h5>
                      {description &&
                      description !== "" &&
                      description !== undefined
                        ? description.substring(
                            0,
                            description.length >= 45 ? description.length : 45
                          )
                        : ""}

                      {description &&
                      description !== "" &&
                      description !== undefined &&
                      description.length > 45
                        ? "..."
                        : ""}
                    </h5>
                  </Col>
                  <Col span={24}>
                    <h6
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      {userName}{" "}
                      <span style={{ color: "#aaa" }}>
                        {" "}
                        {moment(date).format("lll")}
                      </span>
                    </h6>
                  </Col>
                </Row>
              }
            />
          </Skeleton>
        </List.Item>
      </div>
    );
  };

  handleChange = (page, pageSize, load) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (!load) {
      this.setState({
        loading: true,
      });
    }
    if (page <= 1) {
      this.setState({
        minValue: 0,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (page - 1) * pageSize,
        maxValue: page * pageSize,
      });
    }
    this.setState({
      currentPage: page,
      perpageSize: pageSize,
    });
    if (!load) {
      setTimeout(() => {
        this.setState({
          loading: false,
        });
      }, 1000);
    }
  };

  handleChangeTab = async (e) => {
    const { loginUserId } = this.state;
    this.setState({
      loading: true,
      viewAllAlerts: [],
      lastEvaluatedKey: "",
    });
    const updateStatus = e === "1" ? "SM" : e === "2" ? "P,R" : "Q,A";
    let apiUrl = `getNotifications?relatedTo=${loginUserId}&status=${updateStatus}`;
    const allAlerts = await list(apiUrl);
    this.setState({
      activeKey: e,
      viewAllAlerts: allAlerts?.data?.data || [],
      loading: false,
      lastEvaluatedKey: allAlerts?.data?.lastEvaluatedKey || "",
    });
  };

  handleLoadMoreButton = async () => {
    const { loginUserId, lastEvaluatedKey, activeKey, viewAllAlerts } =
      this.state;
    const updateStatus =
      activeKey === "1" ? "SM" : activeKey === "2" ? "P,R" : "Q,A";
    if (lastEvaluatedKey !== "") {
      let apiUrl = `getNotifications?relatedTo=${loginUserId}&status=${updateStatus}&lastEvaluatedKey=${lastEvaluatedKey}`;
      const allAlerts = await list(apiUrl);
      const allData = [...viewAllAlerts, ...allAlerts?.data?.data];
      this.setState({
        viewAllAlerts: allData || [],
        loading: false,
        lastEvaluatedKey: allAlerts?.data?.lastEvaluatedKey || "",
      });
    }
  };

  handleNextButton = () => {
    const { lastEvaluatedKey } = this.state;
    return (
      <div>
        {lastEvaluatedKey !== "" && (
          <Button type="primary" onClick={() => this.handleLoadMoreButton()}>
            Load more
          </Button>
        )}
      </div>
    );
  };

  render() {
    const {
      enableAllNotifications,
      viewAllAlerts,
      viewAllUnreadAlerts,
      currentPage,
      perpageSize,
      minValue,
      maxValue,
      loading,
      unReadMatchAlerts,
      unReadscheduleAlerts,
      unReadQAAlerts,
    } = this.state;
    return (
      <>
        {enableAllNotifications ? (
          <Drawer
            title={"All notifications (" + viewAllAlerts?.length + ")"}
            placement="right"
            width={
              window.screen.width >= "768"
                ? "25%"
                : window.screen.width > "501" && window.screen.width <= "767"
                ? "50%"
                : "100%"
            }
            mask={true}
            closable={true}
            getContainer={false}
            visible={enableAllNotifications}
            onClose={() =>
              this.setState({
                enableAllNotifications: false,
              })
            }
            headerStyle={{ textAlign: "center" }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  current={currentPage}
                  defaultPageSize={perpageSize}
                  onChange={this.handleChange}
                  total={viewAllAlerts?.length}
                  pageSizeOptions={[10, 25, 50, 100, 150, 200]}
                  showTotal={(total, range) =>
                    this.handleNextButton(total, range)
                  }
                />
              </div>
            }
          >
            <Row>
              <Col span={24}>
                <Tabs
                  centered
                  animated
                  useOnPan
                  swipeable
                  size="small"
                  type="card"
                  tabPosition="top"
                  defaultActiveKey="1"
                  onChange={(e) => this.handleChangeTab(e)}
                >
                  <TabPane tab="Homes" key="1">
                    <Spin
                      tip=" Loading..."
                      spinning={loading}
                      className="noResults"
                    />
                    {viewAllAlerts?.length > 0 && !loading
                      ? this.showMatchesAlerts(
                          viewAllAlerts.slice(minValue, maxValue),
                          true
                        )
                      : !loading && this.noResults()}
                  </TabPane>
                  <TabPane tab="Schedule" key="2">
                    <Spin
                      tip=" Loading..."
                      spinning={loading}
                      className="noResults"
                    />
                    {viewAllAlerts?.length > 0 && !loading
                      ? this.scheduleAlerts(
                          viewAllAlerts.slice(minValue, maxValue),
                          true
                        )
                      : !loading && this.noResults()}
                  </TabPane>
                  <TabPane tab="Q & A" key="3">
                    <Spin
                      tip=" Loading..."
                      spinning={loading}
                      className="noResults"
                    />
                    {viewAllAlerts?.length > 0 && !loading
                      ? this.qandaAlerts(
                          viewAllAlerts.slice(minValue, maxValue),
                          true
                        )
                      : !loading && this.noResults()}
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Drawer>
        ) : (
          <Popover
            placement="bottom"
            title={<h3 style={{ textAlign: "center" }}>Notifications</h3>}
            content={
              <div className="notification-height">
                <Tabs
                  centered
                  animated
                  useOnPan
                  swipeable
                  size="small"
                  type="card"
                  tabPosition="top"
                  defaultActiveKey="1"
                >
                  <TabPane tab="Homes" key="1">
                    {unReadMatchAlerts && unReadMatchAlerts?.length > 0
                      ? this.showMatchesAlerts(unReadMatchAlerts, false)
                      : this.noResults()}
                  </TabPane>
                  <TabPane tab="Schedule" key="2">
                    {unReadscheduleAlerts && unReadscheduleAlerts?.length > 0
                      ? this.scheduleAlerts(unReadscheduleAlerts, false)
                      : this.noResults()}
                  </TabPane>
                  <TabPane tab="Q & A" key="3">
                    {unReadQAAlerts && unReadQAAlerts?.length > 0
                      ? this.qandaAlerts(unReadQAAlerts, false)
                      : this.noResults()}
                  </TabPane>
                </Tabs>

                <Divider></Divider>

                <div>
                  <Row justify="space-between">
                    <h4
                      style={{
                        color: "#1890ff",
                        textAlign: "center",
                        marginTop: "-10px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.showAllAlerts();
                      }}
                    >
                      Show all alerts
                    </h4>

                    <h4
                      style={{
                        color: "#1890ff",
                        textAlign: "center",
                        marginTop: "-10px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.readAllAlerts();
                      }}
                    >
                      Mark all as read
                    </h4>
                  </Row>
                </div>
              </div>
            }
            trigger="click"
          >
            <a
              href="!#"
              style={{
                color: "white",
                textDecoration: "none",
                display: "inline-box",
                borderRadius: "2px",
              }}
            >
              <img
                src={notificationBell}
                alt="notification"
                className="notify-header"
                style={{ marginRight: "4px" }}
              />
              <Badge count={viewAllUnreadAlerts?.length}>
                <span className="head-example" />
              </Badge>
            </a>
          </Popover>
        )}
      </>
    );
  }
}

export default withAppContext(withRouter(Notifications));
