import React, { Component } from "react";
import { Drawer } from "antd";
import Lottie from "lottie-react";
import loading from "../images/loading.json";
export default class Loading extends Component {
  render() {
    const { enableLoading } = this.props;
    const style = {
      height: 120,
      marginTop: "35px",
    };

    return (
      <Drawer
        className="background-transparent"
        visible={enableLoading}
        closable={false}
        placement="top"
        width="100%"
        height="100%"
        size="large"
        style={{ backgroundColor: "transparent" }}
      >
        {/* <Row style={{ height: "100%", width: "100%" }} justify="center">
          <Spin
            spinning={enableLoading}
            size="large"
            className="center-item"
          ></Spin>
        </Row> */}
        <div
          // className="glow-on-hover"
          style={{
            width: "240px",
            height: "240px",
            backgroundColor: "#00000073",
            borderRadius: "50%",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
          }}
        >
          <Lottie animationData={loading} style={style} />
          <h2 style={{ color: "#fc572b", textAlign: "center" }}>
            Please wait...
          </h2>
        </div>
      </Drawer>
    );
  }
}
