import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withAppContext } from "../store/contextStore";
import { noAuthlist, AgentPropertiesList, list } from "../api/Apis";
import HomeCard from "../components/Card/HomeCard";
import Advertisement from "../common/Advertisement";
import DataNotFound from "./DataNotFound";
import { Spin, notification } from "antd";

class LocalHomes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      homesList: [],
      mlsHomesList: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getHomeDetails();
  }

  getMyMlsHomes = async () => {
    let totalMlsData = [];
    const stateCodes = JSON.parse(localStorage.getItem("stateCodesFromAdmin"));
    this.props &&
      this.props.agentMlsList &&
      this.props.agentMlsList.map((data) => {
        if (stateCodes && stateCodes.length > 0) {
          let agentId = data.id;
          let vendor = data.state;
          let setVendor = stateCodes.filter(
            (restState) =>
              restState.stateType.toLowerCase() === vendor.toLowerCase()
          );
          let vendorType = setVendor && setVendor[0].vendorType;
          AgentPropertiesList(vendorType, agentId).then((res) => {
            if (res.success) {
              totalMlsData = totalMlsData.concat(res.data);
              this.setState({
                mlsHomesList: totalMlsData,
                countName: "success",
                loading: false,
              });
              this.props.setLoadingfalse();
            }
            this.props.setLoadingfalse();
            this.setState({
              loading: false,
            });
          });
        }
        return true;
      });
  };

  getHomeDetails = async () => {
    const { sharingUserParamsId } = this.props;
    let favoritedUserId = JSON.parse(localStorage.getItem("userDetails"));
    let userId;
    userId = sharingUserParamsId
      ? sharingUserParamsId
      : this.props.match.params.id;

    const homesList = [];
    let listOfFavorites = [];
    if (this.props.agentId || userId) {
      const newApi = await noAuthlist("getHomes", { userId: userId });
      if (newApi.success) {
        this.props.setLoadingfalse();
        this.setState({
          loading: false,
        });

        // GETTING ALL FAVORITED LISTING OF LOGIN USER
        if (favoritedUserId && favoritedUserId.id) {
          try {
            const getFavorites = await list("userFavorites", {
              userId: favoritedUserId.id,
            });
            if (getFavorites.success) {
              this.props.context.handleFavoritesCount(
                getFavorites.data,
                getFavorites.data.length
              );
              listOfFavorites = getFavorites.data;
            }
          } catch (error) {
            notification["error"]({
              message: "Something went wrong",
            });
          }
        }
        for (let singleItem of newApi.data) {
          const favorites =
            listOfFavorites &&
            listOfFavorites.filter((o) => o.leadId === singleItem.id);
          const newImage = [];
          for (let o of singleItem.propertyDetails2.homePhotos) {
            let newImage2 =
              `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
              o;
            newImage.push(newImage2);
          }
          homesList.push({
            ...singleItem,
            homePhotos: newImage,
            favorites,
            mls: true,
            homeAgentName:
              this.props.agentProfileName &&
              this.props.agentProfileName &&
              this.props.agentProfileName,
            homeAgentProfileUrl:
              this.props.agentProfilePicture && this.props.agentProfilePicture,
            agentProfile: true,
          });
        }
        if (sharingUserParamsId) {
          this.getMyMlsHomes();
        }
      } else {
        this.props.setLoadingfalse();
        this.setState({
          loading: false,
        });
      }
    }
    this.setState({
      homesList,
    });
  };
  render() {
    const { listOfFavorites, user } = this.props.context.state;
    const { authenticate, handleFavoritesCount } = this.props;
    const { homesList, mlsHomesList, loading } = this.state;
    const homesData = [];
    const mlsShortData = [];
    homesList &&
      homesList.map((e, index) =>
        homesData.push({
          mls: false,
          id: e.id,
          title:
            e.propertyDetails2 && e.propertyDetails2.title
              ? typeof e.propertyDetails2.title === "string"
                ? e.propertyDetails2.title
                : e.propertyDetails2.title.join(", ")
              : e.privateDetails &&
                e.privateDetails.city &&
                e.privateDetails.state &&
                e.privateDetails.postalCode &&
                e.privateDetails.city +
                  ", " +
                  e.privateDetails.state +
                  ", " +
                  e.privateDetails.postalCode,
          minPrice: e.propertyDetails.minPrice,
          maxPrice: e.propertyDetails.maxPrice,
          baths: e.propertyFeatures.baths,
          beds: e.propertyFeatures.beds,
          area: e.propertyFeatures.maxArea,
          photos: e.homePhotos && e.homePhotos.length > 0 && e.homePhotos[0],
          favorites: e.favorites,
          userid: e.userId,
          homesList: true,
          createdAt: e.createdAt,
          editHomesData: e,
          labels:
            e.propertyDetails &&
            e.propertyDetails.lables &&
            e.propertyDetails.lables,
          homeAgentName: e.homeAgentName,
          agent: e.homeAgentName,
          homeAgentProfileUrl: e.homeAgentProfileUrl,
          cost:
            e.propertyDetails && e.propertyDetails.listPrice
              ? e.propertyDetails.listPrice
              : e.propertyDetails.maxPrice &&
                e.propertyDetails.maxPrice !== "undefined" &&
                e.propertyDetails.maxPrice,
          type: e.propertyDetails && e.propertyDetails.propertyType,
          listPrice:
            e.propertyDetails && e.propertyDetails.listPrice
              ? e.propertyDetails.listPrice
              : "",
          postType: "OFF MLS",
          status: e.propertyDetails.status,
          date: moment(e.createdAt).format("Do MMM YYYY"),
          photoIds:
            e.propertyDetails2 &&
            e.propertyDetails2.homePhotos &&
            e.propertyDetails2.homePhotos.length > 0 &&
            `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
              e.propertyDetails2.homePhotos[0],
          stateName: "offMls",
          sellerHomeAdded: e.sellerHomeAdded,
        })
      );

    mlsHomesList &&
      mlsHomesList.map((e) =>
        mlsShortData.push({
          mls: true,
          id: e.mlsId,
          title:
            e.address.full +
            ", " +
            e.address.city +
            ", " +
            e.address.state +
            ", " +
            e.address.postalCode,
          baths:
            e.property.bathsFull + (e.property.bathsHalf === 0 ? "" : ".5"),
          beds: e.property.bedrooms,
          cost: e.listPrice,
          agent: e.homesListedAgent
            ? e.homesListedAgent
            : e.agent.firstName + " " + e.agent.lastName,
          area: e.property.area,
          photos: e.photos[0],
          createdAt: e.listDate,
          status: e.mls.status,
          favorites:
            listOfFavorites &&
            listOfFavorites.filter((o) => o.leadId === e.mlsId),
          userid: user && user.userid,
          agentProfile: false,
          mlsList: true,
          date: moment(e.listDate).format("Do MMM YYYY"),
          postType: "MLS",
          stateName: e.address && e.address.state,
        })
      );

    let mlsPlusoffMLS = "";
    mlsPlusoffMLS = homesData.concat(mlsShortData);
    let userLogin = localStorage.getItem("userLogin");
    return (
      <div>
        <Spin spinning={loading}>
          {homesData &&
            mlsPlusoffMLS &&
            homesData.length === 0 &&
            mlsPlusoffMLS.length === 0 && (
              <DataNotFound type="homes" title="No listings as yet." />
            )}
        </Spin>
        {homesData &&
          window.location.pathname !==
            `/agent/viewProfile/${this.props.match.params.id}` &&
          homesData.length > 0 &&
          homesData.map((val, index) => {
            if (authenticate && index % 4 === 0 && index > 1) {
              return (
                <>
                  <Advertisement
                    otherAds={false}
                    adType="Rectangle"
                    adWhereToShow="Homes"
                    adLimit="1"
                    zipCode=""
                  />
                  <HomeCard
                    key={val.id}
                    value={val}
                    handleFavoritesCount={handleFavoritesCount}
                    authenticatePage
                    contextProps={this.props.context}
                    handleItems={this.handleItems}
                    isEditHome
                    getHomesList={this.getHomeDetails}
                    valueId={user.userid}
                    deleteHomeListing={this.deleteHomeListing}
                  />
                </>
              );
            } else {
              return (
                <HomeCard
                  value={val}
                  handleFavoritesCount={handleFavoritesCount}
                  authenticatePage
                  contextProps={this.props.context}
                  handleItems={this.handleItems}
                  isEditHome
                  valueId={user.userid}
                  getHomesList={this.getHomeDetails}
                  deleteHomeListing={this.deleteHomeListing}
                />
              );
            }
          })}
        {!this.props.onlyHomes &&
          window.location.pathname ===
            `/agent/viewProfile/${this.props.match.params.id}` &&
          mlsPlusoffMLS &&
          mlsPlusoffMLS.length > 0 &&
          mlsPlusoffMLS.map((val, index) => {
            if (authenticate && index % 4 === 0 && index > 1) {
              return (
                <>
                  <Advertisement
                    otherAds={false}
                    adType="Rectangle"
                    adWhereToShow="Homes"
                    adLimit="1"
                    zipCode=""
                  />
                  <HomeCard
                    key={val.id}
                    value={val}
                    isEditHome
                    handleFavoritesCount={handleFavoritesCount}
                    authenticatePage={userLogin}
                    contextProps={this.props.context}
                    handleItems={this.handleItems}
                    getHomesList={this.getHomeDetails}
                    valueId={user.userid}
                  />
                </>
              );
            } else {
              return (
                <HomeCard
                  value={val}
                  handleFavoritesCount={handleFavoritesCount}
                  authenticatePage={userLogin}
                  contextProps={this.props.context}
                  handleItems={this.handleItems}
                  valueId={user.userid}
                  getHomesList={this.getHomeDetails}
                />
              );
            }
          })}
      </div>
    );
  }
}
export default withAppContext(withRouter(LocalHomes));
