import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Divider,
  Collapse,
  notification,
  Input,
  Button,
  List,
  Skeleton,
  Drawer,
  DatePicker,
  TimePicker,
  Modal,
  Timeline,
  Select,
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import {
  PlusOutlined,
  CheckOutlined,
  SnippetsOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { list, add, update, noAuthgetDetails, noAuthlist } from "../api/Apis";
import { withAppContext } from "../store/contextStore";
import SignupForm from "../containers/RegisterForm";
import LoginForm from "../containers/LoginForm";
import BuyerPreferedAgentPopup from "./BuyerPreferedAgentPopup";
import DeleteRecord from "./DeleteRecord";

const { TextArea } = Input;
const { Panel } = Collapse;
export class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserPostedAd: false,

      loginUserInfo: {},
      allUserList: [],
      allQueryList: [],
      message: "",

      userDesc: "",
      userScheduleDate: "",
      userScheduleTime: "",
      agentDesc: "",
      agentScheduleDate: "",
      agentScheduleTime: "",

      enableSignup: false,
      queryInput: false,
      selectedSchedule: "",
      dataLoading: false,
      showLoginSiderForm: false,
      scheduleDate: "",
      scheduleTime: "",
      scheduleMessage: "",
      visiblePreferedAgent: false,
      newPreferedAgentEmail: "",
      newPreferedAgentName: "",
      meetingScheduleSuccess: false,
      openHouseDates: [],
      openHouseStartTime: "",
      openHouseEndTime: "",
    };
  }

  componentDidMount = () => {
    this.getLoginUserInfo();
    setTimeout(() => {
      this.getAllSchedules();
    }, 2000);
    this.getOpenHouseDates();
  };
  getOpenHouseDates = () => {
    let id = this.props.listingId && this.props.listingId;
    if (this.props.listingId) {
      let dataObj = {
        vendor: this.props.setMlsVendor,
      };
      noAuthlist(`getOpenhouseDates/${id}`, dataObj).then((res) =>
        this.setState({
          openHouseDates: res.data.data,
        })
      );
    }
  };
  getLoginUserInfo = async () => {
    if (
      this.props &&
      this.props.context.state &&
      this.props.context.state.userDetails &&
      this.props.context.state.userDetails.id
    ) {
      const { agentId } = this.props;
      const loginUserDetails = this.props.context.state.userDetails;
      const loginUserId = loginUserDetails.id;
      const loginUserName =
        loginUserDetails.firstName + " " + loginUserDetails.lastName;

      let loginUserPic = "";
      if (
        loginUserDetails.profilePicture &&
        loginUserDetails.profilePicture[0] &&
        loginUserDetails.profilePicture[0].length > 0
      ) {
        loginUserPic =
          `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
          loginUserDetails.profilePicture[0];
      } else {
        loginUserPic = "";
      }

      if (loginUserDetails && loginUserDetails.id) {
        this.setState({
          saveUserProfile: loginUserDetails.userProfile,
          preferedBuyerAgent:
            loginUserDetails.agentInfo && loginUserDetails.agentInfo.agentName
              ? loginUserDetails.agentInfo.agentName
              : "",
          preferedBuyerAgentEmail:
            loginUserDetails.agentInfo && loginUserDetails.agentInfo.agentEmail
              ? loginUserDetails.agentInfo.agentEmail
              : "",
        });
      }

      const userInfo = {
        userId: loginUserId,
        userName: loginUserName,
        userPic: loginUserPic,
        reservedOpenHouses: loginUserDetails.reservedOpenHouses
          ? loginUserDetails.reservedOpenHouses
          : [],
      };

      this.setState({
        loginUserInfo: userInfo,
      });

      if (loginUserId === agentId) {
        this.setState({
          isUserPostedAd: true,
        });
      } else {
        this.setState({
          isUserPostedAd: false,
        });
      }
    }
  };

  getAllSchedules = async () => {
    this.setState({
      dataLoading: true,
    });
    if (
      this.props &&
      this.props.context.state &&
      this.props.context.state.userDetails &&
      this.props.context.state.userDetails.id
    ) {
      const { propertyId, agentId } = this.props;
      const loginUserDetails = this.props.context.state.userDetails;
      const loginUserId = loginUserDetails.id;

      let queryList = {};

      if (propertyId) {
        try {
          if (loginUserId === agentId) {
            queryList = await list("schedules?propertyId=" + propertyId);
          } else {
            queryList = await list(
              "schedules?propertyId=" + propertyId + "&userId=" + loginUserId
            );
          }

          if (queryList.success) {
            this.setState({
              allQueryList: queryList.data,
              dataLoading: false,
            });
          } else {
            notification["error"]({
              message: queryList.message,
            });
          }
        } catch (error) {
          notification["error"]({
            message: error.message,
          });
        }
      }
    }
  };

  submitQuery = async () => {
    const {
      propertyId,
      propertyTitle,
      propertyImages,
      agentId,
      agentName,
      agentEmail,
      mlsAgentId,
      preferedAgent,
      prefereAgentName,
      showOpenHouseTitle,
    } = this.props;
    const { newPreferedAgentEmail, newPreferedAgentName } = this.state;
    let userOpenHouses = JSON.parse(localStorage.getItem("userDetails"));
    const { userDetails } = this.props.context.state;
    const { scheduleDate, scheduleTime, scheduleMessage } = this.state;
    if (!showOpenHouseTitle) {
      if (scheduleDate === "") {
        notification["error"]({
          message: "Please select your date.",
        });
        return;
      } else if (scheduleTime === "") {
        notification["error"]({
          message: "Please select your time.",
        });
        return;
      } else if (scheduleMessage === "") {
        notification["error"]({
          message: "Please enter your message.",
        });
        return;
      }
    } else {
      this.setState({
        queryInput: false,
        dataLoading: true,
      });
    }
    let openHouseDate = moment(this.state.openHouseStartTime).unix();
    // let openHouseTime = moment(this.state.openHouseEndTime).unix();
    const agentInfo = await noAuthgetDetails("getAgents", agentId);
    const submitData = {
      entityData: {
        propertyId: propertyId.toString(),
        propertyTitle: propertyTitle,
        propertyImgSrc:
          propertyImages && propertyImages.length > 0
            ? propertyImages[0]
            : null,

        userId: userDetails.id,
        userName: userDetails.firstName + " " + userDetails.lastName,
        userPhone:
          userDetails.phone && userDetails.phone !== ""
            ? userDetails.phone.toString()
            : "",
        userEmail:
          userDetails.email && userDetails.email !== ""
            ? userDetails.email
            : "",

        isRegistred:
          agentId !== null && agentId !== undefined && agentId !== ""
            ? true
            : false,

        agentId:
          agentId !== null && agentId !== undefined && agentId !== ""
            ? agentId
            : mlsAgentId,
        agentName:
          prefereAgentName && prefereAgentName !== ""
            ? prefereAgentName
            : newPreferedAgentName && newPreferedAgentName !== ""
            ? newPreferedAgentName
            : agentId !== null && agentId !== undefined && agentId !== ""
            ? agentInfo.data.firstName + " " + agentInfo.data.lastName
            : agentName,

        agentEmail:
          preferedAgent && preferedAgent !== ""
            ? preferedAgent
            : newPreferedAgentEmail && newPreferedAgentEmail !== ""
            ? newPreferedAgentEmail
            : agentId !== null && agentId !== undefined && agentId !== ""
            ? agentInfo.data.email
            : agentEmail !== null &&
              agentEmail !== undefined &&
              agentEmail !== ""
            ? agentEmail
            : mlsAgentId,

        createdBy: "user",
        scheduleStatus: "P",
        scheduleDate: scheduleDate !== "" ? scheduleDate : openHouseDate,
        scheduleTime: scheduleTime !== "" ? scheduleTime : openHouseDate,
        formatedDateAndTime:
          moment
            .unix(scheduleDate !== "" ? scheduleDate : openHouseDate)
            .format("ll") +
          " " +
          moment
            .unix(scheduleTime !== "" ? scheduleTime : openHouseDate)
            .format("LT"),
        scheduleMessage: scheduleMessage,
        scheduleHistory: [],
        relatedTo:
          agentId !== null && agentId !== undefined && agentId !== ""
            ? agentId
            : mlsAgentId,
        stateType: this.props.match.params.stateName,
        typeOfSchedule: showOpenHouseTitle
          ? "openHouseSchedule"
          : "listingSchedule",
      },
    };
    this.setState({
      // scheduleTime: new Date(),
      meetingScheduleSuccess: showOpenHouseTitle ? true : false,
    });
    // return;
    try {
      const response = await add("mettingSchedules", submitData);
      if (response.success) {
        this.handleReset();
        this.getAllSchedules();
        notification["success"]({
          // message: "Hey " + loginUserInfo.userName + ".!",
          message: showOpenHouseTitle
            ? "Open House Reserved successfully"
            : "Schedule a Showing Request Sent.",
          description: "Thank you.",
        });
        this.setState({
          scheduleTime: new Date(),
          meetingScheduleSuccess: true,
        });
      } else {
        notification["error"]({
          message: response.message,
        });
      }
      let reservedOpenHouses = userOpenHouses.reservedOpenHouses
        ? userOpenHouses.reservedOpenHouses
        : [];

      reservedOpenHouses.push(this.props.match.params.id);

      let housesData = {
        entityData: {
          reservedOpenHouses,
        },
      };
      update("users", userOpenHouses.id, housesData)
        .then(
          (res) => this.props.context.setUserDetails(res.data.data),
          this.getLoginUserInfo()
        )
        .catch((error) => console.log(error));
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
    // }
  };

  acceptedSchedule = async (queryId, status) => {
    const { loginUserInfo } = this.state;
    const acceptData = {
      entityData: {
        scheduleStatus: status,
      },
    };

    try {
      const response = await update("mettingSchedules", queryId, acceptData);
      if (response.success) {
        this.handleReset();
        this.getAllSchedules();
        notification["success"]({
          message: "Hey " + loginUserInfo.userName + ".!",
          description: "Your schedule changed successfully",
        });
      } else {
        notification["error"]({
          message: response.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  updateQuery = async (status) => {
    this.setState({
      queryInput: false,
      dataLoading: true,
    });

    const { agentId, agentName, agentEmail } = this.props;
    const agentInfo = await noAuthgetDetails("getAgents", agentId);
    const { userDetails } = this.props.context.state;
    const {
      loginUserInfo,
      scheduleDate,
      scheduleTime,
      scheduleMessage,
      selectedSchedule,
    } = this.state;

    let oldData = {
      count: selectedSchedule.scheduleHistory.length + 1,
      createdBy: selectedSchedule.createdBy,
      scheduleStatus: selectedSchedule.scheduleStatus,
      scheduleDate: selectedSchedule.scheduleDate,
      scheduleTime: selectedSchedule.scheduleTime,
      scheduleMessage: selectedSchedule.scheduleMessage,
    };

    let allScheduleHistory =
      selectedSchedule.scheduleHistory.length > 0
        ? selectedSchedule.scheduleHistory
        : [];

    allScheduleHistory.push(oldData);

    const updatedData = {
      entityData: {
        relatedTo:
          selectedSchedule.createdBy === "agent"
            ? selectedSchedule.userId
            : selectedSchedule.agentId,
        isRegistred:
          agentId !== null && agentId !== undefined && agentId !== ""
            ? true
            : false,
        createdBy:
          selectedSchedule.userId === userDetails.id ? "user" : "agent",
        userName: userDetails.firstName + " " + userDetails.lastName,
        userPhone:
          userDetails.phone && userDetails.phone !== ""
            ? userDetails.phone.toString()
            : "",
        userEmail:
          userDetails.email && userDetails.email !== ""
            ? userDetails.email
            : "",
        agentName:
          agentId !== null && agentId !== undefined && agentId !== ""
            ? agentInfo.data.firstName + " " + agentInfo.data.lastName
            : agentName,
        agentEmail:
          agentId !== null && agentId !== undefined && agentId !== ""
            ? agentInfo.data.email
            : agentEmail !== null &&
              agentEmail !== undefined &&
              agentEmail !== ""
            ? agentEmail
            : "nonregistered",

        scheduleStatus: status,
        scheduleDate: scheduleDate,
        scheduleTime: scheduleTime,
        scheduleMessage: scheduleMessage,
        scheduleHistory:
          allScheduleHistory.length > 0 ? allScheduleHistory : [],
        formatedDateAndTime:
          moment.unix(scheduleDate).format("ll") +
          " " +
          moment.unix(scheduleTime).format("LT"),
        stateType: this.props.match.params.stateName,
      },
    };

    try {
      const response = await update(
        "mettingSchedules",
        selectedSchedule.id,
        updatedData
      );
      if (response.success) {
        this.handleReset();
        this.getAllSchedules();
        notification["success"]({
          message: "Hey " + loginUserInfo.userName + ".!",
          description: "Your schedule changed successfully",
        });
        this.setState({ scheduleTime: "" });
      } else {
        notification["error"]({
          message: response.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  handleDate = (e) => {
    let selectedDate = moment(e).toDate();
    this.setState({
      scheduleDate: moment(selectedDate).unix(),
    });
  };

  handleTime = (time) => {
    let selectedTime = moment(time, "hh a").toDate();
    this.setState({
      scheduleTime: moment(selectedTime).unix(),
    });
  };

  handleMessage = (e) => {
    this.setState({
      scheduleMessage: e.target.value,
    });
  };

  handleReset = () => {
    this.setState({
      queryInput: false,
      enableSignup: false,
      selectedSchedule: "",
      scheduleDate: "",
      scheduleTime: "",
      scheduleMessage: "",
      dataLoading: false,
    });
  };
  cancel = (e) => {
    console.log(e);
  };

  handleToshowLoginSider = () => {
    this.setState({
      showLoginSiderForm: true,
    });
  };

  handletoShowSignupsider = () => {
    this.setState({
      showLoginSiderForm: false,
    });
  };

  queryOptions = (query) => {
    const { loginUserInfo } = this.state;
    return (
      <>
        <Row>
          <Divider></Divider>
        </Row>
        <Row gutter={[16, 16]}>
          {query.createdBy === "user" &&
            query.agentId === loginUserInfo.userId && (
              <>
                <Col span={8}>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#87d068",
                      width: "100%",
                    }}
                    icon={<CheckOutlined />}
                    onClick={() => this.acceptedSchedule(query.id, "A")}
                  >
                    Accept
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#2db7f5",
                      width: "100%",
                    }}
                    icon={<SnippetsOutlined />}
                    onClick={
                      () =>
                        this.setState({
                          queryInput: true,
                          selectedSchedule: query,
                        })
                      // this.handleReschedule(query.id, "R")
                    }
                  >
                    Re-Schedule
                  </Button>
                </Col>
              </>
            )}

          {query.createdBy === "agent" &&
            query.userId === loginUserInfo.userId && (
              <>
                {" "}
                <Col span={8}>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#87d068",
                      width: "100%",
                    }}
                    icon={<CheckOutlined />}
                    onClick={() => this.acceptedSchedule(query.id, "A")}
                  >
                    Accept
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#2db7f5",
                      width: "100%",
                    }}
                    icon={<SnippetsOutlined />}
                    onClick={
                      () =>
                        this.setState({
                          queryInput: true,
                          selectedSchedule: query,
                        })
                      // this.handleReschedule(query.id, "R")
                    }
                  >
                    Re-Schedule
                  </Button>
                </Col>
              </>
            )}

          <Col span={8}>
            <Button
              type="primary"
              style={{
                backgroundColor: "darkgrey",
                width: "100%",
              }}
              icon={<CloseOutlined />}
              onClick={() => this.acceptedSchedule(query.id, "X")}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  showHistory = (query) => {
    const sortHistory = query.scheduleHistory.sort((a, b) =>
      parseInt(a.count) > parseInt(b.count) ? -1 : 1
    );

    return sortHistory.map((history) => (
      <Timeline.Item
        color="green"
        dot={
          <Button
            shape="circle"
            size="small"
            block={true}
            style={{
              backgroundColor:
                history.createdBy === "agent" ? "#eff2f5" : "#f9faff",
            }}
          >
            {history.createdBy === "agent"
              ? query.agentDetails.firstName.charAt(0).toUpperCase()
              : query.userDetails.firstName.charAt(0).toUpperCase()}
          </Button>
        }
      >
        <h4>
          {moment.unix(history.scheduleDate).format("ll") +
            " " +
            moment.unix(history.scheduleTime).format("LT")}
        </h4>
        <Card
          size="small"
          style={{
            marginBottom: "10px",
            backgroundColor:
              history.createdBy === "agent" ? "#eff2f5" : "#f9faff",
          }}
        >
          <h4>
            {history.count === "1" ? "Schedule " : "Reschedule "}
            by :{" "}
            {history.createdBy === "agent"
              ? query.agentDetails.firstName + " " + query.agentDetails.lastName
              : query.userDetails.firstName + " " + query.userDetails.lastName}
          </h4>
          <h5
            style={{
              color: "grey",
              marginBottom: "-5px",
            }}
          >
            {history.scheduleMessage}
          </h5>
        </Card>
      </Timeline.Item>
    ));
  };
  handleOpenHouseTime = (time) => {
    let openHouseStartTime = moment(time[0]).toDate();
    let openHouseEndTime = moment(time[1]).toDate();
    this.setState({
      openHouseStartTime,
      openHouseEndTime,
    });
  };
  manageSchedule = () => {
    return this.state.allQueryList.length > 0 &&
      !this.props.showOpenHouseTitle ? (
      <Row>
        <Col span={24}>
          <Collapse accordion defaultActiveKey={["0"]}>
            {this.state.allQueryList.map((query, index) => (
              <Panel
                destroyInactivePanel
                showArrow={true}
                header={
                  <>
                    {query.userName +
                      " " +
                      moment.unix(query.scheduleDate).format("ll") +
                      " " +
                      moment.unix(query.scheduleTime).format("LT")}
                  </>
                }
                key={index}
                extra={
                  this.state.isUserPostedAd ? (
                    <DeleteRecord
                      recordId={query.id}
                      apiPath={"schedules"}
                      getHomesList={this.getAllSchedules}
                      handleReset={this.handleReset}
                      fontSizeProp={"14px"}
                      deletionType={"DeleteOutlined"}
                      deletionSuccessMessage={
                        "Your record deleted successfully"
                      }
                    />
                  ) : null
                }
                // Don't delete
                // extra={
                //   <h4
                //     style={{
                //       backgroundColor:
                //         query.scheduleStatus == "P"
                //           ? "red"
                //           : query.scheduleStatus == "A"
                //           ? moment
                //               .unix(query.scheduleDate)
                //               .fromNow()
                //               .includes("ago")
                //             ? "green"
                //             : "#2db7f5"
                //           : query.scheduleStatus == "R"
                //           ? "orange"
                //           : query.scheduleStatus == "C"
                //           ? "green"
                //           : query.scheduleStatus == "X"
                //           ? "darkgray"
                //           : "#2db7f5",
                //       color: "#FFF",
                //       padding: "2px 10px",
                //       width: "120px",
                //       textAlign: "center",
                //       margin: "0px",
                //     }}
                //   >
                //     {query.scheduleStatus == "P"
                //       ? "Pending"
                //       : query.scheduleStatus == "A"
                //       ? moment
                //           .unix(query.scheduleDate)
                //           .fromNow()
                //           .includes("ago")
                //         ? "Completed"
                //         : moment.unix(query.scheduleDate).fromNow()
                //       : query.scheduleStatus == "R"
                //       ? "Re-Scheduled"
                //       : query.scheduleStatus == "C"
                //       ? "Completed"
                //       : query.scheduleStatus == "X"
                //       ? "Cancelled"
                //       : moment.unix(query.scheduleDate).fromNow()}
                //   </h4>
                // }
                style={{ overflow: "hidden" }}
              >
                <Card bordered={true}>
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col
                          xl={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                        >
                          <Link
                            to={{
                              pathname: `/agent/viewProfile/${
                                query.createdBy === "agent"
                                  ? query.agentId
                                  : query.userId
                              }`,
                            }}
                            className="profile-view-link"
                          >
                            <Skeleton
                              avatar
                              title={false}
                              loading={false}
                              active
                            >
                              <List.Item.Meta
                                avatar={
                                  <Button
                                    shape="circle"
                                    size="large"
                                    block={true}
                                    style={{
                                      backgroundColor: "#eff2f5",
                                    }}
                                  >
                                    {query.createdBy === "agent"
                                      ? query.agentDetails.firstName
                                          .charAt(0)
                                          .toUpperCase()
                                      : query.userDetails.firstName
                                          .charAt(0)
                                          .toUpperCase()}
                                  </Button>
                                }
                                title={
                                  <h5
                                    style={{
                                      marginLeft: "-10px",
                                    }}
                                  >
                                    {query.createdBy === "agent"
                                      ? query.agentDetails.firstName +
                                        " " +
                                        query.agentDetails.lastName
                                      : query.userDetails.firstName +
                                        " " +
                                        query.userDetails.lastName}
                                  </h5>
                                }
                                description={
                                  <h5
                                    style={{
                                      marginLeft: "-10px",
                                      marginTop: "-3px",
                                      color: "grey",
                                    }}
                                  >
                                    {query.createdBy === "agent"
                                      ? query.agentDetails.userProfile === "AG"
                                        ? "Agent"
                                        : query.agentDetails.userProfile === "B"
                                        ? "Buyer"
                                        : query.agentDetails.userProfile === "S"
                                        ? "Seller"
                                        : "Vendor"
                                      : query.userDetails.userProfile === "AG"
                                      ? "Agent"
                                      : query.userDetails.userProfile === "B"
                                      ? "Buyer"
                                      : query.userDetails.userProfile === "S"
                                      ? "Seller"
                                      : "Vendor"}
                                  </h5>
                                }
                              />
                            </Skeleton>
                          </Link>
                        </Col>
                        <Col
                          xl={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                        >
                          <h5>
                            {query.createdBy === "agent"
                              ? query.agentDetails.email
                              : query.userDetails.email}
                          </h5>
                          <h5>
                            {query.createdBy === "agent"
                              ? query.agentDetails.phone
                              : query.userDetails.phone}
                          </h5>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          xl={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                        >
                          <h5 className="schedule-date">
                            {" "}
                            Schedule Date :{" "}
                            <span
                              style={{
                                backgroundColor: "#eff2f5",
                                padding: "5px",
                              }}
                            >
                              {moment.unix(query.scheduleDate).format("ll")}
                            </span>
                          </h5>
                        </Col>

                        <Col
                          xl={{ span: 12, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                        >
                          <h5>
                            {" "}
                            Schedule Time :{" "}
                            <span
                              style={{
                                backgroundColor: "#eff2f5",
                                padding: "5px",
                              }}
                            >
                              {moment.unix(query.scheduleTime).format("LT")}
                            </span>
                          </h5>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xl={{ span: 24, offset: 0 }}
                      lg={{ span: 24, offset: 0 }}
                      md={{ span: 22, offset: 0 }}
                      sm={{ span: 22, offset: 1 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <h4 className="schedule-description">
                        {query.scheduleMessage}
                      </h4>
                    </Col>
                  </Row>

                  {/*
                    Don't delete

                    {(query.scheduleStatus == "P" ||
                      query.scheduleStatus == "R") &&
                      this.queryOptions(query)} */}

                  {/* {query.scheduleHistory.length > 0 && (
                      <Row>
                        <Col span={24} style={{ marginBottom: "-10px" }}>
                          <Collapse accordion ghost destroyInactivePanel>
                            <Panel
                              header="Show history"
                              key="1"
                              style={{ marginBottom: "-10px" }}
                            >
                              <Timeline>{this.showHistory(query)}</Timeline>
                            </Panel>
                          </Collapse>
                        </Col>
                      </Row>
                    )} */}
                </Card>
              </Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
    ) : (
      this.state.openHouseDates && this.state.openHouseDates.length > 0 && (
        <Row>
          <Col span={24}>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "600",
                fontFamily: "Poppins, sans-serif",
                textAlign: "left",
                marginBottom: "5px",
              }}
            >
              Open House Date: &nbsp;
            </div>

            {this.state.openHouseDates &&
              this.state.openHouseDates.map((province, index) => (
                <ul>
                  <li className="property-description">
                    {moment.utc(province.startTime).format("lll")}{" "}
                    {province.endTime && (
                      <>
                        {"-"} {moment.utc(province.endTime).format("LT")}
                      </>
                    )}
                  </li>
                </ul>
              ))}
          </Col>
          {this.props.openHouseDesc && (
            <Col span={24}>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  marginBottom: "5px",
                }}
              >
                Description:
              </div>
              <p
                className="property-description font-family-style font-normal-size"
                style={{ paddingLeft: "1vw" }}
              >
                {this.props.openHouseDesc}
              </p>
            </Col>
          )}
        </Row>
      )
    );
  };
  scheduleForm = () => {
    const { scheduleMessage, selectedSchedule } = this.state;

    return (
      <Col span={16} offset={4}>
        <Row>
          <Col span={24} style={{ marginBottom: "20px" }}>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <DatePicker
                  name="scheduleDate"
                  format={"MM/DD/YYYY"}
                  allowClear={true}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12}>
                <TimePicker
                  showNow={false}
                  name="scheduleTime"
                  format="h:mm a"
                  use12Hours={true}
                  allowClear={true}
                  style={{ width: "100%" }}
                  onChange={this.handleTime}
                />
              </Col>
            </Row>
          </Col>

          <Col span={24} style={{ marginBottom: "20px" }}>
            <TextArea
              placeholder={"Start typing.."}
              rows={4}
              name="scheduleMessage"
              value={scheduleMessage}
              onChange={this.handleMessage}
              style={{ marginRight: "10px" }}
            />
          </Col>

          <Col span={24} style={{ marginBottom: "20px" }}>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Button
                  onClick={() => this.handleReset()}
                  type="default"
                  style={{
                    width: "100%",
                    backgroundColor: "#bbb",
                    color: "#FFF",
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  onClick={() =>
                    selectedSchedule.id && selectedSchedule.id !== ""
                      ? this.updateQuery("R")
                      : this.submitQuery()
                  }
                  type="primary"
                  style={{ width: "100%" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  };
  openSendMessage = (agentInfo) => {
    if (agentInfo && agentInfo.agentName && agentInfo.agentEmail) {
      this.setState({
        newPreferedAgentName: agentInfo.agentName,
        newPreferedAgentEmail: agentInfo.agentEmail,
      });
    }
    this.setState({
      queryInput: true,
      visiblePreferedAgent: false,
    });
  };

  handleToShceduleTime = () => {
    const { preferedAgent, buyerLoginSendMessage, showOpenHouseTitle } =
      this.props;
    const { loginUserInfo, meetingScheduleSuccess } = this.state;
    const openHouseId = this.props.match.params.id;
    if (
      (loginUserInfo &&
        loginUserInfo.reservedOpenHouses &&
        showOpenHouseTitle &&
        loginUserInfo.reservedOpenHouses.includes(openHouseId)) ||
      meetingScheduleSuccess
    ) {
      notification["warning"]({
        message: "You have already reserved.",
      });
      return;
    }
    const userLogin = localStorage.getItem("userLogin");
    if (userLogin) {
      if (preferedAgent && preferedAgent !== "") {
        this.setState({
          queryInput: true,
          enableSignup: false,
        });
      } else {
        if (userLogin) {
          if (buyerLoginSendMessage) {
            this.setState({
              visiblePreferedAgent: true,
            });
            notification["warning"]({
              message: "You don't have an agent to share",
            });
          } else {
            this.setState({
              queryInput: true,
              enableSignup: false,
            });
          }
        } else {
          this.setState({
            enableSignup: true,
          });
        }
      }
    } else {
      this.setState({
        queryInput: false,
        enableSignup: true,
      });
    }
  };
  render() {
    const {
      enableSignup,
      selectedSchedule,
      queryInput,
      scheduleMessage,
      showLoginSiderForm,
      visiblePreferedAgent,
      meetingScheduleSuccess,
    } = this.state;
    const userLogin = localStorage.getItem("userLogin");

    const TimeHours = [
      { label: "6 AM", value: "6 AM" },
      { label: "7 Am", value: "7 AM" },
      { label: "8 AM", value: "8 AM" },
      { label: "9 AM", value: "9 AM" },
      { label: "10 AM", value: "10 AM" },
      { label: "11 AM", value: "11 AM" },
      { label: "12 PM", value: "12 PM" },
      { label: "1 PM", value: "1 PM" },
      { label: "2 PM", value: "2 PM" },
      { label: "3 PM", value: "3 PM" },
      { label: "4 PM", value: "4 PM" },
      { label: "5 PM", value: "5 PM" },
      { label: "6 PM", value: "6 PM" },
      { label: "7 PM", value: "7 PM" },
      { label: "8 PM", value: "8 PM" },
      { label: "9 PM", value: "9 PM" },
      { label: "10 PM", value: "10 PM" },
    ];
    const { showOpenHouseTitle } = this.props;
    return (
      <>
        <div id="schedule" className="achoreTagClick">
          <Row className="row-padding">
            <Card
              title={showOpenHouseTitle ? "Open House" : "Schedule a Showing"}
              extra={
                this.state.isUserPostedAd ? null : (
                  <Button
                    type="primary"
                    className="createButton1"
                    icon={<PlusOutlined />}
                    onClick={() => this.handleToShceduleTime()}
                  >
                    {showOpenHouseTitle
                      ? meetingScheduleSuccess
                        ? "Reserved"
                        : "Reserve my attendance"
                      : " Request a date and time"}
                  </Button>
                )
              }
            >
              <Row style={{ marginTop: "-13px", marginBottom: "10px" }}>
                {this.state.isUserPostedAd ? null : (
                  <Button
                    type="primary"
                    className="createButton2"
                    icon={<PlusOutlined />}
                    onClick={() => this.handleToShceduleTime()}
                  >
                    Request a date and time
                  </Button>
                )}
              </Row>
              {/* Don't delete
               {this.scheduleForm()} */}

              {this.manageSchedule()}
              <Modal
                className="modal-width"
                visible={
                  userLogin && visiblePreferedAgent
                    ? visiblePreferedAgent
                    : queryInput
                }
                title={
                  !visiblePreferedAgent
                    ? showOpenHouseTitle
                      ? "Reserve date and time"
                      : "Schedule a Showing"
                    : "Add your Agent to share"
                }
                style={{ marginTop: "2%" }}
                onCancel={() =>
                  this.setState({
                    queryInput: false,
                    visiblePreferedAgent: false,
                  })
                }
                footer={
                  !visiblePreferedAgent ? (
                    <Col span={24}>
                      <Row gutter={[24, 24]}>
                        <Col span={12}>
                          <Button
                            onClick={() => this.handleReset()}
                            type="default"
                            style={{
                              width: "100%",
                              backgroundColor: "#bbb",
                              color: "#FFF",
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Button
                            onClick={() =>
                              selectedSchedule.id && selectedSchedule.id !== ""
                                ? this.updateQuery("R")
                                : this.submitQuery()
                            }
                            type="primary"
                            style={{ width: "100%" }}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  ) : null
                }
              >
                {!visiblePreferedAgent ? (
                  <Card style={{ marginTop: "10px" }}>
                    <Row>
                      {showOpenHouseTitle ? (
                        <>
                          <Col span={13} style={{ display: "flex" }}>
                            <div className="label-titles">Date: &nbsp; </div>
                            <Select
                              placeholder="Select time"
                              onChange={this.handleOpenHouseTime}
                              style={{ width: "100%" }}
                              size="small"
                              showArrow={false}
                            >
                              {this.state.openHouseDates &&
                                this.state.openHouseDates.map(
                                  (province, index) => (
                                    <Select.Option
                                      key={index}
                                      value={[
                                        moment(province.startTime).format(
                                          "lll"
                                        ),
                                        province.endTime &&
                                          moment(province.endTime).format(
                                            "lll"
                                          ),
                                      ]}
                                    >
                                      {moment(province.startTime).format("lll")}{" "}
                                      {province.endTime && (
                                        <>
                                          {"-"}{" "}
                                          {moment(province.endTime).format(
                                            "LT"
                                          )}
                                        </>
                                      )}
                                    </Select.Option>
                                  )
                                )}
                            </Select>
                          </Col>
                          <Col span={24}>
                            <div className="label-titles">Message: </div>
                          </Col>
                        </>
                      ) : (
                        <Col span={24} style={{ marginBottom: "20px" }}>
                          <Row gutter={[24, 24]}>
                            <Col span={12}>
                              <DatePicker
                                name="scheduleDate"
                                format={"MM/DD/YYYY"}
                                allowClear={true}
                                style={{ width: "100%" }}
                                onChange={this.handleDate}
                              />
                            </Col>
                            <Col span={12}>
                              <Select
                                // className="check-box-property-status"
                                placeholder="Select time"
                                onChange={this.handleTime}
                                showSearch={
                                  window.screen.width >= "992" ? true : false
                                }
                                style={{ width: "100%" }}
                              >
                                {TimeHours.map((province, index) => (
                                  <Select.Option
                                    key={index}
                                    value={province.value}
                                  >
                                    {province.value}
                                  </Select.Option>
                                ))}
                              </Select>
                              {/* <TimePicker
                            name="scheduleTime"
                            format="hh:mm a"
                            showNow={false}
                            use12Hours={true}
                            allowClear={true}
                            style={{ width: "100%" }}
                            value={
                              scheduleTime !== "" &&
                              moment(scheduleTime, "hh:mm a")
                            }
                            onSelect={(value) => {
                              const timeString = moment(value).toDate();
                              console.log(timeString, "timeString");
                              this.setState({
                                scheduleTime: timeString,
                              });
                            }}
                      showSearch={window.screen.width >= "992" ? true : false}

                          /> */}
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col span={24} style={{ marginBottom: "20px" }}>
                        <TextArea
                          placeholder={"Start typing.."}
                          rows={4}
                          name="scheduleMessage"
                          value={scheduleMessage}
                          onChange={this.handleMessage}
                          style={{ marginRight: "10px" }}
                        />
                      </Col>
                    </Row>
                  </Card>
                ) : (
                  <BuyerPreferedAgentPopup
                    openSendMessage={this.openSendMessage}
                    scheduleShowing={true}
                  />
                )}
              </Modal>

              <Drawer
                title="Please create an account to request a showing"
                placement="right"
                width={
                  window.screen.width >= "768"
                    ? "40%"
                    : window.screen.width > "501" &&
                      window.screen.width <= "767"
                    ? "80%"
                    : "100%"
                }
                mask={true}
                closable={true}
                getContainer={false}
                visible={enableSignup}
                onClose={() => this.handleReset()}
                headerStyle={{ textAlign: "center" }}
                bodyStyle={{ paddingBottom: 80, backgroundColor: "#eff2f5" }}
                // className="drawer-width"
              >
                <Row>
                  <Col span={24}>
                    {showLoginSiderForm ? (
                      <LoginForm
                        showAdd={false}
                        modalTrue
                        goTopreviousPage={true}
                        handleToshowLoginSider={this.handletoShowSignupsider}
                        showLoginSiderForm={showLoginSiderForm}
                        questionPage
                      />
                    ) : (
                      <SignupForm
                        questionPage
                        showAdd={false}
                        modalTrue
                        goTopreviousPage={true}
                        handleToshowLoginSider={this.handleToshowLoginSider}
                        showLoginSiderForm={showLoginSiderForm}
                      />
                    )}
                  </Col>
                </Row>
              </Drawer>
            </Card>
          </Row>
        </div>
      </>
    );
  }
}

export default withAppContext(withRouter(Schedule));
