import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { withAppContext } from "../store/contextStore";
import Header from "../common/Header";
import AdditionalContent from "../common/AdditionalContent";
import Footer from "../components/home/OpenFooter";
import Navigation from "../common/Navigation";
export class Authenticated extends Component {
  render() {
    return (
      <div style={{ background: "#f0f2f5", marginBottom: "10vw" }}>
        {window.location.pathname !== "/login" &&
          window.location.pathname !== "/register" && <Header />}
        <Switch>
          {AppRoutes.map((route) => (
            <Route
              exact={route.exact}
              path={route.path}
              component={route.component}
              key={route.key}
            />
          ))}
        </Switch>

        {window.location.pathname !== "/login" &&
          window.location.pathname !== "/register" && (
            <div>
              <AdditionalContent />
              <div className="show-on-desktop">
                <Footer />
              </div>
              <div className="show-on-mobile">
                <div style={{ minHeight: "90px" }}></div>
                <Navigation />
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default withAppContext(withRouter(Authenticated));
