import {
  Col,
  Row,
  Form,
  Input,
  notification,
  InputNumber,
  Button,
  Spin,
  Select,
  Drawer,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { PlusOutlined } from "@ant-design/icons";
import { withAppContext } from "../../../store/contextStore";
import { update, getDetails, noAuthlist } from "../../../api/Apis";
import Subscriptions from "../../Subscriptions/Subscriptions";

class BuyerProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: {
        cities: [],
        type: "",
      },
      sellerName: "",
      sellerPhone: "",
      sellerAddress: "",
      citySearch: [],
      saveDetailsFalse: false,
      zipCode: "",
      serviceAreas: [],
      spec: 0,
      zip: "",
      Ziplist: [],
      Addmore: false,
      speclist: [],
      optionsSelected: [],
      zipCount: 0,
      showSubscriptions: false,
      dataLoadingFalse: false,
      richtTextBiofield: "",
      allUsersList: [],
      referedById: "",
      referedByName: "",
      handleZipErrorMsg: false,
      agentsList: [],
      userInput: false,
      addUserName: "",
      addUserEmail: "",
    };
  }

  componentDidMount() {
    this.getAllUsers();
    this.getAllAgents();
    const { userDetails } = this.props.context.state;
    if (
      userDetails &&
      userDetails.newdata &&
      userDetails.newdata.serviceAreas
    ) {
      this.setState({
        serviceAreas: userDetails.newdata.serviceAreas,
      });
    }

    if (userDetails && userDetails.newdata && userDetails.newdata.Specialties) {
      this.setState({
        optionsSelected: userDetails.newdata.Specialties,
      });
    }
    if (
      userDetails &&
      userDetails.newdata &&
      userDetails.newdata.zipCodes &&
      userDetails.newdata.zipCodes
    ) {
      this.setState({
        Ziplist: userDetails.newdata.zipCodes,
      });
    }
  }
  getAllUsers = async () => {
    try {
      const usersList = await noAuthlist("getUsers");
      let finalUsersList = usersList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
        };
      });
      if (usersList.success) {
        this.setState({
          allUsersList: finalUsersList,
        });
      } else {
        notification["error"]({
          message: usersList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  getAllAgents = async () => {
    try {
      const AllagentsList = await noAuthlist("getAgents");
      let singleAgent = AllagentsList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
          email: user.email,
        };
      });
      if (AllagentsList.success) {
        this.setState({
          agentsList: singleAgent,
        });
      } else {
        notification["error"]({
          message: AllagentsList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  handleZip = (value) => {
    this.setState({ zip: value.target.value, multiZip: value.target.value });
    if (this.state.Ziplist.includes(value.target.value)) {
      this.setState({
        handleZipErrorMsg: true,
      });
      notification["error"]({
        message:
          "You have already added " +
          value.target.value +
          " zip code. Please add another",
        // description: "You have already added " + this.state.zip + " zip code",
      });
    }
    //  else {
    //   const Ziplist = [...this.state.Ziplist];
    //   Ziplist.push(value.target.value);
    //   this.setState({ Ziplist, zip: "", zipCount: Ziplist.length });
    // }
  };

  handleSellereProfile = async (name, value) => {
    await this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onFinish = (e) => {
    window.addEventListener("keydown", function (event) {
      //set default value for variable that will hold the status of keypress
      // pressedEnter = false;
      //if user pressed enter, set the variable to true
      // if (event.keyCode == 13)
      //   pressedEnter = true;
      // //we want forms to disable submit for a tenth of a second only
      // setTimeout(function() {
      //   pressedEnter = false;
      // }, 100)
    });
    this.updateDetails(e);
  };

  handleRichtextBio = (bio) => {
    this.setState({
      richtTextBiofield: bio,
    });
  };

  updateDetails = async (data2) => {
    // if (
    //   this.props.context.state &&
    //   this.props.context.state.userDetails &&
    //   this.props.context.state.userDetails.userProfile === "B" &&
    //   this.state.serviceAreas !== "" &&
    //   this.state.serviceAreas.length == 0
    // ) {
    //   notification["error"]({
    //     message: "Towns of Interest",
    //     description: "Please select atleast one town",
    //   });
    //   return;
    // }

    this.setState({
      saveDetailsFalse: true,
    });

    const { richtTextBiofield } = this.state;
    const { userDetails } = this.props.context.state;
    const id = userDetails.id;
    data2["profilePicture"] =
      this.props.fileList.length > 0
        ? this.props.fileList
        : userDetails.profilePicture
        ? userDetails.profilePicture
        : "";
    data2["profileUrl"] = this.props.profileImage
      ? this.props.profileImage
      : userDetails.profileUrl
      ? userDetails.profileUrl
      : "";
    data2["address"] =
      this.state.citySearch.length > 0
        ? this.state.citySearch
        : userDetails.newdata && userDetails.newdata.companyAddress;
    data2["postalCode"] = this.state.zipCode ? this.state.zipCode : "";

    let agentInfo = {
      agentName: this.state.addUserName,
      agentEmail: this.state.addUserEmail,
    };

    // referedByNameAgent: this.state.referedByNameAgent,
    // referedByIdAgent: this.state.referedByIdAgent,

    const data = Object.assign(
      {},
      {
        entityData: {
          newdata: {
            zipCodes: this.state.Ziplist ? this.state.Ziplist : "",
            whatsapp: data2.whatsapp ? data2.whatsapp : "",
            serviceAreas: this.state.serviceAreas
              ? this.state.serviceAreas
              : "",
            titleorPosition: data2.titleorPosition ? data2.titleorPosition : "",
            mobile: data2.mobile ? data2.mobile : "",
            language: data2.language ? data2.language : "",
            faxNumber: data2.faxNumber ? data2.faxNumber : "",
            Specialties: this.state.optionsSelected
              ? this.state.optionsSelected
              : "",
            TaxNumber: data2.TaxNumber ? data2.TaxNumber : "",
            companyName: data2.companyName ? data2.companyName : "",
            companyAddress: data2.address ? data2.address : "",
            officeZipCode: data2.postalCode,
          },
          email: data2.email ? data2.email : "",
          firstName: data2.firstName ? data2.firstName : "",
          lastName: data2.lastName ? data2.lastName : "",
          mlsId: data2.mlsId ? data2.mlsId.toUpperCase() : "",
          phone: data2.phone ? data2.phone : "",
          profilePicture: data2.profilePicture ? data2.profilePicture : "",
          profileUrl: data2.profileUrl ? data2.profileUrl : "",
          userBio: richtTextBiofield ? richtTextBiofield : "",
          referedByName: this.state.referedByName,
          referedById: this.state.referedById,
          licenseIds: data2.licenseIds ? data2.licenseIds : "",
          agentInfo: agentInfo,
          addUserName: this.state.addUserName,
        },
      }
    );

    if (data.entityData.email && data.entityData.firstName) {
      const updatingUserDetails = await update("users", id, data);
      if (updatingUserDetails.success) {
        this.setState({
          editing: false,
          saveDetailsFalse: false,
        });
        notification["success"]({
          message: "Your profile has been updated",
          // description: "Your profile details updated successfully",
          duration: 3,
        });
      }
      const userProfileDetails = await getDetails("users", id);
      localStorage.removeItem("userDetails");
      localStorage.setItem(
        "userDetails",
        JSON.stringify(userProfileDetails.data)
      );
      this.props.context.setUserDetails(userProfileDetails.data);
    }
  };

  handleZipCode = (address) => {
    let companyAddress = address.label.split(",");
    const citySearch = companyAddress;
    this.setState({ citySearch });
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "postal_code") {
            this.setState({
              zipCode: address[i].long_name,
            });
          }
        }
      })
      .catch((error) => console.error("Error", error));
  };

  handleServiceAreas = (address) => {
    let serviceAreas = [];
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            serviceAreas.push(...this.state.serviceAreas, address[i].long_name);
            this.setState({
              serviceAreas,
            });
          }
        }
      })
      .catch((error) => console.error("Error", error));
  };

  onReferedBySelect = (value, option) => {
    this.setState({
      referedById: option.value,
      referedByName: option.label,
    });
  };

  onReferedBySelectAgent = (value, option) => {
    this.setState({
      addUserEmail: option.email,
      addUserName: option.label,
    });
  };

  onSelect = (result) => {
    const address = result.label.split(",");
    let vara = this.state.cities.cities;
    vara.push(address[0]);
    this.setState({
      cities: {
        cities: vara,
        type: "cities",
      },
    });
  };

  onClose = (value) => {
    const data =
      this.state.serviceAreas &&
      this.state.serviceAreas.filter((city) => city !== value);
    this.setState({
      serviceAreas: data,
    });
  };

  handleChange = (value) => {
    if (this.state.Ziplist.includes(this.state.zip)) {
      notification["error"]({
        message:
          "You have already added " +
          this.state.zip +
          " zip code. Please add another",
        // description: "You have already added " + this.state.zip + " zip code",
      });
    } else {
      const Ziplist = [...this.state.Ziplist];
      Ziplist.push(this.state.zip);
      this.setState({ Ziplist, zip: "", zipCount: Ziplist.length });
    }
  };

  handlespecialties = (value) => {
    this.setState({ optionsSelected: value });

    if (
      value.length === this.props &&
      this.props.userDetails &&
      this.props.userDetails.subscriptionPack &&
      this.props.userDetails.subscriptionPack.specialites &&
      this.props.userDetails.subscriptionPack.specialites
    ) {
      notification.info({
        message: "Please upgrade to add more specialites",
      });
    }
  };

  handleDeselect = (value) => {
    const deleteItem = this.state.Ziplist.indexOf(value);
    let presentItems = this.state.Ziplist.splice(deleteItem, 1);
    // this.state.Ziplist.splice(deleteItem, 1);
    this.setState({ zipCount: presentItems.length });
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.setState({
      showSubscriptions: true,
    });
  };

  setLoadingfalse = () => {
    this.setState({
      dataLoadingFalse: false,
    });
  };
  handleAddUser = () => {
    const { addUserName, addUserEmail } = this.state;
    if (addUserName !== "" && addUserEmail !== "") {
      let agentsList = this.state.agentsList;
      agentsList.push({
        label: addUserName,
        email: addUserEmail,
        value: addUserName,
      });
      this.setState({
        agentsList,
      });
    }
    this.setState({
      userInput: true,
    });
  };
  handleaddUserName = (e) => {
    this.setState({
      addUserName: e.target.value,
    });
  };
  handleaddUserEmail = (e) => {
    this.setState({
      addUserEmail: e.target.value,
    });
  };
  render() {
    const {
      saveDetailsFalse,
      showSubscriptions,
      addUserEmail,
      addUserName,
      userInput,
    } = this.state;
    const { userDetails } = this.props.context.state;
    // const opts = [
    //   { label: "Buyer representation", value: "Buyer representation" },
    //   { label: "Seller representation", value: "Seller representation" },
    //   { label: "Downsizing", value: "Downsizing" },
    //   { label: "Upsizing", value: "Upsizing" },
    //   { label: "Relocation", value: "Relocation" },
    //   { label: "Investment properties", value: "Investment properties" },
    //   { label: "Flipping", value: "Flipping" },
    //   { label: "1031 exchanges", value: "1031 exchanges" },
    //   { label: "Commercial", value: "Commercial" },
    //   { label: "Land", value: "Land" },
    //   { label: "New Construction", value: "New Construction" },
    //   { label: "Builder representation", value: "Builder representation" },
    //   { label: "waterfront properties", value: "waterfront properties" },
    //   { label: "Farm/horse properties", value: "Farm/horse properties" },
    //   { label: "Rentals", value: "Rentals" },
    //   { label: "First Time Buyers", value: "First Time Buyers" },
    //   { label: "Short Sales", value: "Short Sales" },
    //   { label: "Business Representation", value: "Business Representation" },
    //   { label: "Over 55/Active Adult", value: "Over 55/Active Adult" },
    //   { label: "Bank Owned", value: "Bank Owned" },
    // ];

    return (
      <>
        <Form
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          size="large"
        >
          <Spin spinning={saveDetailsFalse}>
            <Row>
              <Col
                xl={{ span: 11, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="label-titles">First Name</div>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Full Name!",
                    },
                  ]}
                  initialValue={
                    userDetails
                      ? userDetails.firstName
                        ? userDetails.firstName
                        : ""
                      : ""
                  }
                >
                  <Input placeholder="First Name" className="form-input" />
                </Form.Item>

                <div className="label-titles">Phone</div>
                <Form.Item
                  name="phone"
                  initialValue={
                    userDetails
                      ? userDetails.phone
                        ? userDetails.phone
                        : ""
                      : ""
                  }
                >
                  <InputNumber
                    placeholder="Enter your phone number"
                    className="form-input-number"
                    formatter={(value) => {
                      const cleaned = `${value}`.replace(/\D/g, "");
                      const match = cleaned.match(
                        /^(1|)?(\d{3})(\d{3})(\d{4})$/
                      );
                      if (match) {
                        const intlCode = match[1] ? "+1 " : "";
                        return [
                          intlCode,
                          "(",
                          match[2],
                          ") ",
                          match[3],
                          "-",
                          match[4],
                        ].join("");
                      }
                      return null;
                    }}
                  />
                </Form.Item>

                <>
                  <div className="label-titles">Referred by</div>
                  <Form.Item name="referedBy">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      options={this.state.allUsersList}
                      onSelect={this.onReferedBySelect}
                      placeholder="Referred by"
                      defaultValue={
                        userDetails &&
                        userDetails.referedByName &&
                        userDetails.referedByName !== ""
                          ? userDetails.referedByName
                          : ""
                      }
                      filterOption={(inputValue, option) =>
                        option.label
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    ></Select>
                  </Form.Item>
                </>
              </Col>

              <Col
                xl={{ span: 11, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                md={{ span: 11, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="label-titles">Last Name</div>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Full Name!",
                    },
                  ]}
                  initialValue={
                    userDetails
                      ? userDetails.lastName
                        ? userDetails.lastName
                        : ""
                      : ""
                  }
                >
                  <Input
                    placeholder="Last Name"
                    value=""
                    className="form-input"
                  />
                </Form.Item>

                <div className="label-titles">Email</div>
                <Form.Item
                  name="email"
                  initialValue={
                    userDetails
                      ? userDetails.email
                        ? userDetails.email
                        : ""
                      : ""
                  }
                >
                  <Input
                    readOnly={true}
                    placeholder="email"
                    className="form-input"
                  />
                </Form.Item>

                <>
                  <div className="label-titles">Add Agent</div>
                  <Form.Item name="AddAgent">
                    <Select
                      className="input-width"
                      showSearch
                      optionFilterProp="children"
                      options={this.state.agentsList}
                      onSelect={this.onReferedBySelectAgent}
                      defaultValue={
                        userDetails &&
                        userDetails.addUserName &&
                        userDetails.addUserName !== ""
                          ? userDetails.addUserName
                          : ""
                      }
                      filterOption={(inputValue, option) =>
                        option.label
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) === "0"
                          ? this.handleAddUser
                          : ""
                      }
                    ></Select>
                    <Button
                      type="primary"
                      onClick={this.handleAddUser}
                      className="add-user-width"
                      icon={<PlusOutlined />}
                    >
                      Add User
                    </Button>
                  </Form.Item>
                  {userInput && (
                    <>
                      <Row>
                        <Col span={11}>
                          <div className="label-titles">Agent Name</div>
                          <Form.Item
                            name="AgentName"
                            rules={[
                              {
                                required: true,
                                message: "Please input Agent Name!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Agent Name"
                              type="name"
                              value={addUserName}
                              onChange={this.handleaddUserName}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={11} offset={1}>
                          <div className="label-titles">Agent Email</div>
                          <Form.Item
                            name="AgentEmail"
                            rules={[
                              {
                                required: true,
                                message: "Please input Agent Email!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Agent Email"
                              type="email"
                              value={addUserEmail}
                              onChange={this.handleaddUserEmail}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              </Col>

              <Col
                xl={{ span: 8, offset: 1 }}
                lg={{ span: 8, offset: 1 }}
                md={{ span: 8, offset: 1 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Button type="primary" htmlType="submit" className="update-btn">
                  Update Profile
                </Button>
              </Col>
            </Row>
          </Spin>
        </Form>

        <Drawer
          title={"Upgrade to Add More"}
          placement="bottom"
          width={"100%"}
          height={"100%"}
          mask={true}
          closable={true}
          getContainer={false}
          visible={showSubscriptions}
          onClose={() =>
            this.setState({
              showSubscriptions: false,
            })
          }
          headerStyle={{ textAlign: "center" }}
        >
          <Row>
            <Col span={24}>
              <Subscriptions setLoadingfalse={this.setLoadingfalse} />
            </Col>
          </Row>
        </Drawer>
      </>
    );
  }
}
export default withAppContext(withRouter(BuyerProfileForm));
