import React, { Component } from "react";
import { Layout, Row, Col } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { noAuthlist } from "../../api/Apis";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../../store/contextStore";
import BuyerCard from "../../components/Card/BuyerCard";
import Advertisement from "../../common/Advertisement";
import Loading from "../../common/Loading";
import DataNotFound from "../../common/DataNotFound";
import profileAvatar from "../../images/profileAvatar.png";
import Pagination from "../../components/pagination/Paginations";
import { LoadingOutlined } from "@ant-design/icons";
<LoadingOutlined style={{ fontSize: 40 }} />;
const { Content } = Layout;

class BuyerSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: "",
      count: "",
      emptyData: false,
      dataLoading: false,
      limit: 10,
      offset: 0,
      currentPage: 1,
      city: "",
      maxValue: 10,
      minValue: 0,
      bothDataLoading: true,
      beforeFiltersData: [],
      stateFilterCount: 1,
      query: {
        minprice: 3000,
        maxprice: 900000,
        minarea: 1,
        maxarea: 20000,
        minbaths: undefined,
        maxbaths: undefined,
        minbeds: undefined,
        subTypeText: undefined,
        status: undefined,
      },
    };
  }

  componentDidMount() {
    this.handleLocalHomesList();
  }

  componentWillUnmount() {
    this.props.context.setCity("");
  }

  setPageNumberAndLimit = async (page, pageSize) => {
    await this.setState({
      limit: pageSize,
      offset: page,
    });
  };

  handleLocalHomesList = async () => {
    const allUsers = await noAuthlist("getUsers");
    const localHomesListData = await noAuthlist("getBuyers");

    let localHomesList = localHomesListData.data.filter((buyer) => {
      return buyer.buyerPropertyDetails2.showOnBuyersPage && buyer;
    });

    let properties = "";
    if (localHomesListData.success) {
      properties = localHomesList;
      for (let singleData of properties) {
        singleData["profileUrl"] = "";
        singleData["agentName"] = "";
      }
      for (let singleHome of properties) {
        for (let singleUser of allUsers.data) {
          if (singleHome.userId === singleUser.id) {
            if (
              singleUser.profilePicture &&
              singleHome.buyerPropertyDetails2.showOnBuyersPage
            ) {
              const profileUrl =
                `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                singleUser.profilePicture;
              singleHome.profileUrl = profileUrl && profileUrl;
              singleHome.agentName = singleUser.firstName
                ? singleUser.firstName + " " + singleUser.lastName
                : "";
            } else {
              singleHome.profileUrl = profileAvatar;
              singleHome.agentName = singleUser.firstName
                ? singleUser.firstName + " " + singleUser.lastName
                : "";
            }
          }
        }
      }
      await this.setState({
        properties,
        beforeFiltersData: properties,
        beforeLoading: false,
        bothDataLoading: false,
      });
      this.getDivHeight();
    }
    const oldFilterData =
      this.props.context.state && this.props.context.state.filterSearchData;
    if (oldFilterData) {
      this.handleLocalBuyersFilter(oldFilterData);
    }
  };

  handleFavoritesCount = (favoritesData, dataOflength) => {
    this.props.context.handleFavoritesCount(favoritesData, dataOflength);
  };

  handlePagination = async (val) => {
    this.setState({
      bothDataLoading: true,
    });
    if (val <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 10,
      });
    } else {
      this.setState({
        minValue: this.state.maxValue,
        maxValue: val * 10,
      });
    }
    await this.setState({
      currentPage: val,
    });
    setTimeout(() => {
      this.setState({
        bothDataLoading: false,
      });
      this.getDivHeight();
    }, 3000);
  };

  handleItems = (userid, agent, profileUrl, authenticate, locationState) => {
    this.props.context.setOffMLSuserId(
      userid,
      agent,
      profileUrl,
      authenticate,
      locationState
    );
  };

  handleLocalBuyersFilter = (filterdata) => {
    const { beforeFiltersData } = this.state;
    const filterObject = [];
    for (let filterOption of filterdata) {
      if (filterOption.type === "beds" && filterOption.minbeds !== 0) {
        filterObject["beds"] = filterOption.minbeds;
      }
      if (filterOption.type === "baths" && filterOption.minbaths !== 0) {
        filterObject["baths"] = filterOption.minbaths;
      }
      if (filterOption.type === "area") {
        filterObject["maxarea"] = filterOption.maxarea;
        filterObject["minarea"] = filterOption.minarea;
      }
      if (filterOption.type === "price") {
        filterObject["maxprice"] = filterOption.maxprice;
        filterObject["minprice"] = filterOption.minprice;
      }
      if (
        filterOption.type === "subtype" &&
        filterOption.subTypeText.length > 0
      ) {
        filterObject["subTypeText"] = filterOption.subTypeText;
      }
      if (
        filterOption.type === "status" &&
        filterOption.status &&
        filterOption.status.length > 0
      ) {
        filterObject["status"] = filterOption.status;
      }

      if (
        filterOption.type === "location" &&
        filterOption.multiLocations &&
        filterOption.multiLocations.length > 0
      ) {
        let citiesArray = [];
        filterOption.multiLocations.map((location) =>
          citiesArray.push(location.cityName, location.stateName)
        );
        filterObject["multi"] = citiesArray;
      }
    }

    const filterHomeData = beforeFiltersData.filter((o) => {
      let count = 0;
      if (
        filterObject.baths &&
        o.buyerPropertyFeatures.baths >= filterObject.baths
      ) {
        count += 1;
      }
      if (
        filterObject.beds &&
        o.buyerPropertyFeatures.beds >= filterObject.beds
      ) {
        count += 1;
      }
      if (
        filterObject.minarea &&
        parseInt(o.buyerPropertyFeatures.maxArea) >= filterObject.minarea
      ) {
        count += 1;
      }
      if (
        filterObject.maxarea &&
        parseInt(o.buyerPropertyFeatures.maxArea) <= filterObject.maxarea
      ) {
        count += 1;
      }
      if (
        filterObject.minprice &&
        o.buyerPropertyDetails.minprice >= filterObject.minprice
      ) {
        count += 1;
      }
      if (
        filterObject.maxprice &&
        o.buyerPropertyDetails.maxprice <= filterObject.maxprice
      ) {
        count += 1;
      }
      if (
        filterObject.subTypeText &&
        filterObject.subTypeText &&
        o.buyerPropertyDetails.propertyType
      ) {
        const titleAddress = filterObject.subTypeText.filter((title) =>
          o.buyerPropertyDetails.propertyType.includes(title)
        );
        if (titleAddress.length !== 0) {
          count += 1;
        }
      }
      if (
        filterObject.multi &&
        o.buyerPropertyDetails &&
        o.buyerPropertyDetails.updatedInterestedTowns
      ) {
        if (o.buyerPropertyDetails.stateTypeAdded) {
          const titleAddress =
            o.buyerPropertyDetails.updatedInterestedTowns.filter(
              (citySearch) =>
                filterObject.multi.includes(citySearch.cityName) &&
                filterObject.multi.includes(citySearch.stateName)
            );
          if (titleAddress.length !== 0) {
            count += 1;
          }
        }
      }
      if (count === Object.keys(filterObject).length) {
        return true;
      } else {
        return false;
      }
    });
    if (filterHomeData.length === 0) {
      this.setState({
        emptyData: true,
      });
    }
    this.setState({
      properties: filterHomeData,
    });
    this.props.context.setFilterData(filterdata);
    this.props.context.handleToSetCurrentPage(1, 10);
  };

  update = (filterdata) => {
    this.handleLocalBuyersFilter(filterdata);
    this.setState({
      stateFilterCount: this.state.stateFilterCount + 1,
    });
  };

  getDivHeight = () => {
    let element = document.getElementById("buyerSearch");
    setTimeout(() => {
      let height =
        element && element.offsetHeight && element.offsetHeight !== null
          ? element.offsetHeight
          : 1000;
      this.props && this.props.getDivHeight(height);
    }, 4000);
  };

  render() {
    const { properties, currentPage, bothDataLoading, stateFilterCount } =
      this.state;
    const { listOfFavorites } = this.props.context.state;
    const { FilterData, filterCount } = this.props;
    let newData = [];
    properties &&
      properties.map((o) =>
        newData.push({
          id: o.id,
          favoritesId:
            listOfFavorites &&
            listOfFavorites.filter((oo) => oo.leadId === o.id),
          title:
            (o.buyerPropertyDetails2 &&
              o.buyerPropertyDetails2.title === undefined) ||
            o.buyerPropertyDetails2.title === ""
              ? o.buyerPrivateDetails &&
                o.buyerPrivateDetails.buyerAddress &&
                o.buyerPrivateDetails.buyerAddress.join(", ")
              : o.buyerPropertyDetails2.title,
          minprice:
            o.buyerPropertyDetails && o.buyerPropertyDetails.minprice
              ? o.buyerPropertyDetails.minprice
              : "",
          maxprice:
            o.buyerPropertyDetails && o.buyerPropertyDetails.maxprice
              ? o.buyerPropertyDetails.maxprice
              : "",
          type:
            o.buyerPropertyDetails &&
            o.buyerPropertyDetails.propertyType &&
            o.buyerPropertyDetails.propertyType
              ? o.buyerPropertyDetails.propertyType
              : "",
          agent: o.agentName ? o.agentName : "",
          date: moment(o.createdAt).format("Do MMM YYYY"),
          homeAgentProfileUrl: o.profileUrl && o.profileUrl,
          homeAgentName: o.agentName ? o.agentName : "",
          beds:
            o.buyerPropertyFeatures && o.buyerPropertyFeatures.beds
              ? o.buyerPropertyFeatures.beds
              : 0,
          baths:
            o.buyerPropertyFeatures && o.buyerPropertyFeatures.baths
              ? o.buyerPropertyFeatures.baths
              : 0,
          minArea:
            o.buyerPropertyFeatures && o.buyerPropertyFeatures.minArea
              ? o.buyerPropertyFeatures.minArea
              : "NA",
          maxArea:
            o.buyerPropertyFeatures && o.buyerPropertyFeatures.minArea
              ? o.buyerPropertyFeatures.maxArea
              : "NA",
          userid: o.userId,
          viewProfile: true,
          authenticate: true,
          mls: false,
          stateTypeAdded:
            o.buyerPropertyDetails &&
            o.buyerPropertyDetails.stateTypeAdded &&
            o.buyerPropertyDetails.stateTypeAdded,
          sellerInterestedTown:
            o.buyerPropertyDetails &&
            o.buyerPropertyDetails.sellerInterestedTown &&
            o.buyerPropertyDetails.sellerInterestedTown.length > 0
              ? o.buyerPropertyDetails.sellerInterestedTown
              : [],
          updatedInterestedTowns:
            o.buyerPropertyDetails &&
            o.buyerPropertyDetails.updatedInterestedTowns &&
            o.buyerPropertyDetails.updatedInterestedTowns.length > 0
              ? o.buyerPropertyDetails.updatedInterestedTowns
              : [],
          sellerVsBuyerVsVendor:
            o.sellerVsBuyerVsVendor && o.sellerVsBuyerVsVendor,
          showOnBuyersPage:
            o.buyerPropertyDetails2 && o.buyerPropertyDetails2.showOnBuyersPage,
        })
      );
    FilterData &&
      FilterData !== "undefined" &&
      stateFilterCount === filterCount &&
      this.update(FilterData);
    return (
      <>
        <Loading enableLoading={bothDataLoading} />
        <Layout>
          <Content>
            <div id="buyerSearch">
              <Row>
                <Col
                  span={24}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <div className="show-on-desktop">
                    <p className="listing-heading">
                      Search for and contact buyer profiles on the system who
                      may be a match with a home you have for sale or are
                      representing as a seller’s agent.
                      <br />
                      Buyers found: {properties.length}
                    </p>
                  </div>

                  {!bothDataLoading && newData && newData.length === 0 && (
                    <div style={{ padding: "4vw" }}>
                      <DataNotFound
                        type="buyers"
                        title="There are no listings Found"
                      />
                    </div>
                  )}

                  {newData &&
                    !bothDataLoading &&
                    newData.length >= 0 &&
                    newData
                      .slice(this.state.minValue, this.state.maxValue)
                      .map((val, index) => {
                        if (index % 4 === 0 && index > 1) {
                          return (
                            <>
                              <Advertisement
                                otherAds={false}
                                adType="Rectangle"
                                adWhereToShow="Buyers"
                                adLimit="1"
                                zipCode=""
                              />
                              <BuyerCard
                                value={val}
                                handleFavoritesCount={this.handleFavoritesCount}
                                handleItems={this.handleItems}
                                authenticatePage
                              />
                            </>
                          );
                        } else {
                          return (
                            <BuyerCard
                              value={val}
                              handleFavoritesCount={this.handleFavoritesCount}
                              handleItems={this.handleItems}
                              authenticatePage
                            />
                          );
                        }
                      })}

                  {!bothDataLoading && newData && newData.length > 0 && (
                    <Col
                      xl={{ span: 14, offset: 10 }}
                      lg={{ span: 14, offset: 10 }}
                      md={{ span: 14, offset: 10 }}
                      sm={{ span: 24, offset: 0 }}
                      xs={{ span: 24, offset: 0 }}
                      className="align-right"
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <Pagination
                        total={newData.length}
                        handlePagination={this.handlePagination}
                        currentPage={currentPage}
                      />
                    </Col>
                  )}
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </>
    );
  }
}

export default withAppContext(withRouter(BuyerSearch));
