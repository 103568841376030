import React, { Component } from "react";
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Divider,
  Card,
  Button,
  Input,
  notification,
} from "antd";
import Loading from "../../common/Loading";
import { noAuthadd } from "../../api/Apis";
import tracyPic from "../../images/tracy2.jpeg";
// import kristinPic from "../../images/kristin.jpeg";
const { TextArea } = Input;
export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      contactVisible: false,
      loading: false,
      loanVisible: false,
      to: "",
      from: "",
      email: "",
      phone: "",
      subject: "New contact form submitted on That's My Town",
      message: "",
      EmailTo: "trbarber10@msn.com",
      Name: "Tracy Barber",
      initialLoading: true,
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        initialLoading: false,
      });
    }, 2000);
  };

  handleMailForm = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSendMail = async () => {
    if (this.state.email === "") {
      alert("Please enter email");
      return;
    } else if (this.state.subject === "") {
      alert("Please enter subject");
      return;
    } else if (this.state.message === "") {
      alert("Please enter message");
      return;
    } else {
      const data = {
        entityData: {
          // to: process.env.REACT_APP_AGENT_EMAIL,
          to: this.state.EmailTo,
          // toName: this.state.EmailTo,
          from: this.state.from,
          email: this.state.email,
          phone: this.state.phone,
          subject: this.state.subject,
          message: this.state.message,
        },
      };
      const response = await noAuthadd("submitContactForm", data);
      if (response.success) {
        this.handleCancel();
        notification["success"]({
          message: "You have successfully sent your message. Thank you.",
        });
      } else {
        notification["error"]({
          message: "Message sent failed.",
        });
      }
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      to: "",
      from: "",
      email: "",
      phone: "",
      subject: "New contact form submitted on That's My Town",
      message: "",
    });
  };

  handleSelect = (e) => {
    console.log(e);
    this.setState({
      EmailTo: e[0],
      Name: e[1],
    });
  };

  render() {
    // const EmailData = [
    //   { label: "Tracy Barber", value: "trbarber10@msn.com" },
    //   { label: "Kristin Barber", value: "kristin@thatsmytown.com" },
    // ];
    return (
      <div>
        <Loading enableLoading={this.state.initialLoading} />
        <Row>
          <Col span={20} offset={2}>
            <Divider>
              <h1>Contact Us</h1>
            </Divider>
          </Col>
        </Row>

        <Row>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Row gutter={16}>
              <Col
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                <Card
                  hoverable
                  bordered={false}
                  title={null}
                  bodyStyle={{ padding: "15px" }}
                  style={{
                    background: "#FFF",
                    borderColor: "black",
                    marginTop: "1vw",
                  }}
                  className="padding-card1-whyjoin"
                >
                  <Row className="padding">
                    <Col
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                    >
                      <h3
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          marginBottom: "2.5vw",
                        }}
                      >
                        Contact us anytime for questions, suggestions, etc:
                      </h3>
                    </Col>
                  </Row>
                  <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey  terms">To :</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 18, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      {/* <Select
                        allowClear
                        className="market-place-dropDown"
                        onChange={this.handleSelect}
                        value={this.state.Name}
                      >
                        {EmailData.map((province) => (
                          <Select.Option
                            key={province.value}
                            value={[province.value, province.label]}
                          >
                            {province.label}
                          </Select.Option>
                        ))}
                      </Select> */}
                      <Input
                        disabled={this.state.disableEdit}
                        name="to"
                        placeholder="Enter Your Name"
                        className="form-input"
                        value={this.state.Name}
                        // onChange={this.handleMailForm}
                        style={{
                          border: "1px solid #fff",
                          color: "black",
                          background: "white",
                        }}
                      />
                    </Col>
                  </Row>
                  <Divider style={{ margin: "5px" }}></Divider>

                  <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey  terms">Name :</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 18, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      <Input
                        disabled={this.state.disableEdit}
                        name="from"
                        placeholder="Enter Your Name"
                        className="form-input"
                        value={this.state.from}
                        onChange={this.handleMailForm}
                        style={{
                          border: "1px solid #fff",
                          color: "black",
                          background: "white",
                        }}
                      />
                    </Col>
                  </Row>
                  <Divider style={{ margin: "5px" }}></Divider>

                  <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey terms">Email :</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 18, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      <Input
                        disabled={this.state.disableEdit}
                        name="email"
                        placeholder="Enter Your Email"
                        className="form-input"
                        value={this.state.email}
                        onChange={this.handleMailForm}
                        style={{
                          border: "1px solid #fff",
                          color: "black",
                          background: "white",
                        }}
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: "5px" }}></Divider>

                  <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey terms">Phone :</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 18, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      <Input
                        disabled={this.state.disableEdit}
                        name="phone"
                        placeholder="Enter Your Phone"
                        className="form-input"
                        value={this.state.phone}
                        onChange={this.handleMailForm}
                        style={{
                          border: "1px solid #fff",
                          color: "black",
                          background: "white",
                        }}
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: "5px" }}></Divider>

                  {/* <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey terms">Subject :</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 18, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      <Input
                        name="subject"
                        placeholder="Enter Your Subject"
                        className="form-input"
                        value={this.state.subject}
                        onChange={this.handleMailForm}
                        style={{ border: "1px solid #fff" }}
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: "5px" }}></Divider> */}

                  <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 5 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey terms">Message :</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 17, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      <TextArea
                        placeholder="Start Typing.."
                        rows={3}
                        name="message"
                        value={this.state.message}
                        onChange={this.handleMailForm}
                        style={{ border: "1px solid #fff" }}
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: "5px" }}></Divider>
                  <div style={{ float: "right" }}>
                    <Button
                      key="submit"
                      type="primary"
                      loading={this.state.loading}
                      onClick={this.handleSendMail}
                      className="send-button-mobile-view"
                    >
                      <h6
                        style={{
                          color: "#FFF",
                          fontWeight: "bold",
                          fontSize: "14px",
                          marginTop: "5px",
                        }}
                      >
                        Send
                      </h6>
                    </Button>
                  </div>
                </Card>
              </Col>
              <Col
                xl={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                className="card-description"
              >
                <Card
                  hoverable
                  bordered={false}
                  title={null}
                  bodyStyle={{ padding: "15px" }}
                  className="padding-card1-whyjoin-image-card "
                  style={{
                    background: "#FFF",
                    borderColor: "black",
                    marginTop: "1vw",
                  }}
                >
                  <Row>
                    <Col
                      xl={{ span: 11, offset: 0 }}
                      lg={{ span: 11, offset: 0 }}
                      md={{ span: 11, offset: 0 }}
                      sm={{ span: 11, offset: 0 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <img
                        alt="Tracy"
                        src={tracyPic}
                        style={{
                          boxShadow: "0px 0px 10px 0px",
                          border: "6px solid rgb(28, 45, 65)",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Col>
                    <Col
                      xl={{ span: 12, offset: 1 }}
                      lg={{ span: 12, offset: 1 }}
                      md={{ span: 12, offset: 1 }}
                      sm={{ span: 12, offset: 1 }}
                      xs={{ span: 22, offset: 1 }}
                      className="card-description"
                    >
                      <h3 style={{ fontWeight: "bold" }}>
                        Tracy Barber, Founder
                      </h3>
                      <h3>That's My Town, Inc.</h3>
                      <h3>14 Linda Drive, Westminster, MA 01473</h3>
                      {/* <h3>Email : trbarber10@msn.com</h3> */}
                      <h3>Phone : (978) 400-1617</h3>
                    </Col>
                  </Row>
                </Card>

                {/* <Row>
                  <Col span={24} className="card-description">
                    <Card
                      hoverable
                      bordered={false}
                      title={null}
                      bodyStyle={{ padding: "0px" }}
                      style={{
                        background: "#FFF",
                        // padding: "30px",
                        borderColor: "black",
                        marginTop: "1vw",
                      }}
                      className="padding-card1-whyjoin"
                    >
                      <Row>
                        <Col
                          xl={{ span: 8, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          md={{ span: 8, offset: 0 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                        >
                          <img
                            alt="Kristin"
                            src={kristinPic}
                            style={{
                              boxShadow: "0px 0px 10px 0px",
                              border: "6px solid rgb(28, 45, 65)",
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </Col>
                        <Col
                          xl={{ span: 14, offset: 2 }}
                          lg={{ span: 14, offset: 2 }}
                          md={{ span: 14, offset: 2 }}
                          sm={{ span: 22, offset: 1 }}
                          xs={{ span: 22, offset: 1 }}
                          className="card-description"
                        >
                          <h3 style={{ fontWeight: "bold" }}>
                            Kristin Barber, Marketing Director
                          </h3>
                          <h3>That's My Town, Inc.</h3>
                          <h3>14 Linda Drive, Westminster, MA 01473</h3>
                          <h3>Phone : (978) 407-4070</h3>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
