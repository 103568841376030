import React, { Component } from "react";
import {
  Modal,
} from "antd";
import SignupForm from "../containers/RegisterForm";
import LoginPopup from "../containers/LoginForm";
export default class SignupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableSignupForm2: false,
      showLoginSiderForm: false,
    };
  }

  handleToshowLoginSider = () => {
    this.setState({
      showLoginSiderForm: false,
    });
  };

  render() {
    const { showLoginPopup, enableSignupForm } = this.props;
    return (
      <div>
        <Modal
          visible={enableSignupForm}
          width="40vw"
          onCancel={this.props.handleonCancel}
          className="buyer-agent-modal"
          footer={null}
        >
          <div style={{ padding: "30px" }} className="buyer-agent-signupform">
            {showLoginPopup ? (
              <>
                <LoginPopup
                  showAdd={false}
                  modalTrue
                  closePreviouspopUp={this.props.handleonCancel}
                  goTopreviousPage={this.props.goTopreviousPage}
                  handleToshowLoginSider={this.handleToshowLoginSider}
                />
              </>
            ) : (
              <>
                <SignupForm
                  showAdd={false}
                  modalTrue
                  closePreviouspopUp={this.props.handleonCancel}
                  goTopreviousPage={this.props.goTopreviousPage}
                  handleToshowLoginSider={this.handleToshowLoginSider}
                />
              </>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
