import React, { Component } from "react";
import Invitation from "./Invitation";
import { Row, Col, Button, Form, Checkbox, notification, Select } from "antd";
import { withAppContext } from "../store/contextStore";
import { withRouter } from "react-router-dom";
import { update, noAuthlist } from "../api/Apis";
class BuyerPreferedAgentPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visiblePreferedAgent: false,
      agentsList: [],
      addUserName: "",
      addUserEmail: "",
      inviteAgentPopup: false,
      newPreferedAgent: "",
    };
  }

  componentDidMount() {
    this.getAllAgents();
  }

  getAllAgents = async () => {
    try {
      const AllagentsList = await noAuthlist("getAgents");
      let singleAgent = AllagentsList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
          email: user.email,
        };
      });
      if (AllagentsList.success) {
        this.setState({
          agentsList: singleAgent,
        });
      } else {
        notification["error"]({
          message: AllagentsList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  showInviteAgentForm = () => {
    this.setState({
      inviteAgentPopup: true,
      addUserEmail: "",
      addUserName: "",
    });
  };
  handleCloseInvitationModal = () => {
    this.setState({
      visiblePreferedAgent: false,
      inviteAgentPopup: false,
    });
  };
  onReferedBySelectAgent = (value, option) => {
    this.setState({
      addUserEmail: option.email,
      addUserName: option.label,
    });
  };
  submitPreferedAgent = (agentName, agentEmail) => {
    const { addUserName, addUserEmail } = this.state;
    let userLoginDetails = JSON.parse(localStorage.getItem("userDetails"));
    let data = {};
    let agentInfo = {};
    if (addUserName !== "" && addUserEmail !== "") {
      agentInfo = {
        agentName: this.state.addUserName,
        agentEmail: this.state.addUserEmail,
      };
      data = Object.assign(
        {},
        {
          entityData: {
            agentInfo,
            addUserName: this.state.addUserName,
          },
        }
      );
    }
    if (agentName && agentEmail && agentName !== "" && agentEmail !== "") {
      agentInfo = {
        agentName: agentName,
        agentEmail: agentEmail,
      };
      data = Object.assign(
        {},
        {
          entityData: {
            agentInfo,
            addUserName: agentName,
          },
        }
      );
    }
    if (
      data &&
      data.entityData &&
      data.entityData.agentInfo &&
      data.entityData.agentInfo.agentEmail
    ) {
      update("users", userLoginDetails.id, data).then((res) => {
        if (res.success) {
          this.props.openSendMessage(agentInfo);
          localStorage.removeItem("userDetails");
          localStorage.setItem("userDetails", JSON.stringify(res.data.data));
          this.props.context.setUserDetails(res.data.data);
          this.setState({
            visiblePreferedAgent: false,
            inviteAgentPopup: false,
          });
        }
      });
    }
  };

  noAgentToRepresent = () => {
    this.setState({
      visiblePreferedAgent: false,
      inviteAgentPopup: false,
    });
    this.props.openSendMessage();
  };
  render() {
    const { agentsList, inviteAgentPopup, addUserEmail, addUserName } =
      this.state;
    return (
      <div>
        <>
          <Row
            style={{
              padding: !inviteAgentPopup ? "20px 10px 10px 10px" : "10px",
            }}
          >
            {!inviteAgentPopup && (
              <Col span={24}>
                <Form.Item name="AddAgent">
                  <Select
                    // className="input-width"
                    showSearch
                    optionFilterProp="children"
                    options={agentsList}
                    onSelect={this.onReferedBySelectAgent}
                    filterOption={(inputValue, option) =>
                      option.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    placeholder="Find your agent"
                  ></Select>
                </Form.Item>
                {addUserEmail &&
                  addUserEmail &&
                  addUserEmail !== "" &&
                  addUserName !== "" && (
                    <Button
                      key="submit"
                      type="primary"
                      onClick={this.submitPreferedAgent}
                    >
                      Save and Continue
                    </Button>
                  )}
              </Col>
            )}
            {inviteAgentPopup && (
              <Col span={24}>
                <Invitation
                  buyerInvitaion={true}
                  handleCloseInvitationModal={this.handleCloseInvitationModal}
                  invitationTitle="agent"
                  submitPreferedAgent={this.submitPreferedAgent}
                />
              </Col>
            )}
            {!inviteAgentPopup && (
              <>
                <div className="label-titles">Or</div>
                <Col span={24} style={{ paddingTop: "10px" }}>
                  <div className="label-titles">
                    Do you want invite an agent...{" "}
                    <a
                      href={() => false}
                      rel="noreferrer"
                      onClick={() => this.showInviteAgentForm()}
                    >
                      Click here to invite
                    </a>
                  </div>
                </Col>
                <Col span={24} style={{ paddingTop: "20px" }}>
                  <div className="label-titles">
                    <Checkbox onChange={this.noAgentToRepresent}>
                      I do not have an agent to represent me
                    </Checkbox>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </>
      </div>
    );
  }
}

export default withAppContext(withRouter(BuyerPreferedAgentPopup));
