import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  notification,
  Button,
  Rate,
  Input,
  Divider,
} from "antd";
import "antd/dist/antd.css";
import { withRouter } from "react-router";
import { noAuthadd } from "../api/Apis";
import { withAppContext } from "../store/contextStore";
const { TextArea } = Input;
class ExternalTestimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      testimonialData: "",
      testimonialRating: 0,
      localKnowledgeRating: 0,
      professionalismRating: 0,
      responsivenessRating: 0,
      industryKnowledgeRating: 0,
    };
  }

  saveUserTestmonials = async () => {
    let userId =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id;
    const {
      firstName,
      lastName,
      testimonialData,
      localKnowledgeRating,
      professionalismRating,
      responsivenessRating,
      industryKnowledgeRating,
    } = this.state;

    if (firstName === "") {
      notification["error"]({
        message: "Please enter your first name.",
      });
      return;
    }

    if (lastName === "") {
      notification["error"]({
        message: "Please enter your last name.",
      });
      return;
    }

    if (testimonialData === "") {
      notification["error"]({
        message: "Please enter your comment.",
      });
      return;
    }

    if (
      parseInt(this.state.localKnowledgeRating) === 0 &&
      parseInt(this.state.professionalismRating) === 0 &&
      parseInt(this.state.responsivenessRating) === 0 &&
      parseInt(this.state.industryKnowledgeRating) === 0
    ) {
      notification["error"]({
        message: "Please give your rating.",
      });
      return;
    }
    let ratingAverage = (
      (localKnowledgeRating +
        professionalismRating +
        responsivenessRating +
        industryKnowledgeRating) /
      4
    ).toFixed(1);

    const data = {
      entityData: {
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        testimonialDesc: testimonialData,
        rating: ratingAverage,
        localKnowledgeRating: localKnowledgeRating,
        professionalismRating: professionalismRating,
        responsivenessRating: responsivenessRating,
        industryKnowledgeRating: industryKnowledgeRating,
        postedBy: null,
        dataType: "testimonial",
      },
    };

    try {
      const response = await noAuthadd("addTestimonials", data);
      if (response.success) {
        notification["success"]({
          message: "Your record added successfully",
        });
        this.resetToDefaults(userId);
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      window.location.assign("/pageNotFound/newtWork");
    }
  };

  handleTestMonials = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  resetToDefaults = (userId) => {
    this.setState({
      firstName: "",
      lastName: "",
      testimonialData: "",
      testimonialRating: 0,
      localKnowledgeRating: 0,
      professionalismRating: 0,
      responsivenessRating: 0,
      industryKnowledgeRating: 0,
      enableAddTestimonial: false,
    });

    if (window.location.pathname === `/testimonials/${userId}`) {
      window.location.replace(`/viewProfile/${userId}`);
    } else {
      this.props.resetTestimonial();
    }
  };

  render() {
    const {
      firstName,
      lastName,
      testimonialData,
      localKnowledgeRating,
      professionalismRating,
      responsivenessRating,
      industryKnowledgeRating,
    } = this.state;
    return (
      <Card>
        <Row>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            style={{ padding: "10px", marginTop: "20px" }}
          >
            <div className="label-titles">First name :</div>
            <Input
              placeholder="Enter your first name"
              name="firstName"
              value={firstName}
              onChange={this.handleTestMonials}
            />
          </Col>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            style={{ padding: "10px", marginTop: "20px" }}
          >
            <div className="label-titles">Last name :</div>
            <Input
              placeholder="Enter your last name"
              name="lastName"
              value={lastName}
              onChange={this.handleTestMonials}
            />
          </Col>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            style={{ padding: "10px", marginTop: "20px" }}
          >
            <div className="label-titles">Testimonial :</div>
            <TextArea
              placeholder="Start typing..."
              rows={4}
              name="testimonialData"
              value={testimonialData}
              onChange={this.handleTestMonials}
            />
          </Col>

          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
            style={{
              padding: "10px",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <div className="label-titles">Local knowledge :</div>
            <span>
              <Rate
                name="localKnowledgeRating"
                onChange={(value) =>
                  this.setState({
                    localKnowledgeRating: value,
                  })
                }
                value={localKnowledgeRating}
              />
            </span>
          </Col>

          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
            style={{
              padding: "10px",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <div className="label-titles">Professionalism</div>
            <span>
              <Rate
                name="professionalismRating"
                onChange={(value) =>
                  this.setState({
                    professionalismRating: value,
                  })
                }
                value={professionalismRating}
              />
            </span>
          </Col>

          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
            style={{
              padding: "10px",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <div className="label-titles">Responsiveness :</div>
            <span>
              <Rate
                name="responsivenessRating"
                onChange={(value) =>
                  this.setState({
                    responsivenessRating: value,
                  })
                }
                value={responsivenessRating}
              />
            </span>
          </Col>

          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
            style={{
              padding: "10px",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <div className="label-titles">Industry Knowledge :</div>
            <span>
              <Rate
                name="industryKnowledgeRating"
                onChange={(value) =>
                  this.setState({
                    industryKnowledgeRating: value,
                  })
                }
                value={industryKnowledgeRating}
              />
            </span>
          </Col>
        </Row>
        <Row>
          <Divider></Divider>
        </Row>
        <Row>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
          >
            <Row>
              <Col span={12} style={{ padding: "10px" }}>
                <Button
                  type="default"
                  style={{ width: "100%" }}
                  onClick={() => this.props.resetTestimonial()}
                >
                  Cancel
                </Button>
              </Col>

              <Col span={12} style={{ padding: "10px" }}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={this.saveUserTestmonials}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  }
}
export default withAppContext(withRouter(ExternalTestimonials));
