import { Popconfirm, notification } from "antd";
import React, { Component } from "react";
import { HeartFilled, DeleteOutlined } from "@ant-design/icons";
import { deleteRecord } from "../api/Apis";
import { withRouter } from "react-router-dom";
import { withAppContext } from "../store/contextStore";

class DeleteRecord extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  confirm = async (id, tableName) => {
    let data12 = "";
    let searchListData = [];

    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails && userDetails.userSavedSearch) {
      searchListData = userDetails && userDetails.userSavedSearch;
    }

    try {
      const response = await deleteRecord(tableName, id);
      if (response.success) {
        notification["success"]({
          message: `${this.props.deletionSuccessMessage}`,
        });
        this.props.context.setHomeDetails(data12, "EMPTY_DATA");
        this.props.context.setBuyerHomeDetails(data12, "BUYER_EMPTY_DATA");
        this.props.getHomesList(id);
        if (
          this.props.apiPath === "questionsAndAnswers" ||
          this.props.apiPath === "schedules" ||
          this.props.apiPath === "testimonials"
        ) {
          this.props.handleReset();
        }
        if (
          searchListData &&
          searchListData.length > 0 &&
          this.props.apiPath === "buyers"
        ) {
          this.props.updateSavedSearchWithBuyerPref(
            searchListData,
            id,
            userDetails.id
          );
        }
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      // window.location.assign("/pageNotFound/newtWork");
    }
  };
  cancel = (e) => {
    console.log(e);
  };
  render() {
    const { recordId, apiPath, buttonText, fontSizeProp, deletionType } =
      this.props;
    console.log(fontSizeProp);
    return (
      <div>
        {deletionType === "DeleteOutlined" ? (
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => this.confirm(recordId, apiPath)}
            onCancel={() => this.cancel(recordId, apiPath)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              style={{
                color: "red",
                fontSize: `${fontSizeProp}`,
                cursor: "pointer",
              }}
            />
            {buttonText && <span style={{ color: "red" }}> {buttonText}</span>}
          </Popconfirm>
        ) : (
          <>
            <HeartFilled
              className="homeSearch heart"
              onClick={() => this.confirm(recordId, apiPath)}
            />
          </>
        )}
      </div>
    );
  }
}
export default withAppContext(withRouter(DeleteRecord));
