import React, { Component } from "react";
import { Card, Row, Col, Divider } from "antd";
import Loading from "../../common/Loading";
import userPic from "../../images/tracy1.jpeg";
import familyPic from "../../images/tracy2.jpeg";
export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoading: true,
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        initialLoading: false,
      });
    }, 2000);
  };

  render() {
    return (
      <div>
        <Loading enableLoading={this.state.initialLoading} />
        <Row>
          <Col span={20} offset={2}>
            <Divider>
              <h1>About Us</h1>
            </Divider>
          </Col>
        </Row>

        <Row>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Card
              bordered={false}
              title={"Our story"}
              bodyStyle={{ padding: "25px" }}
            >
              <Row>
                <Col
                  xl={{ span: 10, offset: 1 }}
                  lg={{ span: 10, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <img
                    alt="Tracy"
                    src={familyPic}
                    className="img-about"
                    style={{
                      // marginLeft: "20px",
                      // // float: "right",
                      // width: "45%",
                      // padding: " 1%",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </Col>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <p className="margin-aboutus">
                    That's my Town is the brain child of its founder Tracy
                    Barber, a 15 year veteran of the industry. Tracy was tired
                    of seeing the major consumer home search engines not being
                    100% transparent with the public. Tracy felt, that while
                    these websites were cleverly run, they ultimately led
                    consumers down a path where they didn’t know who they were
                    speaking with when making inquiries on listings, often times
                    speaking with agents who had no knowledge about the property
                    and getting bombarded with calls from multiple agents.
                  </p>

                  <p>
                    Tracy saw a different future for home searching and a more
                    transparent approach to buying and selling homes. He noticed
                    a huge gap of organized online opportunities in the market.
                    Currently there exists MLS’s across the country which feed
                    thousands of websites with MLS listed properties
                    universally. Then there are millions of off MLS
                    opportunities that exist in the world of real estate which
                    remain highly un-organized. There exists no major unified
                    source of off MLS opportunities in the market today and this
                    is a major problem for the entire industry.
                  </p>

                  <p>
                    Currently the only major option that exists for sellers is
                    listing in MLS and opening their home to anyone, including
                    buyers who’s buying scenario may not be the right fit for
                    the sellers situation. Second to MLS a seller will only find
                    random for sale by owner sites and iBuyer programs where the
                    seller sells their home to a company like Zillow. This
                    doesn’t create an open opportunity to create competitive
                    offer situations for sellers who feel MLS is not right for
                    them, or not right right now.
                  </p>

                  <p>
                    Tracy believes that there is a whole world of possibilities
                    for sellers, and agents alike, that have not had an
                    organized portal to achieve their goals. The
                    That's my Town portal allows for competitive off MLS bid
                    situations for sellers who may not feel MLS is the right
                    path for them. An example of this would include a seller who
                    has inherited a home needing a lot of repair and trash
                    removal who would prefer to sell on cash terms and in a
                    discrete manner. Why should a seller who does not want to be
                    on MLS have no other options to sell their home on their
                    terms and time frame and be able to still market it to a
                    broader world than just word of mouth or quietly finding one
                    investor who knows they won’t be competing with other
                    investors?{" "}
                  </p>

                  <p>
                    Why should a seller not be able to create a private sale
                    listing with their terms and time frame defined so that they
                    could be found by a buyer who may have a matching need? One
                    great example of this is a seller who wants to downsize and
                    can’t find a home they like yet. This seller may not want to
                    just sit on MLS adding days on market to the listing. What
                    if they were able to list their home privately themselves or
                    through their agent on an off MLS network? What if a buyer
                    in the same, but opposite scenario, wants to upsize and is
                    able to do the same? What if they found a perfect swap
                    scenario and could facilitate a swap sale and purchase
                    without all the stress of listing on MLS and having to show
                    it to buyers who don’t fit the situation? This is all
                    possible and much more with an organized network like
                    That's my Town that is built to help connect agents and
                    consumers in new ways.
                  </p>

                  <p>
                    This is where the founder, Tracy Barber, sees unlimited
                    possibilities with a national real estate website built by
                    agents and trusted by consumers. That's my Town is
                    changing the game of real estate and we hope you will join
                    us to build a brighter future for the real estate industry!
                  </p>
                </Col>
                {/* </p> */}
              </Row>
            </Card>
          </Col>
        </Row>

        <Row style={{ backgroundColor: "#f9faff", marginTop: "2.5vw" }}>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            style={{
              // padding: "25px",
              marginTop: "2.5vw",
              marginBottom: "2.5vw",
            }}
          >
            <Row gutter={[24, 24]}>
              <Col
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Card
                  hoverable
                  bordered={false}
                  title={null}
                  headerStyle={{
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                  bodyStyle={{ padding: "25px" }}
                >
                  <Divider>
                    <h2>QUALITY</h2>
                  </Divider>
                  <p style={{ textAlign: "center" }}>
                    “We take the quality of our data very seriously. MLS feeds
                    are checked and updated for accuracy every 15 minutes
                    inclusive of changes in status. Manually entered listings
                    are also carefully reviewed prior to being approved.”
                  </p>
                </Card>
              </Col>
              <Col
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Card
                  hoverable
                  bordered={false}
                  title={null}
                  headerStyle={{
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                  bodyStyle={{ padding: "25px" }}
                >
                  <Divider>
                    <h2>RESPONSIBILITY</h2>
                  </Divider>
                  <p style={{ textAlign: "center" }}>
                    “We believe it is our core responsibility to never deceive
                    the public or use smoke and mirrors methods to sell
                    advertising. It is our duty to be the trusted real estate
                    resource for professionals and consumers alike.”
                  </p>
                </Card>
              </Col>
              <Col
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Card
                  hoverable
                  bordered={false}
                  title={null}
                  headerStyle={{
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                  bodyStyle={{ padding: "25px" }}
                >
                  <Divider>
                    <h2>INNOVATION</h2>
                  </Divider>
                  <p style={{ textAlign: "center" }}>
                    “We are always innovating! In fact we are already working on
                    a far advanced 2.0 version of That's my Town that will be
                    launched soon. We also want your feedback because this site
                    is about you!
                  </p>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col
            xl={{ span: 7, offset: 5 }}
            lg={{ span: 10, offset: 2 }}
            md={{ span: 11, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            style={{
              marginTop: "2.5vw",
              marginBottom: "2.5vw",
              padding: "1vw",
            }}
            bodyStyle={{ padding: "0px" }}
          >
            <Card
              bordered={false}
              title={null}
              bodyStyle={{ padding: "0px" }}
              style={{ background: "#FFF", borderColor: "black" }}
              cover={
                <img
                  alt="Tracy"
                  src={userPic}
                  className="img-about"
                  style={{ objectFit: "cover", padding: "3%" }}
                />
              }
            ></Card>
          </Col>
          <Col
            xl={{ span: 7 }}
            lg={{ span: 9, offset: 1 }}
            md={{ span: 10, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            style={{
              marginTop: "2.5vw",
              marginBottom: "2.5vw",
              padding: "1vw",
            }}
          >
            <h3 className="text-about" style={{ marginTop: "1vw" }}>
              I am deeply committed to pioneering changes in the real estate
              industry that bring more transparency to the consumer, give them
              clear choices on who they want to interact and contract with, and
              breathe life back into the careers of real estate agents
              nationwide.
            </h3>
            <h3 className="text-about">
              Agents nationwide have spent millions of dollars on advertising
              with the big home search giants only to be placed in front of
              consumers who don’t know who they are and most of the time had no
              interest speaking with them.
            </h3>
            <h3 className="text-about">
              It’s time for change and That's my Town is the vehicle to drive
              this change to a brighter future for real estate.
            </h3>
            <h3 className="text-about">Sincerely,</h3>
            <h3 className="text-about">Tracy Barber,</h3>
            <h3 className="text-about">Founder,</h3>
            <h3 className="text-about">HomeListedBy.com Inc.</h3>
          </Col>
        </Row>
      </div>
    );
  }
}
