import React, { Component } from "react";
import "antd/dist/antd.css";
import Login from "./Login";
import {
  Form,
  Input,
  Popover,
  Button,
  Row,
  Col,
  Checkbox,
  message,
  Modal,
  Divider,
  Drawer,
  notification,
  Select,
} from "antd";
import Amplify, { Auth } from "aws-amplify";
import AwsConfig from "../config/AwsConfig";
import AppConfig from "../config/AppConfig";
import { withRouter, Link } from "react-router-dom";
import { getDetails, noAuthadd, noAuthlist, usersList } from "../api/Apis";
import NumberFormat from "react-number-format";
import { withAppContext } from "../store/contextStore";
import SignupModal from "../common/SignupModal";
// import logo from "../images/logo.png";
import logo from "../images/loginLogo.png";
import { CloseOutlined } from "@ant-design/icons";
import SigninWithGoogle from "./SigninWithGoogle";
import SigninWithFacebook from "./SigninWithFacebook";

Amplify.configure(AwsConfig);

const { Option } = Select;
export class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {
        userProfile: "B",
      },
      errors: "",
      loading: false,
      validatePassword: "",
      signUpResponseData: "",
      visible: false,
      password: "",
      email: "",
      loginPopupTrue: false,
      visibleTerms: false,
      allUsersList: [],
      referedById: "",
      referedByName: "",
      userProfile: "",
      accountCreateWithEmail: false,
    };
  }

  componentDidMount() {
    this.getAllUsers();
  }

  getAllUsers = async () => {
    try {
      const usersList = await noAuthlist("getUsers");
      let finalUsersList = usersList.data.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName,
        };
      });
      if (usersList.success) {
        this.setState({
          allUsersList: finalUsersList,
        });
      } else {
        notification["error"]({
          message: usersList.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: error.message,
      });
    }
  };

  showDrawer = () => {
    this.setState({
      visibleTerms: true,
    });
  };

  onClose = () => {
    this.setState({
      visibleTerms: false,
    });
  };

  handleOnChange = (element, value) => {
    if (element === "password") {
      const valid =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
          value
        );
      if (valid) {
        this.setState({ validatePassword: true });
      } else if (value === "") {
        this.setState({ validatePassword: "" });
      } else {
        this.setState({ validatePassword: false });
      }
    }
    if (element === "mlsId") {
      this.setState({
        userDetails: {
          ...this.state.userDetails,
          [element]: value.toUpperCase(),
        },
      });
    }
    if (element !== "mlsId" && element !== "email") {
      this.setState({
        userDetails: {
          ...this.state.userDetails,
          [element]: value,
        },
      });
    }
    if (element === "email") {
      this.setState({
        userDetails: {
          ...this.state.userDetails,
          [element]: value.toLowerCase(),
        },
      });
    }
  };

  handlePhone = (e) => {
    // console.log(e.target.value);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onFinish = async (e) => {
    if (e.remember === false) {
      notification["error"]({
        message: "Warning.!",
        description: "Please accept our Terms and Conditions",
      });
      return;
    }

    if (
      this.state.userProfile === "" ||
      this.state.userProfile === undefined ||
      this.state.userProfile === null
    ) {
      notification["error"]({
        message: "Warning.!",
        description: "Please select the user type",
      });
      return;
    }

    this.setState({
      loading: true,
    });
    let lowerEmail = e.email.toLowerCase();
    try {
      await Auth.signUp(lowerEmail, e.password).then((data) => {
        this.saveUser(data, lowerEmail);
        this.setState({
          loginPopupTrue: false,
          visible: true,
          signUpResponseData: data,
          email: lowerEmail,
          password: e.password,
          loading: false,
        });
      });
    } catch (error) {
      message.error(error.message);
      this.setState({
        visible: false,
        loading: false,
      });
    }
  };

  handleOk = async (e) => {
    try {
      this.setState({
        loading: true,
      });
      await Auth.confirmSignUp(this.state.email, e.code).then(
        async (result) => {
          this.doLogin();
        }
      );
    } catch (error) {
      this.setState({
        loading: false,
      });
      message.error("Invalid Confirm Code");
    }
  };

  doLogin = async () => {
    try {
      const user = await Auth.signIn(this.state.email, this.state.password);
      const userDetails = Object.assign(
        {},
        {
          token: user.signInUserSession.idToken.jwtToken,
          userid: user.username,
        }
      );
      this.props.context.setUser(userDetails, false);
      this.gotoApp(user.username);
    } catch (err) {
      message.error("Error: unable to login");
      this.setState({
        loading: false,
        errors: err.message,
      });
    }
  };

  saveUser = async (userKey, lowerEmail) => {
    const { userDetails, userProfile, referedByName, referedById } = this.state;
    const checkBuyerProfile = await usersList("buyerProfileSignup");

    const buyerAgentInfo = (checkBuyerProfile?.data || [])?.find(
      (eachBuyer) =>
        eachBuyer?.buyerEmail?.toLowerCase() === lowerEmail
    );
    const data = {
      entityData: {
        id: userKey.userSub,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        phone: userDetails.phone,
        email: userDetails.email,
        userProfile: userProfile,
        recordStatus: true,
        mlsId: userDetails.mlsId,
        referedByName: referedByName,
        referedById: referedById,
        agentInfo: {
          agentEmail: buyerAgentInfo?.agentEmail
            ? buyerAgentInfo?.agentEmail
            : "",
          agentName: buyerAgentInfo?.agentName ? buyerAgentInfo?.agentName : "",
        },
        addUserName: buyerAgentInfo?.agentName ? buyerAgentInfo?.agentName : "",
      },
    };

    noAuthadd(AppConfig["entityNames"]["users"], data).then((result) => {
      if (result.success) {
        // this.props.context.setUserDetails(data["entityData"]);
        this.setState({
          loading: false,
        });
        message.success("User created successfully.");
      } else {
        this.setState({
          loading: false,
        });
        message.error(result.errors);
        console.log(result.errors);
      }
    });
  };

  handleToRedirectPath = () => {
    this.props.history.push("/homes");
  };

  gotoApp = (id) => {
    getDetails(AppConfig["entityNames"]["users"], id).then((result) => {
      if (result.success) {
        this.props.context.setUserDetails(result.data);
        localStorage.setItem("userDetails", JSON.stringify(result.data));
        localStorage.setItem("userLogin", JSON.stringify(true));
        localStorage.setItem(
          "userLoginTime",
          JSON.stringify(new Date().getTime())
        );
        if (result.data.userProfile === "B") {
          if (this.props.goTopreviousPage) {
            window.location.reload();
          } else {
            this.props.history.push(`/viewProfile/${result.data.id}`);
          }
        } else {
          if (this.props.goTopreviousPage) {
            window.location.reload();
          } else {
            this.props.history.push("/homes");
          }
        }
      } else {
        message.error(result.errors);
        this.setState({
          loading: false,
        });
      }
    });
  };

  content = (
    <div>
      <p>-Minimum password length: 6</p>
      <p>-Required at least one uppercase letter</p>
      <p>-Required at least one lowercase letter</p>
      <p>-Required at least one number</p>
      <p>-Required at least one nonalphanumeric character</p>
    </div>
  );

  hanleLoginPopUp = () => {
    if (window.location.pathname === "/register") {
      this.props.history.push("/login");
    } else {
      if (!this.props.showLoginSiderForm && this.props.questionPage) {
        this.props.handleToshowLoginSider();
      } else {
        this.setState({
          loginPopupTrue: true,
        });
        this.props.closePreviouspopUp();
      }
    }
  };

  handleonCancel = () => {
    this.setState({
      loginPopupTrue: false,
    });
  };

  onSelect = (value, option) => {
    this.setState({
      referedById: option.value,
      referedByName: option.label,
    });
  };

  handleUserType = (option) => {
    this.setState({
      userProfile: option,
    });
  };

  handleLoginForm = () => {
    this.props.userHasAuthenticated(true);
  };

  handleWithEmail = () => {
    this.setState({
      accountCreateWithEmail: true,
    });
  };

  signupForm = () => {
    return (
      <>
        <Row className="register" style={{ padding: "0.5%" }}>
          <Col
            xl={{ span: 18, offset: 5 }}
            lg={{ span: 18, offset: 5 }}
            md={{ span: 18, offset: 5 }}
            xs={{ span: 17, offset: 5 }}
            sm={{ span: 17, offset: 5 }}
          >
            <Button
              type="text"
              className="register-text"
              onClick={this.hanleLoginPopUp}
            >
              Already have an account? Login here
            </Button>
          </Col>
          {window.location.pathname === "/register" ? (
            <Col
              xl={{ span: 1 }}
              lg={{ span: 1 }}
              md={{ span: 1 }}
              xs={{ span: 1 }}
              sm={{ span: 1 }}
              style={{ textAlign: "left" }}
            >
              <Link to="/">
                <CloseOutlined className="close-btn" />
              </Link>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
            className="mobile-view-display"
          >
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                className="logo-header-login"
                style={{ marginBottom: "0px" }}
              />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col
            xl={{ span: 12, offset: 6 }}
            lg={{ span: 21, offset: 2 }}
            md={{ span: 21, offset: 2 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            {/* <Form.Item style={{ marginBottom: "0px" }}>
              <h1 className="login-form-center register-form-center-weight login-margin-top">
              </h1>
            </Form.Item> */}

            <div className="login-form-center">
              <h1 className="register-form-center-weight login-margin-top">
                Create an account
              </h1>
              <Row>
                <Col
                  xxl={{ span: 12, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 21, offset: 2 }}
                  md={{ span: 21, offset: 2 }}
                  sm={{ span: 24, offset: 1 }}
                  xs={{ span: 24, offset: 1 }}
                >
                  <SigninWithFacebook
                    buttonText={"Sign Up"}
                    handleToRedirectPath={this.handleToRedirectPath}
                    getLoginData={this.gotoApp}
                  />
                </Col>
                <Col
                  xxl={{ span: 12, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                  lg={{ span: 21, offset: 2 }}
                  md={{ span: 21, offset: 2 }}
                  sm={{ span: 24, offset: 1 }}
                  xs={{ span: 24, offset: 1 }}
                >
                  <SigninWithGoogle
                    buttonText={"Sign Up"}
                    handleToRedirectPath={this.handleToRedirectPath}
                    getLoginData={this.gotoApp}
                  />
                </Col>
              </Row>
            </div>

            <Divider> or </Divider>

            {/* 
            <Row style={{ paddingTop: "10px", justifyContent: "end" }}>
              <Col>
                <SignInWithFacebook
                  handleToRedirectPath={this.handleToRedirectPath}
                />
              </Col>
              <Col>
                <button
                  class="loginBtn-email loginBtn--email"
                  onClick={this.handleWithEmail}
                  style={{ cursor: "pointer" }}
                >
                  Create an account
                </button>
                <Button type="primary"></Button>
              </Col>
            </Row> */}

            {/* <h1 className="login-form-center register-form-center-weight login-margin-top">
              {" "}
            </h1> */}

            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={this.onFinish}
            >
              <div className="register-form">
                <Form.Item>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder="I am "
                    onChange={this.handleUserType}
                  >
                    <Option value="B">Buyer</Option>
                    <Option value="S">Seller</Option>
                    <Option value="AG">Agent</Option>
                    <Option value="L">Lender</Option>
                    <Option value="V">Vendor</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="First Name"
                    value={this.state.userDetails.firstName}
                    onChange={(e) =>
                      this.handleOnChange("firstName", e.target.value)
                    }
                    className="form-input"
                  />
                </Form.Item>
                <Form.Item
                  name="username1"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Last Name"
                    value={this.state.userDetails.lastName}
                    onChange={(e) =>
                      this.handleOnChange("lastName", e.target.value)
                    }
                    className="form-input"
                  />
                </Form.Item>

                {/*
                Don't Delete
                <Form.Item name="referedBy">
                  <Select
                      className="form-input"
                    showSearch
                    optionFilterProp="children"
                    options={this.state.allUsersList}
                    onSelect={this.onSelect}
                    placeholder="Refered by"
                    filterOption={(inputValue, option) =>
                      option.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    size="large"
                  ></Select>
                </Form.Item>
                Don't Delete
                */}

                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <NumberFormat
                    className="ant-input-number"
                    placeholder="Phone"
                    value={this.state.userDetails.phone}
                    onChange={(e) =>
                      this.handleOnChange("phone", e.target.value)
                    }
                    format="(###) ###-####"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your e-mail!",
                    },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="Email"
                    value={this.state.userDetails.email}
                    onChange={(e) =>
                      this.handleOnChange("email", e.target.value)
                    }
                    style={{ textTransform: "lowercase" }}
                    className="form-input"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  // hasFeedback
                  // validateStatus={
                  //   this.state.validatePassword === "" &&
                  //   this.state.validatePassword === true
                  //     ? "success"
                  //     : "warning"
                  // }
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="password"
                    value={this.state.userDetails.Password}
                    onChange={(e) =>
                      this.handleOnChange("password", e.target.value)
                    }
                    className="form-input"
                  />
                </Form.Item>

                <Form.Item className="password-criteria">
                  <Popover content={this.content} title="Password Criteria">
                    <Button
                      type="text"
                      style={{
                        color: "#39acda",
                        marginLeft: "-15px",
                      }}
                    >
                      Password Criteria
                    </Button>
                  </Popover>
                </Form.Item>

                {this.state.userDetails.userProfile === "AG" && (
                  <Form.Item
                    name="mlsId"
                    rules={[
                      {
                        required: true,
                        message: "Please input your MLSID!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="MLSID"
                      value={this.state.userDetails.mlsId}
                      onChange={(e) =>
                        this.handleOnChange("mlsId", e.target.value)
                      }
                      style={{ width: "100%", textTransform: "uppercase" }}
                      className="form-input"
                    />
                  </Form.Item>
                )}
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox checked>
                    <span className="terms">I agree with </span>
                    <Button
                      type="text"
                      onClick={() =>
                        this.setState({
                          visibleTerms: true,
                        })
                      }
                      style={{
                        color: "#39acda",
                        marginLeft: "-15px",
                      }}
                    >
                      Terms & Conditions
                    </Button>
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.state.loading}
                    className="login-form-button"
                  >
                    Create an account
                  </Button>
                </Form.Item>
              </div>
            </Form>
            {this.state.visible ? (
              <div>
                <Modal
                  visible={this.state.visible}
                  title={
                    <p
                      className="font-family-style font-medium-size"
                      style={{ color: "blue", margin: "0px" }}
                    >
                      Confirm SignUp
                    </p>
                  }
                  onCancel={this.handleCancel}
                  maskClosable={false}
                  className="modal-black-close"
                  footer={null}
                >
                  <div style={{ padding: "5px 10px" }}>
                    <span className="font-family-style text-size padding register-confirm">
                      We have sent you a confirmation code to your email. Please
                      enter the code below to complete your signup. Thank you.
                    </span>
                  </div>
                  <Form
                    className="padding font-family-style text-size"
                    onFinish={this.handleOk}
                    style={{ padding: "5px 10px" }}
                  >
                    <Form.Item
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input the code.",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Please input the code."
                        className="form-input"
                        maxLength="6"
                      />
                    </Form.Item>
                    <Divider></Divider>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.state.loading}
                      >
                        <span className="align">Submit</span>
                      </Button>
                    </Form.Item>
                  </Form>
                </Modal>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Drawer
          title="Terms & Conditions"
          placement="bottom"
          height={"100%"}
          mask={true}
          closable={true}
          getContainer={false}
          visible={this.state.visibleTerms}
          onClose={this.onClose}
          headerStyle={{ textAlign: "center" }}
          bodyStyle={{ paddingBottom: 80, backgroundColor: "#eff2f5" }}
          style={{ position: "absolute" }}
        >
          <Row>
            <Col span={24} style={{ padding: "20px" }}>
              <Row>
                <p style={{ textAlign: "justify" }}>
                  I understand that by creating an account and adding property
                  listings it will not mean it has been listed in MLS and will
                  only be listed on That's my Town. I understand that as a
                  consumer creating an account and submitting listings on the
                  network that this does not allow for the property to be
                  syndicated to thousands of search sites and it will only be
                  listed on That's my Town. I also understand that in order to
                  maximize exposure on my posted listings it may be in my best
                  interests to consider listing it on MLS with a Realtor or seek
                  an entry-only service.
                </p>
              </Row>
              <Row>
                <p style={{ textAlign: "justify" }}>
                  As a licensed real estate agent/broker entering listings on
                  this portal I understand that I am subject to all MLS and
                  Realtor board rules and regulations which may require me to
                  list this property in MLS. By entering listings on this site I
                  am attesting that I am in full compliance with all MLS &
                  Realtor board rules and regulations and have any required
                  contracts in place at the time of posting listings which may
                  include but not limited to a listing contract, non-mls listing
                  agreement, delayed listing forms, etc.
                </p>
              </Row>
              <Row>
                <p style={{ textAlign: "justify" }}>
                  thatsmytown.com will not be responsible for any violations
                  by an agent or consumer due to false advertising or lack of
                  required contracts, disclosures, etc.
                </p>
              </Row>
            </Col>
          </Row>
        </Drawer>
      </>
    );
  };

  render() {
    const { showAdd } = this.props;
    return (
      <div style={{ overflow: "hidden", backgroundColor: "#eff2f5" }}>
        {showAdd === false ? (
          <Row>
            <Col xs={24}>{this.signupForm()}</Col>
          </Row>
        ) : (
          <Row>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Login />
            </Col>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              {this.signupForm()}
            </Col>
          </Row>
        )}

        <SignupModal
          enableSignupForm={
            !this.props.comingFromDrawer && this.state.loginPopupTrue
          }
          handleonCancel={this.handleonCancel}
          showLoginPopup
          goTopreviousPage={this.props.goTopreviousPage}
        />
      </div>
    );
  }
}

export default withAppContext(withRouter(RegisterForm));
