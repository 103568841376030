import React, { Component } from "react";
import {
  Layout,
  Button,
  Row,
  Select,
  Col,
  Card,
  Avatar,
  notification,
} from "antd";
import "antd/dist/antd.css";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { withRouter, Link } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import axios from "axios";

import BuyerProfileContext from "./BuyerProfileContext";
import { withAppContext } from "../../store/contextStore";
import { noAuthadd } from "../../api/Apis";
import HomesCarousel from "../../common/HomesCarousel";
import buildingLogo from "../../images/buildingLogo.png";
import buyHome from "../../images/buyHome.png";
import sellHome from "../../images/sellHome.png";
import agentHome from "../../images/agentHome.png";
import offerHome from "../../images/offerHome.png";
import arrowIcon from "../../../src/images/ArrowIcon.svg";
import arroworangeIcon from "../../../src/images/ArrowOrangeIcon.svg";

const { Content } = Layout;
const { Option } = Select;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      citySearch: "",
      displayCityState: "",
      // initialLoading: true,

      cities: {
        searchLocation: [],
        type: "",
        citiesList: [],
      },
    };
  }

  componentDidMount = () => {
    this.getIpAddress();
  };

  getIpAddress = () => {
    //   setTimeout(() => {
    //     this.setState({
    //       initialLoading: false,
    //     });
    //   }, 3500);

    axios
      .get(process.env.REACT_APP_IPADDRESS)
      .then((result) => {
        this.addIpAddress(result.data);
      })
      .catch((error) => console.log("Error :", error));
  };

  addIpAddress = async (data) => {
    const payload = {
      entityData: data,
    };
    try {
      const response = await noAuthadd("ipAddressAnalytics", payload);
      console.log(response);
    } catch (error) {
      console.log("Error :", error);
    }
  };

  goTo = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.props.history.push(`/register`);
  };

  handleSearches = (values) => {
    this.setState({ search: values });
  };

  handleSingleSearch = (address) => {
    let cityValue = "";
    let stateValue = "";
    let newLocations = [];
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
        }
        const stateCodes = JSON.parse(
          localStorage.getItem("stateCodesFromAdmin")
        );
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateValue.toLowerCase() ||
            restState.stateType.toLowerCase() === stateValue.toLowerCase()
        );
        if (setVendor && setVendor.length > 0) {
          let newSearchLocation = {
            cityName: cityValue,
            stateName: stateValue,
            locationType: "location",
          };
          newLocations.push(newSearchLocation);
          this.setState({
            cities: {
              searchLocation: newLocations,
              type: "location",
              citiesList: [
                ...this.state.cities.citiesList,
                `${stateValue}:${cityValue}`,
              ],
            },
          });
        } else {
          notification["error"]({
            message: "Please enter valid city...",
            description: "You can search cities from MA, CT, RI, NH states",
            placement: "topLeft",
          });
        }
      })
      .catch((error) => console.error("Error", error));
  };

  enterSeacheHomePage = () => {
    this.props.history.push("/homes");
  };

  enterSeacheBuyerPage = () => {
    this.props.history.push("/buyers");
  };

  handleSearchNow = () => {
    const { search, citySearch, displayCityState, cities } = this.state;
    if (cities && cities.citiesList && cities.citiesList.length > 0) {
      localStorage.setItem("selectedCities", JSON.stringify(cities));
    }
    const filterCitySearch = {
      cities: citySearch,
    };
    // console.log(filterCitySearch, "iii");
    if (filterCitySearch) {
      if (search === "Off MLS Homes") {
        this.props.history.push("/offMLSHomes");
        this.props.context.setCity(citySearch, displayCityState);
      } else if (search === "Buyers") {
        this.props.history.push("/buyers");
      } else {
        this.props.context.setCity(citySearch, displayCityState);
        this.props.history.push("/homes");
      }
      if (filterCitySearch && filterCitySearch.cities !== "") {
        this.props.context.setFilterData(filterCitySearch);
      }
    }
  };

  render() {
    return (
      <>
        {/* <Loading enableLoading={this.state.initialLoading} /> */}
        <Layout style={{ width: "100%", height: "100%" }}>
          <Content style={{ backgroundColor: "white" }}>
            <Row style={{ height: "50vw" }} className="img-width">
              <Card style={{ position: "relative" }} className="img-bg">
                <div className="img-text">
                  <Col span={24}>
                    <Row>
                      <h1 className="home-text">
                        The better way to search for your next home & sell your
                        current home
                      </h1>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col xl={12} lg={12} md={24} xs={24} sm={24}>
                            <Row className="home-margin-row">
                              <Col
                                xl={{ span: 2 }}
                                lg={{ span: 2 }}
                                md={{ span: 2 }}
                                sm={{ span: 2 }}
                                xs={{ span: 2 }}
                                className="check-icon-home"
                              >
                                <CheckCircleOutlined />
                              </Col>
                              <Col
                                xl={{ span: 21, offset: 0 }}
                                lg={{ span: 21, offset: 0 }}
                                md={{ span: 21, offset: 0 }}
                                sm={{ span: 21, offset: 0 }}
                                xs={{ span: 21, offset: 1 }}
                              >
                                <span className="home-text-check">
                                  Search for on and off MLS homes for sale!
                                </span>
                              </Col>
                            </Row>
                            <Row className="home-margin-row2">
                              <Col
                                xl={{ span: 2 }}
                                lg={{ span: 2 }}
                                md={{ span: 2 }}
                                sm={{ span: 2 }}
                                xs={{ span: 2 }}
                                className="check-icon-home"
                              >
                                <CheckCircleOutlined />
                              </Col>
                              <Col
                                xl={{ span: 21, offset: 0 }}
                                lg={{ span: 21, offset: 0 }}
                                md={{ span: 21, offset: 0 }}
                                sm={{ span: 21, offset: 0 }}
                                xs={{ span: 21, offset: 1 }}
                              >
                                <span className="home-text-check">
                                  Find out who the listing agent is and choose
                                  who you want to talk to and when!
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={12} lg={12} md={24} xs={24} sm={24}>
                            <Row className="home-margin-row1">
                              <Col
                                xl={{ span: 2 }}
                                lg={{ span: 2 }}
                                md={{ span: 2 }}
                                sm={{ span: 2 }}
                                xs={{ span: 2 }}
                                className="check-icon-home"
                              >
                                <CheckCircleOutlined />
                              </Col>
                              <Col
                                xl={{ span: 21, offset: 0 }}
                                lg={{ span: 21, offset: 0 }}
                                md={{ span: 21, offset: 0 }}
                                sm={{ span: 21, offset: 0 }}
                                xs={{ span: 21, offset: 1 }}
                              >
                                <span className="home-text-check">
                                  List your property for sale on the network for
                                  FREE
                                </span>
                              </Col>
                            </Row>
                            <Row className="home-margin-row2">
                              <Col
                                xl={{ span: 2 }}
                                lg={{ span: 2 }}
                                md={{ span: 2 }}
                                sm={{ span: 2 }}
                                xs={{ span: 2 }}
                                className="check-icon-home"
                              >
                                <CheckCircleOutlined />
                              </Col>
                              <Col
                                xl={{ span: 21, offset: 0 }}
                                lg={{ span: 21, offset: 0 }}
                                md={{ span: 21, offset: 0 }}
                                sm={{ span: 21, offset: 0 }}
                                xs={{ span: 21, offset: 1 }}
                              >
                                <span className="home-text-check">
                                  Sell on your terms and timeframe without the
                                  pressure of listing on MLS
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <div
                        style={{
                          position: "absolute",
                          marginTop: "40px",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Button
                          onClick={() => this.goTo()}
                          type="primary"
                          danger
                          className="footer-button"
                          size="large"
                        >
                          <span className="footer-buttonText">
                            JOIN FOR FREE
                          </span>
                        </Button>
                      </div>
                    </Row>

                    <Row
                      style={{
                        backgroundColor: "#FFF",
                        padding: "10px 20px",
                        marginTop: "100px",
                      }}
                    >
                      <Col
                        xl={{ span: 7, offset: 0 }}
                        lg={{ span: 7, offset: 0 }}
                        md={{ span: 7, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                        className="home-margin-btn"
                      >
                        <Select
                          onChange={this.handleSearches}
                          className="home-page-search"
                          placeholder="I am looking for"
                          bordered={false}
                        >
                          <Option value="Buyers">Buyers</Option>
                          <Option value="Homes">Homes</Option>
                          <Option value="Off MLS Homes">Off MLS Homes</Option>
                        </Select>
                      </Col>
                      <Col
                        xl={{ span: 10, offset: 1 }}
                        lg={{ span: 10, offset: 1 }}
                        md={{ span: 8, offset: 1 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                        className="home-margin-btn"
                      >
                        <div className="openHeader-Address">
                          <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_MAPS_KEY}
                            autocompletionRequest={{
                              types: ["(cities)"],
                              componentRestrictions: {
                                country: ["us"],
                              },
                            }}
                            selectProps={{
                              placeholder: "Enter city name",
                              onChange: (result) => {
                                this.handleSingleSearch(result);
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col
                        xl={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        md={{ span: 7, offset: 1 }}
                        sm={{ span: 24, offset: 0 }}
                        xs={{ span: 24, offset: 0 }}
                        className="home-margin-btn"
                      >
                        <Button
                          type="primary"
                          onClick={this.handleSearchNow}
                          className="home-Btn-primary"
                        >
                          Search Now{" "}
                          {
                            <img
                              src={arrowIcon}
                              alt={arrowIcon}
                              style={{ marginLeft: "15px" }}
                            />
                          }
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Card>
            </Row>

            <Row
              gutter={[16, 16]}
              justify="center"
              style={{
                marginLeft: "0px",
                marginRight: "0px",
                background: "#F9FAFF",
              }}
            >
              <Col
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 6 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <Card bordered={false} className="card-BuyHome" hoverable>
                  <Row justify="center">
                    <Avatar
                      shape="square"
                      size={80}
                      icon={<img src={buyHome} alt="buyHome" />}
                      style={{ background: "#F9FAFF" }}
                    />
                  </Row>
                  <Row justify="center">
                    <h2
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        marginTop: "5px",
                      }}
                    >
                      Buy a Home
                    </h2>
                  </Row>
                  <Row justify="center">
                    <p
                      style={{
                        textAlign: "justify",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Find your dream home by browsing both MLS and Off MLS
                      listings. You decide if you want to talk to the seller,
                      listing agent, or a local buyers' agent.{" "}
                    </p>
                  </Row>
                </Card>
              </Col>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 6 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <Card bordered={false} className="card-BuyHome" hoverable>
                  <Row justify="center">
                    <Avatar
                      shape="square"
                      size={80}
                      icon={<img src={sellHome} alt="" />}
                      style={{ background: "#F9FAFF" }}
                    />
                  </Row>
                  <Row justify="center">
                    <h2
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        marginTop: "5px",
                      }}
                    >
                      Sell a Property
                    </h2>
                  </Row>
                  <Row justify="center">
                    <p
                      style={{
                        textAlign: "justify",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Sellers can add off-market opportunities without the
                      pressure to sell. Sellers can also search for matching
                      buyer profiles to look for matches such as cash buyers,
                      investors, etc.
                    </p>
                  </Row>
                </Card>
              </Col>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 6 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <Card bordered={false} className="card-BuyHome" hoverable>
                  <Row justify="center">
                    <Avatar
                      shape="square"
                      size={80}
                      icon={<img src={agentHome} alt="" />}
                      style={{ background: "#F9FAFF" }}
                    />
                  </Row>
                  <Row justify="center">
                    <h2
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        marginTop: "5px",
                      }}
                    >
                      Agent Services
                    </h2>
                  </Row>
                  <Row justify="center">
                    <p
                      style={{
                        textAlign: "justify",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Agents can create sell profiles for coming soon listings,
                      private sales, would sell for right fit sellers, and more.
                    </p>
                  </Row>
                </Card>
              </Col>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 6 }}
                md={{ span: 6 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <Card bordered={false} className="card-BuyHome" hoverable>
                  <Row justify="center">
                    <Avatar
                      shape="square"
                      size={80}
                      icon={<img src={offerHome} alt="" />}
                      style={{ background: "#F9FAFF" }}
                    />
                  </Row>
                  <Row justify="center">
                    <h2
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        marginTop: "5px",
                      }}
                    >
                      Business Services
                    </h2>
                  </Row>
                  <Row justify="center">
                    <p
                      style={{
                        textAlign: "justify",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Sign up as a vendor and offer your business services to
                      the real estate market. With buyers, sellers and agents
                      all in one place, see your sales grow exponentially.{" "}
                    </p>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row className="New-listing-margin" style={{ padding: "20px" }}>
              <Col
                xl={{ span: 8, offset: 1 }}
                lg={{ span: 8, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
              >
                <p class="sale-heading">New Listings for Sale</p>
              </Col>
              <Col
                xl={{ span: 13, offset: 1 }}
                lg={{ span: 13, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
              >
                <p class="sale-content">
                  Search all MLS listed and off MLS homes for sale. Create a
                  FREE account to add buyer and seller profiles, save favorite
                  homes, get alerts when new properties hit the market, and
                  more.
                </p>
                <Link onClick={() => this.goTo()}>
                  <p style={{ color: "#F75B47", fontSize: "20px" }}>
                    Learn more{" "}
                    <img
                      src={arroworangeIcon}
                      alt=""
                      style={{ marginLeft: "5px" }}
                    />
                  </p>
                </Link>
              </Col>
            </Row>

            <Col
              span={24}
              style={{ background: " #F9FAFF", paddingBottom: "1vw" }}
            >
              <HomesCarousel whereToShow="homes" limit="20" />
            </Col>

            <Row justify="center">
              <Col style={{ marginTop: "2vw" }}>
                <h3 className="title">Why That's my Town</h3>
                <Col
                  xl={{ span: 22, offset: 1 }}
                  lg={{ span: 22, offset: 1 }}
                  md={{ span: 22, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                >
                  <p className="content">
                    That's my Town offers a better real estate experience for
                    buyers, sellers, and agents on the only fully transparent
                    home search engine.
                  </p>
                </Col>
              </Col>
            </Row>

            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Col>
                <Row className="side-row-top">
                  <Col
                    xl={{ span: 1, offset: 2 }}
                    lg={{ span: 1, offset: 1 }}
                    md={{ span: 1, offset: 1 }}
                    sm={{ span: 1, offset: 1 }}
                    xs={{ span: 1, offset: 1 }}
                  >
                    <div className="background-color">
                      <img
                        src={buildingLogo}
                        alt="space"
                        className="homes-img-width"
                      />
                    </div>
                  </Col>
                  <Col
                    xl={{ span: 8, offset: 0 }}
                    lg={{ span: 8, offset: 1 }}
                    md={{ span: 20, offset: 1 }}
                    sm={{ span: 20, offset: 1 }}
                    xs={{ span: 20, offset: 1 }}
                  >
                    <Row>
                      <p className="side-title">
                      That's my Town is a fully transparent home search site.
                      </p>
                    </Row>
                    <Row>
                      <p className="side-content">
                        You get decide who you want to speak to and when! You
                        decide if you want to speak to the listing agent
                        directly or a local buyers agent who would represent
                        your interests. No more smoke and mirrors and multiple
                        agents calling you from one inquiry.
                      </p>
                    </Row>
                  </Col>
                  <Col
                    xl={{ span: 1, offset: 2 }}
                    lg={{ span: 1, offset: 1 }}
                    md={{ span: 1, offset: 1 }}
                    sm={{ span: 1, offset: 1 }}
                    xs={{ span: 1, offset: 1 }}
                  >
                    <div className="background-color">
                      <img
                        src={buildingLogo}
                        alt="space"
                        className="homes-img-width"
                      />
                    </div>
                  </Col>
                  <Col
                    xl={{ span: 8, offset: 0 }}
                    lg={{ span: 8, offset: 1 }}
                    md={{ span: 20, offset: 1 }}
                    sm={{ span: 20, offset: 1 }}
                    xs={{ span: 20, offset: 1 }}
                  >
                    <Row>
                      <p className="side-title2">Off Market Homes For Sale</p>
                    </Row>
                    <Row>
                      <p className="side-content">
                        Agents and sellers alike can add off market
                        opportunities that you won’t find on other sites. These
                        secret opportunities could be the key to finding your
                        perfect home. Find links and information on the best
                        agents and lenders in your area so you can pick your
                        team to achieve your dream.
                      </p>
                    </Row>
                  </Col>
                </Row>

                <Row className="side-row-top">
                  <Col
                    xl={{ span: 1, offset: 2 }}
                    lg={{ span: 1, offset: 1 }}
                    md={{ span: 1, offset: 1 }}
                    sm={{ span: 1, offset: 1 }}
                    xs={{ span: 1, offset: 1 }}
                  >
                    <div className="background-color">
                      <img
                        src={buildingLogo}
                        alt="space"
                        className="homes-img-width"
                      />
                    </div>
                  </Col>
                  <Col
                    xl={{ span: 8, offset: 0 }}
                    lg={{ span: 8, offset: 1 }}
                    md={{ span: 20, offset: 1 }}
                    sm={{ span: 20, offset: 1 }}
                    xs={{ span: 20, offset: 1 }}
                  >
                    <Row>
                      <p className="side-title1">Create A Listing</p>
                    </Row>
                    <Row>
                      <p className="side-content">
                        Whether you are a seller or an agent you can create sell
                        profiles for coming soon listings, private sales, would
                        sell for right fit sellers, and more.
                      </p>
                    </Row>
                  </Col>
                  <Col
                    xl={{ span: 1, offset: 2 }}
                    lg={{ span: 1, offset: 1 }}
                    md={{ span: 1, offset: 1 }}
                    sm={{ span: 1, offset: 1 }}
                    xs={{ span: 1, offset: 1 }}
                  >
                    <div className="background-color">
                      <img
                        src={buildingLogo}
                        alt="space"
                        className="homes-img-width"
                      />
                    </div>
                  </Col>
                  <Col
                    xl={{ span: 8, offset: 0 }}
                    lg={{ span: 8, offset: 1 }}
                    md={{ span: 20, offset: 1 }}
                    sm={{ span: 20, offset: 1 }}
                    xs={{ span: 20, offset: 1 }}
                  >
                    <Row>
                      <p className="side-title1">Create Buyer Profiles</p>
                    </Row>
                    <Row>
                      <p className="side-content">
                        Buyers and agents can create buyer profiles and receive
                        match alerts when new on and off MLS listings hit the
                        site. Sellers can also search for matching buyer
                        profiles to look for matches such as cash buyers,
                        investors, etc. when you make your profiles public.
                      </p>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <BuyerProfileContext />
            </Col>
          </Content>
        </Layout>
      </>
    );
  }
}

export default withAppContext(withRouter(Home));
