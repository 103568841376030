import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../store/contextStore";
import { Layout, Steps,  Button, notification, Row, Col } from "antd";
import BuyerPropertyDetails from "./AddbuyerProfile/BuyerPropertyDetails";
import BuyerPropertyDetails2 from "./AddbuyerProfile/BuyerPropertyDetails2";
import BuyerPrivateDetails from "./AddbuyerProfile/BuyerPrivateDetails";
import PropertyFeatures from "./Addhomes/PropertyFeatures";
import { Link } from "react-router-dom";
import { add, noAuthgetDetails } from "../../api/Apis";
import { update } from "../../api/Apis";
import Loading from "../../common/Loading";

const { Step } = Steps;

class AddBuyerprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      savingDetails: false,
      buyerHomeDetails: "",
      doBackPage: false,
    };
  }

  prev = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      doBackPage: true,
    });
    // this.setState({ current: this.state.current - 1 });
  };

  next = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // if (this.state.current === 3) {
    //   this.handleAddhome();
    // }
    this.setState({
      doBackPage: false,
    });
  };
  buyerProfile = (e) => {
    if (e && this.state.current !== 3) {
      this.setState({ current: this.state.current + 1 });
    }
  };
  callingApiForBuyerReq = async () => {
    const { buyerHomeDetails, userDetails } = this.props.context.state;
    if (userDetails && userDetails.id) {
      if (
        buyerHomeDetails &&
        buyerHomeDetails.buyerPropertyDetails2.title &&
        (buyerHomeDetails.buyerPropertyDetails2.title !== "" ||
          buyerHomeDetails.buyerPropertyDetails2.title !== "undefined")
      ) {
        this.setState({
          savingDetails: true,
        });
        const buyerData={
          entityData:{
            buyerEmail:buyerHomeDetails&&buyerHomeDetails.buyerPrivateDetails.email,
            agentName:userDetails&&userDetails.firstName + " " + userDetails.lastName,
            agentEmail:userDetails.email
          }
        }

       await add("buyerProfileSignup",buyerData)
        if (
          window.location.pathname ===
          `/add/buyerProfile/${this.props.match.params.id}`
        ) {
          const data = Object.assign(
            {},
            {
              entityData: {
                createdAt: this.props.location.state.createdAt,
                buyerPrivateDetails: buyerHomeDetails.buyerPrivateDetails,
                buyerPropertyDetails: buyerHomeDetails.buyerPropertyDetails,
                buyerPropertyFeatures: buyerHomeDetails.buyerPropertyFeatures,
                buyerPropertyDetails2: buyerHomeDetails.buyerPropertyDetails2,
                userId: userDetails.id,
              },
            }
          );
          try {
            const UpdateHomeDetails = await update(
              "buyers",
              this.props.match.params.id,
              data
            );
            if (UpdateHomeDetails.success) {
              this.setState({
                savingDetails: false,
              });
              notification["success"]({
                message: "Buyer details updated",
              });
              this.props.history.push(`/viewProfile/${userDetails.id}`);
            }
          } catch (error) {
            notification["error"]({
              message: "Something went wrong!",
              description: "Please try again",
            });
          }
        } else {
          const data = Object.assign(
            {},
            {
              entityData: {
                userId: userDetails.id,
                buyerPrivateDetails: buyerHomeDetails.buyerPrivateDetails,
                buyerPropertyDetails: buyerHomeDetails.buyerPropertyDetails,
                buyerPropertyFeatures: buyerHomeDetails.buyerPropertyFeatures,
                buyerPropertyDetails2: buyerHomeDetails.buyerPropertyDetails2,
              },
            }
          );
          try {
            add("buyers", data).then(
              (result) =>
                result.success &&
                this.setState({
                  savingDetails: false,
                })
            );
            notification["success"]({
              message: "New Buyer added",
            });
            if (userDetails.userProfile === "V") {
              this.props.history.push(
                `/sellerBuyer/viewProfile/${userDetails.id}`
              );
            } else {
              this.props.history.push(`/viewProfile/${userDetails.id}`);
            }
          } catch (error) {
            notification["error"]({
              message: "Something went wrong!",
              description: "Please try again",
            });
          }
        }
      } else {
        this.setState({
          errorMsg: "Please input your title",
        });
      }
    } else {
      notification["error"]({
        message: "Something went wrong!",
        description: "Please try again",
      });
    }
  };

  handleAddhome = async () => {
    this.setState({
      savingDetails: true,
    });
    await setTimeout(() => this.callingApiForBuyerReq(), 3000);
  };

  setLoadingFalse = () => {
    this.props.context.setLoading(true);
  };

  newMethod = (e, type) => {
    if (type === "BUYER_PRIVATE_DETAILS") {
      this.props.context.setBuyerHomeDetails(e, "BUYER_PRIVATE_DETAILS");
    } else if (type === "BUYER_PROPERTY_DETAILS") {
      this.props.context.setBuyerHomeDetails(e, "BUYER_PROPERTY_DETAILS");
    } else if (type === "BUYER_PROPERTY_FEATURES") {
      this.props.context.setBuyerHomeDetails(e, "BUYER_PROPERTY_FEATURES");
    } else if (type === "BUYER_PROPERTY_DETAILS2") {
    }
    if (e && this.state.current !== 3 && !this.state.doBackPage) {
      this.setState({ current: this.state.current + 1 });
    } else {
      this.setState({ current: this.state.current - 1 });
    }
  };
  componentDidMount = () => {
    if (window.location.pathname !== "/add/buyerProfile") {
      const id = this.props.match.params.id;
      noAuthgetDetails("getBuyers", id).then((editBuyers) => {
        this.setState({ buyerHomeDetails: editBuyers.data });
      });
    }
  };

  render() {
    const { current, savingDetails, buyerHomeDetails } = this.state;
    const steps = [
      {
        title: "Private details",
        content: (
          <BuyerPrivateDetails
            handleBuyerDetails={this.newMethod}
            buyerHomeDetails={
              window.location.pathname === "/add/buyerProfile"
                ? this.props.context.state.buyerHomeDetails
                : buyerHomeDetails
            }
          />
        ),
      },
      {
        title: "Buyer Details",
        content: (
          <BuyerPropertyDetails
            handleBuyerDetails={this.newMethod}
            buyerHomeDetails={
              window.location.pathname === "/add/buyerProfile"
                ? this.props.context.state.buyerHomeDetails
                : buyerHomeDetails
            }
            doBackPage={this.state.doBackPage}
          />
        ),
      },
      {
        title: "Property Features",
        content: (
          <PropertyFeatures
            handleBuyerDetails={this.newMethod}
            AddBuyer
            buyerHomeDetails={
              window.location.pathname === "/add/buyerProfile"
                ? this.props.context.state.buyerHomeDetails
                : buyerHomeDetails
            }
            doBackPage={this.state.doBackPage}
          />
        ),
      },
      {
        title: "Buyer Details",
        content: (
          <BuyerPropertyDetails2
            saveBuyerReq={this.handleAddhome}
            handleBuyerDetails={this.newMethod}
            buyerHomeDetails={
              window.location.pathname === "/add/buyerProfile"
                ? this.props.context.state.buyerHomeDetails
                : buyerHomeDetails
            }
            doBackPage={this.state.doBackPage}
          />
        ),
      },
    ];
    return (
      <div
        style={{
          backgroundColor: "rgb(249, 250, 255)",
          height: "fit-content",
        }}
      >
        <Loading enableLoading={savingDetails} />
        <Layout className="Add-home-details">
          {buyerHomeDetails ? (
            <>
              <Steps
                size="small"
                current={current}
                direction={
                  window.screen.width >= "605" ? "horizontal" : "vertical"
                }
              >
                {steps.map((item) => (
                  <Step key={item.title} title={item.title}></Step>
                ))}
              </Steps>
              <div className="steps-content">{steps[current].content}</div>
            </>
          ) : (
            window.location.pathname === "/add/buyerProfile" && (
              <>
                <Steps
                  size="small"
                  current={current}
                  direction={
                    window.screen.width >= "605" ? "horizontal" : "vertical"
                  }
                >
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title}></Step>
                  ))}
                </Steps>
                <div className="steps-content">{steps[current].content}</div>
              </>
            )
          )}
        </Layout>

        <Row style={{ marginBottom: "1vw" }}>
          <Col
            xl={{ span: 3, offset: 8 }}
            lg={{ span: 3, offset: 8 }}
            md={{ span: 3, offset: 8 }}
            xs={{ span: 10, offset: 1 }}
            sm={{ span: 10, offset: 1 }}
          >
            {current > 0 ? (
              <Button
                // type="primary"
                // onClick={this.prev}
                // style={{ width: "100%", height: "40px" }}
                onClick={this.prev}
                type="primary"
                form="form"
                style={{ width: "100%", height: "40px" }}
                htmlType="submit"
              >
                Back
              </Button>
            ) : (
              <Link
                to={`/viewProfile/${this.props.context.state.userDetails.id}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{ width: "100%", height: "40px" }}
                  type="primary"
                  onClick={this.setLoadingFalse}
                >
                  Cancel
                </Button>
              </Link>
            )}
          </Col>
          <Col
            xl={{ span: 3, offset: 1 }}
            lg={{ span: 3, offset: 1 }}
            md={{ span: 3, offset: 1 }}
            xs={{ span: 10, offset: 2 }}
            sm={{ span: 10, offset: 2 }}
          >
            {current < steps.length - 1 && (
              <>
                <Button
                  type="primary"
                  form="form"
                  style={{ width: "100%", height: "40px" }}
                  htmlType="submit"
                  onClick={this.next}
                >
                  Next Step
                </Button>
              </>
            )}
            {current === steps.length - 1 && (
              <>
                <Button
                  type="primary"
                  id="step1"
                  form="form"
                  style={{ width: "100%", height: "40px" }}
                  htmlType="submit"
                  onClick={this.next}
                >
                  Save
                </Button>
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
export default withAppContext(withRouter(AddBuyerprofile));
