import React, { Component } from "react";
import { Row, Col, Layout, Rate, notification, Button } from "antd";
import { withRouter, Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import NumberFormat from "react-number-format";
import AOS from "aos";
import "aos/dist/aos.css";
import { withAppContext } from "../../store/contextStore";
import { noAuthlist } from "../../api/Apis";
import ImageGallery from "../../common/ImageGallery";
import FilterAgent from "../../components/filter/FilterAgent";
import ProfileCardAgent from "./Myprofile/ProfileCardAgent";
import SendMessage from "../../common/SendMessage";
import Advertisement from "../../common/Advertisement";
import Loading from "../../common/Loading";
import DataNotFound from "../../common/DataNotFound";
import profilePic from "../../images/profileAvatar.png";
import Pagination from "../../components/pagination/Paginations";
import Invitation from "../../common/Invitation";

class Agent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AgentsDetails: "",
      AgentHomes: "",
      FilterState: "",
      beforeLoginLoading: true,
      count: 1,
      beforerFilterAgents: "",
      Viewprofile: true,
      userPortfolio: [],
      currentPage: 1,
      perpageSize: 10,
      minValue: 0,
      maxValue: 10,
      showingAdsAtSearch: false,
      enableInviteLenersPopUp: false,
    };
  }

  componentDidMount = () => {
    this.getAllUsers();
    this.handleLocalHomesList();

    if (
      this.props.context.state.currentPage &&
      this.props.context.state.pageSize
    ) {
      this.setState({
        currentPage: this.props.context.state.currentPage,
      });
      this.handlePagination(
        this.props.context.state.currentPage,
        this.props.context.state.pageSize
      );
    }
  };

  handleItem = (id) => {
    this.props.history.push(`/agentHomes/${id}`);
  };

  getAllUsers = async () => {
    AOS.init({
      duration: 1500,
    });
    try {
      const allUsers = await noAuthlist("getAgents");
      const allFilterAgents = allUsers.data.filter(
        (o) =>
          o.subscriptionPack &&
          o.subscriptionPack &&
          o.subscriptionPack.premierStatus === "Y"
      );

      const FilterAgents = allFilterAgents.sort((a, b) =>
        parseInt(a.testimonialCount) > parseInt(b.testimonialCount) ? -1 : 1
      );

      const AgentsDetails = [];
      if (FilterAgents) {
        for (let singleItem of FilterAgents) {
          let newImage = "";
          if (singleItem.profilePicture) {
            let newImage2 =
              `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
              singleItem.profilePicture[0];
            newImage = newImage2;
            singleItem["AgnetProfileUrl"] = newImage;
            AgentsDetails.push({ ...singleItem });
          } else {
            singleItem["AgnetProfileUrl"] = "";
            AgentsDetails.push({ ...singleItem });
          }
        }
        this.setState({ AgentsDetails, beforerFilterAgents: AgentsDetails });
        const { filtersearch } = this.props.context.state;
        if (filtersearch && filtersearch.length > 0) {
          this.handleFilterDetails(filtersearch);
        }
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      window.location.assign("/pageNotFound/newtWork");
    }
  };

  handleLocalHomesList = async () => {
    const localHomesList = await noAuthlist("getHomes");
    if (localHomesList.success) {
      this.setState({
        AgentHomes: localHomesList.data,
      });
    }
  };

  SetAgentHomes = () => {
    const { AgentsDetails, AgentHomes, count } = this.state;
    for (let singleAgent of AgentsDetails) {
      singleAgent["Homes"] = [];
    }
    for (let home of AgentHomes) {
      home["homeAgentProfileUrl"] = "";
      home["homeAgentName"] = "";
      home["agentPage"] = "agentPage";
    }
    for (let singleItem of AgentHomes) {
      for (let singleHome of AgentsDetails) {
        if (singleHome.id === singleItem.userId) {
          singleItem.homeAgentProfileUrl = singleHome.AgnetProfileUrl;
          singleItem.homeAgentName =
            singleHome.firstName + " " + singleHome.lastName;
          singleHome.Homes = [...singleHome.Homes, singleItem];
        }
      }
    }
    if (count === 1) {
      this.setState({
        beforeLoginLoading: false,
        count: count + 1,
      });
    }
  };

  handleFilterDetails = (filterData) => {
    const { beforerFilterAgents } = this.state;
    const FilterObj = {};
    for (let filterOne of filterData) {
      if (filterOne.type === "NAME" && filterOne.name) {
        FilterObj["name"] = filterOne.name;
      }
      if (filterOne.type === "ZIP" && filterOne.zipCode) {
        FilterObj["zip"] = filterOne.zipCode;
      }
      if (
        filterOne.type === "cities" &&
        filterOne.multi &&
        filterOne.multi.length > 0
      ) {
        FilterObj["cities"] = filterOne.multi;
      }
      if (
        filterOne.type === "SPA" &&
        filterOne.specialties &&
        filterOne.specialties.length > 0
      ) {
        FilterObj["specialties"] = filterOne.specialties;
      }
    }
    this.props.context.setAgentFilterSearch(filterData);

    const FilterState = beforerFilterAgents.filter((o) => {
      let count = 0;
      let checkCityAndZip = false;
      if (
        FilterObj.name &&
        o.firstName &&
        (o.firstName.toLowerCase().includes(FilterObj.name.toLowerCase()) ||
          o.lastName.toLowerCase().includes(FilterObj.name.toLowerCase()))
      ) {
        count += 1;
      }
      if (
        FilterObj.zip &&
        o.newdata &&
        o.newdata.zipCodes &&
        o.newdata.zipCodes !== undefined &&
        o.newdata.zipCodes.includes(FilterObj.zip)
      ) {
        count += 1;
      }
      if (o.newdata && o.newdata.serviceAreas && FilterObj.cities) {
        const titleAddress = o.newdata.serviceAreas.filter((savedName) =>
          FilterObj.cities.some(
            (searchCity) => savedName.cityName === searchCity.cityName
          )
        );
        if (titleAddress.length !== 0) {
          count += 1;
          checkCityAndZip = true;
        }
        if (o.newdata && o.newdata.agentTowns && FilterObj.cities) {
          let getAgentbyZipCodes = o.newdata.agentTowns.filter((savedTown) =>
            FilterObj.cities.some(
              (searchTown) => savedTown.cityName === searchTown.cityName
            )
          );
          if (getAgentbyZipCodes.length !== 0) {
            if (!checkCityAndZip) {
              count += 1;
            }
          }
        }
      }

      if (o.newdata && o.newdata.Specialties && FilterObj.specialties) {
        const titleAddress = FilterObj.specialties.filter((title) =>
          o.newdata.Specialties.includes(title)
        );
        if (titleAddress.length !== 0) {
          count += 1;
        }
      }
      if (count === Object.keys(FilterObj).length) {
        console.log("FilterState", count, Object.keys(FilterObj).length);
        return true;
      } else {
        return false;
      }
    });

    if (FilterState) {
      const FilterAgents = FilterState.sort((a, b) =>
        parseInt(a.testimonialCount) > parseInt(b.testimonialCount) ? -1 : 1
      );
      this.setState({
        AgentsDetails: FilterAgents,
        filterFalse: true,
        beforeLoginLoading: false,
        showingAdsAtSearch: true,
      });
    }
  };

  update = (filterdata, type) => {
    this.setState({
      beforeLoginLoading: true,
    });
    setTimeout(() => {
      this.handleFilterDetails(filterdata, type);
    }, 1000);
  };

  handleItems = (userid, agent, profileUrl, authenticate, locationState) => {
    this.props.context.setOffMLSuserId(
      userid,
      agent,
      profileUrl,
      authenticate,
      locationState
    );
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  agentCard = (userInfo) => {
    let portfolioZoomImages = [];
    userInfo.portfolio &&
      userInfo.portfolio.length > 0 &&
      userInfo.portfolio[0].portfolioImages.length > 0 &&
      userInfo.portfolio[0].portfolioImages.map((portfolio) => {
        let getUrl =
          `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
          portfolio;
        portfolioZoomImages.push(getUrl);
        return true;
      });

    return (
      <div
        data-aos-offset="100"
        data-aos-delay="200"
        data-aos-once="true"
        data-aos="fade-up"
        data-aos-easing="ease-in"
      >
        {userInfo.Homes && (
          <div
            className="card card-agent"
            style={{
              marginLeft: "0px",
              marginRight: "0",
              marginBottom: "1vw",
              marginTop: "",
              height: "fit-content",
            }}
          >
            <Row
              style={{
                paddingTop: "1vw",
                paddingBottom: "1vw",
                backgroundColor: "white",
              }}
            >
              <Col
                xxl={{ span: 3, offset: 0 }}
                xl={{ span: 3, offset: 0 }}
                lg={{ span: 2, offset: 0 }}
                md={{ span: 6, offset: 1 }}
                sm={{ span: 6, offset: 1 }}
                xs={{ span: 6, offset: 1 }}
                className="img-marketplace-lender"
              >
                <Link
                  to={`/agent/viewProfile/${userInfo.id && userInfo.id}`}
                  style={{ textDecoration: "none" }}
                >
                  {userInfo.AgnetProfileUrl ? (
                    <img
                      src={userInfo.AgnetProfileUrl}
                      className="img-width-agent"
                      alt="Agnet-Profile-Url"
                    />
                  ) : (
                    <img
                      src={profilePic}
                      className="img-width-agent"
                      alt="Agnet-Profile-Url"
                    />
                  )}
                </Link>
              </Col>
              <Col
                xxl={{ span: 5, offset: 0 }}
                xl={{ span: 5, offset: 0 }}
                lg={{ span: 5, offset: 0 }}
                md={{ span: 16, offset: 1 }}
                sm={{ span: 16, offset: 1 }}
                xs={{ span: 16, offset: 1 }}
                className="Name-agent1"
              >
                <Col className="Name-agent">
                  {userInfo.firstName &&
                    userInfo.firstName + " " + userInfo.lastName}
                </Col>
                <Col>
                  <Rate disabled defaultValue={userInfo.avgRating} /> (
                  {userInfo.testimonialCount})
                </Col>
                {userInfo.subscriptionPack &&
                userInfo.subscriptionPack &&
                userInfo.subscriptionPack.premierStatus === "Y" ? (
                  <Col>
                    <span
                      style={{
                        fontSize: "15px",
                        backgroundColor: "#3045D2",
                        width: " 76px",
                        height: "15px",
                        color: "#FFF",
                        borderRadius: "2px",
                        padding: "5px 10px 5px",
                        textTransform: "uppercase",
                      }}
                    >
                      premier agent
                    </span>

                    <div className="popover-display-view">
                      <Link
                        to={`/agent/viewProfile/${userInfo.id && userInfo.id}`}
                        style={{ textDecoration: "none" }}
                        onClick={this.handleLink}
                      >
                        <span style={{ fontSize: "15px" }}>
                          <p className="view-agent">View Profile</p>
                        </span>
                      </Link>
                    </div>
                  </Col>
                ) : (
                  <Col></Col>
                )}
              </Col>
              <div className="divided-line-agent" />
              <Col
                xxl={{ span: 7 }}
                xl={{ span: 8 }}
                lg={{ span: 9 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                className="Email-agent"
              >
                Email
                <Col
                  span={24}
                  style={{
                    color: "#131419",
                    marginTop: "5px",
                    fontWeight: "500",
                  }}
                  className="font-size-value-agent"
                >
                  <a
                    type="link"
                    target="_blank"
                    rel="noreferrer"
                    href={"mailto:" + userInfo.email}
                    style={{
                      color: "#131419",
                    }}
                  >
                    {userInfo.email && userInfo.email}
                  </a>
                </Col>
              </Col>
              <div className="divided-line-agent1" />
              <Col
                xxl={{ span: 4 }}
                xl={{ span: 4 }}
                lg={{ span: 4 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                className="Email-agent"
              >
                Phone
                <Col
                  className="font-size-value-agent"
                  style={{
                    color: "#131419",
                    marginTop: "5px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href={userInfo.phone && "tel:" + userInfo.phone}
                    style={{ color: "#000" }}
                  >
                    <NumberFormat
                      format="(###) ###-####"
                      value={userInfo.phone && userInfo.phone}
                      displayType={"text"}
                    />
                  </a>
                </Col>
              </Col>

              <Col
                xxl={{ span: 4 }}
                xl={{ span: 4 }}
                lg={{ span: 4 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                className="viewprofile-display"
              >
                <div
                  style={{ marginBottom: "8px" }}
                  className="viewprofile-display1"
                >
                  <Link
                    to={`/agent/viewProfile/${userInfo.id && userInfo.id}`}
                    style={{ textDecoration: "none" }}
                    onClick={this.handleLink}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <p
                        style={{
                          textAlign: "center",
                        }}
                      >
                        View Profile
                      </p>
                    </span>
                  </Link>
                </div>

                <SendMessage
                  className="btn-message-agent"
                  msgButtonTitle="Message"
                  toName={
                    userInfo.firstName &&
                    userInfo.firstName + " " + userInfo.lastName
                  }
                  propertyTitle="Need buyer representation"
                  buttonAlign="agents-desktop"
                  mlsId={userInfo.mlsId}
                  agentEmail={userInfo.email}
                  AgentMessage
                />
              </Col>
              <Col
                xl={{ span: 2 }}
                lg={{ span: 1 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                className="popover-display"
              >
                <SendMessage
                  className="btn-message-agent"
                  msgButtonTitle="Message"
                  toName={
                    userInfo.firstName &&
                    userInfo.firstName + " " + userInfo.lastName
                  }
                  propertyTitle="Need buyer representation"
                  buttonAlign="agents"
                  mlsId={userInfo.mlsId}
                  agentEmail={userInfo.email}
                  AgentMessage
                />
              </Col>
            </Row>

            <div className="agent-carousel">
              <Carousel
                enableAutoPlay
                autoPlaySpeed={4000}
                itemsToShow={
                  window.screen.width >= "993"
                    ? 3
                    : window.screen.width > "501" &&
                      window.screen.width <= "992"
                    ? 2
                    : 1
                }
              >
                {userInfo.Homes &&
                  userInfo.Homes.length > 0 &&
                  userInfo.Homes.map((oo) => (
                    <ProfileCardAgent
                      name={oo}
                      agentPic={userInfo.AgnetProfileUrl}
                      handleItems={this.handleItems}
                    />
                  ))}

                {userInfo.portfolio &&
                  userInfo.portfolio.length > 0 &&
                  userInfo.portfolio[0].portfolioImages.length > 0 &&
                  userInfo.portfolio[0].portfolioImages.map((portfolio) => (
                    <Row>
                      <div>
                        <Col>
                          <img
                            src={
                              `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
                              portfolio
                            }
                            alt={portfolio}
                            className="agent-homes"
                            style={{ backgroundColor: "#d4d8f1fa" }}
                          />
                          <div style={{ marginTop: "-40px" }}>
                            <ImageGallery photos={portfolioZoomImages} />
                          </div>
                        </Col>
                      </div>
                    </Row>
                  ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>
    );
  };

  showAds = () => {
    return (
      <Advertisement
        otherAds={false}
        adType="Square"
        adWhereToShow="Agents"
        adLimit="10"
        zipCode=""
      />
    );
  };
  showAdsSearch = () => {
    return (
      <Advertisement
        otherAds={false}
        adType="Square"
        adWhereToShow="Agents"
        adLimit="10"
        zipCode=""
      />
    );
  };
  handlePagination = async (page, pageSize) => {
    this.props.context.handleToSetCurrentPage(page, pageSize);
    this.setState({
      beforeLoginLoading: true,
    });
    if (page <= 1) {
      this.setState({
        minValue: 0,
        maxValue: pageSize,
      });
    } else {
      this.setState({
        minValue: (page - 1) * pageSize,
        maxValue: page * pageSize,
      });
    }
    await this.setState({
      currentPage: page,
      perpageSize: pageSize,
    });
    setTimeout(() => {
      this.setState({
        beforeLoginLoading: false,
      });
    }, 3000);
  };
  handleCloseInvitationModal = () => {
    this.setState({
      enableInviteLenersPopUp: false,
    });
  };
  render() {
    const {
      AgentsDetails,
      AgentHomes,
      beforeLoginLoading,
      currentPage,
      perpageSize,
      showingAdsAtSearch,
      enableInviteLenersPopUp,
    } = this.state;
    let checkUserLogin = JSON.parse(localStorage.getItem("userLogin"));
    let checkAgentLogin = JSON.parse(localStorage.getItem("userDetails"));

    return (
      <div className="homeSearch">
        <Loading enableLoading={beforeLoginLoading} />
        <Layout className="margin-body-agent">
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row style={{ backgroundColor: "#f0f2f5" }}>
              <Col
                xl={{ span: 5, offset: 0 }}
                lg={{ span: 5, offset: 0 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                className="side-bar"
              >
                <p
                  className="header-down-text show-on-desktop"
                  style={{ textAlign: "center" }}
                >
                  Find an agent to match your needs
                </p>
                <FilterAgent update={this.update} />
                <div
                  className="Advertisement-show"
                  style={{ marginTop: "2vw" }}
                >
                  {showingAdsAtSearch && this.showAdsSearch()}
                  {!showingAdsAtSearch && this.showAds()}
                </div>
              </Col>
              <Col
                xl={{ span: 18, offset: 0 }}
                lg={{ span: 18, offset: 0 }}
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                className="margin-left-agent"
              >
                <Row>
                  <Col
                    xl={{ span: 22, offset: 0 }}
                    lg={{ span: 20, offset: 0 }}
                    md={{ span: 22, offset: 1 }}
                    sm={{ span: 12, offset: 0 }}
                    xs={{ span: 12, offset: 0 }}
                  >
                    <p className="listing-heading">
                      {AgentsDetails && AgentsDetails.length} Agents Found
                    </p>
                  </Col>
                  {checkUserLogin &&
                    checkAgentLogin &&
                    checkAgentLogin.userProfile === "AG" && (
                      <Col
                        xl={{ span: 2, offset: 0 }}
                        lg={{ span: 4, offset: 0 }}
                        md={{ span: 22, offset: 1 }}
                        sm={{ span: 12, offset: 0 }}
                        xs={{ span: 12, offset: 0 }}
                      >
                        <Button
                          type="primary"
                          onClick={() => {
                            this.setState({ enableInviteLenersPopUp: true });
                          }}
                          className="invitation-col"
                        >
                          <span>Invite Agent</span>
                        </Button>
                      </Col>
                    )}
                </Row>

                {AgentsDetails &&
                  AgentsDetails.length === 0 &&
                  !beforeLoginLoading && (
                    <div style={{ padding: "6vw" }}>
                      <DataNotFound type="agents" title="No Agents Found." />
                    </div>
                  )}

                {!beforeLoginLoading &&
                  AgentsDetails &&
                  AgentsDetails.slice(this.state.minValue, this.state.maxValue)
                    .filter((owner) => {
                      return (
                        owner.email ===
                          process.env.REACT_APP_CLIENT_AGENT_EMAIL && owner
                      );
                    })
                    .map((userInfo) => {
                      return this.agentCard(userInfo);
                    })}

                {!beforeLoginLoading &&
                  AgentsDetails &&
                  AgentsDetails.slice(this.state.minValue, this.state.maxValue)
                    .filter((owner) => {
                      return (
                        owner.email !==
                          process.env.REACT_APP_CLIENT_AGENT_EMAIL && owner
                      );
                    })
                    .map((userInfo) => {
                      return this.agentCard(userInfo);
                    })}

                {!beforeLoginLoading &&
                  AgentsDetails &&
                  AgentsDetails.length > 0 && (
                    <Col
                      className="align-right"
                      style={{
                        marginTop: "20px",
                        marginBottom: "30px",
                      }}
                    >
                      <Pagination
                        total={AgentsDetails.length}
                        handlePagination={this.handlePagination}
                        currentPage={currentPage}
                        handleLoadMoreButton={this.handleLoadMoreButton}
                        perpageSize={perpageSize}
                      />
                    </Col>
                  )}
              </Col>

              <Col
                md={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <div className="Advertisement-show-on-mobile-view">
                  {/* {this.showAds()} */}
                  {showingAdsAtSearch && this.showAdsSearch()}
                  {!showingAdsAtSearch && this.showAds()}
                </div>
              </Col>
            </Row>
            {AgentsDetails && AgentHomes && this.SetAgentHomes()}
          </Col>
        </Layout>

        {enableInviteLenersPopUp && (
          <Invitation
            enableInvitationModal={enableInviteLenersPopUp}
            handleCloseInvitationModal={this.handleCloseInvitationModal}
            invitationTitle={"agent"}
          />
        )}
      </div>
    );
  }
}

export default withAppContext(withRouter(Agent));
