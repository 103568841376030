import { useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { noAuthadd, noAuthlist } from "../api/Apis";
import { Modal, Select } from "antd";
import Loading from "../common/Loading";

const { Option } = Select;

function SigninWithFacebook({ buttonText, getLoginData }) {
  const [userExits, setuserExits] = useState([]);
  const [isSelectedUserType, setisSelectedUserType] = useState({
    isOpen: false,
    isType: "B",
  });

  const [isLoadingPage, setIsLoadingpage] = useState(false)

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log(event, data);
      switch (event) {
        case "signIn":
          if (data.username.indexOf("Facebook") > -1) {
            handleRegisterUser(data);
          }
          // getLoginData(data?.signInUserSession?.idToken?.payload?.sub);
          // case "cognitoHostedUI":
          //   getUser().then((userData) => {
          //     handleRegisterUser(userData);
          //     getLoginData(payload.entityData.id)
          //   });
          //   break;
          break;
        case "signUp":
          console.log(data, "signup for facebook login....");
          handleRegisterUser(data);
          break;
        case "signOut":
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          console.log("Default");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegisterUser = (data) => {
    let selectedProfile = JSON.parse(localStorage.getItem("signupUserSelectedProfile"))
    setIsLoadingpage(true)
    const fb = window.FB;
    let entityData = {};
    let access_token = JSON.parse(localStorage.getItem("facebookAccessToken"));
    fb.api(
      `${data?.signInUserSession?.idToken?.payload?.identities[0]["userId"]}`,
      { fields: "first_name,last_name,email,picture", access_token },
      (response1) => {
        console.log(response1, "me=====> api for fb ");
        if(response1.success){
        entityData["email"] = response1.email;
        entityData["firstName"] = response1.first_name;
        entityData["lastName"] = response1.last_name;
        entityData["profileUrl"] = response1.picture.data.url;
        entityData["profileUrl2"] = response1.picture.data.url;
        localStorage.setItem("fbUserProfilePic",JSON.stringify(response1.picture.data.url))
        }
      }
    );
    
    if(data?.signInUserSession?.idToken?.payload?.sub){
    entityData = {
      ...entityData,
      id: data?.signInUserSession?.idToken?.payload?.sub,
      userProfile: selectedProfile ? selectedProfile : isSelectedUserType.isType,
      facebookCreatedUserName: data.username,
      facebookAppUserId:
        data?.signInUserSession?.idToken?.payload?.identities[0]["userId"],
      agentInfo: {
        agentEmail: "",
        agentName: "",
      },
      addUserName: "",
      recordStatus: true,
    };
    let payload = {
      entityData,
    };

    noAuthlist("getUsers").then((res) => {
      if (userExits.length === 0) {
        let userCred = res.data.filter((us) => us.id === payload.entityData.id);
        console.log(userCred, "exits codeeee");
        setuserExits(userCred);
      }
      if (
        res.data.filter((us) => us.id === payload.entityData.id).length === 0
      ) {
        if (payload.entityData.email !== "") {
          console.log(payload, "payloadddd");
          noAuthadd("users", payload).then((userSuccess) => {
            if (userSuccess.success) {
              console.log(payload, "exits codeeee");
              getLoginData(payload.entityData.id);
              // handleToRedirectPath();
            }
          });
        }
      } else {
        console.log(payload, "exits codeeee");
        // handleToRedirectPath();
        getLoginData(payload.entityData.id);
      }
    });
    }
  };

  //   async function getUser() {
  //     return Auth.currentAuthenticatedUser()
  //       .then((userData) => userData)
  //       .catch(() => console.log("Not signed in"));
  //   }
  const handleClickble = () => {
    if (buttonText === "Sign Up") {
      // handleToContinueSignUp();
      setisSelectedUserType({ ...isSelectedUserType, isOpen: true });
    } else {
      signIn();
    }
  };

  const signIn = () => {
    setisSelectedUserType({ ...isSelectedUserType, isOpen: false });
    const fb = window.FB;
    console.log("fb login details")
    fb.getLoginStatus((response) => {
      console.log(response,"inside --->");
      if (response.status === "connected") {
        console.log(response, "user connected");
        // getAWSCredentials(response.authResponse);
        localStorage.setItem(
          "facebookAccessToken",
          JSON.stringify(response.authResponse.accessToken)
        );
        handleToContinueSignUp();
      } else {
      console.log(response,"inside ---> else condituib");
        fb.login(
          (response) => {
            if (!response || !response.authResponse) {
              return;
            }
            handleToContinueSignUp();
            localStorage.setItem(
              "facebookAccessToken",
              JSON.stringify(response.authResponse.accessToken)
            );
            console.log(response, "user loging");
          },
          {
            scope: "public_profile,email",
          }
        );
      }
    });
    fb.login(
      (response) => {
        if (!response || !response.authResponse) {
          return;
        }
        console.log(response, "user loging");
        handleToContinueSignUp();
        localStorage.setItem(
          "facebookAccessToken",
          JSON.stringify(response.authResponse.accessToken)
        );
      },
      {
        scope: "public_profile,email",
      }
    );
  };

  const handleToContinueSignUp = () => {
    setisSelectedUserType({ ...isSelectedUserType, isOpen: false });
    setIsLoadingpage(true)

    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
    })
      .then((res) => {
        console.log(res, "CognitoHostedUIIdentityProvider");
      })
      .catch((err) => console.log(err));
  };


  return (
    <div>
      {/* <p>User: {user ? JSON.stringify(user.attributes) : "None"}</p>
      {user ? (
        <button onClick={() => Auth.signOut()}>Sign Out</button>
      ) : (
        <> */}
      {/* <div > */}

      <Loading enableLoading={isLoadingPage} />

      <button
        onClick={() => handleClickble()}
        className="loginBtn loginBtn--facebook"
      >
        {" "}
        {buttonText} With Facebook{" "}
      </button>
      {/* </div> */}
      {/* </>
      )} */}

      {isSelectedUserType.isOpen && (
        <Modal
          className="modal-black-close"
          visible={isSelectedUserType.isOpen}
          style={{ marginTop: "5%" }}
          onCancel={() =>
            setisSelectedUserType({ ...isSelectedUserType, isOpen: false })
          }
          // footer={null}
          title="SELECT PROFILE TYPE"
          okText={"Submit"}
          onOk={signIn}
        >
          <Select
            style={{
              width: "100%",
            }}
            placeholder="I am "
            onChange={(e) => localStorage.setItem("signupUserSelectedProfile",JSON.stringify(e))
              // setisSelectedUserType({ ...isSelectedUserType, isType: e })
            }
            className="select_height"
          >
            <Option value="AG">Agent</Option>
            <Option value="B">Buyer</Option>
            <Option value="S">Seller</Option>
            <Option value="L">Lender</Option>
            <Option value="V">Vendor</Option>
          </Select>
        </Modal>
      )}
    </div>
  );
}
export default SigninWithFacebook;
