import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Divider,
  Avatar,
  notification,
  Input,
  Button,
  List,
  Skeleton,
  Drawer,
  Modal,
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import {
  add,
  update,
  noAuthgetDetails,
  noAuthlist,
} from "../api/Apis";
import { withAppContext } from "../store/contextStore";
import SignupForm from "../containers/RegisterForm";
import LoginForm from "../containers/LoginForm";
import profilePic from "../images/profileAvatar.png";
import back from "../images/back.png";
import { PlusOutlined } from "@ant-design/icons";
import BuyerPreferedAgentPopup from "./BuyerPreferedAgentPopup";
import DeleteRecord from "./DeleteRecord";
const { TextArea } = Input;

export class QuestionAnswers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserPostedAd: false,
      enableSignup: false,
      loginUserInfo: {},
      allUserList: [],
      allQueryList: [],
      queryInput: false,
      selectedQuestion: "",
      message: "",
      dataLoading: false,
      showLoginSiderForm: false,
      visiblePreferedAgent: false,
      newPreferedAgentEmail: "",
      newPreferedAgentName: "",
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.getLoginUserInfo();
    }, 2000);
    this.getAllQuestions();
  };

  getLoginUserInfo = async () => {
    if (
      this.props &&
      this.props.context.state &&
      this.props.context.state.userDetails &&
      this.props.context.state.userDetails.id
    ) {
      const { agentId } = this.props;

      const loginUserDetails = this.props.context.state.userDetails;
      const loginUserId = loginUserDetails.id;
      const loginUserName =
        loginUserDetails.firstName + " " + loginUserDetails.lastName;

      let loginUserPic = "";
      if (
        loginUserDetails.profilePicture &&
        loginUserDetails.profilePicture[0] &&
        loginUserDetails.profilePicture[0].length > 0
      ) {
        loginUserPic =
          `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/` +
          loginUserDetails.profilePicture[0];
      } else {
        loginUserPic = "";
      }

      const userInfo = {
        userId: loginUserId,
        userName: loginUserName,
        userPic: loginUserPic,
      };

      this.setState({
        loginUserInfo: userInfo,
      });

      let userLoginID = agentId && agentId !== "" && agentId;
      if (loginUserId === userLoginID) {
        this.setState({
          isUserPostedAd: true,
        });
      } else {
        this.setState({
          isUserPostedAd: false,
        });
      }
    }
  };

  getAllQuestions = async () => {
    this.setState({
      dataLoading: true,
    });
    const { propertyId } = this.props && this.props;
    if (propertyId) {
      try {
        const queryList = await noAuthlist(
          "getQuestions?propertyId=" + propertyId
        );
        if (queryList.success) {
          this.getLoginUserInfo();
          this.setState({
            allQueryList: queryList.data,
            dataLoading: false,
          });
        } else {
          notification["error"]({
            message: queryList.data.message,
          });
        }
      } catch (error) {
        notification["error"]({
          message: error.message,
        });
      }
    }
  };

  submitQuery = async () => {
    this.setState({
      queryInput: false,
      dataLoading: true,
    });
    const {
      propertyId,
      propertyTitle,
      agentId,
      agentName,
      agentEmail,
      propertyImages,
      mlsAgentId,
      preferedAgent,
      prefereAgentName,
    } = this.props;
    const { newPreferedAgentEmail, newPreferedAgentName } = this.state;

    const { userDetails } = this.props.context.state;
    const { message } = this.state;
    const agentInfo = await noAuthgetDetails("getAgents", agentId);

    const data = {
      entityData: {
        question: message,
        propertyId: propertyId.toString(),
        propertyName: propertyTitle,
        propertyImgSrc: propertyImages,
        agentId:
          agentId !== null && agentId !== undefined && agentId !== ""
            ? agentId
            : mlsAgentId,
        agentName:
          prefereAgentName && prefereAgentName !== ""
            ? prefereAgentName
            : newPreferedAgentName && newPreferedAgentName !== ""
            ? newPreferedAgentName
            : agentId !== null && agentId !== undefined && agentId !== ""
            ? agentInfo.data.firstName + " " + agentInfo.data.lastName
            : agentName,
        agentEmail:
          preferedAgent && preferedAgent !== ""
            ? preferedAgent
            : newPreferedAgentEmail && newPreferedAgentEmail !== ""
            ? newPreferedAgentEmail
            : agentId !== null && agentId !== undefined && agentId !== ""
            ? agentInfo.data.email
            : agentEmail,
        userId: userDetails.id,
        userName: userDetails.firstName + " " + userDetails.lastName,
        userPhone:
          userDetails.phone &&
          userDetails.phone !== "" &&
          userDetails.phone !== undefined &&
          userDetails.phone !== null
            ? userDetails.phone
            : "N/A",
        userEmail: userDetails.email,
        relatedTo:
          agentId !== null && agentId !== undefined && agentId !== ""
            ? agentId
            : mlsAgentId,
        stateType: this.props.match.params.stateName,
        isRegistred:
          agentId !== null && agentId !== undefined && agentId !== ""
            ? true
            : false,
      },
    };

    try {
      const response = await add("questionsAndAnswers", data);
      if (response.success) {
        this.handleReset();
        this.getAllQuestions();
        // notification["success"]({
        //   message: "Hey " + loginUserInfo.userName + ".!",
        //   description: "Your question sent successfully",
        // });
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      window.location.assign("/pageNotFound/newtWork");
    }
  };

  updateQuery = async () => {
    this.setState({
      queryInput: false,
      dataLoading: true,
    });
    const { message, selectedQuestion } = this.state;
    const data = {
      entityData: {
        isAnswered: true,
        isNewQuestion: false,
        answer: message,
        userName:
          selectedQuestion.userDetails.firstName +
          " " +
          selectedQuestion.userDetails.lastName,
        userEmail: selectedQuestion.userDetails.email,
        relatedTo: selectedQuestion.userDetails.id,
        stateType: this.props.match.params.stateName,
      },
    };

    try {
      const response = await update(
        "questionsAndAnswers",
        selectedQuestion.id,
        data
      );
      if (response.success) {
        this.handleReset();
        this.getAllQuestions();
      } else {
        notification["error"]({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Something went wrong",
      });
      window.location.assign("/pageNotFound/newtWork");
    }
  };

  handleMessage = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleReset = () => {
    this.setState({
      queryInput: false,
      enableSignup: false,
      selectedQuestion: "",
      message: "",
      dataLoading: false,
    });
  };

  cancel = (e) => {
    console.log(e);
  };

  handleToshowLoginSider = () => {
    this.setState({
      showLoginSiderForm: true,
    });
  };

  handletoShowSignupsider = () => {
    this.setState({
      showLoginSiderForm: false,
    });
  };
  openSendMessage = (agentInfo) => {
    if (agentInfo && agentInfo.agentName && agentInfo.agentEmail) {
      this.setState({
        newPreferedAgentName: agentInfo.agentName,
        newPreferedAgentEmail: agentInfo.agentEmail,
      });
    }
    this.setState({
      queryInput: true,
      visiblePreferedAgent: false,
    });
  };

  handleToShceduleTime = () => {
    const { preferedAgent, buyerLoginSendMessage } = this.props;
    const userLogin = localStorage.getItem("userLogin");
    if (userLogin) {
      if (preferedAgent && preferedAgent !== "") {
        this.setState({
          queryInput: true,
          enableSignup: false,
        });
      } else {
        if (userLogin) {
          if (buyerLoginSendMessage) {
            this.setState({
              visiblePreferedAgent: true,
            });
            notification["warning"]({
              message: "You don't have an agent to share",
            });
          } else {
            this.setState({
              queryInput: true,
              enableSignup: false,
            });
          }
        } else {
          this.setState({
            enableSignup: true,
          });
        }
      }
    } else {
      this.setState({
        queryInput: false,
        enableSignup: true,
      });
    }
  };
  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.submitQuery();
    }
  };
  render() {
    const {
      enableSignup,
      isUserPostedAd,
      loginUserInfo,
      allQueryList,
      selectedQuestion,
      queryInput,
      message,
      dataLoading,
      showLoginSiderForm,
      visiblePreferedAgent,
    } = this.state;
    const userLogin = localStorage.getItem("userLogin");

    return (
      <>
        <div id="questions" className="achoreTagClick">
          <Row className="row-padding">
            <Card
              title={allQueryList.length + " Question(s)"}
              extra={
                <Button
                  type="primary"
                  className="createButton"
                  icon={<PlusOutlined />}
                  onClick={() => this.handleToShceduleTime()}
                >
                  Ask a Question
                </Button>
              }
            >
              <Row>
                <Col span={24}>
                  <List
                    itemLayout="horizontal"
                    dataSource={allQueryList}
                    renderItem={(query, index) => (
                      <Row key={index}>
                        <Col span={24}>
                          {query.question && (
                            <Row className="font-family-style">
                              <Col
                                xl={{ span: 4, offset: 0 }}
                                lg={{ span: 5, offset: 0 }}
                                md={{ span: 5, offset: 0 }}
                                sm={{ span: 22, offset: 1 }}
                                xs={{ span: 22, offset: 1 }}
                              >
                                <Skeleton
                                  title={false}
                                  loading={dataLoading}
                                  active
                                >
                                  <span
                                    className="property-detail-name  font-normal-size"
                                    style={{
                                      color: "#99A2C3",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Question:
                                  </span>

                                  {/* <h5
                                    style={{
                                      fontWeight: "100",
                                      color: "grey",
                                    }}
                                  >
                                    {moment(query.createdAt).format("lll")}
                                  </h5> */}
                                </Skeleton>
                              </Col>
                              <Col
                                xl={{ span: 13, offset: 1 }}
                                lg={{ span: 12, offset: 1 }}
                                md={{ span: 12, offset: 1 }}
                                sm={{ span: 22, offset: 1 }}
                                xs={{ span: 22, offset: 1 }}
                              >
                                <Row>
                                  <Skeleton
                                    title={false}
                                    loading={dataLoading}
                                    active
                                  >
                                    <span
                                      className="property-detail-value font-normal-size"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {query.question}
                                    </span>
                                  </Skeleton>
                                </Row>
                              </Col>
                              <Col
                                xl={{ span: 5, offset: 1 }}
                                lg={{ span: 5, offset: 1 }}
                                md={{ span: 5, offset: 1 }}
                                sm={{ span: 22, offset: 1 }}
                                xs={{ span: 22, offset: 1 }}
                              >
                                <h5
                                  style={{
                                    fontWeight: "100",
                                    color: "grey",
                                  }}
                                >
                                  {moment(query.createdAt).format("lll")}
                                </h5>
                                {/* <Link
                                  to={{
                                    pathname: `/agentProfile/${query.userDetails.id}`,
                                  }}
                                  className="profile-view-link"
                                >
                                  <Skeleton
                                    avatar
                                    title={false}
                                    loading={dataLoading}
                                    active
                                  >
                                    <List.Item.Meta
                                      avatar={
                                        <h5
                                          style={{
                                            height: "32px",
                                            width: "32px",
                                            borderRadius: "16px",
                                            backgroundColor: "#99A2C3",
                                            textAlign: "center",
                                            color: "#000",
                                            fontSize: "20px",
                                          }}
                                        >
                                          {query.userDetails.firstName
                                            .charAt(0)
                                            .toUpperCase()}
                                        </h5>
                                      }
                                      title={
                                        <h5
                                          style={{
                                            marginLeft: "-10px",
                                          }}
                                        >
                                          {query.userDetails.firstName +
                                            " " +
                                            query.userDetails.lastName}
                                        </h5>
                                      }
                                      description={
                                        <h6
                                          style={{
                                            marginLeft: "-10px",
                                            marginTop: "-5px",
                                            color: "grey",
                                          }}
                                        >
                                          {query.userDetails.userProfile == "AG"
                                            ? "Agent"
                                            : query.userDetails.userProfile ==
                                              "B"
                                            ? "Buyer"
                                            : query.userDetails.userProfile ==
                                              "S"
                                            ? "Seller"
                                            : "Vendor"}
                                        </h6>
                                      }
                                    />
                                  </Skeleton>
                                </Link> */}
                              </Col>
                            </Row>
                          )}

                          <Row
                            className="font-family-style font-normal-size"
                            // style={{ marginTop: "-5px" }}
                          >
                            <Col
                              xl={{ span: 4, offset: 0 }}
                              lg={{ span: 5, offset: 0 }}
                              md={{ span: 5, offset: 0 }}
                              sm={{ span: 22, offset: 1 }}
                              xs={{ span: 22, offset: 1 }}
                            >
                              {query.answer && (
                                <Skeleton
                                  title={false}
                                  loading={dataLoading}
                                  active
                                >
                                  <span
                                    className="property-detail-name"
                                    style={{
                                      color: "#99A2C3",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Answer:{" "}
                                  </span>

                                  {/* <h5
                                    style={{ fontWeight: "100", color: "grey" }}
                                  >
                                    {moment(query.updatedAt).format("lll")}
                                  </h5> */}
                                </Skeleton>
                              )}
                            </Col>
                            <Col
                              xl={{ span: 13, offset: 1 }}
                              lg={{ span: 12, offset: 1 }}
                              md={{ span: 12, offset: 1 }}
                              sm={{ span: 22, offset: 1 }}
                              xs={{ span: 22, offset: 1 }}
                            >
                              {query.answer && (
                                <Row>
                                  <span
                                    className="property-detail-value"
                                    style={{
                                      fontWeight: "100",
                                      color: "grey",
                                    }}
                                  >
                                    <Skeleton
                                      title={false}
                                      loading={dataLoading}
                                      active
                                    >
                                      {query.answer}
                                    </Skeleton>
                                  </span>
                                </Row>
                              )}
                            </Col>
                            <Col
                              xl={{ span: 5, offset: 1 }}
                              lg={{ span: 5, offset: 1 }}
                              md={{ span: 5, offset: 1 }}
                              sm={{ span: 22, offset: 1 }}
                              xs={{ span: 22, offset: 1 }}
                            >
                              {query.answer && (
                                // <Link
                                //   to={{
                                //     pathname: `/agentProfile/${query.agentDetails.id}`,
                                //   }}
                                //   className="profile-view-link"
                                // >
                                //   <Skeleton
                                //     avatar
                                //     title={false}
                                //     loading={dataLoading}
                                //     active
                                //   >
                                //     <List.Item.Meta
                                //       avatar={
                                //         <h5
                                //           style={{
                                //             height: "32px",
                                //             width: "32px",
                                //             borderRadius: "16px",
                                //             backgroundColor: "#99A2C3",
                                //             textAlign: "center",
                                //             color: "#000",
                                //             fontSize: "20px",
                                //           }}
                                //         >
                                //           {query.agentDetails.firstName
                                //             .charAt(0)
                                //             .toUpperCase()}
                                //         </h5>
                                //       }
                                //       title={
                                //         <h5
                                //           style={{
                                //             marginLeft: "-10px",
                                //           }}
                                //         >
                                //           {query.agentDetails.firstName +
                                //             " " +
                                //             query.agentDetails.lastName}
                                //         </h5>
                                //       }
                                //       description={
                                //         <h6
                                //           style={{
                                //             marginLeft: "-10px",
                                //             marginTop: "-5px",
                                //             color: "grey",
                                //           }}
                                //         >
                                //           {query.agentDetails.userProfile ==
                                //           "AG"
                                //             ? "Agent"
                                //             : query.agentDetails.userProfile ==
                                //               "B"
                                //             ? "Buyer"
                                //             : query.agentDetails.userProfile ==
                                //               "S"
                                //             ? "Seller"
                                //             : "Vendor"}
                                //         </h6>
                                //       }
                                //     />
                                //   </Skeleton>
                                // </Link>

                                <h5
                                  style={{ fontWeight: "100", color: "grey" }}
                                >
                                  {moment(query.updatedAt).format("lll")}
                                </h5>
                              )}

                              <div style={{ float: "right" }}>
                                {isUserPostedAd && (
                                  <List.Item
                                    actions={[
                                      !query.isAnswered ? (
                                        <a
                                          href="!#"
                                          key="edit"
                                          onClick={() => {
                                            this.setState({
                                              selectedQuestion: query,
                                              queryInput: true,
                                            });
                                          }}
                                        >
                                          <img
                                            src={back}
                                            alt=""
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                              color: "blue",
                                              marginBottom: "6px",
                                            }}
                                          />
                                        </a>
                                      ) : (
                                        <a key="edit" href="!#">
                                          <div
                                            style={{
                                              width: "10px",
                                              height: "10px",
                                            }}
                                          />
                                        </a>
                                      ),
                                      <DeleteRecord
                                        recordId={query.id}
                                        apiPath={"questionsAndAnswers"}
                                        getHomesList={this.getAllQuestions}
                                        handleReset={this.handleReset}
                                        fontSizeProp={"14px"}
                                        deletionType={"DeleteOutlined"}
                                        deletionSuccessMessage={
                                          "Your record deleted successfully"
                                        }
                                      />,
                                    ]}
                                  ></List.Item>
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Divider></Divider>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  />
                </Col>
              </Row>
              <Modal
                className="modal-width"
                visible={userLogin && visiblePreferedAgent}
                title={"Add Agent"}
                style={{ marginTop: "2%" }}
                onCancel={() =>
                  this.setState({
                    queryInput: false,
                    visiblePreferedAgent: false,
                  })
                }
                footer={null}
              >
                {visiblePreferedAgent && (
                  <BuyerPreferedAgentPopup
                    openSendMessage={this.openSendMessage}
                    scheduleShowing={true}
                  />
                )}
              </Modal>
              <Drawer
                title="Please register to ask your question"
                placement="right"
                width={
                  window.screen.width >= "768"
                    ? "40%"
                    : window.screen.width > "501" &&
                      window.screen.width <= "767"
                    ? "80%"
                    : "100%"
                }
                mask={true}
                closable={true}
                getContainer={false}
                visible={enableSignup}
                onClose={() => this.handleReset()}
                headerStyle={{ textAlign: "center" }}
                bodyStyle={{ paddingBottom: 80, backgroundColor: "#eff2f5" }}
              >
                <Row>
                  <Col span={24}>
                    {showLoginSiderForm ? (
                      <LoginForm
                        showAdd={false}
                        modalTrue
                        goTopreviousPage={true}
                        handleToshowLoginSider={this.handletoShowSignupsider}
                        showLoginSiderForm={showLoginSiderForm}
                        questionPage
                      />
                    ) : (
                      <SignupForm
                        questionPage
                        showAdd={false}
                        modalTrue
                        goTopreviousPage={true}
                        handleToshowLoginSider={this.handleToshowLoginSider}
                        showLoginSiderForm={showLoginSiderForm}
                      />
                    )}
                  </Col>
                </Row>
              </Drawer>

              <Drawer
                title={
                  selectedQuestion.id && selectedQuestion.id !== ""
                    ? "Question : " + selectedQuestion.question
                    : "Please post your question"
                }
                //Don't Delete :: loginUserInfo.userName + " please post your question"
                placement="bottom"
                height={200}
                mask={true}
                closable={true}
                getContainer={false}
                onClose={() => this.handleReset()}
                visible={queryInput}
                headerStyle={{ marginLeft: "12%", marginRight: "12%" }}
                bodyStyle={{ paddingBottom: 80 }}
              >
                <Row>
                  <Col
                    xl={{ span: 17, offset: 3 }}
                    lg={{ span: 17, offset: 3 }}
                    md={{ span: 17, offset: 3 }}
                    sm={{ span: 22, offset: 1 }}
                    xs={{ span: 21, offset: 0 }}
                  >
                    <Row>
                      <Col xl={23} lg={23} md={22} sm={21} xs={21}>
                        <Row>
                          <Col xl={1} lg={2} md={2} sm={3} xs={4}>
                            <Avatar
                              src={
                                loginUserInfo.userPic !== ""
                                  ? loginUserInfo.userPic
                                  : profilePic
                              }
                            />
                          </Col>
                          <Col xl={23} lg={22} md={22} sm={21} xs={20}>
                            <TextArea
                              placeholder={
                                selectedQuestion.id &&
                                selectedQuestion.id !== ""
                                  ? "Please enter your answer"
                                  : "Start typing.."
                              }
                              //Don't Delete :: loginUserInfo.userName + " please enter your answer"
                              rows={1}
                              name="message"
                              value={message}
                              onChange={this.handleMessage}
                              style={{ marginRight: "10px" }}
                              onKeyPress={this.onKeyPress}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={1} lg={1} md={2} sm={3} xs={3}>
                        <Button
                          htmlType="submit"
                          onClick={() =>
                            selectedQuestion.id && selectedQuestion.id !== ""
                              ? this.updateQuery()
                              : this.submitQuery()
                          }
                          type="primary"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Drawer>
            </Card>
          </Row>
        </div>
      </>
    );
  }
}

export default withAppContext(withRouter(QuestionAnswers));
