import AppConfig from "./AppConfig";
let awsConfigure = {
  Auth: {
    mandatorySignIn: true,
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: `${AppConfig.APP_IDENTITY_POOL_ID}`,
    // REQUIRED - Amazon Cognito Region
    region: `${AppConfig.APP_COGNITO_REGION}`,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: `${AppConfig.APP_COGNITO_USER_POOL_ID}`,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: `${AppConfig.APP_CLIENT_ID}`,
  },
  Storage: {
    bucket: `${AppConfig.APP_S3_BUCKET}`,
    region: `${AppConfig.APP_S3_REGION}`,
    identityPoolId: `${AppConfig.APP_IDENTITY_POOL_ID}`,
  },
  social: {
    FB: '1011901460558834'
  },
  oauth: {
    domain: 'homeslistedby-prod.auth.us-east-1.amazoncognito.com',
    scope: ["aws.cognito.signin.user.admin", "email","openid","profile","phone"],
    redirectSignIn: `${window.location.origin}/login`,
    // process.env.REACT_APP_CALLBACK_URL,
    // "http://localhost:3000/login",
    // ,
    redirectSignOut:`${window.location.origin}/login`,
    //  process.env.REACT_APP_SIGNOUT_URL,
    // "http://localhost:3000/login",
    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
};
export default awsConfigure;
