import { Col, Row, Form, Input, InputNumber } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAppContext } from "../../../store/contextStore";
import Advertisement from "../../../common/Advertisement";
class BuyerPrivateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      citySearch: [],
      buyerName: "",
      buyerPhone: "",
    };
  }

  onFinish = (e) => {
    this.props.context.setBuyerHomeDetails(e, "BUYER_PRIVATE_DETAILS");
    this.props.handleBuyerDetails(e);
  };

  handleSingleSearch = (values) => {
    let address = values.label.split(",");
    this.setState({
      citySearch: address,
    });
  };

  render() {
    const { buyerPhone } = this.state;
    // const { buyerHomeDetails } = this.props.context.state;
    const { buyerHomeDetails } = this.props;
    return (
      <div>
        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <div className="privateD-title">Private Details</div>
            <div style={{ marginBottom: 20 }}>
              <span className="privateD-des">
                Other agents can't see this info but if you want to use John Doe
                or Unknown you can
              </span>
            </div>
          </Col>
          <Col
            xl={{ span: 16, offset: 0 }}
            lg={{ span: 16, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Form
              id="form"
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              size="large"
            >
              <Row>
                <Col
                  xl={{ span: 11, offset: 0 }}
                  lg={{ span: 11, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <div className="form-titles">
                    First Name (Only you will see this)
                  </div>
                  <Form.Item
                    name="firstName"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPrivateDetails.firstName
                    }
                  >
                    <Input placeholder="First Name" className="form-input" />
                  </Form.Item>
                  <div className="form-titles">
                    Email (Only you will see this, required for client to
                    receive email alerts)
                  </div>
                  <Form.Item
                    name="email"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPrivateDetails.email
                    }
                  >
                    <Input placeholder="Email" className="form-input" />
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                >
                  <div className="form-titles">
                    Last Name (Only you will see this)
                  </div>
                  <Form.Item
                    name="lastName"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPrivateDetails.lastName
                    }
                  >
                    <Input placeholder="Last Name" className="form-input" />
                  </Form.Item>
                  <div className="form-titles">
                    Buyer Phone # (Only you will see this)
                  </div>
                  <Form.Item
                    name="phone"
                    initialValue={
                      buyerHomeDetails &&
                      buyerHomeDetails.buyerPrivateDetails.phone
                    }
                  >
                    <InputNumber
                      placeholder="Phone"
                      className="form-input-number"
                      value={buyerPhone}
                      formatter={(value) => {
                        const cleaned = `${value}`.replace(/\D/g, "");
                        const match = cleaned.match(
                          /^(1|)?(\d{3})(\d{3})(\d{4})$/
                        );
                        if (match) {
                          const intlCode = match[1] ? "+1 " : "";
                          return [
                            intlCode,
                            "(",
                            match[2],
                            ") ",
                            match[3],
                            "-",
                            match[4],
                          ].join("");
                        }
                        return null;
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            xl={{ span: 7, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
            md={{ span: 24, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
          >
            <Advertisement
              otherAds={false}
              adType="Square"
              adWhereToShow="Buyers"
              adLimit="1"
              zipCode=""
            />
          </Col>
        </Row>
      </div>
    );
  }
}
export default withAppContext(withRouter(BuyerPrivateDetails));
